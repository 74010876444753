import * as React from "react";
import { Color, PageRoute } from "../../../shared/types/enums";
import { List, Divider, Box, Typography, Avatar, Button } from "@mui/material";
import { useRecoilValue } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import useLogout from "../../../shared/hooks/auth/useLogout";
import { useNavigate } from "react-router-dom";
import { getImageUrl } from "../../../shared/utils/imageUtils";
import { TourSelector } from "../../../shared/types/tourEnums";
import MenuListItem from "./MenuListItem";
import logo from "../../../shared/assets/branding/white-horizontal-logo.png";
import StaffStudentToggleButton from "../../../shared/components/navigation/StaffStudentToggleButton";

const StudentSideDesktopNavbar = () => {
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const { logout } = useLogout();
  const navigate = useNavigate();
  const [avatarURL, setAvatarURL] = React.useState<string | null>(null);
  React.useEffect(() => {
    if (!loggedInStudent) return;
    const getURL = async () => {
      const url = await getImageUrl(loggedInStudent.avatar);
      setAvatarURL(url);
    };
    getURL();
  }, [loggedInStudent]);
  return (
    <Box
      sx={{
        height: "100VH",
        backgroundColor: "#13352E",
        color: Color.WHITE,
        p: 1.5,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        {loggedInStudent && (
          <>
            <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
              <Avatar
                alt={loggedInStudent.avatar}
                src={avatarURL ? avatarURL : ""}
                sx={{
                  ml: 1,
                  backgroundColor: "#F4F0DC",
                  color: "#00362E",
                  cursor: "pointer",
                }}
                data-tut={TourSelector.PROFILE}
                onClick={() => navigate(PageRoute.STUDENT_PROFILE)}
              />
              <Box>
                <Typography sx={{ color: "#fff", ml: 2, fontSize: 14 }}>
                  <b>
                    {loggedInStudent.firstName} {loggedInStudent.lastName}
                  </b>
                </Typography>
                <Typography sx={{ color: "#fff", ml: 2 }}>{loggedInStudent.gradeLevel}</Typography>
              </Box>
            </Box>
            <Divider sx={{ mt: 2, color: "#fff", borderColor: "rgba(255, 255, 255, .2)" }} />
            <List>
              {coreMenuItems.map((item) => (
                <MenuListItem key={item.label} item={item} />
              ))}
            </List>
            <Typography variant="body1" sx={{ mt: 2, ml: 1, color: "rgba(255, 255, 255, .6)" }}>
              <b>EXPLORE</b>
            </Typography>
            <List>
              {exploreMenuItems.map((item) => (
                <MenuListItem key={item.label} item={item} />
              ))}
            </List>
          </>
        )}
      </Box>
      <Box sx={{ mb: 5 }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={logo} alt="Willow" style={{ width: "90%" }} />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={logout} variant="text" color="white">
            LOG OUT
          </Button>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <StaffStudentToggleButton />
        </Box>
      </Box>
    </Box>
  );
};

export default StudentSideDesktopNavbar;

const coreMenuItems = [
  {
    label: "Feed",
    route: PageRoute.FEED,
    icon: "home",
    tourSelector: TourSelector.HOME_NAV,
  },
  {
    label: "Bookmarks",
    route: PageRoute.STUDENT_MY_LIST,
    icon: "bookmark",
    tourSelector: TourSelector.BOOKMARKS_NAV,
    subMenuItems: [
      { label: "Careers", subRoute: "bookmarkedCareers" },
      { label: "Programs", subRoute: "bookmarkedPrograms" },
      { label: "Videos", subRoute: "bookmarkedVideos" },
    ],
  },
  {
    label: "Profile",
    route: PageRoute.STUDENT_PROFILE,
    icon: "person",
    tourSelector: TourSelector.PROFILE,
  },
  {
    label: "Alma",
    route: PageRoute.STUDENT_ALMA,
    icon: "ai",
    tourSelector: TourSelector.ALMA_NAV,
  },
];

const exploreMenuItems = [
  {
    label: "Careers",
    route: PageRoute.EXPLORE_CAREERS,
    icon: "careers",
    tourSelector: TourSelector.CAREERS_NAV,
    subMenuItems: [
      { label: "Recommended", subRoute: "recommendedCareers" },
      { label: "All", subRoute: "allCareers" },
    ],
  },
  {
    label: "Programs",
    route: PageRoute.EXPLORE_PROGRAMS,
    icon: "programs",
    tourSelector: TourSelector.PROGRAMS_NAV,
    subMenuItems: [
      { label: "Recommended", subRoute: "recommendedPrograms" },
      { label: "All", subRoute: "allPrograms" },
    ],
  },
  {
    label: "Scholarships",
    route: PageRoute.STUDENT_EXPLORE_SCHOLARSHIPS,
    icon: "dollar-sign",
    tourSelector: TourSelector.SCHOLARSHIPS_NAV,
    subMenuItems: [
      { label: "Manual", subRoute: "manualScholarships" },
      { label: "All", subRoute: "allScholarships" },
    ],
  },
];
