import { Box, LinearProgress, Typography } from "@mui/material";
import { numberToPercent } from "../../utils/formatUtils";
import { Color } from "../../types/enums";

type StatWithProgressProps = {
  label: string;
  value?: number;
  range?: { min: number; max: number };
  variant: "progress" | "range";
};

const StatWithProgress = ({ label, value, range, variant }: StatWithProgressProps) => {
  const percentValue = value ? value * 100 : 0;
  const percentLabel = value ? numberToPercent({ number: value }) : "Unknown";

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Typography
        sx={{
          flex: "none",
          width: "150px",
          fontSize: 14,
          fontWeight: 700,
          color: Color.CHARCOAL,
        }}
      >
        {label}:
      </Typography>
      {variant === "progress" && (
        <Box sx={{ position: "relative", width: "100%" }}>
          <LinearProgress
            variant="determinate"
            value={percentValue}
            sx={{
              width: "100%",
              height: "28px",
              borderRadius: "4px",
              backgroundColor: Color.SOFT_GRAY,
              "& .MuiLinearProgress-bar": {
                background: Color.MINT,
                borderRadius: "4px",
              },
            }}
          />
          <Typography
            variant="body2"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: percentValue < 50 ? Color.DARK_TEAL : Color.WHITE,
              fontSize: 12,
              fontWeight: 700,
            }}
          >
            {percentLabel}
          </Typography>
        </Box>
      )}
      {variant === "range" && range && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "28px",
            borderRadius: "4px",
            backgroundColor: Color.SOFT_GRAY,
            position: "relative",
          }}
        >
          <Typography
            sx={{
              position: "absolute",
              left: 4,
              color: Color.DARK_SOFT_GRAY,
              fontWeight: 700,
            }}
          >
            1
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "100px",
              width: `${((range.max - range.min) / 36) * 100}%`,
              height: "100%",
              backgroundColor: Color.MINT,
              borderRadius: "4px",
              color: Color.WHITE,
              fontWeight: "bold",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: Color.GRAY_50,
                fontSize: 12,
                fontWeight: 700,
              }}
            >
              {range.min === 0 || range.max === 0 ? "Unknown" : `${range.min}-${range.max}`}
            </Typography>
          </Box>
          <Typography
            sx={{
              position: "absolute",
              right: 4,
              color: Color.DARK_SOFT_GRAY,
              fontWeight: 700,
            }}
          >
            36
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default StatWithProgress;
