import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import { Collection, UserType } from "../../../shared/types/enums";
import { useRecoilState, useSetRecoilState } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import useLogger from "../../../shared/hooks/logging/useLogger";
import { LogEventType } from "../../../shared/types/logEnums";
import { selectedStudentAtom } from "../../../staff/recoil/studentsAtoms";
import { personalityTypeAtom } from "../../recoil/personalityTypeAtoms";
import { PersonalityTypeRecord } from "../../types/types";
import useGetFSDoc from "../db/useGetFSDoc";
import { parseSinglePersonalityTypeRecord } from "../../utils/parserUtils";

type Props = {
  userType: UserType;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setUnlockMatchesOpen?: (open: boolean) => void;
};

const useQuizResultsDialog = ({ userType, setOpen, setUnlockMatchesOpen }: Props) => {
  const dialogContentRef = useRef<HTMLDivElement>(null);
  const [student, setStudent] = useRecoilState(
    userType === UserType.STUDENT ? loggedInStudentAtom : selectedStudentAtom,
  );
  const setPersonalityType = useSetRecoilState(personalityTypeAtom);
  const { getFSDoc } = useGetFSDoc();
  const { updateFSDoc } = useUpdateFSDoc();
  const { submitLog } = useLogger();

  useEffect(() => {
    const getPersonalityType = async () => {
      if (student?.personalityType && userType !== UserType.STUDENT) {
        const personalityType = await getFSDoc<PersonalityTypeRecord>({
          col: Collection.PERSONALITY_TYPE,
          id: student.personalityType,
        });
        if (personalityType)
          setPersonalityType(personalityType ? parseSinglePersonalityTypeRecord(personalityType) : null);
      }
    };
    getPersonalityType();
  }, [student, userType, getFSDoc, setPersonalityType]);

  const handleClose = async () => {
    if (!student) return;
    if (!student.hasViewedPersonalityType && userType === UserType.STUDENT) {
      try {
        await updateFSDoc({
          col: Collection.STUDENTS,
          id: student.id,
          data: { hasViewedPersonalityType: true },
        });
        setStudent((prev) => (prev ? { ...prev, hasViewedPersonalityType: true } : null));
        submitLog({
          eventType: LogEventType.USER_PERSONALITY_TYPE_VIEWED,
          file: "useQuizResultsDialog.ts",
        });
        if (setUnlockMatchesOpen) setUnlockMatchesOpen(true);
      } catch (error) {
        submitLog({
          error,
          snackbarMessage: `There was an error viewing the personality type, please refresh and try again.`,
          eventType: LogEventType.USER_PERSONALITY_TYPE_VIEW_ERROR,
          file: "useQuizResultsDialog.ts",
        });
      }
    }
    setOpen(false);
  };

  return {
    dialogContentRef,
    student,
    handleClose,
  };
};

export default useQuizResultsDialog;
