import { useCallback } from "react";
import { collection, getDocs, where, orderBy, limit, query, WhereFilterOp, OrderByDirection } from "firebase/firestore";
import { db, nationalDB } from "../../../firebase";

interface SendRequestProps {
  col: string;
  config?: {
    where?: [string, WhereFilterOp, any];
    orderBy?: [string, OrderByDirection?];
    limit?: number;
  };
  national?: boolean;
}

type DBRecord = {
  id: string;
};

type GetDocsType = {
  getFSDocs: <T extends DBRecord>(requestProps: SendRequestProps) => Promise<T[]>;
};

const useGetFSDocs = (): GetDocsType => {
  const getFSDocs = useCallback(
    async <T extends DBRecord>({ col, config, national = false }: SendRequestProps): Promise<T[]> => {
      const conditions: any = [where("active", "==", true)];
      if (config?.where) {
        conditions.push(where(config.where[0], config.where[1], config.where[2]));
      }
      if (config?.orderBy) {
        conditions.push(orderBy(config.orderBy[0], config.orderBy[1] ? config.orderBy[1] : "asc"));
      }
      if (config?.limit) {
        conditions.push(limit(config.limit));
      }
      const docRef = collection(national ? nationalDB : db, col);
      let snapshot;
      if (conditions.length > 0) {
        snapshot = await getDocs(query(docRef, ...conditions));
      } else {
        snapshot = await getDocs(docRef);
      }
      const tempArray: T[] = [];
      if (snapshot) {
        snapshot.forEach((s) => {
          tempArray.push({ ...s.data(), id: s.id } as DBRecord as T);
        });
      }

      return tempArray;
    },
    [],
  );
  return { getFSDocs };
};

export default useGetFSDocs;
