import React from "react";
import { Box, Button, Typography } from "@mui/material";

interface CareerPlanReminderProps {
  text: string;
}

const CareerPlanMessage: React.FC<CareerPlanReminderProps> = ({ text }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#EBF8FF",
        borderRadius: 2,
        borderLeft: "8px solid #B8E5FF",
        padding: 2,
        display: "flex",
        gap: 1,
        position: "relative",
        mt: 1,
      }}
    >
      <Box>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          Keep in mind your career plan!
        </Typography>
        <Typography variant="body2">{text}</Typography>
      </Box>
      <Button
        variant="text"
        sx={{
          textTransform: "none",
          color: "#00362E",
        }}
      >
        Edit
      </Button>
    </Box>
  );
};

export default CareerPlanMessage;
