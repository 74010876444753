import { useMemo } from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-premium";
import { StudentRecord } from "../../../shared/types/types";

type Props = {
  studentsInGroup: StudentRecord[];
  renderDeleteButton: (params: GridRenderCellParams) => JSX.Element;
};

const useStudentsInGroupTable = ({ studentsInGroup, renderDeleteButton }: Props) => {
  const rows = useMemo(
    () =>
      studentsInGroup.map((student) => ({
        id: student.id,
        fullName: `${student.firstName} ${student.lastName}`,
        email: student.email || "No email provided",
      })),
    [studentsInGroup],
  );

  const columns = useMemo(
    () => [
      {
        field: "fullName",
        headerName: "Student Name",
        flex: 1,
        headerClassName: "tableHeader",
        cellClassName: "hoverPointer",
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
        headerClassName: "tableHeader",
        cellClassName: "hoverPointer",
      },
      {
        field: "delete",
        sortable: false,
        headerName: "Remove",
        headerClassName: "tableHeader",
        cellClassName: "hoverPointer",
        renderCell: renderDeleteButton,
      },
    ],
    [renderDeleteButton],
  );
  return { rows, columns };
};

export default useStudentsInGroupTable;
