import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { ProviderProgramRecordTwo } from "../../types/types";
import { Color } from "../../types/enums";
import FeatherIcon from "../../assets/icons/featherIcons/FeatherIcon";
import { formatCurrency } from "../../utils/formatUtils";

type Props = {
  programs: ProviderProgramRecordTwo[];
  setSelectedLearnMoreProgram: Dispatch<SetStateAction<ProviderProgramRecordTwo | null>>;
};

interface ProgramTableRow {
  name: string;
  roi: string;
  institution?: boolean;
  program: ProviderProgramRecordTwo;
}

const ProgramList = ({ programs, setSelectedLearnMoreProgram }: Props) => {
  const [page, setPage] = useState<number>(0);
  const [rows, setRows] = useState<ProgramTableRow[]>([]);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | "">("");
  const [sortedBy, setSortedBy] = useState<"name" | "roi" | "">("");
  const rowsPerPage = 5;

  useEffect(() => {
    setRows(
      programs.map((program) => ({
        name: program.programName ?? "",
        roi: program.willowROICalculated ? formatCurrency({ amount: program.willowROICalculated }) : "Unknown",
        institution: program.programEarningsCalculated ? false : true,
        program,
      })),
    );
  }, [programs]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleSort = (column: "name" | "roi") => {
    const isSameColumn = sortedBy === column;

    // Cycle through states: original -> asc -> desc
    let newSortOrder: "asc" | "desc" | "";
    let newColumn: "name" | "roi" | "" = column;

    if (isSameColumn) {
      if (sortOrder === "") {
        newSortOrder = "asc";
      } else if (sortOrder === "asc") {
        newSortOrder = "desc";
      } else if (sortOrder === "desc") {
        newSortOrder = "";
        newColumn = "";
      } else {
        newSortOrder = "";
        newColumn = "";
      }
    } else {
      newSortOrder = "asc";
    }

    setSortOrder(newSortOrder);
    setSortedBy(newColumn);

    if (newSortOrder === "") {
      setRows(
        programs.map((program) => ({
          name: program.programName ?? "",
          roi: program.willowROICalculated ? formatCurrency({ amount: program.willowROICalculated }) : "Unknown",
          institution: program.programEarningsCalculated ? false : true,
          program,
        })),
      );
    } else {
      const sortedRows = [...rows].sort((a, b) => {
        const valueA = column === "name" ? a.name : a.roi;
        const valueB = column === "name" ? b.name : b.roi;

        if (newSortOrder === "asc") {
          return valueA.localeCompare(valueB, undefined, { numeric: true });
        } else {
          return valueB.localeCompare(valueA, undefined, { numeric: true });
        }
      });

      setRows(sortedRows);
    }
  };

  return (
    <Box
      sx={{
        mt: 3,
        display: "flex",
        flexDirection: "column",
        gap: 1.5,
        p: 2,
        background: Color.WHITE,
        borderRadius: "var(--Number-Scale-2s-12, 12px)",
        boxShadow: "0px 8px 24px -3px rgba(16, 24, 40, 0.10), 0px 8px 24px -3px rgba(16, 24, 40, 0.05)",
      }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 0.5,
                    cursor: "pointer",
                  }}
                  onClick={() => handleSort("name")}
                >
                  <Typography fontWeight="bold" color={Color.CHARCOAL}>
                    Program name
                  </Typography>
                  {sortedBy === "name" && (
                    <FeatherIcon
                      width="20px"
                      height="20px"
                      viewBox="0 0 20 20"
                      icon={sortOrder === "asc" ? "arrow-up" : "arrow-down"}
                      strokeColor="none"
                      fillColor={Color.CHALKBOARD}
                    />
                  )}
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 0.5,
                    cursor: "pointer",
                  }}
                  onClick={() => handleSort("roi")}
                >
                  <Typography fontWeight="bold" color={Color.CHARCOAL}>
                    Program ROI
                  </Typography>
                  {sortedBy === "roi" && (
                    <FeatherIcon
                      width="20px"
                      height="20px"
                      viewBox="0 0 20 20"
                      icon={sortOrder === "asc" ? "arrow-up" : "arrow-down"}
                      strokeColor="none"
                      fillColor={Color.CHALKBOARD}
                    />
                  )}
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow
                key={index}
                hover
                sx={{ cursor: "pointer" }}
                onClick={() => setSelectedLearnMoreProgram(row.program)}
              >
                <TableCell color={Color.CHARCOAL}>{row.name}</TableCell>
                <TableCell color={Color.CHARCOAL}>
                  <Typography fontWeight={row.institution ? "normal" : "bold"}>{row.roi}</Typography>
                  {row.institution && (
                    <Typography variant="caption" color="textSecondary">
                      (Institution)
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          labelDisplayedRows={({ from, to, count }) => (
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "12px",
                lineHeight: "18px",
                color: Color.CHARCOAL,
              }}
            >
              {`${from}-${to} of ${count}`}
            </Typography>
          )}
        />
      </TableContainer>
    </Box>
  );
};

export default ProgramList;
