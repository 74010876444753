import { useCallback, useEffect, useState } from "react";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import useIntersectionObserver from "../../../shared/hooks/videoListPlayer/useIntersectionObserver";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useMyListContext } from "../../contexts/myListContext";
import Empty from "./Empty";
import { CareerVideoRecord, StudentRecord } from "../../../shared/types/types";
import JobDetailsDialog from "../../../shared/components/exploreCareers/common/jobs/JobDetailsDialog";
import { useRecoilState } from "recoil";
import { selectedJobIdAtom } from "../../../shared/recoil/exploreCareersAtoms";
import VideoPlayer from "../../../shared/components/video/VideoPlayer";

type Props = {
  loggedInStudent: StudentRecord;
};

const Videos = ({ loggedInStudent }: Props) => {
  const [currentlyPlayingVideo, setCurrentlyPlayingVideo] = useState<string | null>(null);
  const [globalMute, setGlobalMute] = useState(true);
  const [remountKey, setRemountKey] = useState(0);
  const { width } = useWindowDimensions();
  const [selectedJobId, setSelectedJobId] = useRecoilState(selectedJobIdAtom);
  const mobile = width < 900;

  useEffect(() => {
    setRemountKey((prev) => prev + 1);
  }, []);

  const { likedVideos, interactions, loading } = useMyListContext();

  const pauseOtherVideos = useCallback((currentSrc: string | null) => {
    const videoElements = document.querySelectorAll("video");
    videoElements.forEach((video) => {
      if (video.src !== currentSrc) {
        video.pause();
      }
    });
  }, []);
  const { observe, unobserve } = useIntersectionObserver(setCurrentlyPlayingVideo, pauseOtherVideos, globalMute);

  const handleLearnMoreClick = (video: CareerVideoRecord) => {
    setSelectedJobId(video.onet);
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {!mobile && (
        <Typography sx={{ mt: 1, p: 1 }} variant="pageTitle">
          Bookmarked Videos
        </Typography>
      )}
      <Box
        sx={{
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          height: mobile ? "calc(100vh - 120px)" : "calc(100vh - 60px)",
        }}
      >
        <Box
          sx={{
            p: 1,
            display: "flex",
            flexDirection: "column",
            width: mobile ? "100%" : "calc(80px + 80VH*9/16)",
          }}
          key={remountKey}
        >
          {likedVideos.length === 0 && !loading && <Empty />}
          <>
            {!loading ? (
              likedVideos.map((video, index) => (
                <Box key={video.fileName} sx={{ p: 2 }}>
                  {video.url && (
                    <VideoPlayer
                      mobile={mobile}
                      url={video.url}
                      pauseOtherVideos={pauseOtherVideos}
                      studentId={loggedInStudent.id}
                      video={likedVideos[index]}
                      globalMute={globalMute}
                      setGlobalMute={setGlobalMute}
                      interaction={
                        interactions.find((interaction) => interaction.videoId === likedVideos[index].id) ?? null
                      }
                      currentlyPlayingVideo={currentlyPlayingVideo}
                      setCurrentlyPlayingVideo={setCurrentlyPlayingVideo}
                      observe={observe}
                      unobserve={unobserve}
                      handleLearnMoreClick={handleLearnMoreClick}
                    />
                  )}
                </Box>
              ))
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50VH",
                }}
              >
                <CircularProgress size={80} />
              </Box>
            )}
          </>
        </Box>
        {selectedJobId && <JobDetailsDialog />}
      </Box>
    </Box>
  );
};

export default Videos;
