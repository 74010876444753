import { useRecoilState, useSetRecoilState } from "recoil";
import { auth } from "../../../firebase";
import { isDemoUserAtom, loggedInStudentAtom, ownEFCDataAtom, userTypeAtom } from "../../recoil/userAtoms";
import { stepsAtom, tourNameAtom } from "../../recoil/tourAtoms";
import { studentAlmaChatsAtom } from "../../recoil/almaAtoms";

const useLogout = () => {
  const setLoggedInStudent = useSetRecoilState(loggedInStudentAtom);
  const setOwnEFCData = useSetRecoilState(ownEFCDataAtom);
  const setUserType = useSetRecoilState(userTypeAtom);
  const setTourName = useSetRecoilState(tourNameAtom);
  const setTourSteps = useSetRecoilState(stepsAtom);
  const setStudentAlmaChats = useSetRecoilState(studentAlmaChatsAtom);
  const [isDemoUser, setIsDemoUser] = useRecoilState(isDemoUserAtom);

  const logout = () => {
    setLoggedInStudent(null);
    setOwnEFCData(null);
    setUserType(null);
    setTourName(null);
    setStudentAlmaChats([]);
    setTourSteps([]);
    if (isDemoUser) {
      setIsDemoUser(false);
    }
    return auth.signOut();
  };

  return { logout };
};

export default useLogout;
