import { useRecoilValue } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { ProviderProgramRecordTwo } from "../../../shared/types/types";

const useAutoAdmit = () => {
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const availableShowMessage = (program: ProviderProgramRecordTwo) => {
    if (loggedInStudent) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const isAfterJuly1st = currentDate.getMonth() >= 6;

      if (!loggedInStudent.graduationYear || loggedInStudent.graduationYear === currentYear.toString()) {
        return Boolean(program.autoAdmit) && !isAfterJuly1st;
      } else if (!loggedInStudent.graduationYear || loggedInStudent.graduationYear === (currentYear + 1).toString()) {
        return Boolean(program.autoAdmit) && isAfterJuly1st;
      }
    } else {
      return false;
    }
  };
  return { availableShowMessage };
};

export default useAutoAdmit;
