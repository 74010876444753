import { useRef } from "react";
import ChatDisplay from "./ChatDisplay";
import ChatInput from "./ChatInput";
import { Accordion, AccordionDetails, AccordionSummary, Box } from "@mui/material";
import ChatSidebar from "./ChatSidebar";
import useChatContainer from "../../hooks/alma/useChatContainer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useResetChat from "../../hooks/alma/useResetChat";
import useSubmitChat from "../../hooks/alma/useSubmitChat";
import { AlmaChatRecord, SchoolRecord, StudentRecord } from "../../../shared/types/types";
import { Player } from "@lottiefiles/react-lottie-player";
const disableLottie = process.env.REACT_APP_DISABLE_LOTTIE === "true";

type Props = {
  loggedInStudent: StudentRecord;
  schools: SchoolRecord[];
  currentStudentAlmaChat: AlmaChatRecord | null;
};

const StudentAlmaMobile = ({ loggedInStudent, schools, currentStudentAlmaChat }: Props) => {
  const lottieRef = useRef<any>(null);
  const { chatHistory, setChatHistory, initialChatContext } = useChatContainer({
    loggedInStudent,
    schools,
    currentStudentAlmaChat,
  });
  const { resetChat } = useResetChat();
  const { handleSubmit, isLoading, message, setMessage } = useSubmitChat({
    chatHistory,
    setChatHistory,
    initialChatContext,
  });

  return (
    <>
      <Accordion sx={{ m: 0, p: 0 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="starter questions" id="starter-questions">
          Prompts To Get Started
        </AccordionSummary>
        <AccordionDetails sx={{ m: 0, p: 0 }}>
          <ChatSidebar handleSubmit={handleSubmit} resetChat={resetChat} />
        </AccordionDetails>
      </Accordion>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "calc(90VH - 80px)",
          justifyContent: "space-between",
        }}
      >
        <ChatDisplay chatHistory={chatHistory} />
        <Box>
          <Box sx={{ display: "flex", justifyContent: "end", mr: 2 }}>
            <Box
              style={{
                display: isLoading ? "block" : "none",
                width: "60px",
              }}
            >
              {!disableLottie && (
                <Player
                  ref={lottieRef}
                  loop={true}
                  autoplay={true}
                  src="https://lottie.host/ae406bb9-3048-4976-a031-9675b58e0149/rVjOgw3dRk.json"
                ></Player>
              )}
            </Box>
          </Box>
          <ChatInput onSubmit={handleSubmit} message={message} setMessage={setMessage} />
        </Box>
      </Box>
    </>
  );
};

export default StudentAlmaMobile;
