import { Box, Chip, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { Collection, Color } from "../../../shared/types/enums";
import { formatPhoneNumber } from "../../../shared/utils/formatUtils";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import CustomAvatar from "../../../shared/components/avatar/CustomAvatar";
import EditStudentCardDialog from "./EditStudentCardDialog";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";

const StudentCard = () => {
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const [open, setOpen] = useState(false);
  const { width } = useWindowDimensions();
  const isMobile = width < 900;

  return (
    <>
      {loggedInStudent && (
        <>
          <Box
            sx={{
              backgroundColor: Color.CHALKBOARD,
              p: 3,
              borderRadius: 3,
              display: isMobile ? "block" : "flex",
              alignItems: "start",
              gap: 3,
              position: "relative",
              textAlign: isMobile ? "center" : "left",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CustomAvatar
                size={100}
                fileName={loggedInStudent?.avatar ?? ""}
                collection={Collection.STUDENTS}
                documentId={loggedInStudent.id}
                url={loggedInStudent.avatarURL}
                edit={true}
              />
            </Box>
            <Box>
              <Typography sx={{ color: Color.CHALK }} variant="h4">
                {`${loggedInStudent.firstName} ${loggedInStudent.lastName}`}
              </Typography>
              <Chip label={loggedInStudent.gradeLevel} color="warning" size="small" sx={{ mt: 1 }} />
              <Box
                sx={{
                  display: "flex",
                  verticalAlign: "center",
                  alignItems: "center",
                  justifyContent: isMobile ? "center" : "left",
                  gap: "4px",
                  color: Color.CHALK,
                  mt: 2,
                }}
              >
                <FeatherIcon width="20px" icon="location" />
                <Typography sx={{ color: Color.CHALK }} variant="body1">
                  {loggedInStudent.address.address}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  verticalAlign: "center",
                  alignItems: "center",
                  justifyContent: isMobile ? "center" : "left",
                  gap: "4px",
                  color: Color.CHALK,
                  mt: 1,
                }}
              >
                <FeatherIcon width="20px" icon="phone" />
                <Typography sx={{ color: Color.CHALK }} variant="body1">
                  {formatPhoneNumber({ phoneNumber: loggedInStudent.phone })}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  verticalAlign: "center",
                  alignItems: "center",
                  justifyContent: isMobile ? "center" : "left",
                  gap: "4px",
                  color: Color.CHALK,
                  mt: 1,
                }}
              >
                <FeatherIcon width="20px" height="24px" icon="mail" />
                <Typography sx={{ color: Color.CHALK }} variant="body1">
                  {loggedInStudent.email}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ position: "absolute", right: 14, top: 16 }}>
              <IconButton onClick={() => setOpen(true)} sx={{ color: Color.STANDARD_GRAY }}>
                <FeatherIcon width="24px" icon="edit" />
              </IconButton>
            </Box>
          </Box>
          <EditStudentCardDialog open={open} setOpen={setOpen} />
        </>
      )}
    </>
  );
};

export default StudentCard;
