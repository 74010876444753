import * as React from "react";
import { Color } from "../../../shared/types/enums";
import { Box, IconButton, ListItemText, ListItemButton, ListItem, List, Divider } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom";
import logo from "../../../shared/assets/branding/inline-chalk.svg";
import Drawer from "../../../shared/components/navigation/Drawer";
import SidebarHeader from "../../../shared/components/navigation/SidebarHeader";
import StaffSideMenu from "./StaffSideMenu";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const StaffSideDesktopNavbar = ({ open, setOpen }: Props) => {
  const navigate = useNavigate();

  const handleDrawerOpen = React.useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleDrawerClose = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      <Drawer variant="permanent" open={open} color="primary" sx={{ zIndex: 999 }}>
        <SidebarHeader>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              aria-label="Home"
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => navigate("/")}
            >
              <Box sx={{ marginRight: open ? 3 : "auto", mt: 1 }}>
                <img width="24px" src={logo} alt="Willow" height="24px" />
              </Box>

              <ListItemText primary="Willow" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </SidebarHeader>
        <Divider />
        <List
          sx={{
            height: "100VH",
          }}
        >
          <ListItem aria-label="Open/Close Menu" sx={{ display: "block" }}>
            {open ? (
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon
                  sx={{
                    justifyContent: "center",
                    color: Color.CHALK,
                  }}
                  aria-label="Close Drawer"
                />
              </IconButton>
            ) : (
              <ListItemButton
                onClick={handleDrawerOpen}
                sx={{
                  justifyContent: "center",
                }}
                aria-label="Open Drawer"
              >
                <ChevronRightIcon />
              </ListItemButton>
            )}
          </ListItem>
          <StaffSideMenu open={open} />
        </List>
      </Drawer>
    </>
  );
};

export default StaffSideDesktopNavbar;
