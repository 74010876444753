import { Box } from "@mui/material";
// import { profileSteps, navigationSteps } from "../static/tourData";
// import WillowTourProvider from "../../shared/providers/WillowTourProvider";
// import { TourName } from "../../shared/types/tourEnums";
// import { loggedInStudentAtom } from "../../shared/recoil/userAtoms";
// import { useRecoilValue } from "recoil";
// import { useEffect, useState } from "react";
import TakeQuizContainer from "../components/quiz/TakeQuizContainer";
import { QuizProvider } from "../providers/QuizProvider";

const StudentTakeQuizPage = () => {
  // const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  // const [steps, setSteps] = useState<{ selector: string; content: string }[]>([]);
  // const [tourName, setTourName] = useState(TourName.PROFILE);
  // //There are currently two different tours that happen on the profile page, one for the top nav and one for bottom. Currently, this is my best solution for navigating two tours on one page that can't get combined into one tour because some user action needs to happen before the second tour loads
  // useEffect(() => {
  //   if (!loggedInStudent) return;
  //   setSteps(loggedInStudent.setupComplete ? navigationSteps : profileSteps);
  //   setTourName(loggedInStudent.setupComplete ? TourName.NAVIGATION : TourName.PROFILE);
  // }, [loggedInStudent]);
  return (
    <>
      {/* <WillowTourProvider steps={steps} tour={tourName}> */}
      <QuizProvider>
        <Box sx={{ height: "calc(100VH - 64px)" }}>
          <TakeQuizContainer />
        </Box>
      </QuizProvider>
      {/* </WillowTourProvider> */}
    </>
  );
};

export default StudentTakeQuizPage;
