import { Box, Checkbox, FormControl, FormControlLabel, FormLabel, Slider } from "@mui/material";
import { useState } from "react";
import { Color } from "../../../shared/types/enums";
import { FeedFilterProps } from "../../../shared/types/types";

type Props = {
  filters: FeedFilterProps;
  setFilters: React.Dispatch<React.SetStateAction<FeedFilterProps>>;
};

// Define the income buckets

const FeedFilters = ({ filters, setFilters }: Props) => {
  // Define the income buckets
  const incomeBuckets = [0, 60000, 120000, 180000];

  // Local state for slider value
  const [sliderValue, setSliderValue] = useState<[number, number]>([
    incomeBuckets.indexOf(filters.medianIncome[0]),
    incomeBuckets.indexOf(filters.medianIncome[1]),
  ]);

  // Ensure the slider values have a minimum difference of 1
  const enforceMinimumDifference = (value: [number, number]): [number, number] => {
    if (value[0] === value[1]) {
      if (value[1] < incomeBuckets.length - 1) {
        value[1] += 1; // Increment high if possible
      } else if (value[0] > 0) {
        value[0] -= 1; // Decrement low if possible
      }
    }
    return value;
  };

  // Update slider value as the user drags
  const handleIncomeChange = (_: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") return;
    setSliderValue(enforceMinimumDifference([newValue[0], newValue[1]]));
  };

  // Commit the slider value to filters on release
  const handleIncomeChangeCommitted = (_: Event | React.SyntheticEvent, newValue: number | number[]) => {
    if (typeof newValue === "number") return;

    const adjustedValue = enforceMinimumDifference([newValue[0], newValue[1]]);
    const newCost: [number, number] = [incomeBuckets[adjustedValue[0]], incomeBuckets[adjustedValue[1]]];

    setFilters((pV) => ({
      ...pV,
      medianIncome: newCost,
    }));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFilters((pV) => ({
      ...pV,
      [name]: checked,
    }));
  };

  // Format numbers as "50k", "75k", etc.
  const formatToK = (value: number) => (value === 0 ? "0" : value === 180000 ? "180k+" : `${value / 1000}k`);

  return (
    <Box
      sx={{
        backgroundColor: Color.WHITE,
        borderRadius: 3,
        boxShadow: "0px 2px 8px -1px #10182814",
        cursor: "pointer",
        border: "1px solid #E0E0E0",
        padding: 2,
      }}
    >
      <FormControlLabel
        control={<Checkbox checked={filters.brightOutlook} name="brightOutlook" onChange={handleCheckboxChange} />}
        label="Just Careers Going Up In Demand"
      />
      <FormControlLabel
        control={<Checkbox checked={filters.greenEconomy} name="greenEconomy" onChange={handleCheckboxChange} />}
        label="Just Green Careers"
      />
      <FormControlLabel
        control={<Checkbox checked={filters.careerOutOfHS} name="careerOutOfHS" onChange={handleCheckboxChange} />}
        label="Just Careers Right Out Of High School"
      />
      <Box sx={{ p: 1 }}>
        <FormControl fullWidth>
          <FormLabel component="legend">National Median Income</FormLabel>
          <Slider
            value={sliderValue} // Use local state for real-time feedback
            onChange={handleIncomeChange} // Update local state on drag
            onChangeCommitted={handleIncomeChangeCommitted} // Update filters on release
            min={0}
            max={incomeBuckets.length - 1}
            step={1} // Ensure discrete steps for each bucket
            marks={incomeBuckets.map((value, index) => ({
              value: index,
              label: formatToK(value), // Use shorthand format
            }))}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => formatToK(incomeBuckets[value])} // Format value label
          />
        </FormControl>
      </Box>
    </Box>
  );
};

export default FeedFilters;
