import { StudentRecord } from "../../../shared/types/types";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import FeedDesktop from "./FeedDesktop";
import FeedMobile from "./FeedMobile";

type Props = {
  loggedInStudent: StudentRecord;
};

const FeedContainer = ({ loggedInStudent }: Props) => {
  const { width } = useWindowDimensions();
  const isMobile = width < 900;

  return (
    <>
      {isMobile ? <FeedMobile loggedInStudent={loggedInStudent} /> : <FeedDesktop loggedInStudent={loggedInStudent} />}
    </>
  );
};

export default FeedContainer;
