import React from "react";
import { Box, Checkbox, Divider, Typography } from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { MilestoneRecord } from "../../../shared/types/types";

type Props = {
  milestone: MilestoneRecord;
  studentId: string;
};

const MilestoneCard = ({ milestone }: Props) => {
  const formatDueDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const formatDateColor = (dateString: string, completed: boolean) => {
    const date = new Date(dateString);
    const today = new Date();
    date.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    if (date < today && !completed) {
      return "#f00";
    }
    return "#555";
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mb: 1, px: 1, ml: 5, mt: 1 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            checked={milestone.completed}
            icon={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  border: "1px solid #ccc",
                  width: 20,
                  height: 20,
                }}
              >
                <RadioButtonUncheckedIcon sx={{ color: "transparent" }} />
              </Box>
            }
            checkedIcon={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  border: "1px solid #777",
                  width: 20,
                  height: 20,
                  backgroundColor: "#fff",
                }}
              >
                <CheckCircleIcon sx={{ color: "#777" }} />
              </Box>
            }
          />

          <Box>
            <Typography
              variant="body1"
              sx={{
                flexGrow: 1,
                marginLeft: "8px",
                color: milestone.completed ? "#777" : "#333",
                textDecoration: milestone.completed ? "line-through" : "none",
              }}
            >
              {milestone.milestone}
            </Typography>
            <Box display="flex" alignItems="center" sx={{ mt: 0.5, ml: 1 }}>
              <CalendarTodayIcon
                sx={{ fontSize: 16, color: formatDateColor(milestone.dueDate, milestone.completed), mr: 0.5 }}
              />
              <Typography variant="body2" sx={{ color: formatDateColor(milestone.dueDate, milestone.completed) }}>
                {formatDueDate(milestone.dueDate)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default MilestoneCard;
