import { useState, useEffect, useCallback, useRef } from "react";
import {
  User,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithPopup,
} from "firebase/auth";
import { auth, provider as googleAuthProvider } from "../../firebase";
import { AuthContext } from "../contexts/AuthContext";
import useLogout from "../hooks/auth/useLogout";
import { generateAuthError } from "../utils/authUtils";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authLoadingAtom } from "../recoil/loadingAtoms";
import { LogEventType } from "../types/logEnums";
import useLogger from "../hooks/logging/useLogger";
import { isDemoUserAtom } from "../recoil/userAtoms";

type EmailPasswordProps = { email: string; password: string };

type Props = {
  children: JSX.Element;
};

const AuthProvider = ({ children }: Props) => {
  const { logout } = useLogout();
  const [loginError, setLoginError] = useState<string | null>(null);
  const [currentAuthUser, setCurrentAuthUser] = useState<User | null>(null);
  const setAuthLoading = useSetRecoilState(authLoadingAtom);
  const setIsDemoUser = useSetRecoilState(isDemoUserAtom);
  const { submitLog } = useLogger();
  const isCreatingDemoUser = useRef(false);

  useEffect(() => {
    if (
      currentAuthUser?.email!.includes("demo-student-user") ||
      currentAuthUser?.email!.includes("demo-staff@willowed.org")
    ) {
      setIsDemoUser(true);
    }
  }, [currentAuthUser, setIsDemoUser]);

  const createEmailUser = useCallback(
    async ({ email, password }: EmailPasswordProps) => {
      if (email.includes("demo-student-user")) {
        isCreatingDemoUser.current = true;
      }

      try {
        await createUserWithEmailAndPassword(auth, email, password);
        submitLog({
          eventType: LogEventType.EMAIL_USER_CREATED,
          file: "AuthProvider.tsx",
        });
      } catch (error) {
        submitLog({
          error: error instanceof Error ? error : new Error("Error creating email user"),
          snackbarMessage: "Error creating email user",
          eventType: LogEventType.ERROR_CREATING_EMAIL_USER,
          file: "AuthProvider.tsx",
        });
      }
    },
    [submitLog],
  );

  const resetPassword = useCallback(
    async (email: string) => {
      setLoginError(null);
      try {
        await sendPasswordResetEmail(auth, email);
        submitLog({
          eventType: LogEventType.PASSWORD_SUCCSESSFULLY_RESET,
          file: "AuthProvider.tsx",
        });
      } catch (error: any) {
        submitLog({
          error: error instanceof Error ? error : new Error("Error resetting password"),
          snackbarMessage: "Error resetting your password. Please try again.",
          eventType: LogEventType.ERROR_RESETTING_PASSWORD,
          file: "AuthProvider.tsx",
        });
        return null;
      }
    },
    [submitLog],
  );

  const emailSignIn = useCallback(
    async ({ email, password }: EmailPasswordProps) => {
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
      if (!isValidEmail) {
        setLoginError("Please enter a valid email address and try again.");
        return;
      }
      if (email.includes("demo-staff@willowed.org")) {
        isCreatingDemoUser.current = true;
      }
      try {
        setAuthLoading(true);
        const results = await signInWithEmailAndPassword(auth, email, password);
        submitLog({
          eventType: LogEventType.USER_EMAIL_SIGNED_IN,
          file: "AuthProvider.tsx",
        });
        setAuthLoading(false);
        return results;
      } catch (error: any) {
        const message = generateAuthError(error.code);
        setLoginError(message);
        submitLog({
          error: error instanceof Error ? error : new Error("Error signing in via email"),
          eventType: LogEventType.USER_EMAIL_SIGN_IN_ERROR,
          file: "AuthProvider.tsx",
        });
        setAuthLoading(false);
        return null;
      }
    },
    [setAuthLoading, submitLog],
  );

  const signInWithGoogle = useCallback(async () => {
    setAuthLoading(true);
    try {
      const { user } = await signInWithPopup(auth, googleAuthProvider);
      if (!user) {
        logout();
      }
      submitLog({
        eventType: LogEventType.USER_GOOGLE_SIGNED_IN,
        file: "AuthProvider.tsx",
      });
    } catch (error: any) {}
    setAuthLoading(false);
  }, [logout, setAuthLoading, submitLog]);

  const signInWithClever = useCallback(() => {
    setAuthLoading(true);

    const cleverAuthUrl = new URL("https://clever.com/oauth/authorize");

    cleverAuthUrl.searchParams.set("client_id", String(process.env.REACT_APP_CLEVER_CLIENT_ID));
    cleverAuthUrl.searchParams.set("redirect_uri", String(process.env.REACT_APP_CLEVER_REDIRECT_URI));
    cleverAuthUrl.searchParams.set("response_type", "code");
    window.location.href = cleverAuthUrl.href;
    setAuthLoading(false);
  }, [setAuthLoading]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user: User | null) => {
      if (!user) {
        if (!isCreatingDemoUser.current) {
          logout();
        }
        setCurrentAuthUser(null);
        setAuthLoading(false);
        isCreatingDemoUser.current = false;
        return;
      }
      setCurrentAuthUser(user);
    });

    return unsubscribe;
  }, [logout, setAuthLoading]);

  useEffect(() => {
    if (currentAuthUser) {
      setAuthLoading(false);
    }
  }, [currentAuthUser, setAuthLoading]);

  const value = {
    currentAuthUser,
    signInWithGoogle,
    signInWithClever,
    logout,
    createEmailUser,
    loginError,
    emailSignIn,
    resetPassword,
    setLoginError,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
