import React from "react";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, TextField, Typography } from "@mui/material";
import useMessageStudentsDialog from "../../hooks/messages/useMessageStudentsDialog";
import { StudentRecord } from "../../../shared/types/types";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  students: StudentRecord[];
};

const MessageStudentsDialog = ({ open, setOpen, students }: Props) => {
  const { studentNamesWithBadNumbers, message, handleTextChange, handleClose, handleSend } = useMessageStudentsDialog({
    setOpen,
    students,
  });

  return (
    <>
      {students && (
        <Dialog open={open} fullWidth maxWidth="sm">
          <DialogContent>
            <Box>
              <Typography variant="h3">{`Message ${students.length} Students`}</Typography>
              <TextField sx={{ mt: 2 }} fullWidth label="Message" onChange={handleTextChange} value={message.message} />
              {studentNamesWithBadNumbers.length > 0 && (
                <Alert sx={{ mt: 2 }} severity="error">
                  <Typography variant="body1">
                    The following students do not have a valid phone number and will not be sent a message
                  </Typography>
                  <ul>
                    {studentNamesWithBadNumbers.map((name, i) => (
                      <li key={name + i}>{name}</li>
                    ))}
                  </ul>
                </Alert>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSend} disabled={!message.message.trim()}>
              Send
            </Button>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default MessageStudentsDialog;
