import { Dispatch, useCallback, useMemo, useState } from "react";
import { StudentGroup, StudentRecord } from "../../../shared/types/types";
import { Collection } from "../../../shared/types/enums";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import { allSchoolStudentsAtom, schoolGroupsAtom } from "../../recoil/studentsAtoms";
import { useSetRecoilState } from "recoil";

type Props = {
  group?: StudentGroup;
  students: StudentRecord[];
  setSelectedGroup: Dispatch<React.SetStateAction<StudentGroup | null>>;
};

const useManageStudentsInGroupDialog = ({ group, students, setSelectedGroup }: Props) => {
  const [currentGroup, setCurrentGroup] = useState<StudentRecord[]>([]);
  const { updateFSDoc } = useUpdateFSDoc();
  const setAllSchoolStudents = useSetRecoilState(allSchoolStudentsAtom);
  const setSchoolGroups = useSetRecoilState(schoolGroupsAtom);

  const studentsInGroup = useMemo(() => {
    if (!group) return [];
    return students.filter((student) => student.schoolGroups?.includes(group.id));
  }, [group, students]);

  const studentsNotInGroup = useMemo(() => {
    if (!group) return [];
    return students.filter((student) => !student.schoolGroups?.includes(group.id));
  }, [group, students]);

  const handleAddToGroup = useCallback(
    async (selectedStudents: StudentRecord[]) => {
      if (!group || selectedStudents.length === 0) return;

      const updatedStudents = selectedStudents.map((student) => ({
        ...student,
        schoolGroups: [...(student.schoolGroups || []), group.id],
      }));

      await Promise.all(
        updatedStudents.map((student) =>
          updateFSDoc({
            col: Collection.STUDENTS,
            data: { schoolGroups: student.schoolGroups },
            id: student.id,
          }),
        ),
      );

      setAllSchoolStudents((prev) =>
        prev?.map((student) => updatedStudents.find((updated) => updated.id === student.id) || student),
      );
      setCurrentGroup([]);
    },
    [group, updateFSDoc, setAllSchoolStudents],
  );

  const handleDelete = useCallback(
    async (studentId: string) => {
      if (!group) return;

      const studentToUpdate = studentsInGroup.find((student) => student.id === studentId);
      if (!studentToUpdate) return;

      const updatedSchoolGroups = studentToUpdate.schoolGroups?.filter((id) => id !== group.id) || [];

      await updateFSDoc({
        col: Collection.STUDENTS,
        data: { schoolGroups: updatedSchoolGroups },
        id: studentId,
      });

      setAllSchoolStudents((prev) =>
        prev?.map((student) =>
          student.id === studentId ? { ...student, schoolGroups: updatedSchoolGroups } : student,
        ),
      );
    },
    [group, studentsInGroup, updateFSDoc, setAllSchoolStudents],
  );

  const handleCloseModal = useCallback(() => {
    setSelectedGroup(null);
    setCurrentGroup([]);
  }, [setSelectedGroup]);

  const handleDeleteGroup = useCallback(async () => {
    if (!group) return;

    const updatedStudents = students.map((student) => ({
      ...student,
      schoolGroups: student.schoolGroups?.filter((id) => id !== group.id),
    }));

    await Promise.all(
      updatedStudents.map((student) =>
        updateFSDoc({
          col: Collection.STUDENTS,
          data: { schoolGroups: student.schoolGroups },
          id: student.id,
        }),
      ),
    );

    setAllSchoolStudents((prev) =>
      prev?.map((student) => updatedStudents.find((updated) => updated.id === student.id) || student),
    );

    await updateFSDoc({
      col: Collection.GROUPS,
      id: group.id,
      data: { active: false },
    });
    setSchoolGroups((prev) => prev.filter((g) => g.id !== group.id));

    setSelectedGroup(null);
  }, [group, setAllSchoolStudents, setSchoolGroups, setSelectedGroup, students, updateFSDoc]);

  return {
    handleAddToGroup,
    handleDelete,
    handleCloseModal,
    currentGroup,
    setCurrentGroup,
    studentsInGroup,
    studentsNotInGroup,
    handleDeleteGroup,
  };
};

export default useManageStudentsInGroupDialog;
