import { atom } from "recoil";
import { ProviderProgramRecordTwo } from "../../shared/types/types";

export const recommendedProgramsAtom = atom<ProviderProgramRecordTwo[]>({
  key: "recommendedPrograms",
  default: [],
});

export const bookmarkedProgramsAtom = atom<ProviderProgramRecordTwo[]>({
  key: "bookmarkedPrograms",
  default: [],
});

export const selectedProgramForRecommendationsAtom = atom<ProviderProgramRecordTwo | null>({
  key: "selectedProgramForRecommendationsAtom",
  default: null,
});
