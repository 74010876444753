import { useEffect, useState } from "react";
import useGetFSDocs from "../../../shared/hooks/db/useGetFSDocs";
import { ScholarshipApplicationRecord, ScholarshipRecord, StudentRecord } from "../../../shared/types/types";
import { Collection } from "../../../shared/types/enums";

const useExploreScholarshipsContainer = (loggedInStudent: StudentRecord) => {
  const { getFSDocs } = useGetFSDocs();
  const [scholarships, setScholarships] = useState<ScholarshipRecord[]>([]);
  const [applications, setApplications] = useState<ScholarshipApplicationRecord[]>([]);

  useEffect(() => {
    const fetchScholarships = async () => {
      const docs = await getFSDocs<ScholarshipRecord>({
        col: Collection.SCHOLARSHIPS,
      });
      setScholarships(docs);
    };
    fetchScholarships();
  }, [getFSDocs]);

  useEffect(() => {
    const fetchApplications = async () => {
      const docs = await getFSDocs<ScholarshipApplicationRecord>({
        col: Collection.SCHOLARSHIP_APPLICATIONS,
        config: { where: ["studentId", "==", loggedInStudent.id] },
      });
      setApplications(docs);
    };
    fetchApplications();
  }, [getFSDocs, loggedInStudent.id]);

  return { scholarships, applications, setApplications };
};

export default useExploreScholarshipsContainer;
