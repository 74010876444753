import { ChangeEvent, Dispatch } from "react";
import { Staff, StaffRecord } from "../../../shared/types/types";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { Collection, FunctionName, KlaviyoListType } from "../../../shared/types/enums";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import useSetFSDoc from "../../../shared/hooks/db/useSetFSDoc";
import { staffUsersAtom } from "../../../shared/recoil/userAtoms";
import { allSchoolsAtom } from "../../../shared/recoil/schoolAndDistrictAtoms";
import { fetchData } from "../../../shared/utils/fetchUtils";
import { selectedSchoolIdAtom } from "../../recoil/selectedSchoolAtom";

type Props = {
  staffForm: StaffRecord;
  setStaffForm: Dispatch<React.SetStateAction<StaffRecord>>;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
};

const generatePassword = () => {
  return "Password1!";
};

const useManageStaffDialog = ({ staffForm, setStaffForm, setOpen }: Props) => {
  const { updateFSDoc } = useUpdateFSDoc();
  const { setFSDoc } = useSetFSDoc();
  const setStaff = useSetRecoilState(staffUsersAtom);
  const selectedSchoolId = useRecoilValue(selectedSchoolIdAtom);
  const schools = useRecoilValue(allSchoolsAtom);
  const newStaff = staffForm.id === "" ? true : false;
  const currentSchool = schools.find((school) => school.id === selectedSchoolId);

  const handleTextChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    const trimValue = name === "firstName" || name === "lastName" || name === "email" ? value : value;
    setStaffForm((pV) => {
      return { ...pV, [name as keyof Staff]: trimValue };
    });
  };

  const handleUnenroll = () => {
    setOpen(false);
    updateFSDoc({ col: "staff", id: staffForm.id, data: { enrolled: false } });
    setStaff((pV) =>
      pV.map((staff) => {
        if (staff.id === staffForm.id) {
          return { ...staff, enrolled: false };
        } else {
          return staff;
        }
      }),
    );
  };

  const createEmailUser = async (email: string, password: string) => {
    try {
      const response = await fetchData({
        functionName: FunctionName.CREATE_USER,
        payload: { email, password },
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || `HTTP error! status: ${response.status}`);
      }
      return data;
    } catch (error: any) {
      console.error("Error creating User:", error.message);
    }
  };

  const getTwilioNumber = () => {
    if (process.env.REACT_APP_FIREBASE_PROJECT_ID === "willow-dev-f6a2e") {
      return "18667682163";
    }
    if (process.env.REACT_APP_FIREBASE_PROJECT_ID === "willow-production-428ab") {
      return "18667906413";
    }
    if (process.env.REACT_APP_FIREBASE_PROJECT_ID === "willow-demo-d3c85") {
      return "18446091950";
    }
    if (process.env.REACT_APP_FIREBASE_PROJECT_ID === "willowed-qa") {
      return "18556484270";
    }
    return "18667906413";
  };

  const handleSave = async () => {
    if (!currentSchool) return;
    setOpen(false);
    if (staffForm.id === "") {
      const password = generatePassword();
      await createEmailUser(staffForm.email, password);
      const results = await fetchData({
        functionName: FunctionName.GET_UID,
        payload: { email: staffForm.email },
      });
      const resultsJson = await results.json();
      const uid = resultsJson.uid;

      const klaviyoResults = await fetchData({
        functionName: FunctionName.CREATE_PROFILE_AND_ADD_TO_LIST_KLAVIYO,
        payload: {
          email: staffForm.email,
          firstName: staffForm.firstName,
          lastName: staffForm.lastName,
          listType: KlaviyoListType.STAFF,
        },
      });
      const klaviyoResultsJson = await klaviyoResults.json();
      const klaviyoProfileId = klaviyoResultsJson.id ?? null;

      const response = await setFSDoc<StaffRecord>({
        col: Collection.STAFF,
        data: {
          ...staffForm,
          schoolId: currentSchool.id,
          districtId: currentSchool.districtId,
          smsNumber: getTwilioNumber(),
          klaviyoProfileId: klaviyoProfileId,
        },
        id: uid,
      });
      if (!response) return;
      setStaff((pV) => {
        const newArray = [
          ...pV,
          {
            ...staffForm,
            id: response.id,
            createdAt: response.createdAt,
            lastUpdatedAt: response.lastUpdatedAt,
            schoolId: currentSchool.id,
            districtId: currentSchool.districtId,
            smsNumber: getTwilioNumber(),
          },
        ];
        newArray.sort((a, b) => {
          if (a.lastName < b.lastName) return -1;
          if (a.lastName > b.lastName) return 1;
          return 0;
        });
        return newArray;
      });
    } else {
      updateFSDoc({ col: Collection.STAFF, id: staffForm.id, data: staffForm });
      setStaff((pV) => {
        const newArray = pV.map((staff) => {
          if (staff.id === staffForm.id) {
            return { ...staff, ...staffForm };
          } else {
            return staff;
          }
        });
        newArray.sort((a, b) => {
          if (a.lastName < b.lastName) return -1;
          if (a.lastName > b.lastName) return 1;
          return 0;
        });
        return newArray;
      });
    }
  };

  return { handleSave, handleTextChange, handleUnenroll, newStaff };
};

export default useManageStaffDialog;
