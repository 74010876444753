import { Box, Button, TextField } from "@mui/material";
import { ChangeEvent, FormEvent } from "react";

type Props = {
  onSubmit: (message: string) => void;
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
};

const ChatInput = ({ onSubmit, message, setMessage }: Props) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSubmit(message);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <form onSubmit={handleFormSubmit}>
        <TextField
          sx={{ mt: 1 }}
          fullWidth
          type="text"
          value={message}
          onChange={handleInputChange}
          aria-label="input-chat-message"
        />
        <Button
          disableRipple
          fullWidth
          type="submit"
          variant="outlined"
          sx={{ mt: 1 }}
          disabled={message.trim().length === 0}
        >
          Send
        </Button>
      </form>
    </Box>
  );
};

export default ChatInput;
