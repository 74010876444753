import { StudentRecord } from "../../../shared/types/types";
import QuizQuestionManager from "./QuizQuestionManager";
import useQuestionSelector from "../../hooks/quiz/useQuestionSelector";

type Props = {
  loggedInStudent: StudentRecord;
};

const QuestionSelector = ({ loggedInStudent }: Props) => {
  const { currentQuestion, currentAnswer, goBack, goForward, isLastQuestion } = useQuestionSelector({
    loggedInStudent,
  });
  return (
    <>
      {currentQuestion && (
        <QuizQuestionManager
          loggedInStudent={loggedInStudent}
          question={currentQuestion}
          answer={currentAnswer}
          goBack={goBack}
          goForward={goForward}
          isLastQuestion={isLastQuestion}
        />
      )}
    </>
  );
};

export default QuestionSelector;
