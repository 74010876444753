import { Box, Button, Typography } from "@mui/material";
import { Color, QuizResultsOption } from "../../types/enums";
import { PersonalityTypeRecord } from "../../types/types";
import FeatherIcon from "../../assets/icons/featherIcons/FeatherIcon";
import NavigationButtons from "./NavigationButtons";
import { useRecoilState } from "recoil";
import { selectedJobIdAtom } from "../../recoil/exploreCareersAtoms";
import JobDetailsDialog from "../exploreCareers/common/jobs/JobDetailsDialog";

type Props = {
  mobile: boolean;
  personalityType: PersonalityTypeRecord;
  onActionButtonClick: (value: QuizResultsOption) => void;
};

const RecommendedCareersToExplore = ({ mobile, personalityType, onActionButtonClick }: Props) => {
  const [selectedJobId, setSelectedJobId] = useRecoilState(selectedJobIdAtom);
  return (
    <>
      <Box>
        <Typography sx={{ mt: 2, fontWeight: 600 }} variant="h6" fontSize={16} color={Color.ORANGE_700}>
          {personalityType.title}
        </Typography>
        <Typography variant="h3" fontSize={30} color={Color.ORANGE_700}>
          {QuizResultsOption.RECOMMENDED_CAREERS_TO_EXPLORE}
        </Typography>
        <Typography sx={{ mt: 4 }} variant="body1" fontSize={14} color={Color.CHALKBOARD}>
          {personalityType.recommendedCareersOpening}
        </Typography>
        <Box>
          {personalityType.recommendedCareers.map((career, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: mobile ? "column" : "row",
                justifyContent: mobile ? "start" : "space-between",
                alignItems: mobile ? "start" : "center",
                width: "100%",
                py: 4,
                borderBottom: index !== personalityType.recommendedCareers.length - 1 ? 0.5 : "none",
                borderColor: Color.BLACK,
              }}
            >
              <Box>
                <Typography variant="h4" fontSize={20} color={Color.CHALKBOARD}>
                  {career.title}
                </Typography>
                <Typography sx={{ mt: 1 }} variant="body1" fontSize={14} color={Color.CHALKBOARD}>
                  {career.description}
                </Typography>
              </Box>
              <Button
                variant="text"
                onClick={() => setSelectedJobId(career.onetCode)}
                endIcon={
                  <FeatherIcon
                    width="20px"
                    height="20px"
                    viewBox="0 0 20 20"
                    strokeWidth="1"
                    icon="arrow-right"
                    strokeColor="none"
                    fillColor={Color.ORANGE_700}
                  />
                }
                sx={{
                  flex: "none",
                  mt: 1,
                  p: 0,
                  color: Color.CHALKBOARD,
                  fontWeight: "bold",
                  textTransform: "none",
                }}
              >
                Learn More
              </Button>
            </Box>
          ))}
        </Box>
        <NavigationButtons
          mobile={mobile}
          currentTitle={QuizResultsOption.RECOMMENDED_CAREERS_TO_EXPLORE}
          onNextClick={(nextTitle) => onActionButtonClick(nextTitle)}
          onPreviousClick={(nextTitle) => onActionButtonClick(nextTitle)}
        />
      </Box>
      {selectedJobId && <JobDetailsDialog />}
    </>
  );
};

export default RecommendedCareersToExplore;
