import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import CloseIcon from "../../../shared/components/closeIcon/CloseIcon";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleDeleteGroup: () => Promise<void>; // Assuming this is async
};

const ConfirmDeleteGroupDialog = ({ open, setOpen, handleDeleteGroup }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await handleDeleteGroup();
      setOpen(false); // Close the dialog after successful deletion
    } catch (error) {
      console.error("Failed to delete group:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="xs">
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Confirm Delete</Typography>
        <Box sx={{ mr: -1 }}>
          <CloseIcon onClose={() => setOpen(false)} />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete this group? This action cannot be undone.</Typography>
      </DialogContent>
      <DialogActions sx={{ px: 2 }}>
        <Button
          onClick={handleDelete}
          color="error"
          variant="contained"
          disabled={isLoading}
          startIcon={isLoading ? <CircularProgress size={20} /> : null}
        >
          {isLoading ? "Deleting..." : "Yes, Delete"}
        </Button>
        <Button onClick={() => setOpen(false)} variant="outlined" disabled={isLoading}>
          Nevermind
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteGroupDialog;
