import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../../../shared/utils/fetchUtils";
import { LogEventType } from "../../../shared/types/logEnums";
import { validateEmailBasic } from "../../../shared/utils/validationUtils";
import { FileName, FunctionName, PageRoute } from "../../../shared/types/enums";
import useLogger from "../../../shared/hooks/logging/useLogger";

const useSignupContainer = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const { submitLog } = useLogger();

  const backToLogin = () => {
    navigate(PageRoute.LOGIN);
  };

  const checkIfUserExists = async () => {
    setLoading(true);
    //no UID returns 204
    try {
      const results = await fetchData({
        functionName: FunctionName.GET_UID,
        payload: { email },
      });
      setUserExists(results.status === 200);
    } catch (error) {
      submitLog({
        error,
        snackbarMessage: "Error checking if user exists",
        eventType: LogEventType.ERROR_GETTING_UID_BY_EMAIL,
        file: FileName.SIGNUP_AUTH,
      });
    }
    setEmailChecked(true);
    setLoading(false);
  };

  const setCleanedEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const rawEmail = event.target.value;
    const cleanedEmail = rawEmail.trim().toLocaleLowerCase();
    setEmailValid(validateEmailBasic({ email: cleanedEmail }));
    setEmail(cleanedEmail);
  };
  return {
    email,
    setEmail,
    loading,
    userExists,
    emailChecked,
    resetOpen,
    setEmailChecked,
    setResetOpen,
    emailValid,
    setCleanedEmail,
    checkIfUserExists,
    backToLogin,
  };
};

export default useSignupContainer;
