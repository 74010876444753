import { Box, IconButton } from "@mui/material";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { useRecoilState } from "recoil";
import { Collection, REACTION_COLOR } from "../../../shared/types/enums";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import useGenerateProgramRecs from "../../hooks/recommendationAlgorithm/useGenerateProgramRecs";
import useLogger from "../../../shared/hooks/logging/useLogger";
import { LogEventType } from "../../../shared/types/logEnums";

type Props = {
  programId: string;
};

const Reactions = ({ programId }: Props) => {
  const [loggedInStudent, setLoggedInStudent] = useRecoilState(loggedInStudentAtom);
  const { updateFSDoc } = useUpdateFSDoc();
  const { handleGenerate } = useGenerateProgramRecs();
  const { submitLog } = useLogger();

  const handleHeart = (id: string) => {
    if (!loggedInStudent) return;
    const [...favoriteProgramIds] = loggedInStudent?.favoriteProgramIds;
    let programsBookmarkSuccess = loggedInStudent?.getStartedData?.programsBookmarkSuccess || false;

    if (favoriteProgramIds.includes(id)) {
      const index = favoriteProgramIds.indexOf(id);
      favoriteProgramIds.splice(index, 1);
    } else {
      favoriteProgramIds.push(id);
      programsBookmarkSuccess = favoriteProgramIds.length === 6;
      submitLog({
        eventType: LogEventType.PROGRAM_BOOKMARKED,
        file: "Reactions.tsx",
        changeLog: `Student ${loggedInStudent.id} bookmarked program ${id}`,
      });
    }
    updateFSDoc({
      col: Collection.STUDENTS,
      id: loggedInStudent.id,
      data: {
        favoriteProgramIds,
        getStartedData: {
          ...loggedInStudent.getStartedData,
          programsBookmarkSuccess: loggedInStudent.getStartedData?.programsBookmarkSuccess
            ? true
            : programsBookmarkSuccess,
        },
      },
    });

    setLoggedInStudent((prev) =>
      prev
        ? {
            ...prev,
            favoriteProgramIds,
            getStartedData: {
              ...prev.getStartedData,
              programsBookmarkSuccess: loggedInStudent.getStartedData?.programsBookmarkSuccess
                ? true
                : programsBookmarkSuccess,
            },
          }
        : prev,
    );
  };

  const handleRemove = (id: string) => {
    if (!loggedInStudent) return;
    const [...removedProgramIds] = loggedInStudent?.removedProgramIds;
    if (removedProgramIds.includes(id)) {
      const index = removedProgramIds.indexOf(id);
      removedProgramIds.splice(index, 1);
    } else {
      removedProgramIds.push(id);
    }
    updateFSDoc({
      col: Collection.STUDENTS,
      id: loggedInStudent.id,
      data: { removedProgramIds },
    });
    setLoggedInStudent((prev) => (prev ? { ...prev, removedProgramIds } : prev));
    handleGenerate({
      gpaMax: loggedInStudent.gpaMax ?? 4,
      gpaValue: loggedInStudent.gpaValue ?? 3.2,
      programTypeForRecommendations: loggedInStudent.programTypeForRecommendations,
      includeOnlineOnly: loggedInStudent.includeOnlineOnly,
      statesForRecommendations: loggedInStudent.statesForRecommendations,
      citiesForRecommendations: loggedInStudent.citiesForRecommendations,
      onetCodes: [...loggedInStudent.willowRecommendedJobIds, ...loggedInStudent.favoriteJobIds],
      removedProgramIds: removedProgramIds,
      dislikedJobIds: loggedInStudent.dislikedJobIds,
      act: loggedInStudent.act,
    });
    submitLog({
      eventType: LogEventType.PROGRAM_DISLIKED,
      file: "Reactions.tsx",
      changeLog: `Student ${loggedInStudent.id} disliked program ${id}`,
    });
  };

  return (
    <>
      {loggedInStudent && (
        <Box>
          {loggedInStudent.favoriteProgramIds.includes(programId) && (
            <IconButton
              onClick={() => handleHeart(programId)}
              sx={{
                width: 45,
                height: 45,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                pt: 1,
              }}
            >
              <FeatherIcon
                icon="bookmark"
                fillColor={REACTION_COLOR.BOOKMARK}
                strokeColor={REACTION_COLOR.STROKE}
                width="24px"
              />
            </IconButton>
          )}
          {loggedInStudent.removedProgramIds.includes(programId) && (
            <IconButton
              onClick={() => handleRemove(programId)}
              sx={{
                width: 40,
                height: 40,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                pt: 2,
              }}
            >
              <FeatherIcon
                width="24px"
                icon="thumb-down"
                fillColor={
                  loggedInStudent.removedProgramIds.includes(programId) ? REACTION_COLOR.DISLIKE : "transparent"
                }
                strokeColor={REACTION_COLOR.STROKE}
              />
            </IconButton>
          )}
          {!loggedInStudent.favoriteProgramIds.includes(programId) &&
            !loggedInStudent.removedProgramIds.includes(programId) && (
              <Box sx={{ display: "flex" }}>
                <IconButton
                  onClick={() => handleRemove(programId)}
                  sx={{
                    width: 45,
                    height: 45,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    pt: 2,
                  }}
                >
                  <FeatherIcon
                    width="24px"
                    icon="thumb-down"
                    fillColor={"transparent"}
                    strokeColor={REACTION_COLOR.STROKE}
                  />
                </IconButton>

                <IconButton
                  sx={{
                    width: 45,
                    height: 45,
                    color: REACTION_COLOR.BOOKMARK,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    pt: 1,
                  }}
                  onClick={() => handleHeart(programId)}
                >
                  <FeatherIcon icon="bookmark" strokeColor={REACTION_COLOR.STROKE} fillColor="none" width="24px" />
                </IconButton>
              </Box>
            )}
        </Box>
      )}
    </>
  );
};

export default Reactions;
