import { ChatEntry, SchoolRecord, StaffRecord, StudentRecord } from "../../shared/types/types";
import personalities from "../../shared/assets/data/personalitiesforai.json";
import titleConverter from "../../shared/assets/data/personalityTitleConverter.json";

type BuildInterestPromptType = {
  selectedStudent: StudentRecord;
  school: SchoolRecord | undefined;
  openingPrompt: string;
};

export const buildInterestPrompt = ({ selectedStudent, school, openingPrompt }: BuildInterestPromptType) => {
  let promptString = `${openingPrompt} \n *Student: ${selectedStudent.firstName} is a ${
    selectedStudent.gradeLevel
  }th grader ${school ? `at ${school.name}` : ""}. \n`;

  if (selectedStudent.gpaValue && selectedStudent.gpaMax) {
    promptString += `*GPA: ${selectedStudent.gpaValue} out of ${selectedStudent.gpaMax}.\n`;
  }
  if (selectedStudent.myWhy) {
    promptString += `*What's most important to them: ${selectedStudent.myWhy}. \n`;
  }

  if (selectedStudent.personalityType) {
    const personalityMap = personalities as { [key: string]: string };
    const titleMap = titleConverter as { [key: string]: string };
    const title = titleMap[selectedStudent.personalityType];
    const personality = personalityMap[title];
    promptString += `*Their personality type and description is: ${personality}. \n`;
  }
  return promptString;
};

type StaffStartingPromptProps = {
  loggedInStaff: StaffRecord;
};
export const generateStaffStartingPrompt = ({ loggedInStaff }: StaffStartingPromptProps) => {
  const startingHistory: ChatEntry[] = [
    {
      role: "user",
      timestamp: new Date().toISOString(),
      parts: [
        {
          text: generateAlmaStaffContextPrompt({
            loggedInStaff,
          }),
        },
      ],
    },
    {
      role: "model",
      timestamp: new Date().toISOString(),
      parts: [
        {
          text: `Hello ${loggedInStaff.firstName}! I'm Alma, your career and college coach. I'm here to help you help your students. What can I do for  you today?`,
        },
      ],
    },
  ];
  return startingHistory;
};

type GenerateAlmaStaffProps = {
  loggedInStaff: StaffRecord;
};

const generateAlmaStaffContextPrompt = ({ loggedInStaff }: GenerateAlmaStaffProps) => {
  const fullPrompt = `Your name is Alma, you are an AI post-secondary counselor for a high school staff member named ${loggedInStaff.firstName} inside an app they're using called Willow. Willow helps students match with high-quality careers and programs including colleges and professional skill development programs. Your job is to help high school counselors best navigate supporting students as they learn about their interests, the types of careers and jobs they may be interested in, the college and workforce programs that will be best for them, and the application process for programs and financial aid. Do not respond to any questions or topics that are not about what I've described so far.`;
  return fullPrompt;
};

const chatMessageStyle = {
  padding: "10px",
  margin: "10px",
  borderRadius: "10px",
  maxWidth: "70%",
  width: "fit-content",
  fontFamily: "poppins",
  fontSize: "14px",
};

export const formatChatBackground = (role: string) => {
  return {
    user: {
      ...chatMessageStyle,
      backgroundColor: "#EBF8FF",
    },
    model: {
      ...chatMessageStyle,
      backgroundColor: "#EDFCF9",
    },
  }[role];
};

export const STAFF_STARTER_PROMPTS = [
  "What are some good questions to ask students to help them figure out what they want to study after high school?",
  "How can I help students break down the college application process and give them guidance?",
  "What are some tips for students who are writing their college application essays?",
];

// \n Only recommend colleges on this list: ${collegeList.join(",")}. Do not list a college unless you include the name and the the id of that college based on the list I just shared. Write the id in parenthesis immediately after the name.
