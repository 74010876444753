import { useState } from "react";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import { Collection, PageRoute, UserType } from "../../../shared/types/enums";
import { db } from "../../../firebase";
import { collection, getDocs, query, updateDoc, where } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { LogEventType } from "../../../shared/types/logEnums";
import useLogger from "../../../shared/hooks/logging/useLogger";
import { allSchoolStudentsAtom, selectedStudentAtom } from "../../../staff/recoil/studentsAtoms";

type Props = {
  userType: UserType;
};

const useResetQuizResultsDialog = ({ userType }: Props) => {
  const [student, setStudent] = useRecoilState(
    userType === UserType.STUDENT ? loggedInStudentAtom : selectedStudentAtom,
  );
  const setStudents = useSetRecoilState(allSchoolStudentsAtom);
  const { updateFSDoc } = useUpdateFSDoc();
  const { submitLog } = useLogger();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const resetQuizResults = async () => {
    if (!student) return;
    // Initialize Firestore

    // Reference to the collection (replace 'your_collection_name' with the actual collection name)
    const collectionRef = collection(db, Collection.ANSWERS);

    // Create a query to find all documents where studentId matches the logged-in student's ID
    const q = query(collectionRef, where("studentId", "==", student.id));

    setLoading(true);
    try {
      // Execute the query
      const querySnapshot = await getDocs(q);

      // Array to store all update promises
      const updatePromises: Promise<void>[] = [];

      // Iterate through the matching documents
      querySnapshot.forEach((doc) => {
        // Update each document, setting 'active' to false
        const updatePromise = updateDoc(doc.ref, {
          active: false,
        });
        updatePromises.push(updatePromise);
      });

      // Wait for all updates to complete
      await Promise.all(updatePromises);
      await updateFSDoc({
        col: Collection.STUDENTS,
        id: student.id,
        data: { quizComplete: false, lastQuestionId: null },
      });
      setStudent((prev) => (prev ? { ...prev, quizComplete: false, lastQuestionId: null } : prev));

      if (userType === UserType.STUDENT) {
        submitLog({
          eventType: LogEventType.RESET_QUIZ_RESULTS,
          file: "useResetQuizResultsDialog.ts",
        });
        navigate(PageRoute.STUDENT_TAKE_QUIZ);
      } else {
        setStudents((pV) =>
          pV.map((item) => (item.id === student.id ? { ...item, quizComplete: false, lastQuestionId: null } : item)),
        );

        submitLog({
          eventType: LogEventType.STAFF_RESET_QUIZ_RESULTS,
          changeLog: `Staff reset quiz results of user ${student.id} successfully`,
          file: "useResetQuizResultsDialog.ts",
        });
      }
    } catch (error) {
      if (userType === UserType.STUDENT) {
        submitLog({
          error,
          snackbarMessage: `There was an error reseting your quiz results.`,
          eventType: LogEventType.RESET_QUIZ_RESULTS_ERROR,
          file: "useResetQuizResultsDialog.ts",
        });
      } else {
        submitLog({
          error,
          snackbarMessage: `There was an error reseting user quiz results.`,
          eventType: LogEventType.STAFF_RESET_QUIZ_RESULTS_ERROR,
          file: "useResetQuizResultsDialog.ts",
        });
      }
    }
    setLoading(false);
  };
  return { student, resetQuizResults, loading };
};

export default useResetQuizResultsDialog;
