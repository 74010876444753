import { useCallback, useEffect } from "react";
import {
  allSchoolsAtom,
  districtsAtom,
  selectedDistrictAtom,
  selectedSchoolAtom,
} from "../../shared/recoil/schoolAndDistrictAtoms";
import { siteLoadingAtom, userLoadingAtom } from "../../shared/recoil/loadingAtoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { loggedInStudentAtom, mentorUsersAtom, ownEFCDataAtom } from "../../shared/recoil/userAtoms";
import {
  DistrictRecord,
  EFCDataRecord,
  MentorUserRecord,
  MSACodeRecord,
  PersonalityTypeRecord,
  SchoolRecord,
  VideoInteractionRecord,
} from "../../shared/types/types";
import { Collection, FunctionName } from "../../shared/types/enums";
import useGetFSDocs from "../../shared/hooks/db/useGetFSDocs";
import {
  parseDistrictResponse,
  parseMentorUsersResponse,
  parseSchoolResponse,
  parseSingleEFCDataResponse,
  parseSingleMSACodeResponse,
  parseSinglePersonalityTypeRecord,
} from "../../shared/utils/parserUtils";
import { StudentDataContext } from "../contexts/studentDataContext";
import { getStudentDistrict, getStudentSchool } from "../utils/filterUtils";
import { useAuth } from "../../shared/contexts/AuthContext";
import useGetFSDoc from "../../shared/hooks/db/useGetFSDoc";
import { videoInteractionsAtom } from "../recoil/videoInteractionAtoms";
import { fetchData } from "../../shared/utils/fetchUtils";
import { recommendedProgramsAtom } from "../recoil/recommendedProgramAtoms";
import { personalityTypeAtom } from "../../shared/recoil/personalityTypeAtoms";
import { msaCodeAtom } from "../recoil/msaCodeAtoms";

type Props = {
  children: JSX.Element;
};

export const StudentDBProvider = ({ children }: Props) => {
  const setSelectedSchool = useSetRecoilState(selectedSchoolAtom);
  const setSelectedDistrict = useSetRecoilState(selectedDistrictAtom);
  const setDistricts = useSetRecoilState(districtsAtom);
  const setAllSchools = useSetRecoilState(allSchoolsAtom);
  const setSiteLoading = useSetRecoilState(siteLoadingAtom);
  const setOwnEFCData = useSetRecoilState(ownEFCDataAtom);
  const setMentorUsers = useSetRecoilState(mentorUsersAtom);
  const setVideoInteractions = useSetRecoilState(videoInteractionsAtom);
  const setPersonalityType = useSetRecoilState(personalityTypeAtom);
  const setRecommendedPrograms = useSetRecoilState(recommendedProgramsAtom);
  const setMSACode = useSetRecoilState(msaCodeAtom);
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const { getFSDoc } = useGetFSDoc();
  const { getFSDocs } = useGetFSDocs();
  const userLoading = useRecoilValue(userLoadingAtom);
  const currentAuthUser = useAuth();

  type DataLoaderPromises = [
    Promise<DistrictRecord[]>,
    Promise<SchoolRecord[]>,
    Promise<EFCDataRecord | null>?,
    Promise<MentorUserRecord[]>?,
    Promise<VideoInteractionRecord[]>?,
    Promise<MSACodeRecord[] | null>?,
    Promise<PersonalityTypeRecord | null>?,
  ];

  const getDataPromises = useCallback(() => {
    const dataLoaderPromises: DataLoaderPromises = [
      getFSDocs<DistrictRecord>({
        col: Collection.DISTRICTS,
      }),
      getFSDocs<SchoolRecord>({
        col: Collection.SCHOOLS,
      }),
    ];

    if (loggedInStudent?.id) {
      dataLoaderPromises.push(
        getFSDoc<EFCDataRecord>({
          col: Collection.EFCDATA,
          id: loggedInStudent.id,
        }),
        getFSDocs<MentorUserRecord>({
          col: Collection.MENTOR_USERS,
          config: {
            where: ["studentIds", "array-contains", loggedInStudent.id],
          },
        }),
        getFSDocs<VideoInteractionRecord>({
          col: Collection.VIDEO_INTERACTIONS,
          config: { where: ["studentId", "==", loggedInStudent.id] },
        }),
        getFSDocs<MSACodeRecord>({
          col: Collection.MSA_CODES,
          config: {
            where: ["COUNTY_NAME", "==", loggedInStudent.address?.county ?? ""],
          },
        }),
      );
    }
    if (loggedInStudent?.personalityType) {
      dataLoaderPromises.push(
        getFSDoc<PersonalityTypeRecord>({
          col: Collection.PERSONALITY_TYPE,
          id: loggedInStudent.personalityType,
        }),
      );
    }

    return dataLoaderPromises;
  }, [getFSDoc, getFSDocs, loggedInStudent?.id, loggedInStudent?.personalityType, loggedInStudent?.address?.county]);

  useEffect(() => {
    /*this function loads promises of all the data needed for when students log in into dataLoaderPromises
    and then awaits for all the data to be loaded and then sets the data into the recoil state*/

    const loadData = async () => {
      if (userLoading) return;
      setSiteLoading(true);
      if (!currentAuthUser) {
        setSiteLoading(false);
      }
      const dataLoaderPromises = getDataPromises();
      const results = await Promise.all(dataLoaderPromises);
      const [districts, schools, efcData, mentorUsers, videoInteractions, msaCodes, personalityType] = results;
      setDistricts(parseDistrictResponse(districts));
      setAllSchools(parseSchoolResponse(schools));
      if (loggedInStudent?.id) {
        setOwnEFCData(efcData ? parseSingleEFCDataResponse(efcData) : null);
        setMentorUsers(parseMentorUsersResponse(mentorUsers ? mentorUsers : []));
        setVideoInteractions(videoInteractions ? videoInteractions : []);
        setMSACode(msaCodes ? parseSingleMSACodeResponse(msaCodes[0]) : null);
        setPersonalityType(personalityType ? parseSinglePersonalityTypeRecord(personalityType) : null);
      }
      if (loggedInStudent?.districtId && loggedInStudent?.schoolId) {
        const userDistrict = getStudentDistrict({
          districts,
          districtId: loggedInStudent.districtId,
        });
        const userSchool = getStudentSchool({
          schools,
          schoolId: loggedInStudent.schoolId,
        });
        setSelectedDistrict(userDistrict ? userDistrict : null);
        setSelectedSchool(userSchool ? userSchool : null);
      }
      setSiteLoading(false);
    };
    loadData();
  }, [
    getDataPromises,
    loggedInStudent?.districtId,
    loggedInStudent?.schoolId,
    setDistricts,
    setAllSchools,
    setOwnEFCData,
    setMentorUsers,
    setSelectedDistrict,
    setSelectedSchool,
    setSiteLoading,
    userLoading,
    currentAuthUser,
    loggedInStudent?.id,
    setVideoInteractions,
    setPersonalityType,
    setMSACode,
  ]);

  useEffect(() => {
    if (!loggedInStudent?.willowRecommendedProgramIds) return;
    const getRecommendedPrograms = async () => {
      const results = await fetchData({
        functionName: FunctionName.GET_PROGRAMS_AND_PROVIDERS_BY_ID,
        payload: {
          documentIds: [...loggedInStudent.willowRecommendedProgramIds, ...loggedInStudent.staffRecommendedProgramIds],
        },
      });
      const matchedResults = await results.json();
      setRecommendedPrograms(matchedResults.programs);
    };
    getRecommendedPrograms();
  }, [
    loggedInStudent?.staffRecommendedProgramIds,
    loggedInStudent?.willowRecommendedProgramIds,
    setRecommendedPrograms,
    loggedInStudent,
  ]);

  return <StudentDataContext.Provider value={null}>{children}</StudentDataContext.Provider>;
};
