import { Box } from "@mui/material";
import StudentAlmaChatContainer from "../components/alma/StudentAlmaChatContainer";
import { StudentAlmaProvider } from "../providers/StudentAlmaProvider";

const StudentAlmaChatPage = () => {
  return (
    <>
      <StudentAlmaProvider>
        <Box>
          <StudentAlmaChatContainer />
        </Box>
      </StudentAlmaProvider>
    </>
  );
};

export default StudentAlmaChatPage;
