export const measureNetworkSpeed = async (): Promise<number | null> => {
  try {
    const startTime = performance.now();
    const response = await fetch("https://evan-ryan-york.github.io/file-for-network-testing/1MB.jpg"); // Use a small test file
    const endTime = performance.now();
    const sizeInBytes = parseInt(response.headers.get("Content-Length") || "0", 10);
    const durationInSeconds = (endTime - startTime) / 1000;

    if (sizeInBytes && durationInSeconds) {
      return (sizeInBytes * 8) / (durationInSeconds * 1_000_000); // Speed in Mbps
    } else {
      return null;
    }
  } catch (error) {
    console.error("Failed to measure network speed:", error);
    return null;
  }
};
