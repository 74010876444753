import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { allSchoolStudentsSelector } from "../../recoil/studentsAtoms";
import { studentRowsAtom } from "../../recoil/dashboardAtoms";
import { convertToCSV } from "../../utils/csvUtils";
import { allSchoolsAtom } from "../../../shared/recoil/schoolAndDistrictAtoms";
import personalityTypeConverter from "../../../shared/assets/data/personalityTitleConverter.json";

type StudentsForExport = {
  firstName: string;
  lastName: string;
  schoolEmail: string;
  schoolName: string;
  gpa: string;
  bookmarkedPrograms: number;
  bookmarkedJobs: number;
  personalityType: string;
};

const useStudentTableTools = () => {
  const [studentsAllForExport, setStudentsAllForExport] = useState("");
  const studentRows = useRecoilValue(studentRowsAtom);
  const students = useRecoilValue(allSchoolStudentsSelector);
  const schools = useRecoilValue(allSchoolsAtom);

  const formatPersonalityType = (type: string) => {
    const personalityTypeObject = personalityTypeConverter as {
      [key: string]: string;
    };
    const personalityType = personalityTypeObject[type] ? personalityTypeObject[type] : "Quiz Not Completed";
    return personalityType;
  };

  useEffect(() => {
    const tempArrayForExport: StudentsForExport[] = [];
    if (!studentRows) return;
    //generates the data needed for the overall CSV export
    studentRows.forEach((studentRow) => {
      const studentRecord = students.find((stu) => stu.id === studentRow.id);
      if (!studentRecord) return;
      tempArrayForExport.push({
        firstName: studentRow.firstName,
        lastName: studentRow.lastName,
        gpa: studentRow.gpaValue && studentRow.gpaMax ? `${studentRow.gpaValue} out of ${studentRow.gpaMax}` : `N/A`,
        bookmarkedPrograms: studentRow.bookmarkedPrograms,
        bookmarkedJobs: studentRow.bookmarkedJobs,
        personalityType: formatPersonalityType(studentRow.personalityType),
        schoolEmail: studentRecord.email,
        schoolName: schools.find((school) => school.id === studentRecord.schoolId)?.name ?? "",
      });
    });

    const arrayForExport = convertToCSV(tempArrayForExport);
    setStudentsAllForExport(arrayForExport);
  }, [schools, studentRows, students]);

  return { studentsAllForExport };
};

export default useStudentTableTools;
