import { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { allSchoolStudentsSelector } from "../../recoil/studentsAtoms";
import { ScholarshipStudentTableRow } from "../../../shared/types/types";

type PieData = { name: string; value: number; students: string[] };

const useStudentPieCharts = ({ rows }: { rows: ScholarshipStudentTableRow[] }) => {
  // Initialize pie chart data states
  const [applicationData, setApplicationData] = useState<PieData[]>([]);
  const [awardData, setAwardData] = useState<PieData[]>([]);
  const allSchoolStudents = useRecoilValue(allSchoolStudentsSelector);

  useEffect(() => {
    // Define types for accumulator objects
    const applicationsPerStudent: Record<string, number> = {};
    const awardsPerStudent: Record<string, number> = {};

    // Count applications per student
    rows.forEach((row) => {
      applicationsPerStudent[row.studentId] = (applicationsPerStudent[row.studentId] || 0) + 1;
    });

    // Count awards per student
    rows.forEach((row) => {
      if (row.status === "awarded") {
        awardsPerStudent[row.studentId] = (awardsPerStudent[row.studentId] || 0) + 1;
      }
    });

    // Classify students based on application counts and sort alphabetically
    const noApplicationStudents = allSchoolStudents
      .filter((student) => !applicationsPerStudent[student.id])
      .map((student) => student.firstName + " " + student.lastName)
      .sort();

    const appliedOnceStudents = Object.entries(applicationsPerStudent)
      .filter(([_, count]) => count >= 1 && count < 3)
      .map(
        ([studentId]) =>
          allSchoolStudents.find((student) => student.id === studentId)?.firstName +
          " " +
          allSchoolStudents.find((student) => student.id === studentId)?.lastName,
      )
      .filter(Boolean)
      .sort() as string[];

    const appliedThreePlusStudents = Object.entries(applicationsPerStudent)
      .filter(([_, count]) => count >= 3)
      .map(
        ([studentId]) =>
          allSchoolStudents.find((student) => student.id === studentId)?.firstName +
          " " +
          allSchoolStudents.find((student) => student.id === studentId)?.lastName,
      )
      .filter(Boolean)
      .sort() as string[];

    // Classify students based on award counts and sort alphabetically
    const noAwardStudents = allSchoolStudents
      .filter((student) => !awardsPerStudent[student.id])
      .map((student) => student.firstName + " " + student.lastName)
      .sort();

    const awardedOnceStudents = Object.entries(awardsPerStudent)
      .filter(([_, count]) => count >= 1 && count < 3)
      .map(
        ([studentId]) =>
          allSchoolStudents.find((student) => student.id === studentId)?.firstName +
          " " +
          allSchoolStudents.find((student) => student.id === studentId)?.lastName,
      )
      .filter(Boolean)
      .sort() as string[];

    const awardedThreePlusStudents = Object.entries(awardsPerStudent)
      .filter(([_, count]) => count >= 3)
      .map(
        ([studentId]) =>
          allSchoolStudents.find((student) => student.id === studentId)?.firstName +
          " " +
          allSchoolStudents.find((student) => student.id === studentId)?.lastName,
      )
      .filter(Boolean)
      .sort() as string[];

    // Set data for application pie chart
    setApplicationData([
      {
        name: "No Applications",
        value: noApplicationStudents.length,
        students: noApplicationStudents,
      },
      {
        name: "Applied for 1+",
        value: appliedOnceStudents.length,
        students: appliedOnceStudents,
      },
      {
        name: "Applied for 3+",
        value: appliedThreePlusStudents.length,
        students: appliedThreePlusStudents,
      },
    ]);

    // Set data for award pie chart
    setAwardData([
      {
        name: "No Awards",
        value: noAwardStudents.length,
        students: noAwardStudents,
      },
      {
        name: "Awarded 1+",
        value: awardedOnceStudents.length,
        students: awardedOnceStudents,
      },
      {
        name: "Awarded 3+",
        value: awardedThreePlusStudents.length,
        students: awardedThreePlusStudents,
      },
    ]);
  }, [rows, allSchoolStudents]);
  return { applicationData, awardData };
};

export default useStudentPieCharts;
