import { Box, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const EmptyGoalCard = () => {
  const navigate = useNavigate();
  return (
    <Box
      display="flex"
      alignItems="center"
      borderRadius="8px"
      border="1px dashed #ccc"
      sx={{ marginY: 2, padding: 4, justifyContent: "center" }}
    >
      <Button
        onClick={() => navigate("/student-manage-goals")}
        sx={{ color: "#777", textTransform: "none", fontWeight: 400 }}
      >
        Click to set up a SMART Goal
      </Button>
    </Box>
  );
};

export default EmptyGoalCard;
