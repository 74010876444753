import { Dispatch, useCallback, useMemo } from "react";
import { GridColDef, GridRowParams, GridRowsProp } from "@mui/x-data-grid-premium";
import { StudentGroup, Student } from "../../../shared/types/types";
import { useRecoilValue } from "recoil";
import { staffUsersAtom } from "../../../shared/recoil/userAtoms";

type Props = {
  groups: StudentGroup[];
  students: Student[];
  setSelectedGroup: Dispatch<React.SetStateAction<StudentGroup | null>>;
};

const useGroupsTable = ({ groups, students, setSelectedGroup }: Props) => {
  const staff = useRecoilValue(staffUsersAtom);

  // Generate rows using useMemo to avoid recalculations
  const rows: GridRowsProp = useMemo(() => {
    return groups.map((group) => {
      const studentCount = students.filter((student) => student.schoolGroups.includes(group.id)).length;

      const createdByStaff = staff.find((staff) => staff.id === group.createdByStaffId);

      return {
        id: group.id,
        groupName: group.groupName,
        numberStudents: studentCount,
        createdBy: createdByStaff ? `${createdByStaff.firstName} ${createdByStaff.lastName}` : "Unknown",
      };
    });
  }, [groups, students, staff]);

  // Define columns
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "groupName",
        headerName: "Group Name",
        flex: 2,
        headerClassName: "tableHeader",
        cellClassName: "hoverPointer",
      },
      {
        field: "createdBy",
        headerName: "Created By",
        flex: 2,
        align: "center",
        headerAlign: "center",
        headerClassName: "tableHeader",
        cellClassName: "hoverPointer",
      },
      {
        field: "numberStudents",
        headerName: "Number of Students",
        flex: 1,
        align: "center",
        headerAlign: "center",
        headerClassName: "tableHeader",
        cellClassName: "hoverPointer",
      },
    ],
    [],
  );

  const handleRowClick = useCallback(
    (params: GridRowParams) => {
      const selectedGroup = groups.find((group) => group.id === params.row.id);
      if (selectedGroup) {
        setSelectedGroup(selectedGroup);
      } else {
        console.error("Group not found");
      }
    },
    [groups, setSelectedGroup],
  );
  return { rows, columns, handleRowClick };
};

export default useGroupsTable;
