import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, Typography } from "@mui/material";
import { Dispatch } from "react";
import StarsIcon from "@mui/icons-material/Stars";
import CloseIcon from "../closeIcon/CloseIcon";

type Props = {
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
};

const WhatIsRTSSchool = ({ open, setOpen }: Props) => {
  const typographySX = { fontSize: 16, mb: 0, textAlign: "justify" };
  return (
    <Dialog onClose={() => setOpen(false)} open={open} fullWidth maxWidth="sm">
      <DialogTitle
        variant="h4"
        sx={{
          fontSize: 44,
          textAlign: "center",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <span>What are reach, target, and safety schools?</span>
        <CloseIcon onClose={() => setOpen(false)} size={32} />
      </DialogTitle>
      <DialogContent>
        <Typography sx={typographySX}>
          In the college admissions process, students often categorize their prospective schools into three main groups:
          reach, target, and safety schools.
        </Typography>

        <List>
          <ListItem sx={{ mb: 2 }}>
            <StarsIcon sx={{ color: "#1DBC9D", mr: 1 }} />
            <Typography sx={typographySX}>
              <b>Reach Schools:</b> These are colleges or universities where the student's academic credentials (such as
              GPA, standardized test scores, and extracurricular achievements) fall below the average range of admitted
              students. While admission to reach schools may be more challenging, they are often prestigious
              institutions that the student aspires to attend.
            </Typography>
          </ListItem>
          <ListItem sx={{ mb: 2 }}>
            <StarsIcon sx={{ color: "#1DBC9D", mr: 1 }} />
            <Typography sx={typographySX}>
              <b>Target Schools:</b> Target schools are those where the student's academic profile closely matches the
              average admitted student profile. Students have a reasonable chance of being accepted to target schools
              based on their qualifications and achievements.
            </Typography>
          </ListItem>
          <ListItem sx={{ mb: 2 }}>
            <StarsIcon sx={{ color: "#1DBC9D", mr: 1 }} />
            <Typography sx={typographySX}>
              <b>Safety Schools:</b> Safety schools are colleges or universities where the student's academic
              credentials exceed the average range of admitted students. These institutions are considered 'safety nets'
              because students are highly likely to be accepted. While safety schools may not be as selective as reach
              or target schools, they still offer quality education and can serve as excellent backup options.
            </Typography>
          </ListItem>
        </List>

        <Typography sx={typographySX}>
          By categorizing prospective schools into reach, target, and safety tiers, students can strategically plan
          their college application process and ensure they have a balanced list of institutions that align with their
          academic abilities and goals.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default WhatIsRTSSchool;
