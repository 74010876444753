import { Box, LinearProgress, Typography } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, LabelList } from "recharts";
import { PieChart, Pie, Cell } from "recharts";
import { Color } from "../../types/enums";
import { ProviderTwoRecord } from "../../types/types";
import { numberToPercent, numberToRound } from "../../utils/formatUtils";
import StatWithProgress from "./StatWithProgress";
import StatDetail from "./StatDetail";
import DiversityChart from "./DiversityChart";

type Props = {
  provider: ProviderTwoRecord;
};

const COLORS = ["#137C67", "#1DBC9D", "#4BE2C4", "#B9F4E8", "#B85300", "#FF7E14", "#FFB375", "#FFEBDB"];

const ProviderStats = ({ provider }: Props) => {
  const blackPercent = provider.percentBlack ? Math.round(provider.percentBlack * 100) : 0;
  const latinxPercent = provider.percentHispanic ? Math.round(provider.percentHispanic * 100) : 0;
  const asianPercent = provider.percentAsian ? Math.round(provider.percentAsian * 100) : 0;
  const aianPercent = provider.percentAIAN ? Math.round(provider.percentAIAN * 100) : 0;
  const nhpiPercent = provider.percentNHPI ? Math.round(provider.percentNHPI * 100) : 0;
  const twoOrMorePercent = provider.percentTwoOrMore ? Math.round(provider.percentTwoOrMore * 100) : 0;
  const whitePercent = provider.percentWhite ? Math.round(provider.percentWhite * 100) : 0;

  const unknownPercent = Math.max(
    0,
    100 - (blackPercent + latinxPercent + asianPercent + aianPercent + nhpiPercent + twoOrMorePercent + whitePercent),
  );
  const segmentedCompletionData = [
    {
      name: "White",
      value: provider.completionRateWhiteCalculated ? Math.round(provider.completionRateWhiteCalculated * 100) : 0,
    },
    {
      name: "Black",
      value: provider.completionRateBlackCalculated ? Math.round(provider.completionRateBlackCalculated * 100) : 0,
    },
    {
      name: "Hispanic",
      value: provider.completionRateHispanicCalculated
        ? Math.round(provider.completionRateHispanicCalculated * 100)
        : 0,
    },
    {
      name: "Asian",
      value: provider.completionRateAsianCalculated ? Math.round(provider.completionRateAsianCalculated * 100) : 0,
    },
    {
      name: "AIAN",
      value: provider.completionRateAIANCalculated ? Math.round(provider.completionRateAIANCalculated * 100) : 0,
    },
    {
      name: "NHPI",
      value: provider.completionRateNHPICalculated ? Math.round(provider.completionRateNHPICalculated * 100) : 0,
    },
    {
      name: "Two or More",
      value: provider.completionRateTwoOrMoreCalculated
        ? Math.round(provider.completionRateTwoOrMoreCalculated * 100)
        : 0,
    },
    {
      name: "Low Income",
      value: provider.lowIncomeCompRateCalculated ? Math.round(provider.lowIncomeCompRateCalculated * 100) : 0,
    },
    {
      name: "First Gen",
      value: provider.firstGenCompRateCalculated ? Math.round(provider.firstGenCompRateCalculated * 100) : 0,
    },
  ].filter((entry) => entry.value !== null);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1.5,
        p: 2,
        background: Color.WHITE,
        borderRadius: "var(--Number-Scale-2s-12, 12px)",
        boxShadow: "0px 8px 24px -3px rgba(16, 24, 40, 0.10), 0px 8px 24px -3px rgba(16, 24, 40, 0.05)",
      }}
    >
      {/* Existing Stats */}
      <StatWithProgress
        label="Admission Rate"
        value={provider.openAdmin ? 1 : provider.admissionPercent || 0}
        variant="progress"
      />
      <StatWithProgress label="Completion Rate" value={provider.completionRateCalculated || 0} variant="progress" />
      <StatWithProgress
        label="Median ACT Range"
        range={{ min: provider.act25th || 0, max: provider.act75th || 0 }}
        variant="range"
      />
      <StatDetail
        label="Total Size"
        value={
          provider.undergradTotalEnrollment ? numberToRound({ number: provider.undergradTotalEnrollment }) : "Unknown"
        }
      />
      <StatDetail
        label="Religious Affiliation"
        value={
          provider.religiousAffiliationCalculated && provider.religiousAffiliationCalculated !== "null"
            ? provider.religiousAffiliationCalculated
            : "None"
        }
      />
      <StatDetail
        label="Single Gender"
        value={provider.menOnly ? "Men Only" : provider.womenOnly ? "Women Only" : "No"}
      />

      {/* New Bar Chart for Race/Ethnicity Completion Rates */}
      <Box sx={{ mt: 2, width: "100%" }}>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 700,
            color: Color.CHARCOAL,
            mb: 1,
          }}
        >
          Completion Rate by Race/Ethnicity:
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={segmentedCompletionData}
            margin={{ top: 10, right: 10, bottom: 40, left: 10 }} // Extra bottom margin for labels
          >
            <XAxis
              dataKey="name"
              tick={{
                fontSize: 10,
                fill: Color.DARK_SOFT_GRAY,
              }}
              tickLine={false}
              interval={0}
              angle={-45}
              textAnchor="end"
            />
            <YAxis tickFormatter={(value) => `${value}%`} />
            <Tooltip formatter={(value: number) => `${value.toFixed(2)}%`} />
            <Bar dataKey="value" fill={Color.MINT} />
          </BarChart>
        </ResponsiveContainer>
      </Box>

      {/* Existing Diversity Chart */}
      <DiversityChart
        data={[
          { name: "Black", value: blackPercent },
          { name: "Latinx", value: latinxPercent },
          { name: "Asian", value: asianPercent },
          { name: "AIAN", value: aianPercent },
          { name: "NHPI", value: nhpiPercent },
          { name: "Two or More", value: twoOrMorePercent },
          { name: "White", value: whitePercent },
          { name: "Unknown", value: unknownPercent },
        ]}
      />
    </Box>
  );
};

export default ProviderStats;
