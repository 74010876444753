import { useState, useCallback, useEffect } from "react";
import BookmarkedPrograms from "./BookmarkedPrograms";
import RecommendedPrograms from "./RecommendedPrograms";
import { Box } from "@mui/material";
import { ProviderProgramRecordTwo } from "../../../shared/types/types";
import { useRecoilValue } from "recoil";
import { selectedStudentAtom } from "../../recoil/studentsAtoms";
import { fetchData } from "../../../shared/utils/fetchUtils";
import { FunctionName } from "../../../shared/types/enums";
import ProgramDetailsDialog from "../../../shared/components/programDetails/ProgramDetailsDialog";
import { formatProgramName } from "../../../shared/utils/formatUtils";

const ProgramsContainer = () => {
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const [bookmarkedPrograms, setBookmarkedPrograms] = useState<ProviderProgramRecordTwo[]>([]);
  const [recommendedPrograms, setRecommendedPrograms] = useState<ProviderProgramRecordTwo[]>([]);
  const [mode, setMode] = useState<"bookmarked" | "recommended">("bookmarked");
  const [selectedProgramDetail, setSelectedProgramDetail] = useState<ProviderProgramRecordTwo | null>(null);

  const switchMode = useCallback(() => {
    if (mode === "bookmarked") {
      setMode("recommended");
    } else {
      setMode("bookmarked");
    }
  }, [mode, setMode]);

  useEffect(() => {
    if (!selectedStudent) return;
    const getPrograms = async () => {
      const results = await fetchData({
        functionName: FunctionName.GET_PROGRAMS_AND_PROVIDERS_BY_ID,
        payload: {
          documentIds: [
            ...selectedStudent.willowRecommendedProgramIds,
            ...selectedStudent.staffRecommendedProgramIds,
            ...selectedStudent.favoriteProgramIds,
          ],
        },
      });
      const matchedResults = await results.json();

      const bookmarked = matchedResults.programs
        .filter((program: ProviderProgramRecordTwo) => selectedStudent.favoriteProgramIds.includes(program.id))
        .map((program: ProviderProgramRecordTwo) => ({
          ...program,
          programName: formatProgramName({ name: program.programName }),
        }));
      const recommended = matchedResults.programs
        .filter(
          (program: ProviderProgramRecordTwo) =>
            selectedStudent.willowRecommendedProgramIds.includes(program.id) ||
            selectedStudent.staffRecommendedProgramIds.includes(program.id),
        )
        .map((program: ProviderProgramRecordTwo) => ({
          ...program,
          programName: formatProgramName({ name: program.programName }),
        }));
      setBookmarkedPrograms(bookmarked);
      setRecommendedPrograms(recommended);
    };
    getPrograms();
  }, [selectedStudent]);

  return (
    <>
      <Box>
        {mode === "bookmarked" ? (
          <BookmarkedPrograms
            handleSwitchMode={switchMode}
            bookmarkedPrograms={bookmarkedPrograms}
            setSelectedProgramDetail={setSelectedProgramDetail}
          />
        ) : (
          <RecommendedPrograms
            handleSwitchMode={switchMode}
            recommendedPrograms={recommendedPrograms}
            setSelectedProgramDetail={setSelectedProgramDetail}
          />
        )}
      </Box>

      {selectedProgramDetail && (
        <ProgramDetailsDialog selectedProgram={selectedProgramDetail} setSelectedProgram={setSelectedProgramDetail} />
      )}
    </>
  );
};

export default ProgramsContainer;
