import { Box, Button } from "@mui/material";
import { useState } from "react";
import ScholarshipTable from "./ScholarshipTable";
import StudentTable from "./StudentTable";
import UploadScholarshipDialog from "./UploadScholarshipsDialog";
import { useRecoilValue } from "recoil";
import { loggedInStaffAtom } from "../../../shared/recoil/userAtoms";
import useScholarshipTable from "../../hooks/scholarships/useScholarshipTable";
import useStudentTable from "../../hooks/scholarships/useStudentTable";

const ScholarshipsContainer = () => {
  const [uploadOpen, setUploadOpen] = useState(false);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const [selectedMenuItem, setSelectedMenuItem] = useState<"student" | "scholarship">("student");

  const { rows: scholarshipRows } = useScholarshipTable();
  const { rows: studentRows } = useStudentTable();

  const handleSwitch = () => {
    setSelectedMenuItem(selectedMenuItem === "student" ? "scholarship" : "student");
  };

  const handleUploadOpen = () => {
    setUploadOpen(true);
  };

  // Download CSV functions for each table view
  const formatDate = (isoString: string | null) => {
    if (!isoString) return ""; // Handle empty or null values
    const date = new Date(isoString);
    const formattedDate = `${String(date.getMonth() + 1).padStart(2, "0")}/${String(date.getDate()).padStart(
      2,
      "0",
    )}/${date.getFullYear()}`;
    return formattedDate;
  };

  const downloadScholarshipCSV = () => {
    const csvRows = [
      ["Scholarship Name", "Student Name", "Submitted On", "Status", "Award Amount"],
      ...scholarshipRows.map((row) => [
        String(row.scholarshipName || ""),
        String(row.studentName || ""),
        formatDate(row.submittedOn),
        String(row.status || ""),
        String(row.awardAmount || ""),
      ]),
    ];
    downloadCSV(csvRows, "scholarship_data.csv");
  };

  const downloadStudentCSV = () => {
    const csvRows = [
      ["Scholarship Name", "Student Name", "Submitted On", "Status", "Award Amount"],
      ...studentRows.map((row) => [
        String(row.scholarshipName || ""),
        String(row.studentName || ""),
        formatDate(row.submittedOn),
        String(row.status || ""),
        String(row.awardAmount || ""),
      ]),
    ];
    downloadCSV(csvRows, "student_data.csv");
  };

  // Helper function to handle CSV download
  const downloadCSV = (csvRows: string[][], fileName: string) => {
    const csvContent = csvRows.map((e) => e.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      <Box sx={{ p: 2 }}>
        {loggedInStaff && loggedInStaff.email === "ryan@willowed.org" && (
          <Box sx={{ py: 2, display: "flex", justifyContent: "right", gap: 2 }}>
            <Button onClick={handleUploadOpen} variant="outlined">
              Upload Scholarships
            </Button>
            <Button variant="outlined">Add New Scholarship</Button>
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "right", gap: 2, mb: 2 }}>
          <Button
            onClick={selectedMenuItem === "scholarship" ? downloadScholarshipCSV : downloadStudentCSV}
            variant="outlined"
          >
            Download CSV
          </Button>
          <Button onClick={handleSwitch} variant="outlined">{`Switch to ${
            selectedMenuItem === "student" ? "Scholarship" : "Student"
          } View`}</Button>
        </Box>
        {selectedMenuItem === "scholarship" ? <ScholarshipTable /> : <StudentTable />}
      </Box>
      <UploadScholarshipDialog open={uploadOpen} setOpen={setUploadOpen} />
    </>
  );
};

export default ScholarshipsContainer;
