import { MouseEvent, useEffect } from "react";
import AllProgramsContainer from "../../../shared/components/explorePrograms/AllProgramsContainer";
import RecommendedProgramsContainer from "./RecommendedProgramsContainer";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import TopNavigationToggle from "./TopNavigationToggle";
import JobDetailsDialog from "../../../shared/components/exploreCareers/common/jobs/JobDetailsDialog";
import { Box } from "@mui/material";
import { useRecoilState } from "recoil";
import { selectedSubMenuAtom } from "../../recoil/navigationAtoms";

const ExploreProgramsContainer = () => {
  const [selectedSubMenu, setSelectedSubMenu] = useRecoilState(selectedSubMenuAtom);
  const { width } = useWindowDimensions();

  useEffect(() => {
    setSelectedSubMenu("recommendedPrograms");
  }, [setSelectedSubMenu]);

  const handleSelected = (_: MouseEvent<HTMLElement>, value: string | null) => {
    if (value === selectedSubMenu || value === null) return;
    setSelectedSubMenu(value);
  };
  return (
    <>
      {width < 900 ? (
        <>
          <TopNavigationToggle selected={selectedSubMenu} handleSelected={handleSelected} />
          {selectedSubMenu === "allPrograms" ? <AllProgramsContainer /> : <RecommendedProgramsContainer />}
        </>
      ) : (
        <Box sx={{ mt: 1, p: 1 }}>
          {selectedSubMenu === "allPrograms" ? <AllProgramsContainer /> : <RecommendedProgramsContainer />}
        </Box>
      )}
      <JobDetailsDialog />
    </>
  );
};

export default ExploreProgramsContainer;
