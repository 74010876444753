import { ProviderProgramRecordTwo } from "../../../shared/types/types";
import { Box, Chip, IconButton, Typography } from "@mui/material";
import { Color } from "../../../shared/types/enums";
import { ChevronRightOutlined } from "@mui/icons-material";
import { formatCredentialCard, formatProgramName } from "../../../shared/utils/formatUtils";

type Props = {
  providerProgram: ProviderProgramRecordTwo;
  setSelectedProgramDetail: (program: ProviderProgramRecordTwo | null) => void;
};

const StaffProgramTitleProviderCard = ({ providerProgram, setSelectedProgramDetail }: Props) => {
  return (
    <>
      <Box
        sx={{
          borderBottom: "1px solid #eaeaea",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 1.5,
          }}
        >
          <Box>
            <Typography textAlign={"left"} variant="h6" sx={{ color: Color.CHALKBOARD, fontSize: 14 }}>
              {formatProgramName({ name: providerProgram.programName })}
            </Typography>
            <Typography textAlign={"left"} variant="body1" sx={{ color: Color.CHALKBOARD, fontSize: 12 }}>
              {providerProgram.providerName}
            </Typography>
            <Chip
              color={formatCredentialCard(providerProgram?.credLevel)}
              label={providerProgram.credentialNameCalculated}
              sx={{ fontSize: 11, ml: -0.5, mt: 1 }}
            />
          </Box>
          <IconButton onClick={() => setSelectedProgramDetail(providerProgram)}>
            <ChevronRightOutlined />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default StaffProgramTitleProviderCard;
