import { Box, Button, Grid, Typography } from "@mui/material";
import useFeedContainer from "../../hooks/feed/useFeedContainer";
import VideoListPlayer from "../../../shared/components/videoList/VideoListPlayer";
import { FeedFilterProps, Industry, StudentRecord } from "../../../shared/types/types";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import JobDetailsDialog from "../../../shared/components/exploreCareers/common/jobs/JobDetailsDialog";
import InfoBox from "../../../shared/components/infoBox/InfoBox";
import IndustryButton from "./IndustryButton";
import industries from "../../../shared/assets/data/industries.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { useCallback, useRef, useState } from "react";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { useSetRecoilState } from "recoil";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import { Collection } from "../../../shared/types/enums";
import FeedFilters from "./FeedFilters";
const disableLottie = process.env.REACT_APP_DISABLE_LOTTIE === "true";

type Props = {
  loggedInStudent: StudentRecord;
};

const FeedDesktop = ({ loggedInStudent }: Props) => {
  const setLoggedInStudent = useSetRecoilState(loggedInStudentAtom);
  const { updateFSDoc } = useUpdateFSDoc();
  const lottieRef = useRef<Player | null>(null);
  const { width } = useWindowDimensions();
  const isMobile = width < 900;
  const [filters, setFilters] = useState<FeedFilterProps>({
    brightOutlook: false,
    greenEconomy: false,
    medianIncome: [0, 180000],
    careerOutOfHS: false,
  });
  const industriesArray = industries.data as Industry[];
  const { selectedIndustryIds, setSelectedIndustryIds, listRef, videos, interactions, loading } = useFeedContainer({
    loggedInStudent,
    filters,
  });

  const handleCloseInfoBox = useCallback(() => {
    setLoggedInStudent((prev) => (prev ? { ...prev, hideFeedMessage: true } : null));
    updateFSDoc({
      col: Collection.STUDENTS,
      id: loggedInStudent.id,
      data: { hideFeedMessage: true },
    });
  }, [loggedInStudent.id, setLoggedInStudent, updateFSDoc]);

  return (
    <Box sx={{ mt: 1, p: 1 }}>
      <Box>
        {!isMobile && (
          <Typography sx={{ p: 1, mt: 1 }} variant="pageTitle">
            Feed
          </Typography>
        )}
      </Box>
      <Grid container spacing={2} width="100%" sx={{ m: 0 }}>
        <Grid item xs={12} sm={4} md={4}>
          <Box
            sx={{
              height: "calc(100vh - 60px)",
              overflowY: "scroll",
            }}
          >
            <Typography variant="h6">Filter By Industry</Typography>
            <Grid container spacing={1} sx={{ mt: 1 }}>
              {industriesArray.map((industry) => (
                <Grid item xs={12} sm={12} md={12} lg={6} key={industry.id}>
                  <IndustryButton
                    industry={industry}
                    selectedIndustryIds={selectedIndustryIds}
                    setSelectedIndustryIds={setSelectedIndustryIds}
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => setSelectedIndustryIds([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16])}
                >
                  All Industries
                </Button>
              </Grid>
              <Grid item xs={12}>
                <FeedFilters filters={filters} setFilters={setFilters} />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={8}>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: "calc(100VH - 97px)",
              overflowY: "scroll",
            }}
            ref={listRef}
          >
            {!loggedInStudent?.hideFeedMessage && (
              <Box sx={{ m: isMobile ? 1 : 2 }}>
                <InfoBox
                  onClose={handleCloseInfoBox}
                  message="Watch the videos below to explore different careers. If you're interested in one, click the bookmark icon to save it. You can view your saved videos on your bookmarks page, and we'll suggest jobs based on what you like."
                />
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 2,
                px: "20px",
              }}
            >
              <VideoListPlayer videos={videos} interactions={interactions} />
              {loading && (
                <Box sx={{ mt: 1 }}>
                  <Typography variant="h4">Loading...</Typography>
                </Box>
              )}
              {!loading && videos.length === 0 && !loading && (
                <Box sx={{ mt: 1 }}>
                  <Typography variant="h4">There are no videos for your selected industries.</Typography>
                  {!disableLottie && (
                    <Player
                      ref={lottieRef}
                      loop={true}
                      autoplay={true}
                      src="https://lottie.host/a3a8bd74-2165-4d1c-9192-63d7942aca8f/pWKQST98wh.json"
                    ></Player>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <JobDetailsDialog />
    </Box>
  );
};

export default FeedDesktop;
