import React from "react";
import { Color } from "../../../shared/types/enums";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";

type Props = {
  selected: string | null;
  handleSelected: (event: React.MouseEvent<HTMLElement>, value: string | null) => void;
};

const TopNavigationToggle: React.FC<Props> = ({ selected, handleSelected }) => {
  return (
    <Box sx={containerStyle}>
      <ToggleButtonGroup
        value={selected}
        exclusive
        fullWidth
        onChange={handleSelected}
        sx={groupSx}
        aria-label="Scholarship filter toggle"
      >
        <ToggleButton
          value="allScholarships"
          sx={toggleButtonSx(selected === "allScholarships")}
          aria-label="Show all scholarships"
        >
          All Scholarships
        </ToggleButton>
        <ToggleButton
          value="manualScholarships"
          sx={toggleButtonSx(selected === "manual")}
          aria-label="Manually added scholarships"
        >
          Manually Add
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export default TopNavigationToggle;

const containerStyle = {
  position: "sticky",
  top: 64, // Consider using a theme spacing value if applicable
  zIndex: 5,
  backgroundColor: "#fff", // Consider using theme.palette.background.default
  marginBottom: "8px",
};

const groupSx = {
  backgroundColor: "#fff", // Use theme.palette.background.default
};

const toggleButtonSx = (isSelected: boolean) => ({
  borderRadius: 0,
  border: 0,
  borderBottom: isSelected ? `1px solid ${Color.MINT}` : Color.GRAY_800,
});
