import React from "react";
import { Dialog, DialogTitle, DialogContent, Button, Typography, Box } from "@mui/material";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";

type AlreadyThreeGoalsDialogProps = {
  open: boolean;
  onClose: () => void;
};

const AlreadyThreeGoalsDialog: React.FC<AlreadyThreeGoalsDialogProps> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Box display="flex" justifyContent="flex-start" alignItems="center" px={2} pt={1}>
        <Box sx={{ backgroundColor: "#fafafa", borderRadius: "50%", p: 1, width: 48, height: 48 }}>
          <Box sx={{ backgroundColor: "#eee", borderRadius: "50%", p: 1, width: 32, height: 32 }}>
            <FeatherIcon icon="info" width="32px" />
          </Box>
        </Box>
      </Box>
      <DialogTitle>You Already Have Three Active Goals</DialogTitle>
      <DialogContent>
        <Typography>
          In Willow, you can only have three active goals at a time. If you have a new goal you want to add, either
          complete or archive an existing goal.
        </Typography>
        <Button
          onClick={onClose}
          variant="outlined"
          color="secondary"
          fullWidth
          sx={{ mt: 2, textTransform: "none", color: "#414651", borderColor: "#D5D7DA", p: 1.5, borderRadius: 3 }}
        >
          Ok
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default AlreadyThreeGoalsDialog;
