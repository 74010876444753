import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { BLANK_VIDEO_INTERACTION_FORM } from "../../utils/blankUtils";
import { Collection } from "../../types/enums";
import useSetFSDoc from "../db/useSetFSDoc";
import { CareerVideoRecord, VideoInteractionRecord } from "../../types/types";
import useUpdateFSDoc from "../db/useUpdateFSDoc";
import useLogger from "../logging/useLogger";
import { LogEventType } from "../../types/logEnums";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { loggedInStudentAtom } from "../../recoil/userAtoms";
import { videoInteractionsAtom } from "../../../students/recoil/videoInteractionAtoms";

interface Props {
  studentId?: string;
  video: CareerVideoRecord;
  interaction: VideoInteractionRecord | null;
  observe: (element: HTMLVideoElement) => void;
  unobserve: (element: HTMLVideoElement) => void;
  globalMute: boolean;
  setGlobalMute: Dispatch<SetStateAction<boolean>>;
}

const useVideoPlayer = ({ studentId, video, interaction, observe, unobserve, globalMute, setGlobalMute }: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const hasLoggedWatch = useRef(false);
  const setVideoInteractions = useSetRecoilState(videoInteractionsAtom);
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const [sharedInteraction, setSharedInteraction] = useState<VideoInteractionRecord | null>(interaction);
  const { setFSDoc } = useSetFSDoc();
  const { updateFSDoc } = useUpdateFSDoc();
  const { submitLog } = useLogger();

  const updateVideoWatchedInteraction = useCallback(async () => {
    if (!studentId) return;

    const videoInteractionId = sharedInteraction?.id || `${studentId}_${video.id}`;
    if (sharedInteraction) {
      if (!sharedInteraction.watched) {
        try {
          await updateFSDoc({
            col: Collection.VIDEO_INTERACTIONS,
            id: videoInteractionId,
            data: { watched: true },
          });
          setSharedInteraction({ ...sharedInteraction, watched: true });
          setVideoInteractions((prev) => [
            ...prev.filter((vi) => vi.id !== videoInteractionId),
            { ...sharedInteraction, watched: true },
          ]);

          submitLog({
            eventType: LogEventType.USER_UPDATED_VIDEO_INTERACTION,
            changeLog: `User updated video interaction ${sharedInteraction.id} successfully`,
            file: "useVideoPlayer.ts",
          });
        } catch (error) {
          submitLog({
            error: error,
            snackbarMessage: "There was an error updating the video interaction, please refresh and try again.",
            eventType: LogEventType.USER_UPDATE_VIDEO_INTERACTION_ERROR,
            file: "useVideoPlayer.ts",
          });
        }
      }
    } else {
      try {
        const newVideoInteraction: VideoInteractionRecord = {
          ...BLANK_VIDEO_INTERACTION_FORM,
          id: videoInteractionId,
          studentId,
          schoolId: loggedInStudent?.schoolId ?? "",
          districtId: loggedInStudent?.districtId ?? "",
          videoId: video.id,
          timestamp: new Date().toISOString(),
          onetCode: video.onet,
          watched: true,
        };
        await setFSDoc<VideoInteractionRecord>({
          col: Collection.VIDEO_INTERACTIONS,
          id: videoInteractionId,
          data: newVideoInteraction,
        });
        setSharedInteraction(newVideoInteraction);
        setVideoInteractions((prev) => [...prev.filter((vi) => vi.id !== videoInteractionId), newVideoInteraction]);

        submitLog({
          eventType: LogEventType.USER_ADDED_VIDEO_INTERACTION,
          changeLog: `User added video interaction ${videoInteractionId} successfully`,
          file: "useVideoPlayer.ts",
        });
      } catch (error) {
        submitLog({
          error,
          snackbarMessage: "There was an error add the video interaction, please refresh and try again.",
          eventType: LogEventType.USER_ADD_VIDEO_INTERACTION_ERROR,
          file: "useVideoPlayer.ts",
        });
      }
    }
  }, [
    studentId,
    sharedInteraction,
    video.id,
    video.onet,
    updateFSDoc,
    setVideoInteractions,
    submitLog,
    loggedInStudent?.schoolId,
    loggedInStudent?.districtId,
    setFSDoc,
  ]);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;

    observe(videoElement);
    videoElement.muted = globalMute;

    const handleVolumeChange = () => {
      if (!videoElement.muted) setGlobalMute(false);
    };

    const handleTimeUpdate = () => {
      if (!hasLoggedWatch.current && videoElement.currentTime >= videoElement.duration * 0.25) {
        updateVideoWatchedInteraction();
        hasLoggedWatch.current = true;
      }
    };

    videoElement.addEventListener("volumechange", handleVolumeChange);
    videoElement.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      videoElement.removeEventListener("volumechange", handleVolumeChange);
      videoElement.removeEventListener("timeupdate", handleTimeUpdate);
      unobserve(videoElement);
    };
  }, [globalMute, observe, setGlobalMute, unobserve, updateVideoWatchedInteraction]);

  return { videoRef, sharedInteraction, setSharedInteraction };
};

export default useVideoPlayer;
