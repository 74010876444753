import { useRecoilValue, useSetRecoilState } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { currentStudentAlmaChatAtom, studentAlmaChatsAtom } from "../../../shared/recoil/almaAtoms";
import { Collection } from "../../../shared/types/enums";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import { LogEventType } from "../../../shared/types/logEnums";
import useLogger from "../../../shared/hooks/logging/useLogger";

const useResetChat = () => {
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const currentStudentAlmaChat = useRecoilValue(currentStudentAlmaChatAtom);
  const setStudentAlmaChats = useSetRecoilState(studentAlmaChatsAtom);
  const { updateFSDoc } = useUpdateFSDoc();
  const { submitLog } = useLogger();
  const resetChat = async () => {
    if (!currentStudentAlmaChat || !loggedInStudent) return;
    try {
      await updateFSDoc({
        col: Collection.ALMA_CHATS,
        id: currentStudentAlmaChat.id,
        data: {
          current: false,
        },
      });
      setStudentAlmaChats((prev) =>
        prev.map((chat) => (chat.id === currentStudentAlmaChat.id ? { ...chat, current: false } : chat)),
      );
      submitLog({
        eventType: LogEventType.STUDENT_ALMA_CHAT_RESET,
        file: "useChatContainer.ts",
      });
    } catch (error) {
      submitLog({
        error,
        snackbarMessage: "Alma had a hard time reseting your chat, please refresh and try again",
        eventType: LogEventType.STUDENT_ALMA_CHAT_RESET_ERROR,
        file: "useChatContainer.ts",
      });
    }
  };
  return { resetChat };
};

export default useResetChat;
