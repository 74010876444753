import { useRef, useEffect, useCallback, useState } from "react";
import TextField from "@mui/material/TextField";
import { useField } from "formik";
import { LogEventType } from "../../types/logEnums";
import { AddressComponents } from "../../types/types";
import { AddressType } from "../../types/enums";
import useLogger from "../../hooks/logging/useLogger";

type Props = {
  label: string;
  id?: string;
  name: string;
  type: AddressType;
  required?: boolean;
  onChange?: (addressComponents: AddressComponents) => void;
  sx?: { [key: string]: any };
  helperText: string;
};

const FormikAddress = ({ label, id = "", name, type = AddressType.ADDRESS, required = false, sx }: Props) => {
  const { submitLog } = useLogger();
  const inputRef = useRef(null);
  const [field, meta, helpers] = useField(name);
  const [isValidSelection, setIsValidSelection] = useState(true);

  useEffect(() => {
    const initializeAutocomplete = () => {
      if (!inputRef.current) return;
      const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
        types: [type],
        componentRestrictions: { country: "us" },
      });

      autocomplete.addListener("place_changed", () => {
        setIsValidSelection(true);
        const place = autocomplete.getPlace();
        if (!place || !place.geometry || !place.geometry.location) {
          submitLog({
            error: new Error("Invalid address"),
            snackbarMessage: "There was a problem with Google Maps",
            eventType: LogEventType.GOOGLE_MAPS_LOADING_ERROR,
            file: "FormikAddress.tsx",
          });
          return;
        }
        const components = place.address_components;
        if (!components) {
          submitLog({
            error: new Error("Invalid address"),
            snackbarMessage: "There was a problem with Google Maps",
            eventType: LogEventType.GOOGLE_MAPS_INSUFFICIENT_COMPONENTS_FOR_ADDRESS,
            file: "FormikAddress.tsx",
          });
          helpers.setValue({ lat: 0, lon: 0, address: "" });
          return;
        }

        let addressComponents: AddressComponents = {
          lat: 0,
          lon: 0,
        };
        const streetNumber = components.find((component) => component.types.includes("street_number"));
        const streetName = components.find((component) => component.types.includes("route"));
        const city = components.find((component) => component.types.includes("locality"));
        const county = components.find((component) => component.types.includes("administrative_area_level_2"));
        const state = components.find((component) => component.types.includes("administrative_area_level_1"));
        const zip = components.find((component) => component.types.includes("postal_code"));
        addressComponents = {
          address: `${streetNumber ? streetNumber.long_name : ""} ${
            streetName ? streetName.long_name : ""
          }, ${city ? city.long_name : ""}, ${state ? state.short_name : ""} ${zip ? zip.short_name : ""}`,
          lat: place.geometry?.location?.lat() ?? 0,
          lon: place.geometry?.location?.lng() ?? 0,
          city: city?.long_name || "",
          state: state?.short_name || "",
          zip: zip?.short_name || "",
          county: county?.long_name || "",
        };

        helpers.setValue(addressComponents);
        field.onChange({ target: { name, value: addressComponents } });
      });
    };

    initializeAutocomplete();

    const style = document.createElement("style");
    style.innerHTML = `
      .pac-container {
        z-index: 10000 !important;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, [field, submitLog, helpers, name, type]);

  const handleBlur = useCallback(() => {
    field.onBlur({ target: { name } });
    helpers.setTouched(true);
  }, [field, name, helpers]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsValidSelection(false);
    helpers.setValue({ ...field.value, address: event.target.value });
  };

  return (
    <TextField
      inputRef={inputRef}
      fullWidth
      id={id}
      name={name}
      label={label}
      value={field.value.address || ""}
      onChange={(event) => handleChange(event)}
      required={required}
      onBlur={handleBlur}
      error={(meta.touched && Boolean(meta.error)) || !isValidSelection}
      helperText={
        (meta.touched && typeof meta.error === "string" && meta.error) ||
        (!isValidSelection && "Please select an address from the list")
      }
      sx={{ ...sx }}
      inputProps={{
        form: {
          autocomplete: "off",
        },
      }}
    />
  );
};

export default FormikAddress;
