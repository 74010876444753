import { Box, Typography } from "@mui/material";
import { QuestionRecord } from "../../../shared/types/types";
import { Color } from "../../../shared/types/enums";
import { Dispatch, SetStateAction, useCallback } from "react";

type Props = {
  question: QuestionRecord;
  picks: { topPick: string; secondPick: string };
  setPicks: Dispatch<SetStateAction<{ topPick: string; secondPick: string }>>;
};

const MSQuestion = ({ question, picks, setPicks }: Props) => {
  const handleClick = useCallback(
    (id: string) => {
      setPicks((prevPicks) => {
        if (prevPicks.topPick === id) {
          return { ...prevPicks, topPick: "" };
        } else if (prevPicks.secondPick === id) {
          return { ...prevPicks, secondPick: "" };
        } else if (!prevPicks.topPick) {
          return { ...prevPicks, topPick: id };
        } else if (!prevPicks.secondPick) {
          return { ...prevPicks, secondPick: id };
        }
        return prevPicks;
      });
    },
    [setPicks],
  );

  return (
    <Box sx={{ px: 2 }}>
      {question.options.map((option) => (
        <Box
          key={option.optionText}
          onClick={() => handleClick(option.optionId)}
          sx={{
            borderRadius: 2,
            border: "1px solid #aaa",
            p: 2,
            mt: 2,
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            backgroundColor:
              option.optionId === picks.topPick
                ? Color.GREEN_500
                : option.optionId === picks.secondPick
                  ? Color.GREEN_700
                  : "white",
          }}
        >
          <Box
            sx={{
              border: "1px solid #aaa",
              width: 24,
              height: 24,
              borderRadius: 2,
              flexShrink: 0,
              mx: 1,
              textAlign: "center",
              p: 0,
              fontWeight: 600,
              color: Color.WHITE,
              backgroundColor:
                option.optionId === picks.topPick
                  ? Color.MINT
                  : option.optionId === picks.secondPick
                    ? Color.GREEN_500
                    : "white",
            }}
          >
            {option.optionId === picks.topPick ? "1" : option.optionId === picks.secondPick ? "2" : ""}
          </Box>
          <Typography variant="body1">{option.optionText}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default MSQuestion;
