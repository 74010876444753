import { loggedInStudentAtom } from "../../shared/recoil/userAtoms";
import { useRecoilValue } from "recoil";
import ExploreScholarshipsContainer from "../components/exploreScholarships/ExploreScholarshipsContainer";
import { Box } from "@mui/material";

const StudentExploreScholarshipsPage = () => {
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);

  return (
    <>
      {loggedInStudent && (
        <Box>
          <ExploreScholarshipsContainer loggedInStudent={loggedInStudent} />
        </Box>
      )}
    </>
  );
};

export default StudentExploreScholarshipsPage;
