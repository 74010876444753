import { Box } from "@mui/material";
import ExploreCareersContainer from "../components/exploreCareers/ExploreCareersContainer";

const ExploreCareersPage = () => {
  return (
    <>
      <Box>
        <ExploreCareersContainer />
      </Box>
    </>
  );
};

export default ExploreCareersPage;
