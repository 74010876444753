import { ListItemButton, ListItemIcon, ListItemText, ListItem, List } from "@mui/material";
import { TourSelector } from "../../../shared/types/tourEnums";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Color, PageRoute } from "../../../shared/types/enums";
import { useRecoilState } from "recoil";
import { selectedSubMenuAtom } from "../../recoil/navigationAtoms";

const setColor = (loc: string | null, path: string) => {
  return loc === path ? Color.CHALK : "rgba(255, 255, 255, .6)";
};

type MenuItem = {
  label: string;
  tourSelector: TourSelector;
  route: PageRoute;
  icon: string;
  subMenuItems?: { label: string; subRoute: string }[];
};

type Props = {
  item: MenuItem;
};

const MenuListItem = ({ item }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedSubMenu, setSelectedSubMenu] = useRecoilState(selectedSubMenuAtom);
  return (
    <ListItem aria-label={item.label} disablePadding sx={{ display: "block" }}>
      <ListItemButton
        aria-label={item.label}
        data-tut={item.tourSelector}
        sx={{
          height: 48,
          px: 2,
        }}
        onClick={() => navigate(item.route)}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: 2,
            justifyContent: "center",
            color: setColor(location.pathname, item.route),
          }}
        >
          <FeatherIcon
            width="24px"
            fillColor="none"
            icon={item.icon}
            strokeColor={setColor(location.pathname, item.route)}
          />
        </ListItemIcon>
        <ListItemText primary={item.label} sx={{ color: setColor(location.pathname, item.route) }} />
      </ListItemButton>
      {location.pathname === item.route && item.subMenuItems && (
        <List>
          {item.subMenuItems.map((subItem) => (
            <ListItem key={subItem.label} sx={{ p: 0, pl: 6 }}>
              <ListItemButton
                sx={{ color: setColor(selectedSubMenu, subItem.subRoute) }}
                aria-label={subItem.label}
                onClick={() => setSelectedSubMenu(subItem.subRoute)}
              >
                <ListItemText primary={`${subItem.label}`} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </ListItem>
  );
};

export default MenuListItem;
