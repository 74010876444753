import { LogSeverity, LogEventType } from "../../types/logEnums";
import { fetchData } from "../../utils/fetchUtils";
import { Log } from "../../types/types";
import { useAuth } from "../../contexts/AuthContext";
import { FunctionName } from "../../types/enums";
import { useCallback } from "react";
import * as Sentry from "@sentry/react";
import { errorMessageAtom } from "../../recoil/errorAtoms";
import { useSetRecoilState } from "recoil";

type SubmitLogProps = {
  severity?: LogSeverity;
  eventType: LogEventType;
  changeLog?: string;
  error?: Error | null | unknown;
  file: string;
  snackbarMessage?: string | null;
};

const useLogger = () => {
  const { currentAuthUser } = useAuth();
  const setErrorMessage = useSetRecoilState(errorMessageAtom);

  const submitLog = useCallback(
    async ({ eventType, changeLog = "", error = null, file, snackbarMessage = null }: SubmitLogProps) => {
      // Always show snackbar if it is not null
      if (snackbarMessage) {
        setErrorMessage(snackbarMessage);
      }

      const isError = !!error;
      const severity = isError ? LogSeverity.ERROR : LogSeverity.INFO;
      const formattedError = error instanceof Error ? error : new Error("An unknown error occurred");
      try {
        if (!currentAuthUser?.uid) {
          console.warn("No user ID available, skipping log");
          return; // Optionally handle missing user IDs
        }

        await fetchData({
          functionName: FunctionName.EVENT_LOGGER,
          payload: {
            severity: error ? LogSeverity.ERROR : severity,
            eventType,
            changeLog,
            error: isError ? formattedError : undefined,
            file,
            timestamp: new Date().toISOString(),
            userId: currentAuthUser.uid,
          } as Log,
        });
        if (error) {
          Sentry.withScope(function (scope) {
            scope.setUser(currentAuthUser as Sentry.User);
            Sentry.setContext("context", {
              eventType,
              file,
              snackbarMessage,
            });
            Sentry.captureException(formattedError);
          });
        }
      } catch (err: any) {
        const error = new Error("Error logging event: ", err);
        Sentry.captureException(error);
      }
    },
    [currentAuthUser, setErrorMessage],
  );

  return { submitLog };
};

export default useLogger;
