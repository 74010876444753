import React, { useCallback } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { loggedInStaffAtom, userTypeAtom } from "../../recoil/userAtoms";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { PageRoute, UserType } from "../../types/enums";

const StaffStudentToggleButton = () => {
  const [userType, setUserType] = useRecoilState(userTypeAtom);
  const navigate = useNavigate();
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);

  const handleToggle = useCallback(() => {
    if (userType === UserType.STUDENT) {
      setUserType(UserType.TEACHER);
      navigate(PageRoute.STAFF_DASHBOARD);
    } else {
      setUserType(UserType.STUDENT);
      navigate(PageRoute.STUDENT_PROFILE);
    }
  }, [navigate, setUserType, userType]);
  return (
    <>
      {loggedInStaff && (
        <Button onClick={handleToggle} variant="text" sx={{ color: "#fff", borderColor: "#fff" }}>
          {userType === UserType.STUDENT ? "Switch to Staff" : "Switch to Student"}
        </Button>
      )}
    </>
  );
};

export default StaffStudentToggleButton;
