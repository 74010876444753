import { useEffect, useState } from "react";
import industries from "../../../shared/assets/data/industries.json";
import { Industry, JobDetails, StudentRecord } from "../../../shared/types/types";
import useGetFSDocs from "../../../shared/hooks/db/useGetFSDocs";
import { Collection } from "../../../shared/types/enums";

type Props = {
  loggedInStudent: StudentRecord;
  setSelectedIndustryIds: React.Dispatch<React.SetStateAction<number[]>>;
};

const useLoadInitialFilterState = ({ loggedInStudent, setSelectedIndustryIds }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const { getFSDocs } = useGetFSDocs();
  const industriesArray = industries.data as Industry[];

  useEffect(() => {
    const getIndustryIds = async () => {
      const jobIds = [...loggedInStudent.willowRecommendedJobIds, ...loggedInStudent.favoriteJobIds];
      const jobIdsNoDuplicates = Array.from(new Set(jobIds));

      const removedDislikedJobs = jobIdsNoDuplicates.filter((jobId) => !loggedInStudent.dislikedJobIds.includes(jobId));

      let industryIds: number[];
      if (removedDislikedJobs.length === 0) {
        industryIds = industriesArray.map((industry) => industry.id);
      } else if (removedDislikedJobs.length < 30) {
        const jobs = await getFSDocs<JobDetails>({
          col: Collection.JOBS,
          config: { where: ["id", "in", removedDislikedJobs] },
        });
        industryIds = Array.from(new Set(jobs.map((job) => job.industry_id)));
      } else {
        // batch getting jobs in increments of 29
        const batchSize = 29;
        const totalBatches = Math.ceil(removedDislikedJobs.length / batchSize);
        const jobs = [];

        for (let i = 0; i < totalBatches; i++) {
          const batch = removedDislikedJobs.slice(i * batchSize, (i + 1) * batchSize);
          const batchJobs = await getFSDocs<JobDetails>({
            col: Collection.JOBS,
            config: { where: ["id", "in", batch] },
          });
          jobs.push(...batchJobs);
        }
        industryIds = Array.from(new Set(jobs.map((job) => job.industry_id)));
      }

      setSelectedIndustryIds(industryIds);
      setIsLoading(false);
    };

    getIndustryIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { isLoading };
};

export default useLoadInitialFilterState;
