import { Grid, IconButton, Dialog, Box } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import ManageGoalsContainer from "../components/goals/ManageGoalsContainer";
import AlmaSidebarContainer from "../components/almaSidebar/AlmaSidebarContainer";
import { useEffect, useState } from "react";
import { AlmaSidebarStarter, ChatGPTEntry } from "../../shared/types/types";
import useWindowDimensions from "../../shared/hooks/responsiveLayout/useWindowDimensions";
import useGenerateStartingPrompt from "../hooks/alma/useGenerateStartingPrompt";
import { useRecoilValue } from "recoil";
import { personalityTypeAtom } from "../../shared/recoil/personalityTypeAtoms";
import { schoolsAtom } from "../../shared/recoil/schoolAndDistrictAtoms";
import { loggedInStudentAtom } from "../../shared/recoil/userAtoms";

const openingPrompt = `
  You are helping a student improve a SMART goal by providing 2 specific, concise pieces of feedback. 
  Focus on ensuring that the goal is **measurable**, **relevant**, and **time-bound**. 
  Start with a brief affirmation about what is good about their goal. 
  If the goal already meets the criteria for a SMART goal, affirm that it looks great and let them know 
  it’s ready to go without giving further suggestions. 

  Do not overcomplicate your feedback—avoid unnecessary detail, such as tracking methods or interim milestones, 
  unless the student’s goal is vague about measurement or timing. Your purpose is to help the student create a 
  clear, achievable goal, not a perfect one. Do not ask them to outline actions they will take, and do not 
  rewrite the goal for them, even if they ask. Give feedback as short bullet points.

  If the student has revised their goal based on earlier feedback, acknowledge their improvement and confirm 
  if the goal now meets SMART criteria. If only minor adjustments are needed, suggest them in a supportive, 
  encouraging way.

  Keep all responses short and to the point. If the student asks for more information, keep all responses to 1 - 2 short ideas.
`;

const StudentManageGoalsPage = () => {
  const [almaStarters, setAlmaStarters] = useState<AlmaSidebarStarter[]>([
    { label: "Get Feedback", prompt: "", systemContext: "", promptPrefix: "Give me feedback on my goal please:" },
  ]);
  const [chatHistory, setChatHistory] = useState<ChatGPTEntry[]>([]);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false); // State to control the dialog
  const { width } = useWindowDimensions();
  const mobile = width < 900;
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);

  const [personalizedContext, setPersonalizedContext] = useState<string>("");
  const { generateInitialPrompt } = useGenerateStartingPrompt();
  const personalityType = useRecoilValue(personalityTypeAtom);
  const schools = useRecoilValue(schoolsAtom);

  useEffect(() => {
    const getPersonalizedContext = async () => {
      if (!loggedInStudent) return;
      const startingPrompt = await generateInitialPrompt(loggedInStudent, schools, personalityType);
      setPersonalizedContext(startingPrompt);
    };
    getPersonalizedContext();
  }, [generateInitialPrompt, loggedInStudent, personalityType, schools]);

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={8}>
          <ManageGoalsContainer
            setAlmaStarters={setAlmaStarters}
            chatHistory={chatHistory}
            almaStarters={almaStarters}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          {!mobile && (
            <AlmaSidebarContainer
              almaStarters={almaStarters}
              chatHistory={chatHistory}
              setChatHistory={setChatHistory}
              pageContext={personalizedContext + " " + openingPrompt}
              loading={loading}
              setLoading={setLoading}
            />
          )}
        </Grid>
      </Grid>

      {/* Show chat icon button on mobile */}
      {mobile && (
        <IconButton
          onClick={handleDialogOpen}
          sx={{
            position: "fixed",
            bottom: 66,
            right: 16,
            backgroundColor: "#7A47BB",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#693aa6",
            },
          }}
          size="large"
        >
          <ChatIcon />
        </IconButton>
      )}

      {/* Fullscreen dialog for Alma sidebar */}
      <Dialog fullScreen open={dialogOpen} onClose={handleDialogClose}>
        <Box sx={{ p: 0 }}>
          <AlmaSidebarContainer
            almaStarters={almaStarters}
            chatHistory={chatHistory}
            setChatHistory={setChatHistory}
            pageContext={personalizedContext + " " + openingPrompt}
            loading={loading}
            setLoading={setLoading}
            handleDialogClose={handleDialogClose}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default StudentManageGoalsPage;
