import { Box, Typography } from "@mui/material";
import { Color, QuizResultsOption } from "../../../shared/types/enums";
import { PersonalityTypeRecord } from "../../../shared/types/types";
import NavigationButtons from "./NavigationButtons";
import sectionImage from "../../assets/profileImages/sections/college-majors-you-might-enjoy.svg";

type Props = {
  mobile: boolean;
  personalityType: PersonalityTypeRecord;
  onActionButtonClick: (value: QuizResultsOption) => void;
};

const CollegeMajorsYouMightEnjoy = ({ mobile, personalityType, onActionButtonClick }: Props) => {
  return (
    <Box>
      <Typography sx={{ mt: 2, fontWeight: 600 }} variant="h6" fontSize={16} color={Color.ORANGE_700}>
        {personalityType.title}
      </Typography>
      <Typography variant="h3" fontSize={30} color={Color.ORANGE_700}>
        {QuizResultsOption.COLLEGE_MAJORS_YOU_MIGHT_ENJOY}
      </Typography>
      <Box sx={{ mt: 2 }}>
        <img
          style={{ width: "100%", maxWidth: mobile ? "260px" : "480px" }}
          src={sectionImage}
          alt={personalityType.title}
        />
      </Box>
      <Box>
        {personalityType.possibleMajors.map((major, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              py: 4,
              gap: 1,
              borderBottom: index !== personalityType.possibleMajors.length - 1 ? 0.5 : "none",
              borderColor: Color.BLACK,
            }}
          >
            <Box>
              <Typography variant="h4" fontSize={20} color={Color.CHALKBOARD}>
                {major.title}
              </Typography>
              <Typography sx={{ mt: 1 }} variant="body1" fontSize={14} color={Color.CHALKBOARD}>
                {major.description}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
      <NavigationButtons
        mobile={mobile}
        currentTitle={QuizResultsOption.COLLEGE_MAJORS_YOU_MIGHT_ENJOY}
        onNextClick={(nextTitle) => onActionButtonClick(nextTitle)}
        onPreviousClick={(nextTitle) => onActionButtonClick(nextTitle)}
      />
    </Box>
  );
};

export default CollegeMajorsYouMightEnjoy;
