import LinearProgress, { LinearProgressProps } from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const LinearProgressWithLabel = (props: LinearProgressProps & { current: number; possible: number }) => {
  const { current, possible } = props;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "right",
        width: "100%",
      }}
    >
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} value={(current * 100) / possible} />
      </Box>
      <Box sx={{ minWidth: 85 }}>
        <Typography variant="body2" color="text.secondary">{`${current} out of ${possible}`}</Typography>
      </Box>
    </Box>
  );
};

export default LinearProgressWithLabel;
