import { Dispatch } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import EFCForm from "./EFCForm";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import useEditFinancialsDialog from "../../hooks/efcDialog/useEditFinancialsDialog";
import { formatCurrency } from "../../../shared/utils/formatUtils";
import { EFCDataRecord } from "../../../shared/types/types";

type Props = {
  open: boolean;
  efcValue?: number;
  finanicalData?: EFCDataRecord | undefined;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: (efcData: EFCDataRecord, calculatedEFC: number) => void;
};

const EditFinancialsDialog = ({ open, efcValue, finanicalData, setOpen, onSubmit }: Props) => {
  const { efc, form, handleSave, handleClose } = useEditFinancialsDialog({
    efcValue,
    finanicalData,
    setOpen,
    onSubmit,
  });

  return (
    <Dialog open={open}>
      <DialogTitle>Estimated Family Contribution</DialogTitle>
      <DialogContent>
        <EFCForm form={form} handleSave={handleSave} />
        <Typography sx={{ mt: 2 }} variant="h6" textAlign={"center"}>
          {efc ? `Estimated Family Contribution: ${formatCurrency({ amount: efc })}` : ""}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditFinancialsDialog;
