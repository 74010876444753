import { Box, CircularProgress, Container, Grid, Typography } from "@mui/material";
import React from "react";
import ProgramCard from "./Programs/ProgramCard";
import { ProviderProgramRecordTwo } from "../../types/types";

type Props = {
  programs: ProviderProgramRecordTwo[];
  loading: boolean;
  isEmptyFilters: boolean;
  showFiltersDialog: boolean;
  setSelectedProgramDetail: React.Dispatch<React.SetStateAction<ProviderProgramRecordTwo | null>>;
};

const AllProgramResults = ({
  programs,
  loading,
  isEmptyFilters,
  showFiltersDialog,
  setSelectedProgramDetail,
}: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress size={100} />
        </Box>
      ) : !isEmptyFilters && !loading && programs.length === 0 ? (
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" sx={{ textAlign: "center", mt: 2 }}>
            No results found, please adjust your filter settings
          </Typography>
        </Box>
      ) : (
        <>
          {!showFiltersDialog && (
            <Container maxWidth="lg">
              <Grid container spacing={5} sx={{ px: 3, py: 1, pb: 20 }}>
                {programs.map((program) => (
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} key={program.id}>
                    <ProgramCard program={program} setSelectedLearnMoreProgram={setSelectedProgramDetail} />
                  </Grid>
                ))}
              </Grid>
            </Container>
          )}
        </>
      )}
    </Box>
  );
};

export default AllProgramResults;
