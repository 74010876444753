import { useMyListContext } from "../../contexts/myListContext";
import { Box, Grid, Typography } from "@mui/material";
import Empty from "./Empty";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import JobDetailsDialog from "../../../shared/components/exploreCareers/common/jobs/JobDetailsDialog";
import JobCard from "../../../shared/components/exploreCareers/common/jobs/JobCard";

const Careers = () => {
  const { jobs } = useMyListContext();
  const { width } = useWindowDimensions();
  const mobile = width < 900;

  return (
    <>
      {!mobile && (
        <Typography sx={{ mt: 1, p: 1 }} variant="pageTitle">
          Bookmarked Careers
        </Typography>
      )}
      <Box
        sx={{
          height: mobile ? "calc(100vh - 180px)" : "calc(100vh - 60px)",
          overflowY: "auto",
        }}
      >
        <Box sx={{ p: 1 }}>
          {jobs.length === 0 && <Empty />}
          <Grid container spacing={2}>
            {jobs.map((job) => (
              <Grid item xs={12} sm={6} md={6} lg={6} key={job.id}>
                <JobCard job={job} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <JobDetailsDialog />
    </>
  );
};

export default Careers;
