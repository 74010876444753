import { Box, Grid, Typography } from "@mui/material";
import { ScholarshipApplicationRecord, ScholarshipRecord } from "../../../shared/types/types";
import ScholarshipCard from "./ScholarshipCard";

type Props = {
  isMobile: boolean;
  scholarships: ScholarshipRecord[];
  scholarshipApplications: ScholarshipApplicationRecord[];
  setScholarshipApplications: React.Dispatch<React.SetStateAction<ScholarshipApplicationRecord[]>>;
  setSelectedScholarshipApplication: React.Dispatch<React.SetStateAction<ScholarshipApplicationRecord | null>>;
  setSelectedScholarship: React.Dispatch<React.SetStateAction<ScholarshipRecord | null>>;
  setOpenDetailDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenUpdateStatusDialog: React.Dispatch<React.SetStateAction<boolean>>;
};

const AllScholarships = ({
  isMobile,
  scholarships,
  setScholarshipApplications,
  scholarshipApplications,
  setSelectedScholarshipApplication,
  setSelectedScholarship,
  setOpenDetailDialog,
  setOpenUpdateStatusDialog,
}: Props) => {
  return (
    <>
      <Box sx={{ mt: 1, p: 1 }}>{!isMobile && <Typography variant="pageTitle">All Scholarships</Typography>}</Box>
      <Box sx={{ height: "calc(100vh - 60px)", overflowY: "scroll" }}>
        <Grid container spacing={2} sx={{ px: 2, py: 2 }}>
          {scholarships.map((scholarship) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              key={scholarship.id}
              sx={{ display: "flex", flexDirection: "column" }} // Make grid items flex containers
            >
              <ScholarshipCard
                scholarship={scholarship}
                scholarshipApplications={scholarshipApplications}
                setScholarshipApplications={setScholarshipApplications}
                setSelectedScholarshipApplication={setSelectedScholarshipApplication}
                setSelectedScholarship={setSelectedScholarship}
                setOpenDetailDialog={setOpenDetailDialog}
                setOpenUpdateStatusDialog={setOpenUpdateStatusDialog}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default AllScholarships;
