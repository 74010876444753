import { RefObject } from "react";
import { Box, Grid } from "@mui/material";
import useQuizResultsContainer from "../../../shared/hooks/quizResults/useQuizResultsContainer";
import QuizResultsSideNavigation from "../../../shared/components/quizResults/QuizResultsSideNavigation";
import QuizResultsContainer from "../../../shared/components/quizResults/QuizResultsContainer";
import PersonalityTypeFeedback from "./PersonalityTypeFeedback";
import { StudentRecord } from "../../../shared/types/types";

type Props = {
  dialogContentRef: RefObject<HTMLDivElement>;
  loggedInStudent: StudentRecord;
};

const PersonalityTypeDetailsDesktop = ({ dialogContentRef, loggedInStudent }: Props) => {
  const submittedFeedback = loggedInStudent.getStartedData?.finishedPersonalitySuccess;
  const { personalityType, selectedOption, setSelectedOption } = useQuizResultsContainer({ dialogContentRef });
  if (!personalityType) return null;
  return (
    <>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <QuizResultsSideNavigation selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
          </Grid>
          <Grid item xs={12} sm={submittedFeedback ? 9 : 6}>
            <Box className="show-scrollbar" sx={{ height: "70VH", overflowY: "scroll" }}>
              <QuizResultsContainer
                personalityType={personalityType}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
            </Box>
          </Grid>
          {!submittedFeedback && (
            <Grid item xs={12} sm={3}>
              <PersonalityTypeFeedback loggedInStudent={loggedInStudent} />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default PersonalityTypeDetailsDesktop;
