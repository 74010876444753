import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { loggedInStudentAtom } from "../../../../shared/recoil/userAtoms";
import { db } from "../../../../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { JobDetailsRecord } from "../../../../shared/types/types";
import { parseJobsResponse } from "../../../../shared/utils/parserUtils";
import JobCard from "../../../../shared/components/exploreCareers/common/jobs/JobCard";
import { Box, Grid, Typography } from "@mui/material";
import useWindowDimensions from "../../../../shared/hooks/responsiveLayout/useWindowDimensions";

const RecommendedCareers = () => {
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const [jobs, setJobs] = useState<JobDetailsRecord[]>([]);
  const { width } = useWindowDimensions();
  const mobile = width < 900;

  useEffect(() => {
    if (!loggedInStudent) return;
    const getJobs = async () => {
      const jobIds = [...loggedInStudent.willowRecommendedJobIds, ...loggedInStudent.staffRecommendedJobIds];
      //get all job documents from firestore by id
      const jobsRef = collection(db, "jobs");
      const q = query(jobsRef, where("__name__", "in", jobIds));
      const querySnapshot = await getDocs(q);
      const jobData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      })) as JobDetailsRecord[];
      const parsedJobs = parseJobsResponse(jobData);
      setJobs(parsedJobs);
    };
    getJobs();
  }, [loggedInStudent]);

  return (
    <Box>
      {!mobile && <Typography variant="pageTitle">Recommended Careers</Typography>}
      <Box
        sx={{
          height: mobile ? "calc(100vh - 180px)" : "calc(100vh - 60px)",
          overflowY: "scroll",
        }}
      >
        <Box sx={{ p: 1 }}>
          <Grid container spacing={4}>
            {jobs.map((job) => (
              <Grid item xs={12} sm={6} md={6} lg={6} xl={4} key={job.id}>
                <JobCard job={job} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default RecommendedCareers;
