import { useCallback } from "react";
import { getDoc, doc } from "firebase/firestore";
import { db, nationalDB } from "../../../firebase";

interface SendRequestProps {
  col: string;
  id: string;
  national?: boolean;
}

type DBRecord = {
  id: string;
};

type GetDocType = {
  getFSDoc: <T extends DBRecord>(requestProps: SendRequestProps) => Promise<T | null>;
};

const useGetFSDoc = (): GetDocType => {
  const getFSDoc = useCallback(
    async <T extends DBRecord>({ col, id, national = false }: SendRequestProps): Promise<T | null> => {
      const docRef = doc(national ? nationalDB : db, col, id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return { ...docSnap.data(), id: docSnap.id } as T;
      } else {
        return null;
      }
    },
    [],
  );
  return { getFSDoc };
};

export default useGetFSDoc;
