import { Box, Button, ButtonGroup, Typography, TextField } from "@mui/material";
import React from "react";
import useSetFSDoc from "../../../shared/hooks/db/useSetFSDoc";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import { StudentRecord } from "../../../shared/types/types";
import { useSetRecoilState } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import useLogger from "../../../shared/hooks/logging/useLogger";
import { LogEventType } from "../../../shared/types/logEnums";

type Props = {
  loggedInStudent: StudentRecord;
};

const PersonalityTypeFeedback = ({ loggedInStudent }: Props) => {
  const [step, setStep] = React.useState(1); // Tracks the current step
  const [showThankYou, setShowThankYou] = React.useState(false); // Tracks "Thank you" message
  const [selectedValueStep1, setSelectedValueStep1] = React.useState<number | null>(null);
  const [feedbackStep1, setFeedbackStep1] = React.useState<string>("");
  const [selectedValueStep2, setSelectedValueStep2] = React.useState<number | null>(null);
  const [feedbackStep2, setFeedbackStep2] = React.useState<string>("");
  const { setFSDoc } = useSetFSDoc();
  const { updateFSDoc } = useUpdateFSDoc();
  const setLoggedInStudent = useSetRecoilState(loggedInStudentAtom);
  const { submitLog } = useLogger();

  const handleClick = (value: number, stepNumber: number) => {
    if (stepNumber === 1) {
      setSelectedValueStep1(value);
    } else if (stepNumber === 2) {
      setSelectedValueStep2(value);
    }
  };

  const handleFeedbackChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    stepNumber: number,
  ) => {
    if (stepNumber === 1) {
      setFeedbackStep1(event.target.value);
    } else if (stepNumber === 2) {
      setFeedbackStep2(event.target.value);
    }
  };

  const handleNext = () => {
    setStep((prev) => prev + 1);
  };

  const handleSubmit = async () => {
    const now = new Date().toISOString();
    const feedbackData = {
      overallScore: selectedValueStep1,
      overallFeedback: feedbackStep1,
      careerScore: selectedValueStep2,
      careerFeedback: feedbackStep2,
      createdAt: now,
      lastUpdatedAt: now,
    };
    try {
      const promises = [];
      promises.push(setFSDoc({ col: "personalityFeedback", id: loggedInStudent.id, data: feedbackData }));
      promises.push(
        updateFSDoc({
          col: "students",
          id: loggedInStudent.id,
          data: { getStartedData: { finishedPersonalitySuccess: true } },
        }),
      );
      await Promise.all(promises);

      setShowThankYou(true);
      submitLog({
        eventType: LogEventType.SUBMITTED_PERSONALITY_FEEDBACK,
        changeLog: `Student ${loggedInStudent.id} submitted personality feedback`,
        file: "PersonalityTypeFeedback.tsx",
      });

      setTimeout(() => {
        setLoggedInStudent((prev) => (prev ? { ...prev, getStartedData: { finishedPersonalitySuccess: true } } : null));
      }, 1000); // Display thank-you message for 1 second
    } catch (error) {
      console.error("Error submitting feedback: ", error);
      submitLog({
        eventType: LogEventType.SUBMITTED_PERSONALITY_FEEDBACK_ERROR,
        file: "PersonalityTypeFeedback.tsx",
        error: error,
        snackbarMessage: "Error submitting feedback, please refresh and try again.",
      });
    }
  };

  if (showThankYou) {
    return (
      <Box sx={{ p: 2, backgroundColor: "#fff", borderRadius: 2 }}>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          Thank you, your feedback has been saved.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2, backgroundColor: "#fff", borderRadius: 2 }}>
      {step === 1 && (
        <>
          <Typography variant="h6" sx={{ pb: 2 }}>
            How well does this result match your personality?
          </Typography>
          <Typography variant="body1" sx={{ pb: 2 }}>
            Please rate from 1 to 5, where 1 is “Not at all” and 5 is “Completely aligned”.
          </Typography>
          <Box sx={{ pb: 2 }}>
            <ButtonGroup color="primary" aria-label="feedback button group" fullWidth>
              {[1, 2, 3, 4, 5].map((value) => (
                <Button
                  key={value}
                  onClick={() => handleClick(value, 1)}
                  variant={selectedValueStep1 === value ? "contained" : "outlined"}
                >
                  {value}
                </Button>
              ))}
            </ButtonGroup>
          </Box>

          {selectedValueStep1 !== null && selectedValueStep1 >= 1 && selectedValueStep1 <= 4 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1" sx={{ pb: 1 }}>
                What aspects of the result felt off to you?
              </Typography>
              <TextField
                label="Your feedback"
                multiline
                rows={4}
                fullWidth
                value={feedbackStep1}
                onChange={(e) => handleFeedbackChange(e, 1)}
                variant="outlined"
              />
            </Box>
          )}

          <Button variant="contained" sx={{ mt: 2 }} onClick={handleNext} disabled={selectedValueStep1 === null}>
            Next
          </Button>
        </>
      )}

      {step === 2 && (
        <>
          <Typography variant="h6" sx={{ pb: 2 }}>
            How aligned do you feel these career matches are with your aspirations?
          </Typography>
          <Typography variant="body1" sx={{ pb: 2 }}>
            Please rate from 1 to 5, where 1 is “Not at all” and 5 is “Completely aligned”.
          </Typography>
          <Box sx={{ pb: 2 }}>
            <ButtonGroup color="primary" aria-label="feedback button group" fullWidth>
              {[1, 2, 3, 4, 5].map((value) => (
                <Button
                  key={value}
                  onClick={() => handleClick(value, 2)}
                  variant={selectedValueStep2 === value ? "contained" : "outlined"}
                >
                  {value}
                </Button>
              ))}
            </ButtonGroup>
          </Box>

          {selectedValueStep2 !== null && selectedValueStep2 >= 1 && selectedValueStep2 <= 4 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1" sx={{ pb: 1 }}>
                What elements of the career matches didn’t resonate with you?
              </Typography>
              <TextField
                label="Your feedback"
                multiline
                rows={4}
                fullWidth
                value={feedbackStep2}
                onChange={(e) => handleFeedbackChange(e, 2)}
                variant="outlined"
              />
            </Box>
          )}

          <Button onClick={handleSubmit} variant="contained" sx={{ mt: 2 }} disabled={selectedValueStep2 === null}>
            Submit
          </Button>
        </>
      )}
    </Box>
  );
};

export default PersonalityTypeFeedback;
