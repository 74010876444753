import { RefObject, useEffect, useState } from "react";
import { QuizResultsOption } from "../../../shared/types/enums";
import { useRecoilValue } from "recoil";
import { personalityTypeAtom } from "../../recoil/personalityTypeAtoms";

type Props = {
  dialogContentRef: RefObject<HTMLDivElement>;
};

const useQuizResultsContainer = ({ dialogContentRef }: Props) => {
  const [selectedOption, setSelectedOption] = useState<QuizResultsOption>(QuizResultsOption.INTRODUCTION);
  const personalityType = useRecoilValue(personalityTypeAtom);

  useEffect(() => {
    if (dialogContentRef.current) {
      dialogContentRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [dialogContentRef, selectedOption]);

  return {
    personalityType,
    selectedOption,
    setSelectedOption,
  };
};

export default useQuizResultsContainer;
