import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, CircularProgress } from "@mui/material";
import useUploadScholarshipDialog from "../../hooks/scholarships/useUploadScholarshipsDialog";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const UploadScholarshipDialog = ({ open, setOpen }: Props) => {
  const { loading, handleClose, handleFileChange, handleUpload, file } = useUploadScholarshipDialog({
    open,
    setOpen,
  });

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Upload Scholarship CSV</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress /> // Show spinner when loading
        ) : (
          <TextField type="file" onChange={handleFileChange} fullWidth />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleUpload} disabled={!file || loading}>
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadScholarshipDialog;
