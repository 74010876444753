import { useState } from "react";
import { Paper, Container, Box, Button, Typography, TextField } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import { Color, FunctionName, UserType } from "../../types/enums";
import useWindowDimensions from "../../hooks/responsiveLayout/useWindowDimensions";
import logo from "../../assets/branding/inline-chalk.svg";
import { useSetRecoilState } from "recoil";
import { isDemoUserAtom } from "../../recoil/userAtoms";
import { fetchData } from "../../utils/fetchUtils";

const generateRandomEmail = () => {
  const randomString = Math.random().toString(36).substring(7);
  return `demo-student-user-${randomString}@willowed.org`;
};

const DemoLoginContainer = () => {
  const { currentAuthUser, createEmailUser, emailSignIn } = useAuth();
  const [selectedUserType, setSelectedUserType] = useState<UserType>(UserType.STUDENT);
  const setIsDemoUser = useSetRecoilState(isDemoUserAtom);
  const { width } = useWindowDimensions();
  const [passcode, setPasscode] = useState("");
  const [passcodeError, setPasscodeError] = useState("");

  const handleExploreButtonClick = async () => {
    try {
      const response = await fetchData({
        functionName: FunctionName.DEMO_LOGIN,
        payload: {
          passcode,
        },
      });

      if (!response.ok) {
        setPasscodeError("Invalid passcode. Please try again.");
        return;
      }

      setIsDemoUser(true);
      if (selectedUserType === UserType.TEACHER) {
        emailSignIn({ email: "demo-staff@willowed.org", password: "Password1" });
      } else {
        createEmailUser({ email: generateRandomEmail(), password: "Password1" });
      }
    } catch (error) {
      setPasscodeError("An error occurred. Please try again.");
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        pt: width > 900 ? 12 : 4,
        pb: width > 900 ? 0 : 16 /* For iPhone scrolling */,
        height: "100VH",
        overflowY: "auto",
      }}
    >
      <>
        {!currentAuthUser && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
              }}
            >
              <img width="50px" style={{ width: width > 900 ? "50px" : "30px" }} src={logo} alt="Chalk Logo" />
              <Typography
                variant="h3"
                align="center"
                sx={{
                  color: "#ffffff",
                  fontSize: width > 900 ? "36px" : "24px",
                  fontWeight: 500,
                }}
              >
                Willow Education
              </Typography>
            </Box>
            <Paper
              sx={{
                mt: width > 900 ? 4 : 2,
                p: width > 900 ? 6 : 3,
                borderRadius: 3,
              }}
            >
              <Typography variant="body1" sx={{ mb: 2 }}>
                Willow empowers high school students to find their best-fit post-graduation options and gives counselors
                the tools to make guidance more effective.
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                <b>Students:</b> Get personalized college and career recommendations. Explore scholarships and financial
                aid. Simplify the application process.
              </Typography>
              <Typography variant="body1" sx={{ mb: 4 }}>
                <b>School Staff:</b> Track student progress. Reduce paperwork. Support more students with targeted
                resources.
              </Typography>
              <hr />
              <Typography variant="h4" align="center" sx={{ mt: 4, mb: 4 }}>
                Demo Login
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                I want to demo Willow as a...
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "start", gap: 2 }}>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 6,
                    textTransform: "none",
                    color: selectedUserType === UserType.TEACHER ? Color.MINT : "",
                    backgroundColor: selectedUserType === UserType.TEACHER ? Color.SURFACE_GREEN : "",
                  }}
                  onClick={() => setSelectedUserType(UserType.STUDENT)}
                >
                  Student
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 6,
                    textTransform: "none",
                    color: selectedUserType === UserType.STUDENT ? Color.MINT : "",
                    backgroundColor: selectedUserType === UserType.STUDENT ? Color.SURFACE_GREEN : "",
                  }}
                  onClick={() => setSelectedUserType(UserType.TEACHER)}
                >
                  Staff Member
                </Button>
              </Box>
              <TextField
                sx={{ mt: 4, borderRadius: 3 }}
                fullWidth
                margin="normal"
                label="Enter Demo Passcode"
                type="password"
                value={passcode}
                onChange={(e) => {
                  setPasscode(e.target.value);
                  setPasscodeError("");
                }}
                error={!!passcodeError}
                helperText={passcodeError}
              />
              <Button
                sx={{ mt: 2, borderRadius: 3, textTransform: "none" }}
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                onClick={handleExploreButtonClick}
                disabled={!passcode}
              >
                Explore Willow
              </Button>
            </Paper>
          </>
        )}
      </>
    </Container>
  );
};

export default DemoLoginContainer;
