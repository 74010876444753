import { useEffect, useState } from "react";
import { Box, Button, List, ListItem, ListItemText, Typography } from "@mui/material";
import DisplayBox from "../../../shared/components/displayBox/DisplayBox";
import { Color, UserType } from "../../../shared/types/enums";
import { getProfileImage } from "../../../shared/utils/imageUtils";
import { PersonalityTypeRecord, StudentRecord } from "../../../shared/types/types";
import useGetFSDoc from "../../../shared/hooks/db/useGetFSDoc";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import PersonalityTypeDetailsDialog from "../personalityTypeDetails/PersonalityTypeDetailsDialog";

interface Section {
  title: string;
  description: string;
}

type Props = {
  loggedInStudent: StudentRecord;
  setUnlockMatchesOpen?: (open: boolean) => void;
};

const PersonalityType = ({ loggedInStudent, setUnlockMatchesOpen }: Props) => {
  const { getFSDoc } = useGetFSDoc();
  const [superpowerSections, setSuperpowerSections] = useState<Section[]>([]);
  const [personalityType, setPersonalityType] = useState<PersonalityTypeRecord | null>(null);
  const [quizResultsOpen, setQuizResultsOpen] = useState(!loggedInStudent.hasViewedPersonalityType);
  const { width } = useWindowDimensions();
  const mobile = width < 900;

  const formatDescription = (text: string) => {
    const sentences = text.split("! ");
    if (sentences.length > 1) {
      const firstSentence = sentences.shift();
      return (
        <>
          <span style={{ display: "block", marginBottom: "16px" }}>{firstSentence}!</span>
          <span>{sentences.join("! ")}</span>
        </>
      );
    }
    return <span>{text}</span>;
  };

  useEffect(() => {
    const fetchpersonalityType = async () => {
      try {
        const ptDoc = await getFSDoc<PersonalityTypeRecord>({
          col: "personalityTypes",
          id: loggedInStudent.personalityType,
        });
        setPersonalityType(ptDoc);
        if (ptDoc) {
          const superpowerResult = parseText(ptDoc.superpowers);
          setSuperpowerSections(superpowerResult);
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
      }
    };

    fetchpersonalityType();
  }, [getFSDoc, loggedInStudent.personalityType]);

  const parseText = (text: string) => {
    const matches = text.match(/[*-]\s*([^:]+):\s*([^*-]+)/g);

    if (matches) {
      const parsedTitleDescriptions = matches.map((match) => {
        const [, title, description] = match.match(/[*-]\s*([^:]+):\s*(.+)/) || [];
        return {
          title: title?.trim() || "",
          description: description?.trim() || "",
        };
      });

      return parsedTitleDescriptions;
    }

    return [];
  };

  return (
    <>
      {personalityType && (
        <Box sx={{ mt: 2 }}>
          <DisplayBox
            title="Personality Type"
            actionButton={
              <Button onClick={() => setQuizResultsOpen(true)} variant="contained" size="small">
                See Details
              </Button>
            }
          >
            {mobile ? (
              <Box sx={{ p: 1 }}>
                <Typography variant="h3" color={Color.ORANGE_700}>
                  {personalityType.title}
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <img
                    style={{ width: "100%" }}
                    src={getProfileImage(personalityType.title)}
                    alt={personalityType.title}
                  />
                </Box>
                <Typography sx={{ mt: 2 }} variant="body1" fontSize={14} color={Color.CHALKBOARD}>
                  {formatDescription(personalityType.shortDescription)}
                </Typography>
                <Box sx={{ mt: 3, display: "flex", gap: 1 }}>
                  <div style={{ marginTop: 1 }}>
                    <FeatherIcon
                      width="24px"
                      height="24px"
                      icon="star"
                      strokeColor="none"
                      fillColor={Color.ORANGE_700}
                    />
                  </div>
                  <Box>
                    <Typography variant="h5" color={Color.CHALKBOARD}>
                      Your Superpowers
                    </Typography>
                    <List sx={{ listStyleType: "disc", pl: 2 }}>
                      {superpowerSections.map((superpowerSection, index) => (
                        <ListItem key={index} sx={{ display: "list-item", p: 0 }}>
                          <ListItemText
                            sx={{ my: 0 }}
                            primary={
                              <Typography variant="h6" fontSize={14} component="span" color={Color.CHALKBOARD}>
                                {`${superpowerSection.title}: `}
                              </Typography>
                            }
                            secondary={
                              <Typography variant="body1" fontSize={14} component="span" color={Color.CHALKBOARD}>
                                {superpowerSection.description}
                              </Typography>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box sx={{ p: 1 }}>
                <Typography variant="h3" color={Color.ORANGE_700}>
                  {personalityType.title}
                </Typography>
                <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
                  <img
                    style={{ width: "50%" }}
                    src={getProfileImage(personalityType.title)}
                    alt={personalityType.title}
                  />
                </Box>
                <Typography sx={{ mt: 2 }} variant="body1" fontSize={14} color={Color.CHALKBOARD}>
                  {formatDescription(personalityType.shortDescription)}
                </Typography>
                <Box sx={{ mt: 3, display: "flex", gap: 1 }}>
                  <div style={{ marginTop: 1 }}>
                    <FeatherIcon
                      width="24px"
                      height="24px"
                      icon="star"
                      strokeColor="none"
                      fillColor={Color.ORANGE_700}
                    />
                  </div>
                  <Box>
                    <Typography variant="h5" color={Color.CHALKBOARD}>
                      Your Superpowers
                    </Typography>
                    <List sx={{ listStyleType: "disc", pl: 2 }}>
                      {superpowerSections.map((superpowerSection, index) => (
                        <ListItem key={index} sx={{ display: "list-item", p: 0 }}>
                          <ListItemText
                            sx={{ my: 0 }}
                            primary={
                              <Typography variant="h6" fontSize={14} component="span" color={Color.CHALKBOARD}>
                                {`${superpowerSection.title}: `}
                              </Typography>
                            }
                            secondary={
                              <Typography variant="body1" fontSize={14} component="span" color={Color.CHALKBOARD}>
                                {superpowerSection.description}
                              </Typography>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Box>
              </Box>
            )}
          </DisplayBox>
          <PersonalityTypeDetailsDialog
            open={quizResultsOpen}
            userType={UserType.STUDENT}
            setOpen={setQuizResultsOpen}
            setUnlockMatchesOpen={setUnlockMatchesOpen}
            loggedInStudent={loggedInStudent}
          />
        </Box>
      )}
    </>
  );
};

export default PersonalityType;
