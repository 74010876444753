import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Chip,
  Autocomplete,
  Typography,
  IconButton,
} from "@mui/material";
import { Dispatch, useState } from "react";
import { StudentGroup, StudentRecord } from "../../../shared/types/types";
import { Color } from "../../../shared/types/enums";
import CloseIcon from "../../../shared/components/closeIcon/CloseIcon";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import StudentsInGroupTable from "./StudentsInGroupTable";
import EditGroupNameDialog from "./EditGroupNameDialog";
import useManageStudentsInGroupDialog from "../../hooks/settings/useManageStudentsInGroupDialog";
import ConfirmDeleteGroupDialog from "./ConfirmDeleteGroupDialog";

export const ManageStudentsInGroupDialog = ({
  open,
  group,
  students,
  setSelectedGroup,
}: {
  open: boolean;
  group?: StudentGroup;
  students: StudentRecord[];
  setSelectedGroup: Dispatch<React.SetStateAction<StudentGroup | null>>;
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const {
    handleCloseModal,
    handleAddToGroup,
    handleDelete,
    studentsNotInGroup,
    setCurrentGroup,
    currentGroup,
    studentsInGroup,
    handleDeleteGroup,
  } = useManageStudentsInGroupDialog({
    group,
    students,
    setSelectedGroup,
  });

  return (
    <>
      {group && (
        <>
          <Dialog open={open} fullWidth maxWidth="md">
            <DialogTitle
              variant="h4"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <span>{group ? group.groupName : "Select a Group"}</span>
                <IconButton onClick={() => setIsEditModalOpen(true)} sx={{ mt: -0.5, ml: 1 }}>
                  <FeatherIcon icon="edit" />
                </IconButton>
              </Box>
              <CloseIcon onClose={handleCloseModal} />
            </DialogTitle>
            <DialogContent>
              <Typography sx={{ mt: 3 }} variant="h5">
                Add a student
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              ></Box>
              <Grid container sx={{ mt: 2 }} spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs>
                      <Autocomplete
                        multiple
                        options={studentsNotInGroup || []}
                        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                        value={currentGroup}
                        onChange={(event, newValue) => setCurrentGroup(newValue)}
                        renderInput={(params) => (
                          <TextField {...params} label="Search student name" variant="outlined" />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.firstName} {option.lastName}
                          </li>
                        )}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => {
                            const { key, ...tagProps } = getTagProps({ index });
                            return (
                              <Chip
                                key={key}
                                label={`${option.firstName} ${option.lastName}`}
                                sx={{
                                  color: Color.SURFACE_GREEN,
                                  backgroundColor: Color.MINT,
                                  px: 1,
                                  border: "none",
                                }}
                                {...tagProps}
                              />
                            );
                          })
                        }
                      />
                    </Grid>
                    <Grid item>
                      <Button onClick={() => handleAddToGroup(currentGroup)} variant="contained">
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box sx={{ mt: 2, height: "380px", width: "100%" }}>
                <StudentsInGroupTable studentsInGroup={studentsInGroup} handleDelete={handleDelete} />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsDeleteModalOpen(true)} color="error" variant="contained">
                Delete Group
              </Button>
            </DialogActions>
          </Dialog>
          <ConfirmDeleteGroupDialog
            open={isDeleteModalOpen}
            setOpen={setIsDeleteModalOpen}
            handleDeleteGroup={handleDeleteGroup}
          />

          <EditGroupNameDialog
            open={isEditModalOpen}
            setOpen={setIsEditModalOpen}
            group={group}
            setSelectedGroup={setSelectedGroup}
          />
        </>
      )}
    </>
  );
};

export default ManageStudentsInGroupDialog;
