import industries from "../../../../assets/data/industries.json";
import { Industry } from "../../../../types/types";
import IndustryCard from "./IndustryCard";
import { Box, Grid } from "@mui/material";

export const Industries = () => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
    <Grid container alignContent="start" spacing="20px">
      {industries.data.map((industry: Industry, index: number) => (
        <Grid key={index} item xs={12} sm={6} md={6} lg={4} xl={4}>
          <IndustryCard industry={industry} />
        </Grid>
      ))}
    </Grid>
  </Box>
);
