import { useRecoilState, useRecoilValue } from "recoil";
import { loggedInStaffAtom } from "../../../shared/recoil/userAtoms";
import { Message, StudentRecord } from "../../../shared/types/types";
import { useEffect, useState } from "react";
import { getDatabase, onValue, ref, set } from "firebase/database";
import { updateMessages } from "../../../shared/utils/messageFunctionUtils";
import { validatePhoneNumber } from "../../../shared/utils/validationUtils";
import { selectedStudentAtom } from "../../recoil/studentsAtoms";

type Props = {
  student: StudentRecord;
};

export const useStudentPanelRow = ({ student }: Props) => {
  const db = getDatabase();
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const [notifications, setNotifications] = useState(0);
  const [lastMessageTimestamp, setLastMessageTimestamp] = useState("");
  const [selectedStudent, setSelectedStudent] = useRecoilState(selectedStudentAtom);
  const studentPhoneJustNumbers = student?.phone?.replace(/\D/g, "");

  //if the current student is the selected student (which means the user is looking at that students messages), reset the number of notifications of new messages to zero
  useEffect(() => {
    if (!loggedInStaff || !selectedStudent) return;
    if (selectedStudent.id === student.id) {
      const notificationRef = ref(db, `notifications/${loggedInStaff.smsNumber}_${studentPhoneJustNumbers}`);
      set(notificationRef, 0);
    }
  }, [db, loggedInStaff, selectedStudent, student.id, studentPhoneJustNumbers]);

  //subscribe to any new messages for this student
  useEffect(() => {
    if (!loggedInStaff) return;
    const notificationRef = ref(db, `notifications/${loggedInStaff.smsNumber}_${studentPhoneJustNumbers}`);
    const unsubscribeNotifications = onValue(notificationRef, (snapshot) => {
      const numberNewMessages = snapshot.val() ? parseInt(snapshot.val()) : 0;
      setNotifications(numberNewMessages);
    });

    const messagesRef = ref(db, `messages/${loggedInStaff.smsNumber}_${studentPhoneJustNumbers}`);
    const unsubscribeIncomingMessages = onValue(messagesRef, (snapshot) => {
      const newData = snapshot.val();
      const messages: Message[] = updateMessages({
        newMessages: newData,
        existingMessages: [],
        selectedStudent: student,
      });

      const lastMatchingMessage = messages
        .reverse()
        .find((message) => message.staffId === loggedInStaff?.id || message.studentId === student.id);
      const lastMatchingMessageTime = lastMatchingMessage ? lastMatchingMessage.timestamp : "";
      setLastMessageTimestamp(lastMatchingMessageTime);
    });

    return () => {
      unsubscribeNotifications();
      unsubscribeIncomingMessages();
    };
  }, [db, loggedInStaff, student, studentPhoneJustNumbers]);

  const handleSelect = () => {
    setSelectedStudent(student);
  };

  const hasPhone = validatePhoneNumber({
    phoneNumber: studentPhoneJustNumbers,
  });

  return {
    handleSelect,
    selectedStudent,
    notifications,
    hasPhone,
    lastMessageTimestamp,
  };
};
