import { RecoilRoot } from "recoil";
import AppBootstrap from "./AppBootstrap";
import AuthProvider from "./shared/providers/AuthProvider";
import { UserProvider } from "./shared/providers/userProvider";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Update 'tracePropagationTargets' to include both QA and production URLs
  tracePropagationTargets: ["localhost", /^https:\/\/qa\.willowed\.org\/api/, /^https:\/\/app\.willowed\.org\/api/],
  // Session Replay
  replaysSessionSampleRate: process.env.REACT_APP_ENV === "production" ? 0.1 : 0,
  replaysOnErrorSampleRate: process.env.REACT_APP_ENV === "production" ? 1.0 : 0,
  environment: process.env.REACT_APP_ENV || "development",
});

function App() {
  const loadGoogleMapsScript = () => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
    script.async = true;
    document.head.appendChild(script);
  };

  useEffect(() => {
    loadGoogleMapsScript();
  }, []);

  return (
    <RecoilRoot>
      <AuthProvider>
        <UserProvider>
          <AppBootstrap />
        </UserProvider>
      </AuthProvider>
    </RecoilRoot>
  );
}

export default App;
