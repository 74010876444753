import { useState } from "react";
import Papa from "papaparse";
import useAddFSDoc from "../../../shared/hooks/db/useAddFSDoc";
import { Collection } from "../../../shared/types/enums";
import { Scholarship } from "../../../shared/types/types";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const useUploadScholarshipDialog = ({ open, setOpen }: Props) => {
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false); // Loading state
  const { addFSDoc } = useAddFSDoc();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const addScholarship = async (scholarship: Scholarship) => {
    await addFSDoc({
      col: Collection.SCHOLARSHIPS,
      data: scholarship,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpload = async () => {
    if (file) {
      setLoading(true); // Set loading state to true

      Papa.parse(file, {
        header: true, // We assume the CSV has headers
        skipEmptyLines: true,
        complete: async (result) => {
          const data = result.data as any[];

          const scholarships: Scholarship[] = data.map((row: any) => ({
            name: row["Name"],
            awardProgramId: "", // Assign based on your logic
            description: row["Description"],
            districtIds: ["f2ek4E1P3lnQCwP2T5Hj", "uRaObX0IieoVa2SkoQjD"], // Add appropriate district IDs
            sponsorName: row["Organization Name"] || null,
            submissionUrl: row["Website"] || "",
            isActive: true,
            startAt: row["Open Date (and time if applicable)"]
              ? new Date(row["Open Date (and time if applicable)"]).toISOString()
              : null,
            endAt: row["Deadline Date (and time if applicable)"]
              ? new Date(row["Deadline Date (and time if applicable)"]).toISOString()
              : null,
            timezone: null,
            national: row["National (Yes/No)"]?.toLowerCase() === "yes",
            states: row["States Applicable"] ? row["States Applicable"].split(",") : [],
            deadlineDescription: row["Deadline Description"] || null,
            eligibilityGpa: row["GPA Eligibility"] ? parseFloat(row["GPA Eligibility"]) : null,
            eligibilityAcks: [],
            eligibilityResidences: [],
            eligibilityMajors: [],
            eligibilityOrgs: [],
            eligibilityOrgAddresses: [],
            eligibilityDescription: row["Eligibility Description"] || null,
            minAwardAmount: row["Minimum Award Amount"]
              ? parseFloat(row["Minimum Award Amount"].replace(/[^\d]/g, ""))
              : null,
            maxAwardAmount: row["Maximum Award Amount"]
              ? parseFloat(row["Maximum Award Amount"].replace(/[^\d]/g, ""))
              : null,
            awardDescription: row["Award Amount Description"] || null,
            numberOfPrizes: null,
            tags: row["Tags"] ? row["Tags"].replace(/[[\]']+/g, "").split(",") : [],
            questions: [],
          }));

          // Upload scholarships to the database
          for (const scholarship of scholarships) {
            await addScholarship(scholarship);
          }

          setLoading(false); // Set loading state to false
          handleClose(); // Close the dialog after upload
        },
      });
    }
  };
  return { handleFileChange, handleUpload, loading, open, handleClose, file };
};

export default useUploadScholarshipDialog;
