import { useState, useCallback } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db, nationalDB } from "../../../firebase";

interface SendRequestProps {
  col: string;
  data: object;
  id: string;
  national?: boolean;
}

const useUpdateFSDoc = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateFSDoc = useCallback(
    async ({ col, data, id, national = false }: SendRequestProps): Promise<string | null> => {
      setIsLoading(true);
      setError(null);
      const now = new Date().toISOString();
      const finalData = { ...data, lastUpdatedAt: now };
      const docRef = doc(national ? nationalDB : db, col, id);
      await updateDoc(docRef, finalData);
      setIsLoading(false);
      return null;
    },
    [],
  );
  return { isLoading, error, updateFSDoc };
};

export default useUpdateFSDoc;
