import { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const ResetPasswordDialog = ({ open, setOpen }: Props) => {
  const { resetPassword } = useAuth();
  const [email, setEmail] = useState("");

  const handleClose = () => {
    setEmail("");
    setOpen(false);
  };

  const handleReset = () => {
    resetPassword(email);
    setEmail("");
    setOpen(false);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle variant="h4">Reset Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText variant="h6">
            Please enter the email address you used to create your account and instructions for how to reset your
            password will be emailed to that address.
          </DialogContentText>
          <TextField fullWidth label="Email Address" sx={{ mt: 2 }} value={email} onChange={handleEmailChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleReset} color="primary" disabled={!isValidEmail(email)}>
            Reset Password
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ResetPasswordDialog;
