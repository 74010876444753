import { MouseEvent, useEffect } from "react";

import Careers from "./Careers";
import Videos from "./Videos";
import Programs from "./Programs";
import NavigationToggle from "./NavigationToggle";
import { StudentRecord } from "../../../shared/types/types";
import { useMyListContext } from "../../contexts/myListContext";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import { Box } from "@mui/material";
import { selectedSubMenuAtom } from "../../recoil/navigationAtoms";
import { useRecoilState } from "recoil";

type Props = {
  loggedInStudent: StudentRecord;
};

const MyListContainer = ({ loggedInStudent }: Props) => {
  const [selectedSubMenu, setSelectedSubMenu] = useRecoilState(selectedSubMenuAtom);
  const { width } = useWindowDimensions();

  useEffect(() => {
    setSelectedSubMenu("bookmarkedCareers");
  }, [setSelectedSubMenu]);

  const handleSelected = (_: MouseEvent<HTMLElement>, value: string | null) => {
    if (value === selectedSubMenu || value === null) return;
    setSelectedSubMenu(value);
  };
  const { loading } = useMyListContext();
  return (
    <>
      {!loading && (
        <>
          {width < 900 ? (
            <>
              <NavigationToggle selected={selectedSubMenu} handleSelected={handleSelected} />
              {selectedSubMenu === "bookmarkedVideos" && <Videos loggedInStudent={loggedInStudent} />}
              {selectedSubMenu === "bookmarkedCareers" && <Careers />}
              {selectedSubMenu === "bookmarkedPrograms" && <Programs />}
            </>
          ) : (
            <Box sx={{ mt: 1, p: 1 }}>
              {selectedSubMenu === "bookmarkedVideos" && <Videos loggedInStudent={loggedInStudent} />}
              {selectedSubMenu === "bookmarkedCareers" && <Careers />}
              {selectedSubMenu === "bookmarkedPrograms" && <Programs />}
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default MyListContainer;
