// almaStyles.ts

const chatMessageStyle = {
  padding: "10px",
  margin: "10px",
  borderRadius: "10px",
  maxWidth: "85%",
  width: "fit-content",
  fontFamily: "poppins",
  fontSize: "14px",
};

export const formatChatBackground = (role: string) =>
  ({
    user: {
      ...chatMessageStyle,
      backgroundColor: "#EBF8FF",
    },
    assistant: {
      ...chatMessageStyle,
      backgroundColor: "#EDFCF9",
    },
  })[role];
