import { Dispatch, SetStateAction } from "react";
import { Box, Dialog, DialogContent, IconButton, Grid } from "@mui/material";
import useProviderDetailsDialog from "../../hooks/providerDetails/useProviderDetailsDialog";
import FeatherIcon from "../../assets/icons/featherIcons/FeatherIcon";
import { Color } from "../../types/enums";
import ProgramDetailsDialog from "../programDetails/ProgramDetailsDialog";
import ProviderInfo from "./ProviderInfo";
import ProviderStats from "./ProviderStats";
import ProgramList from "./ProgramList";

type Props = {
  isEditable?: boolean;
  providerId: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const ProviderDetailsDialog = ({ isEditable = true, providerId, open, setOpen }: Props) => {
  const {
    mobile,
    currentProvider,
    programsByProvider,
    selectedLearnMoreProgram,
    setSelectedLearnMoreProgram,
    providerImageUrl,
  } = useProviderDetailsDialog({
    providerId,
  });

  if (!currentProvider) return null;

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="lg" fullScreen={mobile} onClose={() => setOpen(false)}>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 16,
            top: 16,
            color: Color.DARK_GRAY,
            backgroundColor: Color.WHITE,
            "&:hover": {
              backgroundColor: Color.GRAY_200,
            },
          }}
        >
          <FeatherIcon icon="close" strokeWidth="1" />
        </IconButton>
        <DialogContent sx={{ p: 0 }}>
          {providerImageUrl && (
            <Box sx={{ width: "100%", height: mobile ? "200px" : "287px" }}>
              <img
                src={providerImageUrl}
                alt={currentProvider.providerName || ""}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderTopLeftRadius: 4,
                  borderTopRightRadius: 4,
                }}
              />
            </Box>
          )}
          <Box sx={{ px: 3, pt: 2, pb: 3 }}>
            <ProviderInfo provider={currentProvider} />
            <Box sx={{ mt: 3 }}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item sm={7}>
                  <ProviderStats provider={currentProvider} />
                  <ProgramList
                    programs={programsByProvider}
                    setSelectedLearnMoreProgram={setSelectedLearnMoreProgram}
                  />
                </Grid>
                {/* Video Section */}
                {/* <Grid item sm={5}></Grid> */}
              </Grid>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      {selectedLearnMoreProgram && (
        <ProgramDetailsDialog
          isEditable={isEditable}
          selectedProgram={selectedLearnMoreProgram}
          setSelectedProgram={setSelectedLearnMoreProgram}
        />
      )}
    </>
  );
};

export default ProviderDetailsDialog;
