import { Box, Typography } from "@mui/material";
import { Color, QuizResultsOption } from "../../types/enums";
import { PersonalityTypeRecord } from "../../types/types";
import NavigationButtons from "./NavigationButtons";

type Props = {
  mobile: boolean;
  personalityType: PersonalityTypeRecord;
  onActionButtonClick: (value: QuizResultsOption) => void;
};

const StudyTips = ({ mobile, personalityType, onActionButtonClick }: Props) => {
  const splitTitleAndDescription = (text: string): string[] => {
    const parts = text.split(": ");
    if (parts.length === 2) {
      return parts.map((part) => part.replace(/[*_~`]/g, "").trim());
    }
    return ["", ""];
  };

  return (
    <Box>
      <Typography sx={{ mt: 2, fontWeight: 600 }} variant="h6" fontSize={16} color={Color.ORANGE_700}>
        {personalityType.title}
      </Typography>
      <Typography variant="h3" fontSize={30} color={Color.ORANGE_700}>
        {QuizResultsOption.STUDY_TIPS}
      </Typography>
      <Box>
        {personalityType.studyTips.map((tip, index) => (
          <Box
            key={index}
            sx={{
              py: 4,
              borderBottom: index !== personalityType.personalGoals.length - 1 ? 0.5 : "none",
              borderColor: Color.BLACK,
            }}
          >
            <Typography variant="h4" fontSize={20} color={Color.CHALKBOARD}>
              {splitTitleAndDescription(tip)[0]}
            </Typography>
            <Typography variant="body1" fontSize={14} color={Color.CHALKBOARD} sx={{ mt: 1 }}>
              {splitTitleAndDescription(tip)[1]}
            </Typography>
          </Box>
        ))}
      </Box>
      <NavigationButtons
        mobile={mobile}
        currentTitle={QuizResultsOption.STUDY_TIPS}
        onNextClick={(nextTitle) => onActionButtonClick(nextTitle)}
        onPreviousClick={(nextTitle) => onActionButtonClick(nextTitle)}
      />
    </Box>
  );
};

export default StudyTips;
