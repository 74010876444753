import { Box, Typography, Chip } from "@mui/material";
import { Color, UserType } from "../../types/enums";
import { ProviderTwoRecord } from "../../types/types";
import { useRecoilValue } from "recoil";
import { loggedInStudentAtom, userTypeAtom } from "../../recoil/userAtoms";
import { generateAccessLevel } from "../../utils/programUtils";

type Props = {
  provider: ProviderTwoRecord;
};

const ProviderInfo = ({ provider }: Props) => {
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const userType = useRecoilValue(userTypeAtom);

  const formatURL = (url: string) => (url.includes("http://") || url.includes("https://") ? url : `http://${url}`);

  const accessLevelBadge = () => {
    if (!loggedInStudent) return <></>;

    const accessLevel = generateAccessLevel({
      gpaValue: loggedInStudent.gpaValue,
      gpaMax: loggedInStudent.gpaMax,
      act25th: provider.act25th,
      act75th: provider.act75th,
      openAdmin: provider.openAdmin,
      admissionPercent: provider.admissionPercent,
      act: loggedInStudent.act,
    });

    switch (accessLevel) {
      case "Safety":
        return <Chip label="Safety" size="small" color="safetyLevelColor" />;
      case "Target":
        return <Chip label="Target" size="small" color="targetLevelColor" />;
      case "Reach":
        return <Chip label="Reach" size="small" color="reachLevelColor" />;
      case "Unknown":
        return <Chip label="Far Reach" size="small" color="farReachLevelColor" />;
    }
  };

  return (
    <Box>
      <Typography variant="h4" sx={{ fontSize: 30, color: Color.CHARCOAL, lineHeight: "36px" }}>
        {provider.providerName}
      </Typography>
      <Box
        sx={{
          mt: 0.5,
          display: "flex",
          alignItems: "center",
          gap: 2.5,
          py: 0.5,
        }}
      >
        <Typography sx={{ fontSize: 14, fontWeight: 400, color: Color.GRAY_800 }}>
          {`${provider.city}, ${provider.state}`}
        </Typography>
        <Typography sx={{ fontSize: 14, fontWeight: 400, color: Color.GRAY_800 }}>|</Typography>
        {provider.homeURL && (
          <a
            style={{ fontSize: 14, fontWeight: 400, color: Color.GRAY_800 }}
            target="_blank"
            href={formatURL(provider.homeURL)}
            rel="noreferrer"
          >
            {provider.homeURL.replace(/\/$/, "")}
          </a>
        )}
        {userType === UserType.STUDENT && (
          <>
            <Typography sx={{ fontSize: 14, fontWeight: 400, color: Color.GRAY_800 }}>|</Typography>
            {accessLevelBadge()}
          </>
        )}
      </Box>
      <Typography sx={{ fontSize: 12, fontWeight: 400, color: Color.CHALKBOARD }}>
        {provider.providerDescription}
      </Typography>
    </Box>
  );
};

export default ProviderInfo;
