import React, { useEffect, useRef } from "react";
import { ChatGPTEntry } from "../../../shared/types/types";
import { Box } from "@mui/material";
import Message from "./Message";

type Props = {
  chatHistory: ChatGPTEntry[];
};

const AlmaChatHistory = ({ chatHistory }: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const chatForDisplay = chatHistory.filter((chat) => chat.role !== "system");

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [chatHistory]);
  return (
    <div>
      {chatForDisplay.map((entry, index) => (
        <Box key={`message_${index}`}>
          <Message index={index} entry={entry} />
        </Box>
      ))}
    </div>
  );
};

export default AlmaChatHistory;
