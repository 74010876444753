import { userTypeAtom } from "../../../shared/recoil/userAtoms";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useRecoilState } from "recoil";
import { PageRoute, UserType } from "../../../shared/types/enums";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

const StaffStudentToggle = () => {
  const [userType, setUserType] = useRecoilState(userTypeAtom);
  const navigate = useNavigate();

  const handleToggle = useCallback(
    (event: SelectChangeEvent) => {
      if (event.target.value === UserType.TEACHER) {
        setUserType(UserType.TEACHER);
        navigate(PageRoute.STAFF_DASHBOARD);
      } else {
        setUserType(UserType.STUDENT);
        navigate(PageRoute.STUDENT_PROFILE);
      }
    },
    [navigate, setUserType],
  );

  return (
    <>
      {userType && (
        <Select value={userType} onChange={handleToggle}>
          <MenuItem value={UserType.TEACHER}>Staff</MenuItem>
          <MenuItem value={UserType.STUDENT}>Student</MenuItem>
        </Select>
      )}
    </>
  );
};

export default StaffStudentToggle;
