import { useState } from "react";
import { ProviderProgramRecordTwo } from "../../../shared/types/types";
import { useMyListContext } from "../../contexts/myListContext";
import ProgramCard from "../../../shared/components/explorePrograms/Programs/ProgramCard";
import Empty from "./Empty";
import { Box, Container, Grid, Typography } from "@mui/material";
import ProgramDetailsDialog from "../../../shared/components/programDetails/ProgramDetailsDialog";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";

const Programs = () => {
  const { programs } = useMyListContext();
  const [selectedLearnMoreProgram, setSelectedLearnMoreProgram] = useState<ProviderProgramRecordTwo | null>(null);
  const { width } = useWindowDimensions();
  const mobile = width < 900;

  return (
    <>
      {!mobile && (
        <Typography sx={{ mt: 1, p: 1 }} variant="pageTitle">
          Bookmarked Programs
        </Typography>
      )}
      <Box sx={{ height: mobile ? "calc(100vh - 180px)" : "calc(100vh - 60px)", overflowY: "auto" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: mobile ? 1 : 0 }}>
          {programs.length === 0 ? (
            <Empty />
          ) : (
            <Container maxWidth="lg">
              <Grid container spacing={5} sx={{ px: 3, py: 1, pb: 20 }}>
                {programs.map((program) => (
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} key={program.id}>
                    <ProgramCard
                      key={program.id}
                      program={program}
                      setSelectedLearnMoreProgram={setSelectedLearnMoreProgram}
                    />
                  </Grid>
                ))}
              </Grid>
            </Container>
          )}
        </Box>
      </Box>
      {selectedLearnMoreProgram && (
        <ProgramDetailsDialog
          selectedProgram={selectedLearnMoreProgram}
          setSelectedProgram={setSelectedLearnMoreProgram}
        />
      )}
    </>
  );
};

export default Programs;
