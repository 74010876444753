import { Box, Typography } from "@mui/material";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import { Color } from "../../types/enums";

type DiversityChartProps = {
  data: { name: string; value: number }[];
};

const COLORS = ["#137C67", "#1DBC9D", "#4BE2C4", "#B9F4E8", "#B85300", "#FF7E14", "#FFB375", "#FFEBDB"];

const DiversityChart = ({ data }: DiversityChartProps) => (
  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
    <Typography
      sx={{
        flex: "none",
        width: "150px",
        fontSize: 14,
        fontWeight: 700,
        color: Color.CHARCOAL,
      }}
    >
      Race & ethnicity diversity:
    </Typography>
    <PieChart width={188} height={188}>
      <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={45} outerRadius={90}>
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
    </PieChart>
  </Box>
);

export default DiversityChart;
