import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import LoadingBlock from "../../../shared/components/loading/LoadingBlock";
import useRecommendProgramDialog from "../../hooks/programCard/useRecommendProgramDialog";
import { formatProgramName } from "../../../shared/utils/formatUtils";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const RecommendProgramDialog = () => {
  const {
    loading,
    students,
    handleStudentsChange,
    selectedStudents,
    selectedProgramForRecommendations,
    setSelectedProgramForRecommendations,
    handleSave,
  } = useRecommendProgramDialog();

  return (
    <>
      <Dialog
        open={Boolean(selectedProgramForRecommendations)}
        onClose={() => {
          setSelectedProgramForRecommendations(null);
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle variant="h5">
          {`Recommend ${formatProgramName({
            name: selectedProgramForRecommendations?.programName ?? "",
          })} from ${selectedProgramForRecommendations?.providerName}`}
        </DialogTitle>
        {loading ? (
          <LoadingBlock size={100} mt={8} />
        ) : (
          <DialogContent>
            <Box sx={{ mt: 4 }}>
              <Autocomplete
                multiple
                id="students-program-recommendation"
                options={students}
                onChange={handleStudentsChange}
                disableCloseOnSelect
                value={selectedStudents}
                getOptionLabel={(option) => option.firstName + " " + option.lastName}
                renderOption={(props, option, { selected }) => {
                  const { ...optionProps } = props;
                  return (
                    <li {...optionProps} key={option.id}>
                      <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                      {option.firstName + " " + option.lastName}
                    </li>
                  );
                }}
                renderInput={(params) => <TextField {...params} label="Students" placeholder="Students" />}
              />
            </Box>
          </DialogContent>
        )}
        <DialogActions>
          <Button disabled={loading} onClick={handleSave}>
            Save
          </Button>
          <Button disabled={loading} onClick={() => setSelectedProgramForRecommendations(null)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RecommendProgramDialog;
