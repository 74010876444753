import React, { useCallback, useEffect } from "react";
import { Box, Dialog } from "@mui/material";
import { FilterProps, SearchedProvider } from "../../../types/types";
import { Color } from "../../../types/enums";
import { initialFiltersValues } from "../../../utils/initialProgramFiltersUtil";
import FilterForm from "./FilterForm";

type Props = {
  isDialogMode: boolean; // Should we show a dialog overlay?
  filters: FilterProps;
  setFilters: React.Dispatch<React.SetStateAction<FilterProps>>;
  handleFilterButtonClick: () => void;
  searchedProviders: SearchedProvider[];
  setSearchedProviders: React.Dispatch<React.SetStateAction<SearchedProvider[]>>;
  showFiltersDialog: boolean;
  setShowFiltersDialog: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
};

function ProgramFilterAndSearchBar({
  isDialogMode,
  filters,
  setFilters,
  handleFilterButtonClick,
  searchedProviders,
  setSearchedProviders,
  showFiltersDialog,
  setShowFiltersDialog,
  loading,
}: Props) {
  // "Search" or "Apply Filters" clicked
  const searchButton = useCallback(() => {
    // If we were in dialog mode, close it
    if (isDialogMode) {
      setShowFiltersDialog(false);
    }
    handleFilterButtonClick();
  }, [isDialogMode, handleFilterButtonClick, setShowFiltersDialog]);

  // Reset everything
  const handleResetButtonClick = useCallback(() => {
    setFilters(initialFiltersValues);
    setSearchedProviders([]);
  }, [setFilters, setSearchedProviders]);

  // Just close the dialog
  const handleCloseButtonClick = useCallback(() => {
    setShowFiltersDialog(false);
  }, [setShowFiltersDialog]);


  /**
   * This is the single set of filters. We do NOT conditionally unmount it.
   * We render it once, and optionally cover it with a dialog if isDialogMode is true.
   */

  return (
    <>
      {/* 
        1) The single FilterForm is always rendered in the normal (inline) position.
           We can visually hide it if you really only want to see it when NOT in dialog:
           style={{ display: isDialogMode ? "none" : "block" }}
      */}
      <Box
        sx={{
          display: isDialogMode || loading ? "none" : "block",
        }}
      >
        <FilterForm
          isDialogMode={isDialogMode}
          filters={filters}
          setFilters={setFilters}
          handleResetButtonClick={handleResetButtonClick}
          searchButton={searchButton}
          handleCloseButtonClick={handleCloseButtonClick}
          searchedProviders={searchedProviders}
          setSearchedProviders={setSearchedProviders}
        />
      </Box>

      {/* 
        2) A Dialog that covers the same FilterForm if isDialogMode is true.
           We only "show" the dialog when showFiltersDialog && isDialogMode.
           If you want it always physically in the DOM, just do open={showFiltersDialog}.
           If you never want the inline copy, remove the inline block entirely.
      */}
      <Dialog
        open={showFiltersDialog && isDialogMode}
        fullWidth
        maxWidth="md"
        onClose={handleCloseButtonClick}
        hideBackdrop
        TransitionProps={{ timeout: 0 }}
      >
        <Box sx={{ background: Color.GRAY_50, p: "20px" }}>
          <FilterForm
            isDialogMode={isDialogMode}
            filters={filters}
            setFilters={setFilters}
            handleResetButtonClick={handleResetButtonClick}
            searchButton={searchButton}
            handleCloseButtonClick={handleCloseButtonClick}
            searchedProviders={searchedProviders}
            setSearchedProviders={setSearchedProviders}
          />
        </Box>
      </Dialog>
    </>
  );
}

export default ProgramFilterAndSearchBar;
