/**
 * Custom hook for managing the student table in the counselor dashboard.
 * This hook provides functionality to handle row click events, open student dialog, and retrieve student data.
 *
 * @returns An object containing the following properties:
 *   - onRowClick: A function to handle row click events.
 *   - studentRows: An array of student rows.
 *   - studentDialogOpen: A boolean indicating whether the student dialog is open or not.
 *   - setStudentDialogOpen: A function to set the state of the student dialog.
 */

import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { StaffDashboardStudentRow } from "../../../shared/types/types";
import { allSchoolStudentsSelector, selectedStudentAtom } from "../../recoil/studentsAtoms";
import { studentRowsAtom } from "../../recoil/dashboardAtoms";

type Return = {
  onRowClick: (row: StaffDashboardStudentRow) => void;
  studentRows: StaffDashboardStudentRow[] | null;
  studentDialogOpen: boolean;
  setStudentDialogOpen: Dispatch<SetStateAction<boolean>>;
};

const useStudentTable = (): Return => {
  const [studentDialogOpen, setStudentDialogOpen] = useState(false);
  const students = useRecoilValue(allSchoolStudentsSelector);
  const setSelectedStudent = useSetRecoilState(selectedStudentAtom);
  const studentRows = useRecoilValue(studentRowsAtom);

  const onRowClick = useCallback(
    (row: StaffDashboardStudentRow) => {
      if (!students) return;
      const selectedStudent = students.find((student) => student.id === row.id);
      setSelectedStudent(selectedStudent || null);
      setStudentDialogOpen(true);
    },
    [setSelectedStudent, students],
  );

  return { onRowClick, studentRows, studentDialogOpen, setStudentDialogOpen };
};

export default useStudentTable;
