import { useCallback, useEffect, useState } from "react";
import { JobDetailsRecord } from "../../../shared/types/types";
import { useRecoilValue } from "recoil";
import { selectedStudentAtom } from "../../recoil/studentsAtoms";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import RecommendJobDialog from "../exploreCareers/Common/RecommendJobDialog";
import BookmarkedCareers from "./BookmarkedCareers";
import RecommendedCareers from "./RecommendedCareers";
import { Box } from "@mui/material";
import { parseJobsResponse } from "../../../shared/utils/parserUtils";

const CareersContainer = () => {
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const [bookmarkedJobs, setBookmarkedJobs] = useState<JobDetailsRecord[]>([]);
  const [recommendedJobs, setRecommendedJobs] = useState<JobDetailsRecord[]>([]);

  const [mode, setMode] = useState<"bookmarked" | "recommended">("bookmarked");

  const switchMode = useCallback(() => {
    if (mode === "bookmarked") {
      setMode("recommended");
    } else {
      setMode("bookmarked");
    }
  }, [mode, setMode]);

  useEffect(() => {
    setBookmarkedJobs([]);
    setRecommendedJobs([]);
    if (!selectedStudent) return;

    const getJobs = async () => {
      const jobIds = [
        ...selectedStudent.willowRecommendedJobIds,
        ...selectedStudent.staffRecommendedJobIds,
        ...selectedStudent.favoriteJobIds,
      ];

      const jobsRef = collection(db, "jobs");
      let allJobs: JobDetailsRecord[] = [];

      // Split jobIds into chunks of 10
      const jobIdChunks = chunkArray(jobIds, 10);

      // Fetch jobs for each chunk
      for (const chunk of jobIdChunks) {
        const q = query(jobsRef, where("__name__", "in", chunk));
        const querySnapshot = await getDocs(q);
        const jobData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        })) as JobDetailsRecord[];
        allJobs = [...allJobs, ...jobData];
      }
      const parsedJobs = parseJobsResponse(allJobs);
      setBookmarkedJobs(parsedJobs.filter((job) => selectedStudent.favoriteJobIds.includes(job.id)));
      setRecommendedJobs(
        parsedJobs.filter(
          (job) =>
            selectedStudent.willowRecommendedJobIds.includes(job.id) ||
            selectedStudent.staffRecommendedJobIds.includes(job.id),
        ),
      );
    };

    getJobs();
  }, [selectedStudent]);

  return (
    <>
      <Box>
        {mode === "bookmarked" ? (
          <BookmarkedCareers handleSwitchMode={switchMode} bookmarkedJobs={bookmarkedJobs} />
        ) : (
          <RecommendedCareers handleSwitchMode={switchMode} recommendedJobs={recommendedJobs} />
        )}
      </Box>
      <RecommendJobDialog />
    </>
  );
};

export default CareersContainer;

// Function to split array into chunks
const chunkArray = (arr: string[], size: number) => {
  return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size));
};
