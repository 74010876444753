import { Box, Chip, Grid, Typography } from "@mui/material";
import { useMemo } from "react";
import { formatCurrency, numberToPercent } from "../../../shared/utils/formatUtils";
import { Color } from "../../../shared/types/enums";
import infoIcon from "../../../shared/assets/icons/info.svg";
import WhatIsThat from "../../../shared/components/whatIsThat/WhatIsThat";
import { ProviderProgramRecordTwo } from "../../../shared/types/types";

type Props = {
  setWhatIsROIOpen: React.Dispatch<React.SetStateAction<boolean>>;
  program: ProviderProgramRecordTwo;
  personalizedCost: number | null;
};

const formatROIColor = (roi: number | null) => {
  if (!roi) {
    return "#aaa";
  } else if (roi >= 100000) {
    return Color.MINT;
  } else if (roi >= 0) {
    return Color.ORANGE_700;
  } else {
    return Color.PINK;
  }
};

const formatROILabel = (roi: number | null) => {
  if (!roi) {
    return "Unknown";
  } else if (roi >= 100000) {
    return "Good";
  } else if (roi >= 0) {
    return "Okay";
  } else {
    return "Poor";
  }
};

const formatPercentColor = (rate: number | null) => {
  if (!rate) {
    return "#aaa";
  } else if (rate > 0.8) {
    return Color.MINT;
  } else if (rate > 0.5) {
    return Color.ORANGE_700;
  } else {
    return Color.PINK;
  }
};

const formatPercentLabel = (rate: number | null) => {
  if (!rate) {
    return "Unknown";
  } else if (rate > 0.8) {
    return "Good";
  } else if (rate > 0.5) {
    return "Okay";
  } else {
    return "Poor";
  }
};

const QualityMeasures = ({ program, setWhatIsROIOpen, personalizedCost }: Props) => {
  const personalizedROI = useMemo(() => {
    const roi = program.willowROICalculated;
    if (personalizedCost === null) {
      return roi ? roi : null;
    } else {
      const personalizedCostDifference = program.totalCostCalculated - personalizedCost;
      const personalizedROI = program.willowROICalculated - personalizedCostDifference;
      const finalROI = personalizedROI > 0 ? personalizedROI : 0;
      return finalROI ? finalROI : null;
    }
  }, [personalizedCost, program]);
  const InstitutionTootip = useMemo(
    () => ({
      title: "Return on Investment (ROI)",
      description: `The ROI (Return on Investment) shown for this program is actually the ROI for all of ${program.providerName}, 
    not this specific program. This happens when there isn’t enough data available for us to calculate the 
    ROI for the program itself.`,
    }),
    [program.providerName],
  );

  const completionRate = program.completionRateCalculated ? program.completionRateCalculated : null;

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <Box
            sx={{
              backgroundColor: Color.SURFACE_GREEN,
              textAlign: "left",
              borderRadius: 1,
              pl: 2,
              m: 0,
              height: 110,
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: 34,
                color: program.programEarningsCalculated ? "#17191" : Color.STANDARD_GRAY,
                p: 0,
                m: 0,
              }}
              variant="h6"
            >
              {personalizedROI ? formatCurrency({ amount: personalizedROI }) : "Unknown"}
            </Typography>
            {!program.programEarningsCalculated ? (
              <Box sx={{ display: "flex", alignItems: "center", pb: 0.5 }}>
                <Typography sx={{ color: Color.STANDARD_GRAY, mt: -1 }}>(Institution)</Typography>
                <Box sx={{ mt: -2 }}>
                  <WhatIsThat information={InstitutionTootip} />
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  pb: 1,
                }}
              >
                <Typography sx={{ fontWeight: 400, fontSize: 12, mt: -1 }} variant="h5">
                  ROI: Earnings After 10 Years
                </Typography>
                <img
                  src={infoIcon}
                  alt="info"
                  width="12px"
                  height="12px"
                  style={{
                    cursor: "pointer",
                    marginLeft: "4px",
                    marginTop: "-8px",
                  }}
                  onClick={() => setWhatIsROIOpen(true)}
                />
              </Box>
            )}

            <Box sx={{ with: "fit-content" }}>
              <Chip
                sx={{ backgroundColor: formatROIColor(personalizedROI), color: "#fff" }}
                label={formatROILabel(personalizedROI)}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              backgroundColor: Color.SURFACE_GREEN,
              textAlign: "left",
              borderRadius: 1,
              p: 0,
              m: 0,
              height: 110,
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: 34,
                color: "#171918",
                p: 0,
                m: 0,
              }}
              variant="h6"
            >
              {completionRate ? numberToPercent({ number: completionRate }) : "Not Known"}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                pb: 1,
              }}
            >
              <Typography sx={{ fontWeight: 400, fontSize: 12, mt: -1 }} variant="h5">
                Completion Rate
              </Typography>
            </Box>

            <Box sx={{ with: "fit-content" }}>
              <Chip
                sx={{
                  backgroundColor: formatPercentColor(completionRate),
                  color: "#fff",
                }}
                label={formatPercentLabel(completionRate)}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default QualityMeasures;
