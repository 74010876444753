import { Box, IconButton, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { mentorUsersAtom } from "../../../shared/recoil/userAtoms";
import DisplayBox from "../../../shared/components/displayBox/DisplayBox";
import { useState } from "react";
import { Collection, Color } from "../../../shared/types/enums";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import { MentorUserRecord } from "../../../shared/types/types";
import CustomAvatar from "../../../shared/components/avatar/CustomAvatar";
import EditMentorDialog from "./EditMentorDialog";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";

const Mentors = () => {
  const [open, setOpen] = useState(false);
  const mentorUsers = useRecoilValue(mentorUsersAtom);
  const [selectedMentorUser, setSelectedMentorUser] = useState<MentorUserRecord | null>(null);
  const { width } = useWindowDimensions();
  const mobile = width < 900;

  const handleAddClick = () => {
    setSelectedMentorUser(null);
    setOpen(true);
  };

  const handleEditClick = (mentorUser: MentorUserRecord) => {
    setSelectedMentorUser(mentorUser);
    setOpen(true);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <DisplayBox
        title="Mentors"
        titleSX={{ color: Color.DARK_GREEN }}
        actionButton={
          <IconButton sx={{ color: Color.STANDARD_GRAY }} disableRipple onClick={handleAddClick}>
            <FeatherIcon width="30" icon="add" />
          </IconButton>
        }
      >
        <Box
          className="show-scrollbar"
          sx={{
            display: "flex",
            overflowX: "auto",
            width: mobile ? "calc(100vw - 96px)" : "100%",
          }}
        >
          {mentorUsers.map((mentorUser) => (
            <Box
              key={mentorUser.id}
              sx={{
                flex: "none",
                display: "flex",
                flexDirection: "column",
                gap: 1,
                alignItems: "center",
                padding: 2,
              }}
            >
              <CustomAvatar
                size={70}
                fileName={mentorUser.avatar ?? ""}
                collection={Collection.MENTOR_USERS}
                documentId={mentorUser.id}
                url={mentorUser.avatarURL}
                edit={true}
              />
              <Box sx={{ display: "flex", gap: 1, alignItems: "center", pl: 1 }}>
                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                  {mentorUser.firstName + " " + mentorUser.lastName}
                </Typography>
                <IconButton sx={{ p: 0 }} onClick={() => handleEditClick(mentorUser)}>
                  <FeatherIcon width="15px" icon="edit" />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
      </DisplayBox>
      <EditMentorDialog open={open} setOpen={setOpen} mentorUser={selectedMentorUser} />
    </Box>
  );
};

export default Mentors;
