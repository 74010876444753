import { MouseEvent, useCallback, useEffect } from "react";
import { Box } from "@mui/material";
import ExploreCareersRouter from "../../../shared/components/exploreCareers/ExploreCareersRouter";
import RecommendedCareers from "./common/RecommendedCareers";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import TopNavigationToggle from "./navigation/TopNavigationToggle";
import JobDetailsDialog from "../../../shared/components/exploreCareers/common/jobs/JobDetailsDialog";
import { selectedSubMenuAtom } from "../../recoil/navigationAtoms";
import { useRecoilState } from "recoil";

const ExploreCareersContainer = () => {
  const { width } = useWindowDimensions();
  const [selectedSubMenu, setSelectedSubMenu] = useRecoilState(selectedSubMenuAtom);
  const mobile = width < 900;

  useEffect(() => {
    setSelectedSubMenu("recommendedCareers");
  }, [setSelectedSubMenu]);

  const handleSelected = useCallback(
    (_event: MouseEvent<HTMLElement>, value: string | null) => {
      if (value === selectedSubMenu || value === null) return;
      setSelectedSubMenu(value);
    },
    [selectedSubMenu, setSelectedSubMenu],
  );

  return (
    <>
      {mobile ? (
        <>
          <TopNavigationToggle selected={selectedSubMenu} handleSelected={handleSelected} />
          {selectedSubMenu === "allCareers" ? <ExploreCareersRouter /> : <RecommendedCareers />}
        </>
      ) : (
        <Box sx={{ mt: 1, p: 1 }}>
          {selectedSubMenu === "allCareers" ? <ExploreCareersRouter /> : <RecommendedCareers />}
        </Box>
      )}
      <JobDetailsDialog />
    </>
  );
};

export default ExploreCareersContainer;
