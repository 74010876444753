// almaConstants.ts

export const STARTER_PROMPTS = [
  {
    text: "Writing a Great College Essay",
    svg: "almaEdit",
    prompt:
      "Hi Alma, I need some tips on writing a college essay that will help me stand out. Can you give me advice on what to include?",
  },
  {
    text: "Picking a College",
    svg: "almaSchool",
    prompt:
      "Hi Alma, I'm trying to figure out which college might be a good fit for me. Can you ask me a few questions to help guide my choice and recommend some options?",
  },
  {
    text: "Possible Career Paths",
    svg: "almaGrowth",
    prompt:
      "Hi Alma, I want to learn more about different career paths. Can you help me understand the job market and required skills for careers that are a good fit?",
  },
  {
    text: "Discovering My Purpose",
    svg: "almaBook",
    prompt:
      "Hi Alma, beyond just making money, I want my future work to feel meaningful. Can you ask me some questions (one at a time) to help me discover my purpose?",
  },
  {
    text: "Should I Go To College?",
    svg: "almaGraduation",
    prompt: "Alma, I’m not sure if I should go to college or not. Can you help me refine and evaluate my options?",
  },
  {
    text: "Paying for College or Training",
    svg: "almaMoney",
    prompt:
      "Hi Alma, I’m looking for ways to pay for college or a training program. Can you explain the different options like scholarships, grants, and loans?",
  },
  {
    text: "Getting an Internship",
    svg: "almaPerson",
    prompt:
      "Hi Alma, I want to get some work experience. Can you help me understand how to find internships or other opportunities like volunteering or job shadowing?",
  },
];
