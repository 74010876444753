import { DataGridPremium, useGridApiRef, useKeepGroupedColumnsHidden } from "@mui/x-data-grid-premium";
import { Box } from "@mui/material";
import useScholarshipTable from "../../hooks/scholarships/useScholarshipTable";

const ScholarshipTable = () => {
  const { rows, columns } = useScholarshipTable();
  const apiRef = useGridApiRef();

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ["scholarshipId"],
      },
      aggregation: {
        model: {
          awardAmount: "sum",
          applicationCount: "sum",
        },
      },
    },
  });

  return (
    <>
      <Box sx={{ height: "calc(100VH - 168px)", width: "100%" }}>
        <style>{`
          .MuiDataGrid-aggregationColumnHeaderLabel {
            display: none !important;
          }
        `}</style>
        <DataGridPremium
          columns={columns}
          rows={rows}
          rowHeight={50}
          apiRef={apiRef}
          isRowSelectable={() => false}
          initialState={initialState}
          groupingColDef={{
            hideDescendantCount: true,
          }}
          getRowId={(row) => row.id} // Ensure unique row IDs
        />
      </Box>
    </>
  );
};

export default ScholarshipTable;
