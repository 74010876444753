import { useState, useMemo, useCallback, useEffect } from "react";
import { StudentRecord } from "../../../shared/types/types";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { answersAtom, currentQuestionIndexAtom, questionsAtom } from "../../recoil/quizAtoms";

function shuffleArray<T>(array: T[]): T[] {
  return [...array].sort(() => Math.random() - 0.5);
}

type Props = {
  loggedInStudent: StudentRecord;
};

const useQuestionSelector = ({ loggedInStudent }: Props) => {
  const questions = useRecoilValue(questionsAtom);
  const setCurrentQuestionIndexAtom = useSetRecoilState(currentQuestionIndexAtom);
  const answers = useRecoilValue(answersAtom);
  const [state, setState] = useState(() => {
    const initialIndex = loggedInStudent.lastQuestionId
      ? Math.min(questions.findIndex((q) => q.id === loggedInStudent.lastQuestionId) + 1, questions.length - 1)
      : 0;
    return {
      currentQuestionIndex: initialIndex,
      isLastQuestion: initialIndex === questions.length - 1,
    };
  });

  const currentQuestion = useMemo(() => {
    if (!questions[state.currentQuestionIndex]) return null;
    const { options, ...rest } = questions[state.currentQuestionIndex];
    return { ...rest, options: shuffleArray(options) };
  }, [questions, state.currentQuestionIndex]);

  useEffect(() => {
    setCurrentQuestionIndexAtom(state.currentQuestionIndex);
  }, [setCurrentQuestionIndexAtom, state.currentQuestionIndex]);

  const currentAnswer = useMemo(
    () => answers.find((a) => a.questionId === currentQuestion?.id) ?? null,
    [answers, currentQuestion],
  );

  const goBack = useCallback(() => {
    setState((prevState) => {
      const newIndex = Math.max(prevState.currentQuestionIndex - 1, 0);
      return {
        currentQuestionIndex: newIndex,
        isLastQuestion: newIndex === questions.length - 1,
      };
    });
  }, [questions.length]);

  const goForward = useCallback(() => {
    setState((prevState) => {
      const newIndex = Math.min(prevState.currentQuestionIndex + 1, questions.length - 1);
      return {
        currentQuestionIndex: newIndex,
        isLastQuestion: newIndex === questions.length - 1,
      };
    });
  }, [questions.length]);

  return {
    currentQuestion,
    currentAnswer,
    goBack,
    goForward,
    isLastQuestion: state.isLastQuestion,
  };
};

export default useQuestionSelector;
