import React, { useState } from "react";
import { Box, Checkbox, Divider, IconButton, Typography } from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { MilestoneRecord } from "../../../shared/types/types";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import ConfirmDeleteMilestoneDialog from "./ConfirmDeleteMilestone";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import { Collection } from "../../../shared/types/enums";

type Props = {
  milestone: MilestoneRecord;
  studentId: string;
  setMilestoneToEdit: React.Dispatch<React.SetStateAction<MilestoneRecord | null>>;
  setMilestones: React.Dispatch<React.SetStateAction<MilestoneRecord[]>>;
};

const MilestoneCard = ({ milestone, setMilestoneToEdit, setMilestones }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const { updateFSDoc } = useUpdateFSDoc();

  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = () => setDialogOpen(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const handleConfirmDelete = async () => {
    await updateFSDoc({
      col: Collection.MILESTONES,
      id: milestone.id,
      data: {
        ...milestone,
        active: false,
      },
    });
    setMilestones((prev) => prev.filter((m) => m.id !== milestone.id));
    setDialogOpen(false);
  };

  const handleCheckboxChange = async () => {
    const newState = !milestone.completed;
    setMilestones((prev) => prev.map((m) => (m.id === milestone.id ? { ...m, completed: newState } : m)));
    await updateFSDoc({
      col: Collection.MILESTONES,
      id: milestone.id,
      data: {
        ...milestone,
        completed: newState,
      },
    });
  };

  const formatDueDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const handleEdit = () => {
    setMilestoneToEdit(milestone);
  };

  const formatDateColor = (dateString: string, completed: boolean) => {
    const date = new Date(dateString);
    const today = new Date();
    date.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    if (date < today && !completed) {
      return "#f00";
    }
    return "#555";
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 1, px: 1, ml: 5, mt: 1 }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            checked={milestone.completed}
            onChange={handleCheckboxChange}
            icon={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  border: "1px solid #ccc",
                  width: 20,
                  height: 20,
                }}
              >
                <RadioButtonUncheckedIcon sx={{ color: "transparent" }} />
              </Box>
            }
            checkedIcon={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  border: "1px solid #777",
                  width: 20,
                  height: 20,
                  backgroundColor: "#fff",
                }}
              >
                <CheckCircleIcon sx={{ color: "#777" }} />
              </Box>
            }
          />

          <Box>
            <Typography
              variant="body1"
              sx={{
                flexGrow: 1,
                marginLeft: "8px",
                color: milestone.completed ? "#777" : "#333",
                textDecoration: milestone.completed ? "line-through" : "none",
              }}
            >
              {milestone.milestone}
            </Typography>
            <Box display="flex" alignItems="center" sx={{ mt: 0.5, ml: 1 }}>
              <CalendarTodayIcon
                sx={{ fontSize: 16, color: formatDateColor(milestone.dueDate, milestone.completed), mr: 0.5 }}
              />
              <Typography variant="body2" sx={{ color: formatDateColor(milestone.dueDate, milestone.completed) }}>
                {formatDueDate(milestone.dueDate)}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ opacity: isHovered ? 1 : 0, transition: "opacity 0.3s ease" }}>
          <IconButton
            onClick={handleEdit}
            sx={{
              width: "32px",
              height: "32px",
              borderRadius: "50%",
            }}
          >
            <FeatherIcon icon="edit" width={"16px"} />
          </IconButton>
          <IconButton
            onClick={handleOpenDialog}
            sx={{
              width: "32px",
              height: "32px",
              borderRadius: "50%",
            }}
          >
            <FeatherIcon icon="trash" width={"16px"} />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <ConfirmDeleteMilestoneDialog open={dialogOpen} onClose={handleCloseDialog} onConfirm={handleConfirmDelete} />
    </>
  );
};

export default MilestoneCard;
