import { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid-premium";
import { Box, Chip } from "@mui/material";
import useGetFSDocs from "../../../shared/hooks/db/useGetFSDocs";
import {
  ManualScholarshipRecord,
  ScholarshipApplicationRecord,
  ScholarshipRecord,
  ScholarshipStudentTableRow,
  StudentRecord,
} from "../../../shared/types/types";
import { Collection } from "../../../shared/types/enums";
import { useRecoilValue } from "recoil";
import { allSchoolStudentsSelector } from "../../recoil/studentsAtoms";
import { AvatarCell } from "../../components/scholarships/AvatarCell";

const useStudentTable = () => {
  const [rows, setRows] = useState<ScholarshipStudentTableRow[]>([]);
  const allStudents = useRecoilValue(allSchoolStudentsSelector);
  const { getFSDocs } = useGetFSDocs();

  const buildRow = ({
    scholarships,
    applications,
    students,
    manualScholarships,
  }: {
    scholarships: ScholarshipRecord[];
    applications: ScholarshipApplicationRecord[];
    students: StudentRecord[];
    manualScholarships: ManualScholarshipRecord[];
  }) => [
    ...buildRowsFromApplications(applications, scholarships, students),
    ...buildRowsFromManualScholarships(manualScholarships, students),
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [applications, scholarships, manualScholarships] = await Promise.all([
          getFSDocs<ScholarshipApplicationRecord>({
            col: Collection.SCHOLARSHIP_APPLICATIONS,
          }),
          getFSDocs<ScholarshipRecord>({ col: Collection.SCHOLARSHIPS }),
          getFSDocs<ManualScholarshipRecord>({
            col: Collection.MANUAL_SCHOLARSHIPS,
          }),
        ]);

        setRows(
          buildRow({
            scholarships,
            applications,
            students: allStudents,
            manualScholarships,
          }),
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [allStudents, getFSDocs]);

  const columns: GridColDef[] = [
    {
      field: "studentId",
      headerName: "Student Name",
      width: 200,
      renderCell: (params) => <StudentNameCell value={params.value} rows={rows} />,
    },
    {
      field: "scholarshipName",
      headerName: "Scholarship Name",
      width: 300,
      renderCell: (params) => <ScholarshipNameCell value={params.value} />,
    },
    {
      field: "applicationCount",
      headerName: "Total",
      width: 50,
      type: "number",
      renderCell: (params) => {
        if (params.rowNode.type === "group") {
          return params.value;
        } else {
          return null;
        }
      },
    },
    {
      field: "awardAmount",
      headerName: "Award Amount",
      minWidth: 80,
      flex: 1,
      type: "number",
      renderCell: (params) => {
        return `$${params.formattedValue}`;
      },
    },
    {
      field: "submittedOn",
      headerName: "Submitted On",
      minWidth: 80,
      flex: 1,
      renderCell: (params) => {
        if (!params.value || params.value === "Invalid Date") return ""; // Handle case where value is undefined or null
        const date = new Date(params.value);
        const formattedDate = `${
          String(date.getMonth() + 1).padStart(2, "0") // Add 1 to month since it's zero-based
        }/${String(date.getDate()).padStart(2, "0")}/${date.getFullYear()}`;
        return formattedDate;
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 80,
      flex: 1,
      renderCell: (params) => {
        return params.value ? (
          <Chip
            sx={{ fontSize: 10 }}
            label={params.value}
            color={
              params.value === "applied"
                ? "lightGreen"
                : params.value === "awarded"
                  ? "primary"
                  : params.value === "rejected"
                    ? "lightPink"
                    : "default"
            }
          />
        ) : (
          ""
        );
      },
    },
  ];
  return { rows, columns };
};

export default useStudentTable;

const buildRowsFromApplications = (
  applications: ScholarshipApplicationRecord[],
  scholarships: ScholarshipRecord[],
  students: StudentRecord[],
): ScholarshipStudentTableRow[] => {
  return applications
    .map((application) => {
      const scholarship = scholarships.find((sch) => sch.id === application.scholarshipId);
      const student = students.find((stu) => stu.id === application.studentId);
      if (!scholarship || !student) return null;

      return {
        id: application.id,
        scholarshipName: `${scholarship.name} - ${scholarship.sponsorName}`,
        studentId: student.id,
        studentName: `${student.firstName} ${student.lastName}`,
        submittedOn: application.appliedDate || "",
        status: application.status,
        awardAmount: Number(application.awardAmount) || 0,
        hasApplications: true,
        applicationCount: 1,
        avatar: student.avatar || "",
      };
    })
    .filter(Boolean) as ScholarshipStudentTableRow[];
};

const buildRowsFromManualScholarships = (
  manualScholarships: ManualScholarshipRecord[],
  students: StudentRecord[],
): ScholarshipStudentTableRow[] => {
  return manualScholarships
    .map((manualScholarship) => {
      const student = students.find((stu) => stu.id === manualScholarship.studentId);
      if (!student) return null;

      return {
        id: manualScholarship.id,
        scholarshipName: `${manualScholarship.name} (Manual) - ${manualScholarship.sponsorName}`,
        studentId: student.id,
        studentName: `${student.firstName} ${student.lastName}`,
        submittedOn: manualScholarship.appliedDate || "",
        status: manualScholarship.status,
        awardAmount: Number(manualScholarship.awardAmount) || 0,
        hasApplications: false,
        applicationCount: 1,
        avatar: student.avatar || "",
      };
    })
    .filter(Boolean) as ScholarshipStudentTableRow[];
};

const StudentNameCell = ({ value, rows }: { value: string; rows: ScholarshipStudentTableRow[] }) => {
  if (value) {
    const row = rows.find((row) => row.studentId === value);
    return row ? (
      <div style={{ display: "flex", alignItems: "center" }}>
        <AvatarCell avatar={row.avatar} studentName={row.studentName} />
        <span>{row.studentName}</span>
      </div>
    ) : null;
  }
  return null;
};

const ScholarshipNameCell = ({ value }: { value: string }) => {
  if (!value) return null;
  const [name, sponsor] = value.split(" - ");
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div>{name}</div>
      {sponsor && <div style={{ fontSize: "0.55rem", color: "#888" }}>{sponsor}</div>}
    </Box>
  );
};
