import { Box, Button, Divider, Paper, Tabs, Tab } from "@mui/material";
import { Suspense, useCallback, useState } from "react";
import StaffTable from "./StaffTable";
import { StaffRecord, StudentGroup } from "../../../shared/types/types";
import ManageStaffDialog from "./ManageStaffDialog";
import { useRecoilValue } from "recoil";
import LoadingBlock from "../../../shared/components/loading/LoadingBlock";
import { allSchoolStudentsAtom, schoolGroupsAtom } from "../../recoil/studentsAtoms";
import { BLANK_GROUP_FORM, BLANK_STAFF_FORM } from "../../../shared/utils/blankUtils";
import { ManageGroupsDialog } from "./ManageGroupsDialog";
import { ManageStudentsInGroupDialog } from "./ManageStudentsInGroupDialog";
import GroupsTable from "./GroupsTable";

const SchoolSettingsContainer = () => {
  const [manageStaffOpen, setManageStaffOpen] = useState(false);
  const [manageGroupsOpen, setManageGroupsOpen] = useState(false);

  const [staffForm, setStaffForm] = useState<StaffRecord>({
    ...BLANK_STAFF_FORM,
  });
  const [groupForm, setGroupForm] = useState<StudentGroup>({
    ...BLANK_GROUP_FORM,
  });

  const students = useRecoilValue(allSchoolStudentsAtom);
  const groups = useRecoilValue(schoolGroupsAtom);

  const [activeTab, setActiveTab] = useState(0);

  const [selectedGroup, setSelectedGroup] = useState<StudentGroup | null>(null);

  const handleNewStaffClick = useCallback(() => {
    setStaffForm({ ...BLANK_STAFF_FORM });
    setManageStaffOpen(true);
  }, []);

  const handleNewGroupClick = useCallback(() => {
    setGroupForm({ ...BLANK_GROUP_FORM });
    setManageGroupsOpen(true);
  }, []);
  return (
    <>
      {students ? (
        <>
          <Box sx={{ p: 2 }}>
            <Paper sx={{ backgroundColor: "#fff", borderRadius: 1.5, mt: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 2,
                  p: 1,
                }}
              >
                <Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)}>
                  <Tab label="Staff" />
                  <Tab label="Student Groups" />
                </Tabs>
                {activeTab === 0 ? (
                  <Box>
                    <Button variant="outlined" onClick={handleNewStaffClick}>
                      Add Staff
                    </Button>
                  </Box>
                ) : (
                  <Box>
                    <Button variant="outlined" onClick={handleNewGroupClick}>
                      Add New Group
                    </Button>
                  </Box>
                )}
              </Box>
              <Divider />
              <Suspense fallback={<LoadingBlock size={80} />}>
                {activeTab === 0 ? ( // Show StaffTable when activeTab is 0
                  <StaffTable setStaffForm={setStaffForm} setManageStaffOpen={setManageStaffOpen} />
                ) : (
                  // Show StudentTable when activeTab is 1
                  <GroupsTable groups={groups!} students={students} setSelectedGroup={setSelectedGroup} />
                )}
              </Suspense>
            </Paper>
          </Box>
        </>
      ) : (
        <LoadingBlock size={80} />
      )}
      <ManageStaffDialog
        open={manageStaffOpen}
        setOpen={setManageStaffOpen}
        staffForm={staffForm}
        setStaffForm={setStaffForm}
      />

      <ManageGroupsDialog
        open={manageGroupsOpen}
        setOpen={setManageGroupsOpen}
        groupForm={groupForm}
        setGroupForm={setGroupForm}
        groups={groups!}
      />

      <ManageStudentsInGroupDialog
        open={!!selectedGroup}
        group={selectedGroup!}
        students={students}
        setSelectedGroup={setSelectedGroup}
      />
    </>
  );
};

export default SchoolSettingsContainer;
