import { Color, PageRoute } from "../../../shared/types/enums";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { Box, ListItemIcon, ListItemText } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { TourSelector } from "../../../shared/types/tourEnums";
import StaffStudentToggleButton from "../../../shared/components/navigation/StaffStudentToggleButton";

type StaffSideMenuProps = {
  open: boolean;
};

const StaffSideMenu = ({ open }: StaffSideMenuProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "85vh" }}>
      <Box>
        {/* Dashboard */}
        <ListItem aria-label="Dashboard" disablePadding sx={{ display: "block" }}>
          <ListItemButton
            aria-label="Dashboard"
            data-tut={TourSelector.HOME_NAV}
            sx={{
              height: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            onClick={() => navigate(PageRoute.STAFF_DASHBOARD)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                color: location.pathname === PageRoute.STAFF_DASHBOARD ? Color.MINT : Color.CHALK,
              }}
            >
              <FeatherIcon
                width="24px"
                fillColor="none"
                icon="home"
                strokeColor={location.pathname === PageRoute.STAFF_DASHBOARD ? "#1DBC9D" : "#F4F0DC"}
              />
            </ListItemIcon>
            <ListItemText primary="Dashboard" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>

        {/* Expore Careers */}
        <ListItem aria-label="Explore Careers" disablePadding sx={{ display: "block" }}>
          <ListItemButton
            aria-label="Explore Careers"
            data-tut={TourSelector.CAREERS_NAV}
            sx={{
              height: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            onClick={() => navigate(PageRoute.STAFF_EXPLORE_CAREERS)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                color: location.pathname === PageRoute.STAFF_EXPLORE_CAREERS ? Color.MINT : Color.CHALK,
              }}
            >
              <FeatherIcon
                width="24px"
                fillColor="none"
                icon="careers"
                strokeColor={location.pathname === PageRoute.STAFF_EXPLORE_CAREERS ? "#1DBC9D" : "#F4F0DC"}
              />
            </ListItemIcon>
            <ListItemText primary="Explore Careers" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>

        {/* Explore Programs */}
        <ListItem aria-label="Explore Programs" disablePadding sx={{ display: "block" }}>
          <ListItemButton
            aria-label="Explore Programs"
            data-tut={TourSelector.PROGRAMS_NAV}
            sx={{
              justifyContent: open ? "initial" : "center",
              height: 48,
            }}
            onClick={() => navigate(PageRoute.STAFF_EXPLORE_PROGRAMS)}
          >
            <Box sx={{ marginRight: open ? 3 : "auto", width: 32, height: 48 }}>
              <FeatherIcon
                width="32px"
                icon="programs"
                strokeColor={location.pathname === PageRoute.STAFF_EXPLORE_PROGRAMS ? "#1DBC9D" : "#F4F0DC"}
              />
            </Box>
            <ListItemText primary="Explore Programs" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        {/* Expore Scholarships */}
        <ListItem aria-label="Scholarships" disablePadding sx={{ display: "block" }}>
          <ListItemButton
            aria-label="Scholarships"
            data-tut={TourSelector.SCHOLARSHIPS_NAV}
            sx={{
              height: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            onClick={() => navigate(PageRoute.STAFF_SCHOLARSHIPS)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                color: location.pathname === PageRoute.STAFF_SCHOLARSHIPS ? Color.MINT : Color.CHALK,
              }}
            >
              <FeatherIcon
                width="24px"
                fillColor="none"
                icon="dollar-sign"
                strokeColor={location.pathname === PageRoute.STAFF_SCHOLARSHIPS ? "#1DBC9D" : "#F4F0DC"}
              />
            </ListItemIcon>
            <ListItemText primary="Scholarships" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>

        {/* Alma */}
        <ListItem aria-label="Alma AI Advisor" disablePadding sx={{ display: "block" }}>
          <ListItemButton
            aria-label="Alma AI Advisor"
            sx={{
              height: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            onClick={() => navigate(PageRoute.STAFF_ALMA)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                color: location.pathname === PageRoute.STAFF_ALMA ? Color.MINT : Color.CHALK,
              }}
            >
              <FeatherIcon
                width="24px"
                fillColor="none"
                icon="ai"
                strokeColor={location.pathname === PageRoute.STAFF_ALMA ? Color.MINT : Color.CHALK}
              />
            </ListItemIcon>
            <ListItemText primary="Alma AI Advisor" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        {/* Messages */}
        <ListItem aria-label="Messages" disablePadding sx={{ display: "block" }}>
          <ListItemButton
            aria-label="Messages"
            sx={{
              height: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            onClick={() => navigate(PageRoute.STAFF_MESSAGES)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                color: location.pathname === PageRoute.STAFF_MESSAGES ? Color.MINT : Color.CHALK,
              }}
            >
              <FeatherIcon
                width="24px"
                fillColor="none"
                icon="message-circle"
                strokeColor={location.pathname === PageRoute.STAFF_MESSAGES ? Color.MINT : Color.CHALK}
              />
            </ListItemIcon>
            <ListItemText primary="Messages" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        {/* Program List */}
        <ListItem aria-label="Program List" disablePadding sx={{ display: "block" }}>
          <ListItemButton
            aria-label="Program List"
            sx={{
              height: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.2,
            }}
            onClick={() => navigate(PageRoute.STAFF_PROGRAM_LIST)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                color: location.pathname === PageRoute.STAFF_PROGRAM_LIST ? Color.MINT : Color.CHALK,
              }}
            >
              <FeatherIcon
                width="24px"
                fillColor="none"
                icon="list"
                strokeColor={location.pathname === PageRoute.STAFF_PROGRAM_LIST ? Color.MINT : Color.CHALK}
              />
            </ListItemIcon>
            <ListItemText primary="Program List" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        {/*Settings*/}
        <ListItem aria-label="Settings" disablePadding sx={{ display: "block" }}>
          <ListItemButton
            aria-label="Settings"
            sx={{
              height: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            onClick={() => navigate(PageRoute.STAFF_SETTINGS)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                color: location.pathname === PageRoute.STAFF_SETTINGS ? Color.MINT : Color.CHALK,
              }}
            >
              <FeatherIcon
                width="24px"
                fillColor="none"
                icon="settings"
                strokeColor={location.pathname === PageRoute.STAFF_SETTINGS ? Color.MINT : Color.CHALK}
              />
            </ListItemIcon>
            <ListItemText primary="Settings" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 2 }}>
        <StaffStudentToggleButton />
      </Box>
    </Box>
  );
};

export default StaffSideMenu;
