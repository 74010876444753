import { useState } from "react";
import { Typography } from "@mui/material";
import { Color } from "../../../shared/types/enums";

type Props = {
  content: string;
  maxLength?: number;
  sx?: object;
};

const ExpandableText = ({ content, maxLength = 95, sx }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const isTruncated = content.length > maxLength;
  const displayedText = isExpanded || !isTruncated ? content : `${content.substring(0, maxLength)}...`;

  return (
    <Typography sx={{ ...sx }}>
      {displayedText}

      {isTruncated && (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 700,
            color: Color.MINT,
            cursor: "pointer",
          }}
          component="span"
          onClick={toggleExpand}
        >
          {isExpanded ? " Show Less" : " Show More"}
        </Typography>
      )}
    </Typography>
  );
};

export default ExpandableText;
