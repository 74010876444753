import {
  currentGroupAtom,
  schoolGroupsAtom,
  currentGroupStudentsAtom,
  allSchoolStudentsAtom,
} from "../../recoil/studentsAtoms";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { MenuItem, Select } from "@mui/material";
import { loggedInStaffAtom } from "../../../shared/recoil/userAtoms";
import { allSchoolsAtom } from "../../../shared/recoil/schoolAndDistrictAtoms";
import { useCallback, useEffect } from "react";
import { ProviderProgramRecordTwo, StudentGroup, StudentRecord } from "../../../shared/types/types";
import useGetFSDocs from "../../../shared/hooks/db/useGetFSDocs";
import { parseStudentGroupsResponse } from "../../../shared/utils/parserUtils";
import { Collection, FunctionName } from "../../../shared/types/enums";
import { bookmarkedProgramsAtom, recommendedProgramsAtom } from "../../recoil/programAtoms";
import { fetchData } from "../../../shared/utils/fetchUtils";

type StudentDataLoaderPromises = [Promise<Response>, Promise<Response>];

export const SchoolGroupSelect = () => {
  const { getFSDocs } = useGetFSDocs();

  const [selectedGroup, setSelectedGroup] = useRecoilState(currentGroupAtom);
  const [schoolGroups, setSchoolGroups] = useRecoilState(schoolGroupsAtom);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const allSchools = useRecoilValue(allSchoolsAtom);
  const schoolStudents = useRecoilValue(allSchoolStudentsAtom);
  const setStudentsInGroup = useSetRecoilState(currentGroupStudentsAtom);
  const setRecommendedPrograms = useSetRecoilState(recommendedProgramsAtom);
  const setBookmarkedPrograms = useSetRecoilState(bookmarkedProgramsAtom);

  const getStudentDataPromises = useCallback((students: StudentRecord[]) => {
    const recommendedProgramIds = Array.from(
      new Set(students.flatMap((student) => student.removedProgramIds).filter((id): id is string => id != null)),
    );

    const bookmarkedProgramIds = Array.from(
      new Set(students.flatMap((student) => student.favoriteProgramIds).filter((id): id is string => id != null)),
    );

    const studentDataLoaderPromises: StudentDataLoaderPromises = [
      fetchData({
        functionName: FunctionName.GET_PROGRAMS_AND_PROVIDERS_BY_ID,
        payload: { documentIds: recommendedProgramIds },
      }),
      fetchData({
        functionName: FunctionName.GET_PROGRAMS_AND_PROVIDERS_BY_ID,
        payload: { documentIds: bookmarkedProgramIds },
      }),
    ];
    return studentDataLoaderPromises;
  }, []);

  useEffect(() => {
    if (selectedGroup === "All" || selectedGroup === null) {
      // Null out groupSchoolsStudents to use allSchoolStudents
      setStudentsInGroup(null);
    } else {
      const studentsForGroup = schoolStudents.filter((student) => student.schoolGroups?.includes(selectedGroup));
      // Update currentGroupStudentsAtom with the selected group's students
      setStudentsInGroup(studentsForGroup);
    }
    const updatePrograms = async () => {
      const studentsForProgramData =
        selectedGroup && selectedGroup !== "All"
          ? schoolStudents.filter((student) => student.schoolGroups.includes(selectedGroup))
          : schoolStudents;
      const studentDataLoaderPromises = getStudentDataPromises(studentsForProgramData);
      const [recommendedPrograms, bookmarkedPrograms] = await Promise.all(studentDataLoaderPromises);
      const recommendedProgramsData = await recommendedPrograms.json();
      const bookmarkedProgramsData = await bookmarkedPrograms.json();
      setRecommendedPrograms(recommendedProgramsData.programs as ProviderProgramRecordTwo[]);
      setBookmarkedPrograms(bookmarkedProgramsData.programs as ProviderProgramRecordTwo[]);
    };
    updatePrograms();
  }, [
    getStudentDataPromises,
    schoolStudents,
    selectedGroup,
    setBookmarkedPrograms,
    setRecommendedPrograms,
    setStudentsInGroup,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const isWillowedOrg =
        loggedInStaff?.email.split("@")[1] === "willowed.org" && loggedInStaff.email !== "demo-staff@willowed.org";
      if (isWillowedOrg) {
        const results: StudentGroup[] = await getFSDocs({
          col: Collection.GROUPS,
          config: {},
        });
        if (results) return setSchoolGroups(parseStudentGroupsResponse(results));
      }

      const schools = allSchools.filter((school) => school.districtId === loggedInStaff?.districtId);
      const schoolIds = schools.map((school) => school.id);
      const results: StudentGroup[] = await getFSDocs({
        col: Collection.GROUPS,
        config: { where: ["schoolId", "in", schoolIds] },
      });
      if (results) return setSchoolGroups(parseStudentGroupsResponse(results));
    };

    fetchData();
  }, [allSchools, getFSDocs, loggedInStaff?.districtId, loggedInStaff?.email, setSchoolGroups]);

  if (!schoolGroups || schoolGroups.length === 0) {
    return null;
  }

  return (
    <Select
      value={selectedGroup || "All"}
      onChange={(e) => {
        setSelectedGroup(e.target.value as string);
      }}
    >
      <MenuItem value="All">All</MenuItem>
      {schoolGroups?.map((group) => (
        <MenuItem key={group.id} value={group.id}>
          {group.groupName}
        </MenuItem>
      ))}
    </Select>
  );
};
