import { Checkbox, FormControl, FormControlLabel, FormGroup, Switch, Typography } from "@mui/material";
import { ChangeEvent, Dispatch } from "react";
import { FilterProps } from "../../../../types/types";
import { Color } from "../../../../types/enums";
import useWindowDimensions from "../../../../hooks/responsiveLayout/useWindowDimensions";

type Props = {
  filters: FilterProps;
  setFilters: Dispatch<React.SetStateAction<FilterProps>>;
};

const ProgramTypeFilter = ({ filters, setFilters }: Props) => {
  const { width } = useWindowDimensions();
  const mobile = width < 900;
  const fontSize = mobile ? 24 : 32;

  const handleProgramTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const credLevel = name === "certifications" ? "-1" : name === "associates" ? "2" : "3";
    const credLevels = [...filters.credLevels];
    if (credLevels.includes(credLevel)) {
      credLevels.splice(credLevels.indexOf(credLevel), 1);
    } else {
      credLevels.push(credLevel);
    }
    setFilters((pV) => ({ ...pV, credLevels }));
  };
  return (
    <>
      <FormControl sx={{ px: 2, py: 1 }} component="fieldset" variant="standard">
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "18px",
            color: Color.DARK_GRAY,
          }}
        >
          Program Type
        </Typography>
        <FormGroup sx={{ mt: 1, gap: 1.5 }} row={mobile}>
          <FormControlLabel
            sx={{ height: "24px" }}
            control={
              mobile ? (
                <Switch
                  value={filters.credLevels.includes("-1")}
                  onChange={handleProgramTypeChange}
                  name="certifications"
                />
              ) : (
                <Checkbox
                  checked={filters.credLevels.includes("-1")}
                  onChange={handleProgramTypeChange}
                  name="certifications"
                  sx={{
                    color: Color.MINT,
                    "& .MuiSvgIcon-root": { fontSize: fontSize },
                  }}
                />
              )
            }
            label="Certifications and Trainings (Usually < 1 year)"
          />
          <FormControlLabel
            sx={{ height: "24px" }}
            control={
              mobile ? (
                <Switch value={filters.credLevels.includes("2")} onChange={handleProgramTypeChange} name="associates" />
              ) : (
                <Checkbox
                  checked={filters.credLevels.includes("2")}
                  onChange={handleProgramTypeChange}
                  name="associates"
                  sx={{
                    color: Color.MINT,
                    "& .MuiSvgIcon-root": { fontSize: fontSize },
                  }}
                />
              )
            }
            label="Associate's Degrees (usually 2 years)"
          />
          <FormControlLabel
            sx={{ height: "24px" }}
            control={
              mobile ? (
                <Switch value={filters.credLevels.includes("3")} onChange={handleProgramTypeChange} name="bachelors" />
              ) : (
                <Checkbox
                  checked={filters.credLevels.includes("3")}
                  onChange={handleProgramTypeChange}
                  name="bachelors"
                  sx={{
                    color: Color.MINT,
                    "& .MuiSvgIcon-root": { fontSize: fontSize },
                  }}
                />
              )
            }
            label="Bachelor's Degrees"
          />
        </FormGroup>
      </FormControl>
    </>
  );
};

export default ProgramTypeFilter;
