import { useState, useEffect, useCallback } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { ScholarshipApplicationRecord, ScholarshipRecord } from "../../../shared/types/types";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import { Collection } from "../../../shared/types/enums";

type Props = {
  scholarship: ScholarshipRecord | undefined;
  scholarshipApplication: ScholarshipApplicationRecord;
  setSelectedScholarshipApplication: React.Dispatch<React.SetStateAction<ScholarshipApplicationRecord | null>>;
  setScholarshipApplications: React.Dispatch<React.SetStateAction<ScholarshipApplicationRecord[]>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const statusOptions = [
  { value: "not applied", label: "Not Applied For" },
  { value: "applied", label: "Applied For" },
  { value: "rejected", label: "Turned Down" },
  { value: "awarded", label: "Awarded" },
];

const ScholarshipApplicationDialog: React.FC<Props> = ({
  scholarshipApplication,
  setSelectedScholarshipApplication,
  setScholarshipApplications,
  setOpen,
}) => {
  const [status, setStatus] = useState(scholarshipApplication.status);
  const { updateFSDoc } = useUpdateFSDoc();
  const [appliedDate, setAppliedDate] = useState<Dayjs | null>(
    scholarshipApplication.appliedDate ? dayjs(scholarshipApplication.appliedDate) : null,
  );
  const [awardDate, setAwardDate] = useState<Dayjs | null>(
    scholarshipApplication.awardDate ? dayjs(scholarshipApplication.awardDate) : null,
  );
  const [awardAmount, setAwardAmount] = useState(scholarshipApplication.awardAmount || "");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});
  const [isFormValid, setIsFormValid] = useState(false);
  const today = dayjs().startOf("day");

  const handleClose = () => {
    setOpen(false);
    setSelectedScholarshipApplication(null);
  };

  const handleBlur = (field: string) => {
    setTouched((prev) => ({ ...prev, [field]: true }));
  };

  const validateFields = useCallback(() => {
    const newErrors: { [key: string]: string } = {};

    if (touched.status && (status === "applied" || status === "rejected" || status === "awarded")) {
      if (!appliedDate || !appliedDate.isValid()) {
        newErrors.appliedDate = "Applied Date is required and must be a valid date";
      } else if (appliedDate.isAfter(today)) {
        newErrors.appliedDate = "Applied Date cannot be in the future";
      }
    }

    if (touched.awardDate && status === "awarded") {
      if (!awardDate || !awardDate.isValid()) {
        newErrors.awardDate = "Award Date is required and must be a valid date";
      } else if (awardDate.isAfter(today)) {
        newErrors.awardDate = "Award Date cannot be in the future";
      } else if (appliedDate && awardDate.isBefore(appliedDate)) {
        newErrors.awardDate = "Award Date cannot be before the Applied Date";
      }
    }

    if (touched.awardAmount && status === "awarded") {
      if (!awardAmount || isNaN(Number(awardAmount))) {
        newErrors.awardAmount = "Award Amount must be a valid number";
      }
    }

    setErrors(newErrors);
    setIsFormValid(Object.keys(newErrors).length === 0);
  }, [appliedDate, awardAmount, awardDate, status, today, touched.awardAmount, touched.awardDate, touched.status]);

  useEffect(() => {
    validateFields();
  }, [status, appliedDate, awardDate, awardAmount, validateFields]);

  useEffect(() => {
    setTouched({
      status: false,
      appliedDate: false,
      awardDate: false,
      awardAmount: false,
    });
  }, []);

  const handleSave = async () => {
    if (isFormValid) {
      const updatedValues: ScholarshipApplicationRecord = {
        ...scholarshipApplication,
        status,
        appliedDate: appliedDate ? appliedDate.toISOString() : null,
        awardDate: awardDate ? awardDate.toISOString() : null,
        awardAmount: Number(awardAmount),
      };
      await updateFSDoc({
        col: Collection.SCHOLARSHIP_APPLICATIONS,
        id: scholarshipApplication.id,
        data: updatedValues,
      });

      setScholarshipApplications((prev) => {
        const index = prev.findIndex((app) => app.id === scholarshipApplication.id);
        const updatedApps = [...prev];
        updatedApps[index] = updatedValues;
        return updatedApps;
      });

      handleClose();
    }
  };

  return (
    <Dialog open={true} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Update Scholarship Application</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              name="status"
              label="Status"
              variant="outlined"
              value={status}
              sx={{ mt: 2 }}
              onChange={(e) => setStatus(e.target.value as "not applied" | "applied" | "rejected" | "awarded")}
              onBlur={() => handleBlur("status")}
              error={Boolean(touched.status && errors.status)}
              helperText={touched.status && errors.status}
            >
              {statusOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {status !== "not applied" && (
            <Grid item xs={12} sm={6}>
              <DatePicker
                value={appliedDate}
                onChange={(date) => setAppliedDate(date)}
                onAccept={() => handleBlur("appliedDate")} // Mark as touched when the user accepts a date
                onClose={() => handleBlur("appliedDate")} // Alternatively, mark as touched when the picker closes
                format="MM/DD/YYYY"
                maxDate={today}
                slots={{
                  textField: TextField,
                }}
                slotProps={{
                  textField: {
                    label: "Applied Date",
                    fullWidth: true,
                    error: Boolean(touched.appliedDate && errors.appliedDate),
                    helperText: touched.appliedDate && errors.appliedDate,
                  },
                }}
              />
            </Grid>
          )}

          {status === "awarded" && (
            <>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  value={awardDate}
                  onChange={(date) => setAwardDate(date)}
                  onAccept={() => handleBlur("awardDate")}
                  onClose={() => handleBlur("awardDate")}
                  format="MM/DD/YYYY"
                  minDate={appliedDate || undefined}
                  maxDate={today}
                  slots={{
                    textField: TextField,
                  }}
                  slotProps={{
                    textField: {
                      label: "Award Date",
                      fullWidth: true,
                      error: Boolean(touched.awardDate && errors.awardDate),
                      helperText: touched.awardDate && errors.awardDate,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Award Amount"
                  value={awardAmount ? String(awardAmount).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}
                  onChange={(e) => {
                    const value = e.target.value.replace(/,/g, "");
                    if (/^\d*$/.test(value) && value.length <= 6) {
                      setAwardAmount(value);
                    }
                  }}
                  onBlur={() => handleBlur("awardAmount")}
                  fullWidth
                  error={Boolean(touched.awardAmount && errors.awardAmount)}
                  helperText={touched.awardAmount && errors.awardAmount}
                  InputProps={{
                    startAdornment: <span>$</span>,
                    inputProps: { inputMode: "numeric", pattern: "[0-9]*" },
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary" disabled={!isFormValid}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScholarshipApplicationDialog;
