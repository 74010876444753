import React from "react";
import { Paper, Box, Typography, Button, Grid, Chip } from "@mui/material";
import { ManualScholarshipRecord } from "../../../shared/types/types";
import { Color } from "../../../shared/types/enums";
import { formatCurrency } from "../../../shared/utils/formatUtils";

type Props = {
  scholarship: ManualScholarshipRecord;
  setSelectedScholarship: React.Dispatch<React.SetStateAction<ManualScholarshipRecord | null>>;
  setManageOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ManualScholarshipCard = ({ scholarship, setSelectedScholarship, setManageOpen }: Props) => {
  const handleCardClick = () => {
    setSelectedScholarship(scholarship);
    setManageOpen(true);
  };
  return (
    <Paper sx={paperStyles}>
      <Box sx={flexColumnStyles}>
        {/* Content section */}
        <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              {scholarship.sponsorName && (
                <Typography variant="h6" sx={{ fontSize: 14, color: Color.MINT }}>
                  {scholarship.sponsorName}
                </Typography>
              )}
              <Typography variant="h4">{scholarship.name}</Typography>
            </Box>
          </Box>

          {scholarship.awardAmount && scholarship.status === "awarded" && (
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              <Typography variant="h6">Award Amount: </Typography>
              <Typography variant="body1" sx={{ fontSize: 16, ml: 1 }}>
                {formatCurrency({
                  amount: scholarship.awardAmount,
                  decimalPlaces: 0,
                })}
              </Typography>
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
          <Typography variant="h6">Status:</Typography>
          <Chip sx={{ ml: 1 }} label={scholarship.status} color="primary" />
        </Box>

        {/* Buttons section */}
        <Box sx={{ mt: "auto" }}>
          <Grid container justifyContent="space-between" sx={{ mt: 2 }}>
            {scholarship.status !== "not applied" ? (
              <Button fullWidth variant="outlined" onClick={handleCardClick}>
                Update Status
              </Button>
            ) : (
              <Button fullWidth variant="contained" color="primary" onClick={handleCardClick}>
                Mark As Applied
              </Button>
            )}
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
};

export default ManualScholarshipCard;

const paperStyles = {
  p: 2,
  borderRadius: 4,
  boxShadow: 1,
  display: "flex",
  flexDirection: "column",
  height: "100%",
};

const flexColumnStyles = {
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};
