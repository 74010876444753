import React from "react";
import { Color } from "../../../shared/types/enums";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

type Props = {
  selected: string | null;
  handleSelected: (event: React.MouseEvent<HTMLElement>, value: string) => void;
};

const TopNavigationToggle = ({ selected, handleSelected }: Props) => {
  return (
    <div
      style={{
        position: "sticky",
        top: 64,
        zIndex: 5,
        backgroundColor: "#fff",
      }}
    >
      <ToggleButtonGroup
        value={selected}
        exclusive
        fullWidth
        onChange={handleSelected}
        sx={{ backgroundColor: "#fff" }}
      >
        <ToggleButton
          sx={{
            borderRadius: 0,
            border: 0,
            borderBottom: selected === "allPrograms" ? `1px solid ${Color.MINT}` : Color.GRAY_800,
          }}
          value="allPrograms"
        >
          All Programs
        </ToggleButton>
        <ToggleButton
          sx={{
            borderRadius: 0,
            border: 0,
            borderBottom: selected === "recommendedPrograms" ? `1px solid ${Color.MINT}` : Color.GRAY_800,
          }}
          value="recommendedPrograms"
        >
          Recommended Programs
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export default TopNavigationToggle;
