import { Autocomplete, Box, Checkbox, TextField, Typography } from "@mui/material";
import { Color, UserType } from "../../../../types/enums";
import { Dispatch } from "react";
import { FilterProps } from "../../../../types/types";
import tags from "../../../../assets/data/tags.json";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import useWindowDimensions from "../../../../hooks/responsiveLayout/useWindowDimensions";

type Tags = {
  [key: string]: string[];
};

type CareerPathwayProps = {
  filters: FilterProps;
  setFilters: Dispatch<React.SetStateAction<FilterProps>>;
  userType: UserType | null;
};

const CareerPathwaysFilter = ({ filters, setFilters, userType }: CareerPathwayProps) => {
  const { width } = useWindowDimensions();
  const mobile = width < 900;

  const tagsArray = Object.keys(tags);
  const cipTags: Tags = tags;

  const dropDownIcon = <KeyboardArrowDownOutlinedIcon />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleTagsChange = (_event: any, value: string[]) => {
    const tempCIPs: string[] = [];
    value.forEach((tag) => {
      tempCIPs.push(...cipTags[tag]);
    });
    setFilters((pV) => ({ ...pV, tags: value, cipCodes: tempCIPs }));
  };

  const studentUser = userType === UserType.STUDENT;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          px: 2,
          py: 1,
        }}
      >
        {!studentUser && (
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "18px",
              color: Color.DARK_GRAY,
            }}
          >
            Career Pathways
          </Typography>
        )}
        <Autocomplete
          multiple
          id="career-pathways-filter"
          options={tagsArray}
          value={filters.tags}
          onChange={handleTagsChange}
          filterSelectedOptions
          popupIcon={mobile ? undefined : dropDownIcon}
          renderOption={(props, option, { selected }) => {
            const { ...optionProps } = props;
            return (
              <li {...optionProps} key={option}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {option}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={studentUser ? "Tags" : "Type to search..."}
              sx={{
                "& .MuiInputBase-root": {
                  alignItems: "start",
                  alignContent: "start",
                  minHeight: mobile ? undefined : "200px",
                  borderRadius: "8px",
                  // maxWidth: "250px"
                },
                "& .MuiChip-root": {
                  "& .MuiChip-deleteIcon": {
                    margin: "0 0 0 5px",
                  },
                },
                "& .MuiAutocomplete-endAdornment": { top: mobile ? undefined : "13%" },
                "& .MuiOutlinedInput-root": {
                  "& .MuiAutocomplete-input": {
                    minWidth: 40,
                  },
                },
              }}
            />
          )}
        />
      </Box>
    </>
  );
};

export default CareerPathwaysFilter;
