import { Box, Button, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Color } from "../../../shared/types/enums";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import useStudentTableTools from "../../hooks/dashboard/useStudentTableTools";
import MessageStudentsDialog from "../messages/MessageStudentsDialog";
import StudentFiltersPanel from "./StudentFiltersPanel";
import { studentFiltersAtom, studentRowsAtom } from "../../recoil/dashboardAtoms";
import { setMessageOpenAtom } from "../../recoil/messagesAtoms";
import { allSchoolStudentsSelector } from "../../recoil/studentsAtoms";
import { StudentRecord } from "../../../shared/types/types";

const StudentTableTools = () => {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [messageOpen, setMessageOpen] = useRecoilState(setMessageOpenAtom);
  const { studentsAllForExport } = useStudentTableTools();
  const [{ search }, setStudentFilters] = useRecoilState(studentFiltersAtom);
  const students = useRecoilValue(allSchoolStudentsSelector);
  const studentRows = useRecoilValue(studentRowsAtom);
  const [filteredStudents, setFilteredStudents] = useState<StudentRecord[]>([]);

  useEffect(() => {
    const studentRowIds = studentRows.map((row) => row.id);
    const filteredData = students.filter((student) => studentRowIds.includes(student.id));
    setFilteredStudents(filteredData);
  }, [students, studentRows, setFilteredStudents]);

  const toggleDrawer = () => {
    setFiltersOpen((pV) => !pV);
  };
  const handleTextChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setStudentFilters((pV) => ({
      ...pV,
      search: value,
    }));
  };

  const handleMessageOpen = () => {
    setMessageOpen(true);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          mt: 0,
        }}
      >
        <Typography variant="h5">Student List</Typography>
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          <TextField
            id="student-search"
            label="Search"
            value={search}
            name="student-search"
            onChange={handleTextChange}
            variant="outlined"
            size="small"
            sx={{
              width: "40%",
              minWidth: 200,
            }}
          ></TextField>
          <Button sx={{ ml: 1, height: 36 }} variant="outlined" onClick={toggleDrawer}>
            {filtersOpen ? "Close " : "Open "} <FeatherIcon icon="filter" width="20" />
          </Button>
          <Button variant="contained" sx={{ ml: 1, height: 36 }} onClick={handleMessageOpen}>
            <FeatherIcon icon="message-circle" width="20" />
          </Button>
          <Tooltip title="Download CSV">
            <Button variant="contained" sx={{ ml: 1, height: 36 }}>
              <a
                href={`data:text/csv;charset=utf-8,${encodeURIComponent(studentsAllForExport)}`}
                download={`student-data.csv`}
                style={{
                  textDecoration: "none",
                  color: Color.WHITE,
                  margin: "0px",
                  paddingTop: "4px",
                }}
              >
                <FeatherIcon icon="download" width="20" />
              </a>
            </Button>
          </Tooltip>
        </Box>
      </Box>
      <StudentFiltersPanel open={filtersOpen} setOpen={setFiltersOpen} />
      <MessageStudentsDialog open={messageOpen} setOpen={setMessageOpen} students={filteredStudents} />
    </>
  );
};

export default StudentTableTools;
