import React, { useEffect, useState } from "react";
import DisplayBox from "../../../shared/components/displayBox/DisplayBox";
import { Collection, Color } from "../../../shared/types/enums";
import { Box, Button, Divider, Typography } from "@mui/material";
import { GoalRecord, MilestoneRecord } from "../../../shared/types/types";
import useGetFSDocs from "../../../shared/hooks/db/useGetFSDocs";
import { useRecoilValue } from "recoil";
import { parseGoalResponse, parseMilestoneResponse } from "../../../shared/utils/parserUtils";
import GoalCard from "./GoalCard";
import EmptyGoalCard from "./EmptyGoalCard";
import { selectedStudentAtom } from "../../recoil/studentsAtoms";

const StudentGoals = () => {
  const [goals, setGoals] = useState<GoalRecord[]>([]);
  const [milestones, setMilestones] = useState<MilestoneRecord[]>([]);
  const [visibleGoalType, setVisibleGoalType] = useState<"active" | "completed" | "archived">("active");
  const { getFSDocs } = useGetFSDocs();
  const selectedStudent = useRecoilValue(selectedStudentAtom);

  useEffect(() => {
    if (!selectedStudent) return;
    const getGoals = async () => {
      const goalResponse = await getFSDocs<GoalRecord>({
        col: Collection.GOALS,
        config: { where: ["studentId", "==", selectedStudent.id] },
      });
      const parsedGoals = parseGoalResponse(goalResponse);
      parsedGoals.sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });
      setGoals(parsedGoals);
    };
    getGoals();
  }, [getFSDocs, selectedStudent]);

  useEffect(() => {
    if (!selectedStudent) return;
    const getMilestones = async () => {
      const goalResponse = await getFSDocs<MilestoneRecord>({
        col: Collection.MILESTONES,
        config: { where: ["studentId", "==", selectedStudent.id] },
      });
      const parsedMilestones = parseMilestoneResponse(goalResponse);
      // sort goals by iso timestamp
      parsedMilestones.sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });
      setMilestones(parsedMilestones);
    };
    getMilestones();
  }, [getFSDocs, selectedStudent]);

  return (
    <>
      <Box>
        {selectedStudent && (
          <DisplayBox title="SMART Goals" titleSX={{ color: Color.CHALKBOARD }}>
            <Typography variant="body1" gutterBottom>
              These will help you achieve your post-secondary dreams by breaking them into manageable steps, providing
              motivation and a clear path to track your progress.
            </Typography>
            <Divider />
            <Box sx={{ py: 1, display: "flex", verticalAlign: "center", gap: 1 }}>
              <Button
                sx={{
                  boxShadow: "none",
                  textTransform: "none",
                  color: visibleGoalType === "active" ? "#333" : "#777",
                  backgroundColor: visibleGoalType === "active" ? "#B9F4E8" : "#fff",
                  "&:hover": {
                    backgroundColor: "#B9F4E8",
                  },
                }}
                onClick={() => setVisibleGoalType("active")}
              >
                Active
              </Button>
              <Button
                sx={{
                  boxShadow: "none",
                  textTransform: "none",
                  color: visibleGoalType === "completed" ? "#333" : "#777",
                  backgroundColor: visibleGoalType === "completed" ? "#B9F4E8" : "#fff",
                  "&:hover": {
                    backgroundColor: "#B9F4E8",
                  },
                }}
                onClick={() => setVisibleGoalType("completed")}
              >
                Completed
              </Button>
              <Button
                sx={{
                  boxShadow: "none",
                  textTransform: "none",
                  color: visibleGoalType === "archived" ? "#333" : "#777",
                  backgroundColor: visibleGoalType === "archived" ? "#B9F4E8" : "#fff",
                  "&:hover": {
                    backgroundColor: "#B9F4E8",
                  },
                }}
                onClick={() => setVisibleGoalType("archived")}
              >
                Archived
              </Button>
            </Box>
            {visibleGoalType === "active" && (
              <>
                {goals
                  .filter((goal) => goal.state === "active")
                  .map((goal) => (
                    <GoalCard
                      key={goal.id}
                      goal={goal}
                      studentId={selectedStudent.id}
                      milestones={milestones}
                      setMilestones={setMilestones}
                    />
                  ))}
                {goals.filter((goal) => goal.state === "active").length === 0 && (
                  <>
                    <EmptyGoalCard />
                    <EmptyGoalCard />
                    <EmptyGoalCard />
                  </>
                )}
                {goals.filter((goal) => goal.state === "active").length === 1 && (
                  <>
                    <EmptyGoalCard />
                    <EmptyGoalCard />
                  </>
                )}
                {goals.filter((goal) => goal.state === "active").length === 2 && (
                  <>
                    <EmptyGoalCard />
                  </>
                )}
              </>
            )}
            {visibleGoalType === "completed" && (
              <>
                {goals
                  .filter((goal) => goal.state === "completed")
                  .map((goal) => (
                    <GoalCard
                      key={goal.id}
                      goal={goal}
                      studentId={selectedStudent.id}
                      milestones={milestones}
                      setMilestones={setMilestones}
                    />
                  ))}
              </>
            )}
            {visibleGoalType === "archived" && (
              <>
                {goals
                  .filter((goal) => goal.state === "archived")
                  .map((goal) => (
                    <GoalCard
                      key={goal.id}
                      goal={goal}
                      studentId={selectedStudent.id}
                      milestones={milestones}
                      setMilestones={setMilestones}
                    />
                  ))}
              </>
            )}
          </DisplayBox>
        )}
      </Box>
    </>
  );
};

export default StudentGoals;
