import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { Color, UserType } from "../../../shared/types/enums";
import useQuizResultsDialog from "../../../shared/hooks/quizResults/useQuizResultsDialog";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import CloseIcon from "../../../shared/components/closeIcon/CloseIcon";
import ResetQuizResultsDialog from "../../../shared/components/quizResults/ResetQuizResultsDialog";
import PersonalityTypeDetailsDesktop from "./PersonalityTypeDetailsDesktop";
import PersonalityTypeDetailsMobile from "./PersonalityTypeDetailsMobile";
import { StudentRecord } from "../../../shared/types/types";

type Props = {
  open: boolean;
  userType: UserType;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setUnlockMatchesOpen?: (open: boolean) => void;
  loggedInStudent: StudentRecord;
};

const PersonalityTypeDetailsDialog = ({ open, userType, setOpen, setUnlockMatchesOpen, loggedInStudent }: Props) => {
  const { dialogContentRef, student, handleClose } = useQuizResultsDialog({
    userType,
    setOpen,
    setUnlockMatchesOpen,
  });
  const { width } = useWindowDimensions();
  const mobile = width < 900;
  const [resetQuizOpen, setResetQuizOpen] = useState(false);

  return (
    <>
      {student && (
        <>
          <Dialog open={open} fullScreen fullWidth={!mobile} maxWidth="xl" onClose={handleClose}>
            <DialogTitle
              sx={{
                backgroundColor: Color.GRAY_50,
                fontSize: mobile ? 18 : 30,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>Your Personality Type</span>
              <CloseIcon onClose={handleClose} />
            </DialogTitle>
            <DialogContent sx={{ px: 0, backgroundColor: Color.GRAY_50 }} ref={dialogContentRef}>
              {!mobile && (
                <PersonalityTypeDetailsDesktop dialogContentRef={dialogContentRef} loggedInStudent={loggedInStudent} />
              )}
              {mobile && <PersonalityTypeDetailsMobile dialogContentRef={dialogContentRef} />}
            </DialogContent>
            <DialogActions sx={{ justifyContent: "flex-start" }}>
              {userType === UserType.STUDENT && (
                <Button color="close" variant="outlined" onClick={() => setResetQuizOpen(true)}>
                  Reset Quiz
                </Button>
              )}
            </DialogActions>
          </Dialog>

          <ResetQuizResultsDialog open={resetQuizOpen} setOpen={setResetQuizOpen} userType={userType} />
        </>
      )}
    </>
  );
};

export default PersonalityTypeDetailsDialog;
