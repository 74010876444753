import React, { useState } from "react";
import { Box, Divider, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { GoalRecord, MilestoneRecord } from "../../../shared/types/types";
import ManageMilestone from "./ManageMilestone";
import MilestoneCard from "./MilestoneCard";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import { Collection, PageRoute } from "../../../shared/types/enums";
import { goalToEditAtom } from "../../recoil/goalAtoms";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import ConfirmDeleteGoalDialog from "./ConfirmDeleteGoal";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";

type Props = {
  goal: GoalRecord;
  studentId: string;
  milestones: MilestoneRecord[];
  setMilestones: React.Dispatch<React.SetStateAction<MilestoneRecord[]>>;
  setGoals: React.Dispatch<React.SetStateAction<GoalRecord[]>>;
  visibleGoalType: "active" | "completed" | "archived";
  onAskAlma: (text: string) => void;
  setThreeGoalsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  goals: GoalRecord[];
  setAlmaMobileDialogOpen: (open: boolean) => void;
};

const GoalCard = ({
  goals,
  goal,
  studentId,
  milestones,
  setMilestones,
  setGoals,
  visibleGoalType,
  onAskAlma,
  setThreeGoalsOpen,
  setAlmaMobileDialogOpen,
}: Props) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const setGoalToEdit = useSetRecoilState(goalToEditAtom);
  const [milestoneToEdit, setMilestoneToEdit] = useState<MilestoneRecord | null>(null);
  const menuOpen = Boolean(menuAnchorEl);
  const { updateFSDoc } = useUpdateFSDoc();
  const navigate = useNavigate();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { width } = useWindowDimensions();
  const mobile = width < 900;

  const handleOpenDeleteDialog = () => {
    setDeleteOpen(true);
    setMenuAnchorEl(null);
  };
  const handleCloseDeleteDialog = () => setDeleteOpen(false);
  const handleConfirmDelete = async () => {
    await updateFSDoc({
      col: Collection.GOALS,
      id: goal.id,
      data: {
        active: false,
      },
    });
    setGoals((prev) => prev.filter((g) => g.id !== goal.id));
    setDeleteOpen(false);
  };

  const selectedGoalMilestones = milestones.filter((milestone) => milestone.goalId === goal.id);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleEditClick = () => {
    setGoalToEdit(goal);
    navigate(PageRoute.STUDENT_MANAGE_GOALS);
  };

  const handleArchiveClick = async () => {
    await updateFSDoc({
      col: Collection.GOALS,
      id: goal.id,
      data: {
        ...goal,
        state: "archived",
      },
    });
    // update this goal in the goal state to be state: "archived"
    setGoals((prev) => prev.map((g) => (g.id === goal.id ? { ...g, state: "archived" } : g)));
    handleMenuClose();
  };

  const handleRestoreClick = async () => {
    if (goals.filter((g) => g.state === "active").length >= 3) {
      setThreeGoalsOpen(true);
      handleMenuClose();
      return;
    }
    await updateFSDoc({
      col: Collection.GOALS,
      id: goal.id,
      data: {
        ...goal,
        state: "active",
      },
    });
    // update this goal in the goal state to be state: "archived"
    setGoals((prev) => prev.map((g) => (g.id === goal.id ? { ...g, state: "active" } : g)));
    handleMenuClose();
  };

  const handleCompleteGoal = async () => {
    await updateFSDoc({
      col: Collection.GOALS,
      id: goal.id,
      data: {
        state: "completed",
      },
    });
    setGoals((prev) => prev.map((g) => (g.id === goal.id ? { ...g, state: "completed" } : g)));
    const question = `I had the goal to ${goal.goal}. I completed it and am ready to fill out my reflection with your help.`;
    onAskAlma(question);
    setAlmaMobileDialogOpen(mobile);
    handleMenuClose();
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        borderRadius="8px"
        justifyContent="space-between"
        sx={{ mt: 1, py: 1, px: 1 }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* Goal text */}
          <Box>
            <Typography
              variant="h6"
              sx={{
                flexGrow: 1,
                marginLeft: "8px",
                fontSize: 16,
              }}
            >
              {goal.title}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                flexGrow: 1,
                marginLeft: "8px",
              }}
            >
              {goal.goal}
            </Typography>
          </Box>
        </Box>

        {/* Menu button (three dots) */}
        <IconButton onClick={handleMenuClick}>
          <MoreVertIcon />
        </IconButton>

        {/* Edit menu */}
        <Menu anchorEl={menuAnchorEl} open={menuOpen} onClose={handleMenuClose}>
          {visibleGoalType === "active" && (
            <MenuItem onClick={handleCompleteGoal}>
              <FeatherIcon icon="circle-check" width={"16px"} />
              <Typography variant="body1" sx={{ ml: 1 }}>
                Complete & Reflect
              </Typography>
            </MenuItem>
          )}
          {visibleGoalType === "active" && (
            <MenuItem onClick={handleEditClick}>
              <FeatherIcon icon="edit" width={"16px"} />
              <Typography variant="body1" sx={{ ml: 1 }}>
                Edit
              </Typography>
            </MenuItem>
          )}
          {visibleGoalType === "active" && (
            <MenuItem onClick={handleArchiveClick}>
              <FeatherIcon icon="archive" width={"16px"} />
              <Typography variant="body1" sx={{ ml: 1 }}>
                Archive
              </Typography>
            </MenuItem>
          )}
          {(visibleGoalType === "archived" || visibleGoalType === "completed") && (
            <MenuItem onClick={handleRestoreClick}>
              <FeatherIcon icon="list" width={"16px"} />
              <Typography variant="body1" sx={{ ml: 1 }}>
                Restore to Active
              </Typography>
            </MenuItem>
          )}
          <MenuItem onClick={handleOpenDeleteDialog}>
            <FeatherIcon icon="trash" width={"16px"} strokeColor="red" />
            <Typography variant="body1" sx={{ ml: 1 }}>
              Delete
            </Typography>
          </MenuItem>
        </Menu>
      </Box>
      <Box>
        {selectedGoalMilestones.map((milestone) => (
          <MilestoneCard
            key={milestone.id}
            milestone={milestone}
            studentId={studentId}
            setMilestoneToEdit={setMilestoneToEdit}
            setMilestones={setMilestones}
          />
        ))}
      </Box>
      <ManageMilestone
        goalId={goal.id}
        studentId={studentId}
        setMilestones={setMilestones}
        milestoneToEdit={milestoneToEdit}
        setMilestoneToEdit={setMilestoneToEdit}
      />
      <Divider />
      <ConfirmDeleteGoalDialog open={deleteOpen} onClose={handleCloseDeleteDialog} onConfirm={handleConfirmDelete} />
    </>
  );
};

export default GoalCard;
