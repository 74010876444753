import { Player } from "@lottiefiles/react-lottie-player";
import lottieFile from "../assets/loading/Loading.json";
import useWindowDimensions from "../hooks/responsiveLayout/useWindowDimensions";
import { generateIconWidth } from "../utils/layoutUtils";
const disableLottie = process.env.REACT_APP_DISABLE_LOTTIE === "true";

const SiteLoadingPage = () => {
  const { width } = useWindowDimensions();
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {!disableLottie && (
          <Player autoplay={true} loop={true} src={lottieFile} style={{ width: generateIconWidth(width) }}></Player>
        )}
      </div>
    </>
  );
};

export default SiteLoadingPage;
