import { useState } from "react";
import { TextField, FormControl, InputLabel, Link } from "@mui/material";
import { Field, FormikErrors, FormikTouched, useFormikContext } from "formik";
import EditFinancialsDialog from "../efcDialog/EditFinancialsDialog";
import { EFCDataRecord } from "../../../shared/types/types";

type EFCInputProps = {
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  finanicalData: EFCDataRecord | undefined;
  setFinanicalData: (efcData: EFCDataRecord) => void;
};

const EFCInput: React.FC<EFCInputProps> = ({ errors, touched, finanicalData, setFinanicalData }) => {
  const { setFieldValue, values } = useFormikContext<any>();
  const [openEditFinancialsDialog, setOpenEditFinancialsDialog] = useState(false);

  const handleFinancialSubmit = (efcData: EFCDataRecord, calculatedEFC: number) => {
    setFieldValue("efc", calculatedEFC);
    setFinanicalData(efcData);
  };

  const handleMaxLengthChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    const value = e.target.value;

    // Ensure the input is numeric and at most 10 characters
    if (value.length <= 20 && /^\d*$/.test(value)) {
      setFieldValue(fieldName, value);
    }
  };

  return (
    <>
      <FormControl fullWidth margin="normal" sx={{ mt: 0 }}>
        <InputLabel
          shrink
          htmlFor="efc"
          variant="standard"
          sx={{
            display: "flex",
            fontSize: 16,
            fontWeight: 600,
            top: "-5px",
          }}
        >
          EFC (Optional)
        </InputLabel>
        <Field
          as={TextField}
          id="efc"
          name="efc"
          fullWidth
          margin="normal"
          type="number"
          error={touched.efc && Boolean(errors.efc)}
          helperText={touched.efc && errors.efc}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleMaxLengthChange(e, "efc")}
          onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === "e" || e.key === "E") {
              e.preventDefault(); // Prevent entering scientific notation
            }
          }}
        />
        <div style={{ fontSize: 12 }}>
          Students must enter their EFC to get personalized costs and ROI. You can manually enter your EFC if you know
          it, or{" "}
          <Link
            type="button"
            onClick={() => setOpenEditFinancialsDialog(true)}
            component="button"
            style={{
              textDecoration: "underline",
              color: "#1976d2",
            }}
          >
            click here
          </Link>{" "}
          to use our EFC calculator.
        </div>
      </FormControl>
      <EditFinancialsDialog
        open={openEditFinancialsDialog}
        efcValue={values.efc}
        finanicalData={finanicalData}
        setOpen={setOpenEditFinancialsDialog}
        onSubmit={handleFinancialSubmit}
      />
    </>
  );
};

export default EFCInput;
