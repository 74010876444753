import { TourProvider, StepType } from "@reactour/tour";
import { useMemo } from "react";
import { Collection, Color } from "../types/enums";
import { useRecoilState } from "recoil";
import { loggedInStudentAtom } from "../recoil/userAtoms";
import useUpdateFSDoc from "../hooks/db/useUpdateFSDoc";
import { TourName } from "../types/tourEnums";
import { LogEventType } from "../types/logEnums";
import useLogger from "../hooks/logging/useLogger";

type Props = {
  steps: StepType[];
  children: React.ReactNode;
  tour: TourName | null;
};

const WillowTourProvider = ({ steps, children, tour }: Props) => {
  const [loggedInStudent, setLoggedInStudent] = useRecoilState(loggedInStudentAtom);
  const { updateFSDoc } = useUpdateFSDoc();
  const { submitLog } = useLogger();
  const tourKey = useMemo(() => JSON.stringify(steps), [steps]);

  const handleTourClose = async () => {
    if (!loggedInStudent || !tour) return;
    try {
      await updateFSDoc({
        col: Collection.STUDENTS,
        data: { completedTours: [...loggedInStudent.completedTours, tour] },
        id: loggedInStudent.id,
      });
      setLoggedInStudent((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          completedTours: [...prev.completedTours, tour],
        };
      });
    } catch (error) {
      submitLog({
        error,
        snackbarMessage: "Error updating tour status",
        eventType: LogEventType.ERROR_CLOSING_TOUR,
        file: "WillowTourProvider.tsx",
      });
    }
  };
  return (
    <TourProvider
      steps={steps}
      key={tourKey}
      styles={{
        popover: (base) => {
          return {
            ...base,
            fontSize: "14px",
            color: Color.CHALKBOARD,
            marginTop: 12,
            maxWidth: 300,
            borderRadius: 8,
          };
        },
        dot: (base) => {
          return {
            ...base,
            background: base.background === "none" ? "none" : Color.CHALKBOARD,
          };
        },
        badge: (base) => ({
          ...base,
          left: "auto",
          right: "-0.8125em",
          color: Color.CHALK,
          backgroundColor: Color.CHALKBOARD,
        }),
        controls: (base) => ({ ...base, marginTop: 20 }),
      }}
      onClickClose={async ({ setIsOpen }) => {
        await handleTourClose();
        setIsOpen(false);
      }}
      onClickMask={() => {
        return null;
      }}
    >
      {children}
    </TourProvider>
  );
};

export default WillowTourProvider;
