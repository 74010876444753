import ExploreCareersRouter from "../../../shared/components/exploreCareers/ExploreCareersRouter";
import RecommendJobDialog from "./Common/RecommendJobDialog";
import JobDetailsDialog from "../../../shared/components/exploreCareers/common/jobs/JobDetailsDialog";
import { Box } from "@mui/material";

const ExploreCareersContainer = () => {
  return (
    <>
      <Box sx={{ p: 2 }}>
        <ExploreCareersRouter />
        <RecommendJobDialog />
        <JobDetailsDialog />
      </Box>
    </>
  );
};

export default ExploreCareersContainer;
