import { Box, TextField, Typography } from "@mui/material";
import { QuestionRecord } from "../../../shared/types/types";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import preQuizText from "../../../shared/assets/data/preQuizText.json"; // Make sure the path is correct
const { bestDescribesMeOptions, feedback } = preQuizText;

type QuestionContentProps = {
  activeQuestion: QuestionRecord;
  myWhy: string;
  setMyWhy: (value: string) => void;
  whatDescribesMe: string;
  setWhatDescribesMe: (value: string) => void;
};

const optionSX = {
  borderRadius: 2,
  border: "1px solid rgb(152, 152, 152)",
  p: 2,
  mt: 2,
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
};

const QuestionContent = ({
  activeQuestion,
  myWhy,
  setMyWhy,
  whatDescribesMe,
  setWhatDescribesMe,
}: QuestionContentProps) => {
  const handleOptionClick = (optionText: string) => {
    setWhatDescribesMe(optionText);
  };

  const renderOption = (option: { optionText: string }) => (
    <Box
      key={option.optionText}
      onClick={() => handleOptionClick(option.optionText)}
      sx={{
        ...optionSX,
        backgroundColor: option.optionText === whatDescribesMe ? "rgb(29, 188, 157)" : "white",
      }}
    >
      {option.optionText === whatDescribesMe ? (
        <RadioButtonCheckedIcon sx={{ mr: 2 }} />
      ) : (
        <RadioButtonUncheckedIcon sx={{ mr: 2 }} />
      )}
      <Typography variant="body1">{option.optionText}</Typography>
    </Box>
  );

  const renderFeedback = (description: string) => {
    const feedbackMapping: { [key: string]: string } = {
      [bestDescribesMeOptions[0]]: feedback.classicCollegeExperience.text,
      [bestDescribesMeOptions[1]]: feedback.followingOthersExpectations.text,
      [bestDescribesMeOptions[2]]: feedback.needingToGetAway.text,
      [bestDescribesMeOptions[3]]: feedback.structuredGuidance.text,
      [bestDescribesMeOptions[4]]: feedback.readyToLearnAndChallenge.text,
    };

    const feedbackOptions = feedbackMapping[description];

    if (!feedbackOptions) return null;

    return feedbackOptions.split("\n\n").map((paragraph, index) => (
      <Typography key={index} paragraph>
        {paragraph}
      </Typography>
    ));
  };

  return (
    <Box sx={{ p: 1 }}>
      {activeQuestion.id === "myWhy" && (
        <TextField
          fullWidth
          placeholder="Your 'Why'..."
          multiline
          rows={4}
          value={myWhy}
          onChange={(e) => setMyWhy(e.target.value)}
        />
      )}
      {activeQuestion.id === "whatDescribesMe" && (
        <Box sx={{ px: 2 }}>{bestDescribesMeOptions.map((optionText) => renderOption({ optionText }))}</Box>
      )}
      {activeQuestion.id === "whatDescribesMeFeedback" && <Box sx={{ px: 2 }}>{renderFeedback(whatDescribesMe)}</Box>}
    </Box>
  );
};

export default QuestionContent;
