import { atom, RecoilState } from "recoil";
import { UserType } from "../types/enums";
import {
  EFCDataRecord,
  MentorUserRecord,
  ProviderUserRecord,
  StaffRecord,
  StudentRecord,
  WillowStaffRecord,
} from "../types/types";

export const userTypeAtom = atom<UserType | null>({
  key: "userTypeAtom",
  default: null,
});

export const loggedInStaffAtom = atom<StaffRecord | null>({
  key: "loggedInStaffAtom",
  default: null,
});

export const loggedInStudentAtom = atom<StudentRecord | null>({
  key: "loggedInStudentAtom",
  default: null,
});

export const loggedInMentorAtom = atom<MentorUserRecord | null>({
  key: "loggedInMentorAtom",
  default: null,
});

export const loggedInProviderAtom = atom<ProviderUserRecord | null>({
  key: "loggedInProviderAtom",
  default: null,
});

export const ownEFCDataAtom = atom<EFCDataRecord | null>({
  key: "ownEFCDataAtom",
  default: null,
});

export const mentorUsersAtom = atom<MentorUserRecord[]>({
  key: "mentorUsersAtom",
  default: [],
});

export const loggedInWillowStaffAtom = atom<WillowStaffRecord | null>({
  key: "loggedInWillowStaffAtom",
  default: null,
});

export const staffUsersAtom = atom<StaffRecord[]>({
  key: "staffUsersAtom",
  default: [],
});

export const isDemoUserAtom: RecoilState<boolean> = atom({
  key: "isDemoUserAtom",
  default: false,
});
