import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useRef, useState } from "react";
import AlmaIcon from "../../../shared/assets/icons/AlmaIcon";
import animatedAlma from "../../../shared/assets/icons/animatedAlma.json";
import { QuestionRecord, StudentRecord } from "../../../shared/types/types";
import { Player } from "@lottiefiles/react-lottie-player";

type Props = {
  loggedInStudent: StudentRecord;
  children: React.ReactNode;
  question: QuestionRecord;
  onSubmit: () => void;
  onBack: () => void;
  backDisabled: boolean;
  nextDisabled: boolean;
  isLastQuestion?: boolean;
};

const QuestionWrapper = ({
  children,
  question,
  onSubmit,
  onBack,
  backDisabled,
  nextDisabled,
  isLastQuestion,
}: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [nextLoading, setNextLoading] = useState(false);

  const scrollToTop = () => {
    let element = containerRef.current as any;
    while (element) {
      element.scrollTop = 0; // Reset current element
      element = element.parentElement; // Move to parent
    }
  };

  const handleNext = async () => {
    setNextLoading(true);
    await onSubmit();
    scrollToTop();
    setNextLoading(false);
  };

  const handleBack = () => {
    onBack();
    scrollToTop();
  };

  return (
    <Box ref={containerRef} sx={{ overflowY: "auto", height: "100%" }}>
      <Box>
        <Box sx={{ display: "flex", px: 2, pt: 1, alignItems: "center" }}>
          <Box sx={{ pr: 2, pt: 1, flexShrink: 0 }}>
            {process.env.REACT_APP_DISABLE_LOTTIE === "true" ? (
              <AlmaIcon size={30} />
            ) : (
              <Player autoplay loop src={animatedAlma} style={{ height: "30px" }} />
            )}
          </Box>
          <Box>
            <Typography variant="h6">{question.questionText}</Typography>
            <Typography variant="body2">{question.helperText}</Typography>
            {question.questionType === "ms" && (
              <Typography variant="body1" sx={{ fontSize: 14 }}>
                Select your <b>top</b> and <b>second top</b> option that best describes you
              </Typography>
            )}
          </Box>
        </Box>
        {children}
      </Box>
      <Box sx={{ display: "flex", p: 2, gap: 2, pb: 6 }}>
        <Button disabled={backDisabled} onClick={handleBack} variant="contained" color="gray" fullWidth>
          Back
        </Button>
        <Button
          disabled={nextDisabled || nextLoading} // Disable when loading
          variant="contained"
          color="primary"
          onClick={handleNext}
          fullWidth
        >
          {nextLoading ? <CircularProgress size={24} /> : isLastQuestion ? "Submit" : "Next"}
        </Button>
      </Box>
    </Box>
  );
};

export default QuestionWrapper;
