import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { PageRoute, UserType } from "../types/enums";
import { useAuth } from "../contexts/AuthContext";
import { loggedInStudentAtom, userTypeAtom } from "../recoil/userAtoms";

interface CustomRouteProps {
  children: React.ReactNode;
  permission: UserType[];
  originRoute?: string;
  redirectIfNotAuthenticated?: PageRoute;
  redirectIfNoAccount?: PageRoute;
  redirectIfNoPermission?: PageRoute;
  path?: string;
}

const CustomRoute: React.FC<CustomRouteProps> = ({ children, permission }) => {
  const { currentAuthUser } = useAuth();
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const userType = useRecoilValue(userTypeAtom);
  const location = useLocation();

  const currentPath = location.pathname;

  // Public routes or signup are always accessible
  if (!currentAuthUser) {
    if (permission.includes("public" as UserType) || currentPath === PageRoute.STUDENT_SIGNUP) {
      return <>{children}</>;
    }
    return <Navigate to={PageRoute.LOGIN} replace />;
  }

  if (!userType) {
    if (currentPath === PageRoute.STUDENT_CREATE_PROFILE) {
      return <>{children}</>;
    }
    return <Navigate to={PageRoute.STUDENT_CREATE_PROFILE} replace />;
  }

  /**************** Internal Only Routes ****************/
  if (currentPath === PageRoute.INTERNAL_COLLEGE_IMAGE_UPLOADER) {
    const acceptedEmails = ["andrew@mk-dir.com", "willowed.org"];
    if (currentAuthUser && acceptedEmails.includes(currentAuthUser.email!.split("@")[1])) {
      return <>{children}</>;
    } else {
      return <Navigate to={PageRoute.DASHBOARD} replace />;
    }
  }

  if (currentPath === PageRoute.UPLOAD_PROFESSIONAL_PROGRAMS) {
    const acceptedEmails = ["andrew@mk-dir.com", "ryan@willowed.org"];
    if (currentAuthUser && acceptedEmails.includes(currentAuthUser.email!)) {
      return <>{children}</>;
    } else {
      return <Navigate to={PageRoute.DASHBOARD} replace />;
    }
  }
  /**************** End Internal Only Routes ****************/

  if (userType === UserType.STUDENT) {
    if (!loggedInStudent) return null;
    const { quizComplete, setupComplete } = loggedInStudent;
    if (currentPath === PageRoute.LOGIN && setupComplete) {
      return <Navigate to={PageRoute.FEED} replace />;
    }
    if (currentPath === PageRoute.LOGIN && !setupComplete) {
      return <Navigate to={PageRoute.STUDENT_PROFILE} replace />;
    }

    if (!quizComplete && currentPath !== PageRoute.STUDENT_TAKE_QUIZ) {
      return <Navigate to={PageRoute.STUDENT_TAKE_QUIZ} replace />;
    } else if (!quizComplete && currentPath === PageRoute.STUDENT_TAKE_QUIZ) {
      return <>{children}</>;
    }

    if (!setupComplete && currentPath !== PageRoute.STUDENT_PROFILE) {
      return <Navigate to={PageRoute.STUDENT_PROFILE} replace />;
    } else if (!setupComplete && currentPath === PageRoute.STUDENT_PROFILE) {
      return <>{children}</>;
    }

    if (loggedInStudent && currentPath === PageRoute.STUDENT_CREATE_PROFILE) {
      if (loggedInStudent.setupComplete) {
        return <Navigate to={PageRoute.FEED} replace />;
      } else {
        return <Navigate to={PageRoute.STUDENT_PROFILE} replace />;
      }
    }

    if (permission.includes(UserType.STUDENT)) {
      return <>{children}</>;
    }
    return <Navigate to={PageRoute.FEED} replace />;
  }

  if (userType === UserType.TEACHER || userType === UserType.ADMIN) {
    if (currentPath === PageRoute.LOGIN) {
      return <Navigate to={PageRoute.STAFF_DASHBOARD} replace />;
    }
    if (permission.includes(userType)) {
      return <>{children}</>;
    }
    return <Navigate to={PageRoute.STAFF_DASHBOARD} replace />;
  }

  return <>{children}</>;
};

export default CustomRoute;
