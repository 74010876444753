import { useEffect, useState, useCallback } from "react";
import { Collection } from "../../../shared/types/enums";
import useGetFSDocs from "../../../shared/hooks/db/useGetFSDocs";
import { ManualScholarshipRecord, StudentRecord } from "../../../shared/types/types";

const useManualScholarships = (loggedInStudent: StudentRecord | null) => {
  const { getFSDocs } = useGetFSDocs();
  const [manualScholarships, setManualScholarships] = useState<ManualScholarshipRecord[]>([]);

  const fetchManualScholarships = useCallback(async () => {
    if (!loggedInStudent) return;

    const scholarships = await getFSDocs<ManualScholarshipRecord>({
      col: Collection.MANUAL_SCHOLARSHIPS,
      config: { where: ["studentId", "==", loggedInStudent.id] },
    });
    setManualScholarships(scholarships);
  }, [getFSDocs, loggedInStudent]);

  useEffect(() => {
    fetchManualScholarships();
  }, [fetchManualScholarships]);

  return { manualScholarships, fetchManualScholarships };
};

export default useManualScholarships;
