import { useEffect, useState } from "react";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { selectedProgramForRecommendationsAtom } from "../../recoil/programAtoms";
import { allSchoolStudentsAtom, allSchoolStudentsSelector } from "../../recoil/studentsAtoms";
import { StudentRecord } from "../../../shared/types/types";
import { Collection } from "../../../shared/types/enums";
import useLogger from "../../../shared/hooks/logging/useLogger";
import { LogEventType } from "../../../shared/types/logEnums";

const useRecommendProgramDialog = () => {
  const [loading, setLoading] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState<StudentRecord[]>([]);
  const [selectedProgramForRecommendations, setSelectedProgramForRecommendations] = useRecoilState(
    selectedProgramForRecommendationsAtom,
  );
  const setAllSchoolStudents = useSetRecoilState(allSchoolStudentsAtom); // TODO: check
  const students = useRecoilValue(allSchoolStudentsSelector);
  const { updateFSDoc } = useUpdateFSDoc();
  const { submitLog } = useLogger();

  const handleStudentsChange = (_: any, value: StudentRecord[]) => {
    setSelectedStudents(value);
  };

  useEffect(() => {
    const alreadyRecommendedStudents = students.filter((student) => {
      return student.staffRecommendedProgramIds?.includes(selectedProgramForRecommendations?.id ?? "");
    });
    setSelectedStudents(alreadyRecommendedStudents);
  }, [selectedProgramForRecommendations, students]);

  const handleSave = async () => {
    if (!selectedProgramForRecommendations) return;

    setLoading(true);

    const currentProgramId = selectedProgramForRecommendations.id;
    const updatedStudents = students.map((student) => {
      const isSelected = selectedStudents.some((selectedStudent) => selectedStudent.id === student.id);
      const hasProgramId = student.staffRecommendedProgramIds?.includes(currentProgramId);

      if (isSelected && !hasProgramId) {
        return {
          ...student,
          staffRecommendedProgramIds: [...(student.staffRecommendedProgramIds || []), currentProgramId],
        };
      } else if (!isSelected && hasProgramId) {
        return {
          ...student,
          staffRecommendedProgramIds: student.staffRecommendedProgramIds.filter((id) => id !== currentProgramId),
        };
      } else {
        return student;
      }
    });

    try {
      const updatePromises = updatedStudents.map((student) =>
        updateFSDoc({
          col: Collection.STUDENTS,
          id: student.id,
          data: {
            staffRecommendedProgramIds: student.staffRecommendedProgramIds,
          },
        }),
      );
      await Promise.all(updatePromises);

      submitLog({
        eventType: LogEventType.STAFF_RECOMMENDED_PROGRAM,
        changeLog: `Staff recommened the program ${currentProgramId} successfully`,
        file: "useRecommendProgramDialog.ts",
      });
    } catch (error) {
      submitLog({
        error: error instanceof Error ? error : new Error(`Error recommending the program ${currentProgramId}`),
        snackbarMessage: "There was an error recommendation the program, please refresh and try again.",
        eventType: LogEventType.STAFF_RECOMMEND_PROGRAM_ERROR,
        file: "useRecommendProgramDialog.ts",
      });
    }

    setAllSchoolStudents(updatedStudents);
    setSelectedProgramForRecommendations(null);
    setLoading(false);
  };

  return {
    loading,
    students,
    handleStudentsChange,
    selectedStudents,
    selectedProgramForRecommendations,
    setSelectedProgramForRecommendations,
    handleSave,
  };
};

export default useRecommendProgramDialog;
