import { useRef } from "react";
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import PrintTable from "./PrintTable";
import { JobDetailsRecord, ProviderProgramRecordTwo, ReviewRecord } from "../../../types/types";
import { useRecoilState } from "recoil";
import { printProgramDialogAtom } from "../../../recoil/providerAndProgramAtom";

type Props = {
  selectedProgram: ProviderProgramRecordTwo;
  reviews: ReviewRecord | undefined;
  relatedJobs: JobDetailsRecord[];
};

function PrintProgramContainer({ selectedProgram, reviews, relatedJobs }: Props) {
  const contentRef = useRef<HTMLDivElement>(null);
  const [printModalVisable, setPrintModalVisable] = useRecoilState(printProgramDialogAtom);

  const handlePrint = useReactToPrint({ contentRef });

  const handleClose = () => {
    setPrintModalVisable(false);
  };

  return (
    <>
      <Dialog open={Boolean(printModalVisable)} onClose={() => handleClose()} fullWidth maxWidth="lg">
        <DialogTitle sx={{ fontSize: 44, textAlign: "center" }}>Print Program</DialogTitle>
        <DialogContent>
          <Box
            ref={contentRef}
            sx={{
              width: "8.5in",
              margin: "0px auto",
              backgroundColor: "#ffffff",
              padding: 2,
            }}
          >
            {selectedProgram && (
              <PrintTable reviews={reviews ?? undefined} selectedProgram={selectedProgram} relatedJobs={relatedJobs} />
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => handlePrint()} variant="contained">
            Print
          </Button>
          <Button color="error" onClick={() => handleClose()} variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PrintProgramContainer;
