import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import useWindowDimensions from "../../../../hooks/responsiveLayout/useWindowDimensions";
import { selectedJobIdAtom } from "../../../../recoil/exploreCareersAtoms";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Reactions } from "../Reactions";
import CloseIcon from "../../../closeIcon/CloseIcon";
import jobDescriptions from "../../../../assets/data/jobDescriptions.json";
import { useCallback, useEffect, useRef, useState } from "react";
import { WageDataRecord } from "../../../../types/types";
import useJobDetails from "../../../../hooks/exploreCareers/jobDetails/useJobDetails";
import { loggedInStudentAtom, userTypeAtom } from "../../../../recoil/userAtoms";
import { Player } from "@lottiefiles/react-lottie-player";
import useGetWageData from "../../../../hooks/exploreCareers/jobDetails/useGetWageData";
import { selectedJobForRecommendationsAtom } from "../../../../../staff/recoil/careerAtoms";
import SiteLoadingPage from "../../../../pages/SiteLoadingPage";
import { Color, UserType } from "../../../../types/enums";
import WhatIsThat from "../../../whatIsThat/WhatIsThat";
import QuickLook from "./QuickLook";
import { formatCurrency } from "../../../../utils/formatUtils";
import FeatherIcon from "../../../../assets/icons/featherIcons/FeatherIcon";
import LoadingBlock from "../../../loading/LoadingBlock";
import VideoListPlayer from "../../../videoList/VideoListPlayer";
const disableLottie = process.env.REACT_APP_DISABLE_LOTTIE === "true";

const JobDetailsDialog = () => {
  const { width } = useWindowDimensions();
  const isMobile = width < 900;
  const [selectedJobId, setSelectedJobId] = useRecoilState(selectedJobIdAtom);
  const [wageData, setWageData] = useState<WageDataRecord | null>(null);
  const [wageLabel, setWageLabel] = useState<string | null>(null);
  const {
    mobile,
    jobData,
    industryData,
    industryGroupData,
    fieldData,
    tableStyles,
    progreeBarstyles,
    similarJobsStyles,
    educationAttainmentData,
    videos,
    interactions,
    videosLoading,
  } = useJobDetails({ jobId: selectedJobId! });
  const userType = useRecoilValue(userTypeAtom);
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const lottieRef = useRef<Player>(null);
  const { getWageData } = useGetWageData();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const setSelectedJobForRecommendations = useSetRecoilState(selectedJobForRecommendationsAtom);

  const student = userType === UserType.STUDENT;

  useEffect(() => {
    const callGetWageData = async () => {
      const wageDataResponse = await getWageData({
        city: loggedInStudent?.address.city || "",
        state: loggedInStudent?.address.state || "",
        onetCode: selectedJobId ? selectedJobId : "",
      });
      setWageData(wageDataResponse.wageData);
      setWageLabel(wageDataResponse.label);
    };
    callGetWageData();
  }, [getWageData, selectedJobId, loggedInStudent]);

  const onSimilarJobSelect = useCallback(
    (jobId: string) => {
      setSelectedJobId(jobId);
      scrollRef.current && scrollRef.current.scrollIntoView({ behavior: "smooth" });
    },
    [setSelectedJobId],
  );

  const handleStaffRecommendClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setSelectedJobForRecommendations(jobData);
    },
    [jobData, setSelectedJobForRecommendations],
  );

  const closeModal = useCallback(() => {
    setSelectedJobId(null);
  }, [setSelectedJobId]);

  return (
    <Dialog open={Boolean(selectedJobId)} fullWidth maxWidth="lg" fullScreen={isMobile} onClose={closeModal}>
      <DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h4" fontSize={mobile ? 20 : 24} sx={{ mt: 0.5 }}>
            {jobData?.title}
          </Typography>
          <Box sx={{ display: "flex" }}>
            {student && jobData && <Reactions jobId={jobData.id} />}
            <CloseIcon onClose={closeModal} size={30} />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        {jobData ? (
          <Grid container spacing={mobile ? 4 : 5}>
            <Grid item xs={12} sm={6}>
              <Box sx={{ height: "80VH", overflow: "scroll" }}>
                <Box sx={{ display: "flex", gap: 1 }}>
                  {[
                    {
                      show: loggedInStudent?.willowRecommendedJobIds.includes(jobData.id),
                      label: "Willow Recommended",
                      color: "mint",
                      hasInfo: false,
                    },
                    {
                      show: jobData.bright_outlook,
                      label: "Bright Outlook",
                      color: "surfaceGreen",
                      hasInfo: true,
                      information: jobDescriptions.bright_outlook,
                    },
                    {
                      show: jobData.green_occupation,
                      label: "Green Occupation",
                      color: "surfaceGreen",
                      hasInfo: true,
                      information: jobDescriptions.green_occupation,
                      customSx: {
                        color: Color.CHALKBOARD,
                        backgroundColor: Color.SURFACE_GREEN,
                      },
                    },
                  ].map(
                    (chip, index) =>
                      chip.show && (
                        <Box key={index} sx={{ display: "flex" }}>
                          <Chip label={chip.label} size="small" color={chip.color as any} sx={chip.customSx} />
                          {chip.hasInfo && chip.information && <WhatIsThat information={chip.information} />}
                        </Box>
                      ),
                  )}
                </Box>

                <Box sx={{ mt: mobile ? 2 : 3 }}>
                  <Typography variant="h5">Career Description</Typography>
                  <Typography sx={{ mt: 1 }} variant="body1">
                    {jobData.description}
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body2">
                      Industry: {industryData.find((item) => item.id === jobData.industry_id)?.title}
                    </Typography>
                    <Typography variant="body2">
                      Field: {fieldData.find((item) => item.id === jobData.industry_sub_id)?.title}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "end",
                      mt: 2,
                    }}
                  >
                    <Typography variant="h5">Overview</Typography>
                    <Typography variant="body1" color={Color.DARK_TEAL} fontSize={mobile ? 10 : 12} fontWeight={500}>
                      National
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      mt: 1,
                      px: 1,
                      py: 1.25,
                      backgroundColor: Color.WHITE,
                      border: 1,
                      borderColor: Color.GRAY_200,
                      borderRadius: "12px",
                    }}
                  >
                    <QuickLook job={jobData} />
                  </Box>
                </Box>

                <Box sx={{ mt: mobile ? 4 : 5 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "end",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Typography variant="h5">Pay Range</Typography>
                      <WhatIsThat information={jobDescriptions.entry_salary_and_medium_salary_and_high_salary} />
                    </Box>
                    <Typography variant="body1" color={Color.DARK_TEAL} fontSize={mobile ? 10 : 12} fontWeight={500}>
                      {wageLabel}
                    </Typography>
                  </Box>

                  <TableContainer component={Paper} sx={tableStyles.container} style={{ boxSizing: "border-box" }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={tableStyles.tableHeader}>Type</TableCell>
                          {["Entry", "Medium", "High"].map((type) => (
                            <TableCell key={type} sx={tableStyles.tableHeader} align="center">
                              {type}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {[
                          {
                            label: "Salary",
                            values: [
                              { key: "A_PCT10", format: parseInt },
                              { key: "A_MEDIAN", format: parseInt },
                              { key: "A_PCT90", format: parseInt },
                            ],
                          },
                          {
                            label: "Hourly Wage",
                            values: [
                              { key: "H_PCT10", format: parseFloat },
                              { key: "H_MEDIAN", format: parseFloat },
                              { key: "H_PCT90", format: parseFloat },
                            ],
                          },
                        ].map((row) => (
                          <TableRow key={row.label}>
                            <TableCell component="th" scope="row" sx={tableStyles.header}>
                              {row.label}
                            </TableCell>
                            {row.values.map(({ key, format }) => (
                              <TableCell key={key} align="center" sx={tableStyles.typeCell}>
                                {wageData?.[key as keyof WageDataRecord]
                                  ? formatCurrency({
                                      amount: format(wageData[key as keyof WageDataRecord]),
                                      decimalPlaces: row.label === "Hourly Wage" ? 2 : undefined,
                                    })
                                  : "Unknown"}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box sx={{ mt: mobile ? 4 : 5 }}>
                  <Box sx={{ display: "flex" }}>
                    <Typography variant="h5">Education Attainment</Typography>
                    <WhatIsThat information={jobDescriptions.education_attainment} />
                  </Box>
                  <Box sx={progreeBarstyles.container}>
                    {[
                      { title: "High School Diploma", value: educationAttainmentData?.highSchool },
                      { title: "Associates", value: educationAttainmentData?.associates },
                      { title: "Bachelors", value: educationAttainmentData?.bachelors },
                      { title: "Masters", value: educationAttainmentData?.masters },
                      { title: "PHD", value: educationAttainmentData?.phd },
                    ].map((education) => (
                      <Box key={education.title}>
                        <Box sx={progreeBarstyles.progressLabel}>
                          <Typography variant="body1" sx={progreeBarstyles.progressTitle}>
                            {education.title}
                          </Typography>
                          <Typography variant="body1" sx={progreeBarstyles.progressPercent}>
                            {education.value}%
                          </Typography>
                        </Box>
                        <LinearProgress
                          variant="determinate"
                          value={Number(education.value)}
                          sx={progreeBarstyles.progressBar}
                        />
                      </Box>
                    ))}
                  </Box>

                  {industryGroupData && industryGroupData.length > 0 && (
                    <Box sx={{ mt: mobile ? 4 : 5 }}>
                      <Typography variant="h5">Similar Careers</Typography>
                      <Box sx={similarJobsStyles.container}>
                        {industryGroupData
                          ?.filter((i) => i.id !== selectedJobId)
                          .map((i, index) => (
                            <Typography key={index} variant="body1" sx={similarJobsStyles.similarJobButton}>
                              {i.title}
                              <IconButton
                                onClick={() => onSimilarJobSelect(i.id)}
                                aria-label="arrow-right"
                                content="span"
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <FeatherIcon
                                  icon="arrow-right"
                                  strokeColor={"transparent"}
                                  fillColor={"black"}
                                  width="22px"
                                  height="22px"
                                />
                              </IconButton>
                            </Typography>
                          ))}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                {videosLoading && <LoadingBlock />}
                {videos.length === 0 ? (
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="h4">We haven't found any videos about this career, yet!</Typography>
                    {!disableLottie && (
                      <Player
                        ref={lottieRef}
                        loop={true}
                        autoplay={true}
                        src="https://lottie.host/a3a8bd74-2165-4d1c-9192-63d7942aca8f/pWKQST98wh.json"
                      ></Player>
                    )}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      height: "calc(100VH - 81px)",
                      overflowY: "scroll",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <VideoListPlayer videos={videos} interactions={interactions} showInteractionBar={false} />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Box component="main" sx={{ flexGrow: 1 }}>
            <SiteLoadingPage />
          </Box>
        )}
      </DialogContent>
      {userType !== UserType.STUDENT && (
        <DialogActions>
          <Button variant="contained" onClick={handleStaffRecommendClick}>
            Recommend To Students
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default JobDetailsDialog;
