import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import alma from "../../../shared/assets/static/alma-background-square.png";
import SendIcon from "@mui/icons-material/Send";
import { AlmaSidebarStarter } from "../../../shared/types/types";
import { getAlmaResponse } from "../../utils/studentAlmaUtils";
import { ChatGPTEntry } from "../../../shared/types/types";
import { useRecoilValue } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import AlmaChatHistory from "./AlmaChatHistory";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";

type Props = {
  almaStarters: AlmaSidebarStarter[];
  chatHistory: ChatGPTEntry[];
  setChatHistory: React.Dispatch<React.SetStateAction<ChatGPTEntry[]>>;
  pageContext: string;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  handleDialogClose?: () => void;
};

type StarterSubmitProps = {
  index: number;
};

const AlmaSidebarContainer = ({
  almaStarters,
  chatHistory,
  setChatHistory,
  pageContext,
  loading,
  setLoading,
  handleDialogClose,
}: Props) => {
  const { width } = useWindowDimensions();
  const mobile = width < 900;
  const [text, setText] = useState("");

  const loggedInStudent = useRecoilValue(loggedInStudentAtom);

  // Ref for scrolling to the bottom
  const bottomRef = useRef<HTMLDivElement | null>(null);

  // Function to scroll to the bottom
  const scrollToBottom = useCallback(() => {
    if (bottomRef.current?.parentNode) {
      const scrollableContainer = bottomRef.current.parentNode as HTMLElement;
      scrollableContainer.scrollTop = scrollableContainer.scrollHeight;
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory, scrollToBottom]);

  const handleTextSubmit = useCallback(async () => {
    if (!loggedInStudent) {
      return;
    }
    const originalChatHistory = [...chatHistory];
    const userEntry = { content: text, timestamp: new Date().toISOString(), role: "user" };
    setText(""); // Clear the input field
    scrollToBottom(); // Scroll after adding user message

    const ISONow = new Date().toISOString();
    if (originalChatHistory.length === 0 && pageContext.length > 0) {
      const adminEntry: ChatGPTEntry = {
        role: "system",
        content: pageContext,
        timestamp: ISONow,
      };
      originalChatHistory.push(adminEntry);
      setChatHistory([adminEntry, userEntry as ChatGPTEntry]);
    } else {
      setChatHistory((prev) => [...prev, userEntry as ChatGPTEntry]);
    }
    setLoading(true);
    const responseToSave = await getAlmaResponse({
      chatHistoryForAI: originalChatHistory,
      newEntry: { content: text, timestamp: ISONow, role: "user" },
      contextToAdd: "",
      initialContext: "",
      loggedInStudent: loggedInStudent,
    });
    setLoading(false);
    setChatHistory((prev) => [...prev, responseToSave]);
  }, [chatHistory, loggedInStudent, pageContext, scrollToBottom, setChatHistory, setLoading, text]);

  const handleStarterSubmit = useCallback(
    async ({ index }: StarterSubmitProps) => {
      const prompt = almaStarters[index].prompt;
      const promptPrefix = almaStarters[index].promptPrefix || "";
      if (!prompt || prompt === "") {
        return;
      }

      const systemContext = almaStarters[index].systemContext;
      const ISONow = new Date().toISOString();
      if (!loggedInStudent) {
        return;
      }

      const userContent = promptPrefix + " " + prompt;

      const userEntry = { content: userContent, timestamp: ISONow, role: "user" };
      const originalChatHistory = [...chatHistory];
      if (originalChatHistory.length === 0) {
        if (pageContext.length > 0) {
          const pageSystemEntry: ChatGPTEntry = {
            role: "system",
            content: pageContext,
            timestamp: ISONow,
          };
          originalChatHistory.push(pageSystemEntry);
          setChatHistory([pageSystemEntry, userEntry as ChatGPTEntry]);
        } else if (systemContext.length > 0) {
          const starterEntry: ChatGPTEntry = {
            role: "system",
            content: systemContext,
            timestamp: ISONow,
          };
          originalChatHistory.push(starterEntry);
          setChatHistory([starterEntry, userEntry as ChatGPTEntry]);
        }
      } else {
        setChatHistory((prev) => [...prev, userEntry as ChatGPTEntry]);
      }
      originalChatHistory.push(userEntry as ChatGPTEntry);
      scrollToBottom(); // Scroll after adding user entry

      setLoading(true);
      const responseToSave = await getAlmaResponse({
        chatHistoryForAI: originalChatHistory,
        newEntry: { content: prompt, timestamp: ISONow, role: "user" },
        contextToAdd: "",
        initialContext: "",
        loggedInStudent: loggedInStudent,
      });
      setLoading(false);
      setChatHistory((prev) => [...prev, responseToSave]);
    },
    [almaStarters, loggedInStudent, chatHistory, scrollToBottom, setLoading, setChatHistory, pageContext],
  );

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleTextSubmit();
    }
  };

  return (
    <Box
      sx={{
        height:
          mobile || loggedInStudent?.setupComplete
            ? "100VH"
            : "calc(100VH - 64px)" /* 64px is for top navbar when the profile is not completed */,
        overflow: "hidden", // Prevents accidental overflow
        borderLeft: "1px solid #E0E0E0",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #CACECC",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            p: 2,
            display: "flex",
            alignItems: "center",
            position: "sticky",
            backgroundColor: "#fff",
            zIndex: 1,
          }}
        >
          <Avatar variant="square" src={alma} />
          <Typography variant="h5" sx={{ ml: 2 }}>
            Alma
          </Typography>
        </Box>
        {mobile && (
          <IconButton onClick={handleDialogClose}>
            <FeatherIcon icon="close" />
          </IconButton>
        )}
      </Box>

      {/* Scrollable Content */}
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "scroll",
          backgroundColor: "#fff",
          p: 2,
        }}
      >
        <AlmaChatHistory chatHistory={chatHistory} />
        {/* Invisible div to track the bottom */}
        <div ref={bottomRef} style={{ margin: 0, padding: 0 }} />
      </Box>

      {/* TextField at the bottom */}
      <Box
        sx={{
          p: 2,
          borderTop: "1px solid #E0E0E0",
          position: "sticky",
          bottom: 0,
          backgroundColor: "#F6F8FA",
          zIndex: 1,
        }}
      >
        <Box sx={{ mb: 1 }}>
          <Typography variant="body2" sx={{ color: "#666", mb: 1 }}>
            Suggestions:
          </Typography>
          {almaStarters.map((starter, index) => (
            <Button
              key={starter.label}
              variant="contained"
              sx={{
                textTransform: "none",
                backgroundColor: "#fff",
                borderRadius: 3,
                color: "#171918",
                border: "1px solid #DBDBDB",
                boxShadow: "none",
                "&:hover": {
                  backgroundColor: "#fafafa",
                  boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.05)",
                },
                mr: 1,
              }}
              onClick={() => handleStarterSubmit({ index })}
            >
              {starter.label}
            </Button>
          ))}
        </Box>
        <Box sx={{ display: "flex", mt: 1 }}>
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "end", mr: 2 }}>
              <Box
                style={{
                  display: loading ? "block" : "none",
                  width: "60px",
                }}
              >
                <CircularProgress size={24} sx={{ color: "#7A47BB" }} />
              </Box>
            </Box>
          ) : (
            <>
              <TextField
                fullWidth
                variant="outlined"
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="Ask Alma..."
                onKeyDown={handleKeyDown}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 3,
                    "& fieldset": {
                      borderColor: "#CACECC",
                      border: "1px solid #aaa",
                    },
                    "&:hover fieldset": {
                      borderColor: "#7A47BB", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#7A47BB", // Border color when focused
                    },
                  },
                  "& .MuiOutlinedInput-input": {
                    borderRadius: 3,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTextSubmit}
                        edge="end"
                        color="default"
                        sx={{
                          color: "#aaa", // Default color
                          "&:hover": {
                            color: "#7A47BB", // Color on hover
                          },
                        }}
                      >
                        <SendIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AlmaSidebarContainer;
