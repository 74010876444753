import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const EmptyGoalCard = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      borderRadius="8px"
      border="1px dashed #ccc"
      sx={{ marginY: 2, padding: 4, justifyContent: "center" }}
    >
      <Typography sx={{ color: "#777", textTransform: "none", fontWeight: 400 }}>No Goal Created</Typography>
    </Box>
  );
};

export default EmptyGoalCard;
