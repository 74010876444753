import { useEffect, useRef, useCallback, useState } from "react";

const useIntersectionObserver = (
  setCurrentlyPlayingVideo: (url: string) => void,
  pauseOtherVideos: (currentSrc: string | null) => void,
  globalMute: boolean,
) => {
  const observer = useRef<IntersectionObserver | null>(null);
  const globalMuteRef = useRef(globalMute);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    globalMuteRef.current = globalMute;
  }, [globalMute]);

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (isFullscreen) return; // Skip updates in full-screen mode

      entries.forEach((entry) => {
        const videoElement = entry.target as HTMLVideoElement;

        if (entry.isIntersecting) {
          setCurrentlyPlayingVideo(videoElement.src);
          pauseOtherVideos(videoElement.src);
          videoElement.muted = globalMuteRef.current;
          setTimeout(() => {
            videoElement.play().catch((error) => console.error("Play failed:", error));
          }, 150); // Delay play by 100ms (can adjust if necessary)
        } else {
          setTimeout(() => {
            videoElement.pause();
          }, 150); // Delay pause by 100ms
        }
      });
    },
    [setCurrentlyPlayingVideo, pauseOtherVideos, isFullscreen],
  );

  useEffect(() => {
    const handleFullscreenChange = () => {
      const fullscreenElement = document.fullscreenElement || (document as any).webkitFullscreenElement;
      setIsFullscreen(!!fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener("webkitfullscreenchange", handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
    observer.current = new IntersectionObserver(handleIntersection, {
      threshold: 0.5, // Adjust this value to determine when the video is considered in view
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [handleIntersection]);

  const observe = useCallback((element: HTMLVideoElement) => {
    if (observer.current && element) {
      observer.current.observe(element);
    }
  }, []);

  const unobserve = useCallback((element: HTMLVideoElement) => {
    if (observer.current && element) {
      observer.current.unobserve(element);
    }
  }, []);

  return { observe, unobserve };
};

export default useIntersectionObserver;
