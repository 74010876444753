import { useCallback, useState } from "react";
import { CareerVideoRecord, VideoInteractionRecord } from "../../../shared/types/types";
import useIntersectionObserver from "../../../shared/hooks/videoListPlayer/useIntersectionObserver";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { Box } from "@mui/material";
import { selectedJobIdAtom } from "../../../shared/recoil/exploreCareersAtoms";
import VideoPlayer from "../../../shared/components/video/VideoPlayer";

type Props = {
  videos: CareerVideoRecord[];
  interactions: VideoInteractionRecord[];
  showInteractionBar?: boolean;
};

const VideoListPlayer = ({ videos, interactions, showInteractionBar = true }: Props) => {
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const setSelectedJobId = useSetRecoilState(selectedJobIdAtom);

  const { width } = useWindowDimensions();
  const [currentlyPlayingVideo, setCurrentlyPlayingVideo] = useState<string | null>(null);
  const [globalMute, setGlobalMute] = useState(true);

  const pauseOtherVideos = useCallback((currentSrc: string | null) => {
    const videoElements = document.querySelectorAll("video");
    videoElements.forEach((video) => {
      if (video.src !== currentSrc) {
        video.pause();
      }
    });
  }, []);
  const { observe, unobserve } = useIntersectionObserver(setCurrentlyPlayingVideo, pauseOtherVideos, globalMute);

  const handleLearnMoreClick = (video: CareerVideoRecord) => {
    setSelectedJobId(video.onet);
  };

  return (
    <>
      {loggedInStudent && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          {videos.map((video, index) => (
            <Box key={video.fileName}>
              {video.url && (
                <VideoPlayer
                  mobile={width < 900}
                  url={video.url}
                  pauseOtherVideos={pauseOtherVideos}
                  studentId={loggedInStudent.id}
                  video={videos[index]}
                  globalMute={globalMute}
                  setGlobalMute={setGlobalMute}
                  interaction={interactions.find((interaction) => interaction.videoId === videos[index].id) ?? null}
                  currentlyPlayingVideo={currentlyPlayingVideo}
                  setCurrentlyPlayingVideo={setCurrentlyPlayingVideo}
                  observe={observe}
                  unobserve={unobserve}
                  handleLearnMoreClick={handleLearnMoreClick}
                  showInteractionBar={showInteractionBar}
                />
              )}
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default VideoListPlayer;
