import { DataGridPremium, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { Button } from "@mui/material";
import { StudentRecord } from "../../../shared/types/types";
import useStudentsInGroupTable from "../../hooks/settings/useStudentInGroupsTable";

type Props = {
  studentsInGroup: StudentRecord[];
  handleDelete: (id: string) => void;
};

const StudentsInGroupTable = ({ studentsInGroup, handleDelete }: Props) => {
  const renderDeleteButton = (params: GridRenderCellParams) => (
    <Button variant="outlined" color="error" onClick={() => handleDelete(params.row.id)}>
      Remove
    </Button>
  );

  const { rows, columns } = useStudentsInGroupTable({
    studentsInGroup,
    renderDeleteButton,
  });

  return (
    <DataGridPremium
      pagination
      pageSizeOptions={[5, 10, 25]}
      rows={rows}
      columns={columns}
      initialState={{
        pagination: { paginationModel: { pageSize: 5, page: 0 } },
      }}
      getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd")}
    />
  );
};

export default StudentsInGroupTable;
