import { Box, Tooltip, Typography } from "@mui/material";
import { Color } from "../../../shared/types/enums";
import { StudentRecord } from "../../../shared/types/types";

type Props = {
  denominator: number;
  students: StudentRecord[];
};

const StageSummaryBar = ({ denominator, students }: Props) => {
  const StudentList = (name: string, i: number) => {
    return (
      <Typography key={name + i} sx={{ fontSize: 9 }}>
        {name}
      </Typography>
    );
  };

  return (
    <Box
      sx={{
        height: 140,
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        margin: "0 auto",
        pl: 1,
        pr: 1,
        pt: 2,
        pb: 0,
      }}
    >
      <Tooltip title={students.map((student, i) => StudentList(`${student.firstName} ${student.lastName}`, i))}>
        <Box
          sx={{
            backgroundColor: Color.ELECTRIC_BLUE,
            height: (students.length / denominator) * 100 + "%",
            width: "100%",
            display: "flex",
            margin: "0 auto",
            position: "relative",
            minHeight: students.length > 0 ? 14 : 0,
          }}
        >
          {students.length > 0 && (
            <Typography
              sx={{
                color: "#fff",
                fontSize: 12,
                textAlign: "center",
                width: "100%",
                position: "absolute",
                bottom: 1,
                lineHeight: 1,
              }}
              variant="body1"
            >
              {students.length}
            </Typography>
          )}
        </Box>
      </Tooltip>
    </Box>
  );
};

export default StageSummaryBar;
