import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import { Collection, Color } from "../../../shared/types/enums";
import { MilestoneRecord } from "../../../shared/types/types";
import useAddFSDoc from "../../../shared/hooks/db/useAddFSDoc";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";

type Props = {
  goalId: string;
  studentId: string;
  setMilestones: React.Dispatch<React.SetStateAction<MilestoneRecord[]>>;
  milestoneToEdit?: MilestoneRecord | null;
  setMilestoneToEdit: React.Dispatch<React.SetStateAction<MilestoneRecord | null>>;
};

const ManageMilestone = ({ goalId, studentId, setMilestoneToEdit, setMilestones, milestoneToEdit }: Props) => {
  const [open, setOpen] = useState(false);
  const { addFSDoc } = useAddFSDoc();
  const { updateFSDoc } = useUpdateFSDoc();
  const [text, setText] = useState("");
  const [dueDate, setDueDate] = useState<Dayjs | null>(dayjs());
  const [milestoneId, setMilestoneId] = useState<string | null>(null);

  useEffect(() => {
    if (milestoneToEdit) {
      setOpen(true);
      setText(milestoneToEdit.milestone);
      setDueDate(dayjs(milestoneToEdit.dueDate));
      setMilestoneId(milestoneToEdit.id);
    }
  }, [milestoneToEdit]);

  const handleClose = () => {
    setOpen(false);
    setText("");
    setDueDate(dayjs());
    setMilestoneId(null);
    setMilestoneToEdit(null);
  };

  const handleSave = async () => {
    if (!dueDate) {
      alert("Please select a due date.");
      return;
    }

    const data = {
      milestone: text,
      dueDate: dueDate.toISOString(), // Convert Dayjs to ISO string
    };

    if (!milestoneId) {
      const toAdd = {
        ...data,
        goalId,
        studentId,
        completed: false,
      };
      const results = await addFSDoc<MilestoneRecord>({
        col: Collection.MILESTONES,
        data: toAdd,
      });
      setMilestones((pV) => [...pV, results]);
    } else {
      await updateFSDoc({
        col: Collection.MILESTONES,
        id: milestoneId,
        data,
      });
      setMilestones((pV) => pV.map((m) => (m.id === milestoneId ? { ...m, ...data } : m)));
    }
    handleClose();
  };

  return (
    <>
      {open ? (
        <Box sx={{ ml: 6.5, mb: 1, mt: 1 }}>
          <Box sx={{ border: "1px solid #ccc", borderRadius: "8px" }}>
            <Box sx={{ p: 2 }}>
              <TextField
                fullWidth
                label="Milestone"
                placeholder="Example: Go to after school math tutoring..."
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
              <DatePicker
                sx={{ mt: 2 }}
                label="Due Date"
                value={dueDate}
                disablePast
                onChange={(newValue) => setDueDate(newValue)}
              />
            </Box>
            <Divider />
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", p: 1 }}>
              <Button variant="outlined" color="primary" sx={{ mr: 0.5 }} onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{ ml: 0.5 }}
                disabled={!text.trim() || !dueDate || !dueDate.isValid() || dueDate.isBefore(dayjs(), "day")}
                onClick={handleSave}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{ display: "flex", alignItems: "center", cursor: "pointer", mb: 1, ml: 7, mt: 1 }}
          onClick={() => setOpen(true)}
        >
          <FeatherIcon icon="add" width={"24px"} strokeColor={Color.MINT} />
          <Typography variant="body1" sx={{ color: "#666" }}>
            Add a milestone
          </Typography>
        </Box>
      )}
    </>
  );
};

export default ManageMilestone;
