import { useRecoilValue } from "recoil";
import { recommendedProgramsAtom } from "../../recoil/recommendedProgramAtoms";
import ProgramCard from "../../../shared/components/explorePrograms/Programs/ProgramCard";
import { Box, Container, Grid, Typography } from "@mui/material";
import ProgramDetailsDialog from "../../../shared/components/programDetails/ProgramDetailsDialog";
import { ProviderProgramRecordTwo } from "../../../shared/types/types";
import { useEffect, useState } from "react";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import noProgramsImage from "../../../shared/assets/profileImages/sections/college-majors-you-might-enjoy.svg";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import InfoBox from "../../../shared/components/infoBox/InfoBox";

const RecommendedProgramsContainer = () => {
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const [noProgramsMessage, setNoProgramsMessage] = useState("");
  const recommendedPrograms = useRecoilValue(recommendedProgramsAtom);
  const [selectedProgramDetail, setSelectedProgramDetail] = useState<ProviderProgramRecordTwo | null>(null);
  const { width } = useWindowDimensions();
  const mobile = width < 900;

  useEffect(() => {
    if (!loggedInStudent) return;
    let favoriteJobMessage = false;
    if (loggedInStudent.favoriteJobIds.length < 3) {
      setNoProgramsMessage(
        "You don't have many careers bookmarked. Please bookmark more careers to get better recommendations. \n",
      );
      favoriteJobMessage = true;
    }
    if (loggedInStudent.citiesForRecommendations.length > 0 && loggedInStudent.citiesForRecommendations.length < 3) {
      setNoProgramsMessage(
        (prev) =>
          prev +
          `${
            favoriteJobMessage ? "Also, y" : "Y"
          }ou don't have many cities selected. Broader your geographic preferences to get better recommendations. \n`,
      );
    }
  }, [loggedInStudent]);

  const noBookmarksMessage = `We noticed you haven't bookmarked any careers on the "Explore Careers" page. Bookmarking careers helps us provide you with better program recommendations. Click the bookmark icon on a career to bookmark it.`;
  const lowBookmarkMessage = `We noticed you've only bookmarked ${loggedInStudent?.favoriteJobIds.length} careers. Bookmarking careers helps us provide you with better program recommendations. Click the bookmark icon on a career to bookmark it.`;

  return (
    <>
      {loggedInStudent && (
        <>
          {width > 900 && <Typography variant="pageTitle">Recommended Programs</Typography>}
          <Box
            sx={{
              height: mobile ? "calc(100vh - 180px)" : "calc(100vh - 60px)",
              overflowY: "scroll",
            }}
          >
            <Box sx={{ mt: 2, mx: 1 }}>
              {recommendedPrograms.length > 0 && loggedInStudent.favoriteJobIds.length === 0 && (
                <InfoBox message={noBookmarksMessage} />
              )}
              {recommendedPrograms.length > 0 &&
                loggedInStudent.favoriteJobIds.length > 0 &&
                loggedInStudent.favoriteJobIds.length < 4 && <InfoBox message={lowBookmarkMessage} />}
            </Box>
            {recommendedPrograms.length === 0 ? (
              <>
                <Box
                  sx={{
                    mt: 2,
                    ml: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {<img src={noProgramsImage} alt="No Programs" width="40%" style={{ minWidth: 300 }} />}
                </Box>
                <Box sx={{ p: 2 }}>
                  <Typography variant="h6" sx={{ textAlign: "center", mt: 2 }}>
                    No Program Matches Are Available
                  </Typography>
                  <Typography textAlign={"center"} sx={{ mt: 2 }}>
                    {noProgramsMessage}
                  </Typography>
                </Box>
              </>
            ) : (
              <Container maxWidth="lg">
                <Grid container spacing={5} sx={{ px: 3, py: 1, pb: 20 }}>
                  {recommendedPrograms.map((program) => (
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} key={program.id}>
                      <ProgramCard
                        key={program.id}
                        program={program}
                        setSelectedLearnMoreProgram={setSelectedProgramDetail}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Container>
            )}
          </Box>
          {selectedProgramDetail && (
            <ProgramDetailsDialog
              selectedProgram={selectedProgramDetail}
              setSelectedProgram={setSelectedProgramDetail}
            />
          )}
        </>
      )}
    </>
  );
};

export default RecommendedProgramsContainer;
