import { Box, Grid, Typography } from "@mui/material";
import { JobDetailsRecord } from "../../../../types/types";
import useJobs from "../../../../hooks/exploreCareers/useJobs";
import { useRecoilValue } from "recoil";
import { selectedFieldIdAtom } from "../../../../recoil/exploreCareersAtoms";
import JobCard from "./JobCard";

type Props = {
  jobs: JobDetailsRecord[];
};

export const Jobs = ({ jobs }: Props) => {
  const selectedFieldId = useRecoilValue(selectedFieldIdAtom);
  const { mobile, field, filteredJobs } = useJobs({ selectedFieldId, jobs });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
      <Typography variant="h4" fontSize={mobile ? 24 : 30}>
        {field?.title}
      </Typography>
      <Typography variant="h5" sx={{ mt: 2 }}>
        Careers
      </Typography>
      <Grid container alignContent="start" spacing="20px">
        {filteredJobs.map((job, index: number) => (
          <Grid key={index} item xs={12} sm={6}>
            <JobCard job={job} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
