import { ChangeEvent, Dispatch } from "react";
import { Checkbox, FormControl, FormControlLabel, FormGroup, Switch, Typography } from "@mui/material";
import { FilterProps } from "../../../../types/types";
import { Color } from "../../../../types/enums";
import useWindowDimensions from "../../../../hooks/responsiveLayout/useWindowDimensions";

type Props = {
  filters: FilterProps;
  setFilters: Dispatch<React.SetStateAction<FilterProps>>;
};

const SizeFilter = ({ filters, setFilters }: Props) => {
  const { width } = useWindowDimensions();
  const mobile = width < 900;

  const fontSize = mobile ? 24 : 32;

  const handleSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const sizes = [...filters.sizes];
    if (sizes.includes(name)) {
      sizes.splice(sizes.indexOf(name), 1);
    } else {
      sizes.push(name);
    }
    setFilters((pV) => ({ ...pV, sizes }));
  };
  return (
    <>
      <FormControl sx={{ px: 2, py: 1 }} component="fieldset" variant="standard">
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "18px",
            color: Color.DARK_GRAY,
          }}
        >
          Size
        </Typography>
        <FormGroup sx={{ mt: 1, gap: 1.5 }} row={mobile}>
          <FormControlLabel
            sx={{ height: "24px" }}
            control={
              mobile ? (
                <Switch value={filters.sizes.includes("small")} onChange={handleSizeChange} name="small" />
              ) : (
                <Checkbox
                  checked={filters.sizes.includes("small")}
                  onChange={handleSizeChange}
                  name="small"
                  sx={{
                    fontWeight: 100,
                    color: Color.MINT,
                    "& .MuiSvgIcon-root": { fontSize: fontSize },
                  }}
                />
              )
            }
            label="Small (< 2,000)"
          />
          <FormControlLabel
            sx={{ height: "24px" }}
            control={
              mobile ? (
                <Switch value={filters.sizes.includes("medium")} onChange={handleSizeChange} name="medium" />
              ) : (
                <Checkbox
                  checked={filters.sizes.includes("medium")}
                  onChange={handleSizeChange}
                  name="medium"
                  sx={{
                    color: Color.MINT,
                    "& .MuiSvgIcon-root": { fontSize: fontSize },
                  }}
                />
              )
            }
            label="Medium (2,000 - 10,000)"
          />
          <FormControlLabel
            sx={{ height: "24px" }}
            control={
              mobile ? (
                <Switch value={filters.sizes.includes("large")} onChange={handleSizeChange} name="large" />
              ) : (
                <Checkbox
                  checked={filters.sizes.includes("large")}
                  onChange={handleSizeChange}
                  name="large"
                  sx={{
                    color: Color.MINT,
                    "& .MuiSvgIcon-root": { fontSize: fontSize },
                  }}
                />
              )
            }
            label="Large (> 10,000)"
          />
        </FormGroup>
      </FormControl>
    </>
  );
};

export default SizeFilter;
