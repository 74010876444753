import { Box } from "@mui/material";
import ProviderImageUploader from "../components/providerImageUpload/ProviderImageUploader";

const ProviderImageUploaderPage = () => {
  return (
    <Box sx={{ height: "calc(100VH - 64px)", p: 2 }}>
      <ProviderImageUploader />
    </Box>
  );
};

export default ProviderImageUploaderPage;
