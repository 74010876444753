import { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Collection } from "../../../shared/types/enums";
import { BaseRecord, ChatEntry, StaffAlmaChat, StaffRecord } from "../../../shared/types/types";
import { loggedInStaffAtom } from "../../../shared/recoil/userAtoms";
import { currentStaffAlmaChatAtom, staffAlmaChatsAtom } from "../../recoil/almaAtoms";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import useAddFSDoc from "../../../shared/hooks/db/useAddFSDoc";
import { generateStaffStartingPrompt } from "../../utils/aiUtils";

type NewChatRecordProps = {
  chatHistory: ChatEntry[];
  newEntry: ChatEntry;
  responseToSave: ChatEntry;
  loggedInStaff: StaffRecord;
};

const useChatManagement = () => {
  const [chatHistory, setChatHistory] = useState<ChatEntry[]>([]);
  const currentStaffAlmaChat = useRecoilValue(currentStaffAlmaChatAtom);
  const setStaffAlmaChats = useSetRecoilState(staffAlmaChatsAtom);
  const { updateFSDoc } = useUpdateFSDoc();
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const { addFSDoc } = useAddFSDoc();

  useEffect(() => {
    if (!loggedInStaff) return;
    setChatHistory(
      currentStaffAlmaChat ? currentStaffAlmaChat.chatHistory : generateStaffStartingPrompt({ loggedInStaff }),
    );
  }, [currentStaffAlmaChat, loggedInStaff]);

  const resetChat = async () => {
    if (!currentStaffAlmaChat) return;
    await updateFSDoc({
      col: Collection.StaffAlmaChats,
      id: currentStaffAlmaChat.id,
      data: {
        current: false,
      },
    });
    setStaffAlmaChats((prev) =>
      prev.map((chat) => (chat.id === currentStaffAlmaChat.id ? { ...chat, current: false } : chat)),
    );
  };

  //helper adds a new chat record if one doesn't exist (first time chatting or after a reset)
  const addNewChatRecord = useCallback(
    async ({ chatHistory, newEntry, responseToSave, loggedInStaff }: NewChatRecordProps) => {
      if (chatHistory.length < 2) return;
      const startingChats = [chatHistory[0], chatHistory[1]];
      const now = new Date().toDateString();
      const data: StaffAlmaChat = {
        chatHistory: [...startingChats, newEntry, responseToSave],
        staffId: loggedInStaff.id,
        current: true,
        schoolId: loggedInStaff?.schoolId ?? "",
        districtId: loggedInStaff?.districtId ?? "",
        name: now,
      };
      const meta: BaseRecord | null = await addFSDoc({
        col: Collection.StaffAlmaChats,
        data: data,
      });
      if (!meta) return;
      return { ...data, ...meta };
    },
    [addFSDoc],
  );

  //helper that turns a message into a new chat entrys
  const buildChatEntry = (newMessage: string) => {
    const entry: ChatEntry = {
      role: "user",
      parts: [{ text: newMessage }],
      timestamp: new Date().toISOString(),
    };
    return entry;
  };

  return {
    resetChat,
    buildChatEntry,
    chatHistory,
    setChatHistory,
    addNewChatRecord,
    setStaffAlmaChats,
    currentStaffAlmaChat,
  };
};

export default useChatManagement;
