import { useCallback } from "react";
import { JobDetailsRecord } from "../../../../types/types";
import { Box, Typography, Button } from "@mui/material";
import { Color } from "../../../../types/enums";
import useWindowDimensions from "../../../../hooks/responsiveLayout/useWindowDimensions";
import QuickLook from "./QuickLook";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { selectedJobForRecommendationsAtom } from "../../../../../staff/recoil/careerAtoms";
import { selectedJobIdAtom } from "../../../../recoil/exploreCareersAtoms";
import { loggedInStudentAtom, userTypeAtom } from "../../../../recoil/userAtoms";
import { Reactions } from "..";
import ExpandableText from "../../../expandableText/ExpandableText";

const JobCard = ({ job }: { job: JobDetailsRecord }) => {
  const setSelectedJobForRecommendations = useSetRecoilState(selectedJobForRecommendationsAtom);
  const setSelectedJobId = useSetRecoilState(selectedJobIdAtom);
  const currentUser = useRecoilValue(userTypeAtom);
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const { width } = useWindowDimensions();
  const mobile = width < 900;
  const studentUser = currentUser === "student";
  const staffUser = currentUser === "teacher";

  const handleRecommendClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setSelectedJobForRecommendations(job);
    },
    [job, setSelectedJobForRecommendations],
  );

  if (!studentUser && !staffUser) return null;

  return (
    <Box
      sx={{
        height: "100%",
        backgroundColor: Color.SURFACE_GREEN,
        borderRadius: 4,
        px: 2.5,
        display: "flex",
        flexDirection: "column",
        gap: mobile ? 1 : 2.5,
        boxShadow: "0px 2px 8px -1px #10182814",
      }}
    >
      <Box sx={{ py: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            textAlign={"left"}
            variant="h6"
            sx={{
              lineHeight: 1.2,
              color: Color.CHALKBOARD,
              fontSize: mobile ? 14 : 20,
            }}
          >
            {job.title}
          </Typography>
          <Box sx={{ flexShrink: 0 }}>
            {staffUser ? (
              <Button variant="outlined" onClick={handleRecommendClick}>
                Recommend To Students
              </Button>
            ) : (
              <Reactions jobId={job.id} />
            )}
          </Box>
        </Box>

        {studentUser && loggedInStudent && (
          <>
            {loggedInStudent.willowRecommendedJobIds.includes(job.id) && (
              <Box
                sx={{
                  mt: 1,
                  borderRadius: 12,
                  backgroundColor: Color.MINT,
                  display: "inline-block",
                  p: 1,
                }}
              >
                <Typography sx={{ color: Color.BLACK }} variant="body2">
                  Willow Recommended
                </Typography>
              </Box>
            )}
            {loggedInStudent.staffRecommendedJobIds.includes(job.id) && (
              <Box
                sx={{
                  mt: 1,
                  borderRadius: 12,
                  backgroundColor: Color.ORANGE_700,
                  display: "inline-block",
                  p: 1,
                  ml: 1,
                }}
              >
                <Typography sx={{ color: Color.BLACK }} variant="body2">
                  Staff Recommended
                </Typography>
              </Box>
            )}
          </>
        )}

        <ExpandableText content={job.description} maxLength={mobile ? 50 : 120} sx={{ mt: 1 }} />
        <Box sx={{ mt: 1.5 }}>
          <QuickLook job={job} />
        </Box>
        <Button
          sx={{
            mt: 1.5,
            color: Color.CHALKBOARD,
            backgroundColor: Color.SURFACE_GREEN,
            fontSize: 12,
            lineHeight: "18px",
            fontWeight: 400,
            textTransform: "none",
          }}
          fullWidth
          variant="outlined"
          onClick={() => setSelectedJobId(job.id)}
        >
          Learn More
        </Button>
      </Box>
    </Box>
  );
};

export default JobCard;
