import React from "react";
import { Color } from "../../../shared/types/enums";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

type Props = {
  selected: string | null;
  handleSelected: (event: React.MouseEvent<HTMLElement>, value: string) => void;
};

const NavigationToggle = ({ selected, handleSelected }: Props) => {
  return (
    <div
      style={{
        position: "sticky",
        top: 64,
        zIndex: 5,
        backgroundColor: "#fff",
      }}
    >
      <ToggleButtonGroup
        value={selected}
        exclusive
        fullWidth
        onChange={handleSelected}
        aria-label="text alignment"
        sx={{ backgroundColor: "#fff" }}
      >
        <ToggleButton
          sx={{
            borderRadius: 0,
            border: 0,
            borderBottom: selected === "videos" ? `1px solid ${Color.MINT}` : Color.GRAY_800,
          }}
          value="bookmarkedVideos"
          aria-label="right aligned"
        >
          Videos
        </ToggleButton>
        <ToggleButton
          sx={{
            borderRadius: 0,
            border: 0,
            borderBottom: selected === "careers" ? `1px solid ${Color.MINT}` : Color.GRAY_800,
          }}
          value="bookmarkedCareers"
          aria-label="centered"
        >
          Careers
        </ToggleButton>

        <ToggleButton
          sx={{
            borderRadius: 0,
            border: 0,
            borderBottom: selected === "programs" ? `1px solid ${Color.MINT}` : Color.GRAY_800,
          }}
          value="bookmarkedPrograms"
          aria-label="left aligned"
        >
          Programs
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export default NavigationToggle;
