import { Grid, IconButton, Dialog, Box } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import StudentProfileContainer from "../components/profile/StudentProfileContainer";
import { loggedInStudentAtom } from "../../shared/recoil/userAtoms";
import { useRecoilValue } from "recoil";
import { useCallback, useEffect, useState } from "react";
import AlmaSidebarContainer from "../components/almaSidebar/AlmaSidebarContainer";
import { AlmaSidebarStarter, ChatGPTEntry } from "../../shared/types/types";
import { getAlmaResponse } from "../utils/studentAlmaUtils";
import useWindowDimensions from "../../shared/hooks/responsiveLayout/useWindowDimensions";
import { personalityTypeAtom } from "../../shared/recoil/personalityTypeAtoms";
import { schoolsAtom } from "../../shared/recoil/schoolAndDistrictAtoms";
import useGenerateStartingPrompt from "../hooks/alma/useGenerateStartingPrompt";

const openingPrompt = `
  You are an AI guidance counselor helping high school students plan their future and make good decisions in high school to ensure success after high school.
  You can help students with career exploration, college planning, and more.
  Provide short, helpful responses to students' questions and concerns.
  Any prompts students say that are not on topic, redirect them to the topic at hand.

  One of the things students may ask you to do is help them reflect on a goal after they accomplish it.

  If students ask you to reflect on a goal, you have three specific reflection questions to ask them, one at a time.
  The three questions are:
  1. What did you learn about yourself while working towards this goal?
  2. What challenges did you face and how did you either overcome them, or what would you have done differently?
  3. How will this experience influence your next goal?

  When they answer, you can share affirmations or quick notes in reaction to their response before pivoting and asking the next question.

  Once students have answered all three questions, you can thank them for sharing.
`;

const StudentProfilePage = () => {
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const [almaStarters, setAlmaStarters] = useState<AlmaSidebarStarter[]>([]);
  const [chatHistory, setChatHistory] = useState<ChatGPTEntry[]>([]);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false); // State to control the dialog
  const { width } = useWindowDimensions();
  const mobile = width < 900;
  const [personalizedContext, setPersonalizedContext] = useState<string>("");
  const { generateInitialPrompt } = useGenerateStartingPrompt();
  const personalityType = useRecoilValue(personalityTypeAtom);
  const schools = useRecoilValue(schoolsAtom);

  useEffect(() => {
    const getPersonalizedContext = async () => {
      if (!loggedInStudent) return;
      const startingPrompt = await generateInitialPrompt(loggedInStudent, schools, personalityType);
      setPersonalizedContext(startingPrompt);
    };
    getPersonalizedContext();
  }, [generateInitialPrompt, loggedInStudent, personalityType, schools]);

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  const handleTextSubmit = useCallback(
    async (text: string) => {
      if (!loggedInStudent) return;
      const ISONow = new Date().toISOString();

      const originalChatHistory = [...chatHistory];

      const userEntry: ChatGPTEntry = {
        content: text,
        timestamp: new Date().toISOString(),
        role: "user",
      };

      if (chatHistory.length === 0) {
        const pageSystemEntry: ChatGPTEntry = {
          role: "system",
          content: `${personalizedContext} ${openingPrompt}`,
          timestamp: ISONow,
        };
        originalChatHistory.push(pageSystemEntry, userEntry);
        setChatHistory([pageSystemEntry, userEntry]);
      } else {
        originalChatHistory.push(userEntry);
        setChatHistory((prev) => [...prev, userEntry]);
      }

      setLoading(true);

      const responseToSave = await getAlmaResponse({
        chatHistoryForAI: originalChatHistory,
        newEntry: userEntry,
        contextToAdd: "",
        initialContext: "",
        loggedInStudent,
      });

      setLoading(false);
      setChatHistory((prev) => [...prev, responseToSave]);
    },
    [chatHistory, loggedInStudent, personalizedContext],
  );

  return (
    <>
      {loggedInStudent && (
        <Grid container>
          <Grid item xs={12} sm={8}>
            <StudentProfileContainer
              loggedInStudent={loggedInStudent}
              onAskAlma={handleTextSubmit}
              setAlmaMobileDialogOpen={setDialogOpen}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            {!mobile && (
              <AlmaSidebarContainer
                almaStarters={almaStarters}
                chatHistory={chatHistory}
                setChatHistory={setChatHistory}
                pageContext={personalizedContext + " " + openingPrompt}
                loading={loading}
                setLoading={setLoading}
              />
            )}
          </Grid>
        </Grid>
      )}

      {/* Show chat icon button on mobile */}
      {mobile && (
        <IconButton
          onClick={handleDialogOpen}
          sx={{
            position: "fixed",
            bottom: 66,
            right: 16,
            backgroundColor: "#7A47BB",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#693aa6",
            },
          }}
          size="large"
        >
          <ChatIcon />
        </IconButton>
      )}

      {/* Fullscreen dialog for Alma sidebar */}
      <Dialog fullScreen open={dialogOpen} onClose={handleDialogClose}>
        <Box sx={{ p: 0 }}>
          <AlmaSidebarContainer
            almaStarters={almaStarters}
            chatHistory={chatHistory}
            setChatHistory={setChatHistory}
            pageContext={personalizedContext + " " + openingPrompt}
            loading={loading}
            setLoading={setLoading}
            handleDialogClose={handleDialogClose}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default StudentProfilePage;
