import React, { Dispatch, useEffect, useState } from "react";
import { Box, FormControl, FormLabel, Slider, styled } from "@mui/material";
import { Color } from "../../../../types/enums";
import { FilterProps } from "../../../../types/types";

const CostSlider = styled(Slider)(({ theme }) => ({
  color: Color.MINT,
  height: 6,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 28,
    width: 28,
    backgroundColor: Color.MINT,
    boxShadow: "0 0 2px 0px rgba(0, 0, 0, 0.1)",
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.1)",
      "@media (hover: none)": {
        boxShadow: "none",
      },
    },
    "&:before": {
      boxShadow: "0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)",
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -3,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&::before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: Color.GRAY_800,
    },
  },
  "& .MuiSlider-track": {
    border: "none",
    height: 6,
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    boxShadow: "inset 0px 0px 4px -2px #000",
    backgroundColor: Color.GREEN_700,
  },
}));

type Props = {
  filters: FilterProps;
  setFilters: Dispatch<React.SetStateAction<FilterProps>>;
};

const mapSliderValueToCost = (value: number) => {
  if (value <= 25) {
    return value * 400; // $0 - $10,000
  } else if (value <= 50) {
    return 10000 + (value - 25) * 1600; // $10,000 - $50,000
  } else if (value <= 75) {
    return 50000 + (value - 50) * 2000; // $50,000 - $100,000
  } else {
    return 100000 + (value - 75) * 6000; // $100,000 - $250,000
  }
};

const mapCostToSliderValue = (cost: number) => {
  if (cost <= 10000) {
    return cost / 400;
  } else if (cost <= 50000) {
    return 25 + (cost - 10000) / 1600;
  } else if (cost <= 100000) {
    return 50 + (cost - 50000) / 2000;
  } else {
    return 75 + (cost - 100000) / 6000;
  }
};

const CostFilter = ({ filters, setFilters }: Props) => {
  // Store the slider’s 0..100 range in the child so it moves fluidly
  const [localSliderValue, setLocalSliderValue] = useState<number[]>([0, 100]);

  // Keep local slider in sync with parent’s filters when the parent changes
  useEffect(() => {
    const [costMin, costMax] = filters.cost; // [0, 250,000]
    setLocalSliderValue([mapCostToSliderValue(costMin), mapCostToSliderValue(costMax)]);
  }, [filters.cost]);

  const handleSliderChange = (_: any, newValue: number | number[]) => {
    // This fires continuously as you drag
    if (Array.isArray(newValue)) {
      setLocalSliderValue(newValue);
    }
  };

  const handleSliderCommit = (_: any, newValue: number | number[]) => {
    // This fires on mouse release
    if (Array.isArray(newValue)) {
      const newCost: [number, number] = [
        mapSliderValueToCost(newValue[0]),
        Math.min(mapSliderValueToCost(newValue[1]), 250000),
      ];
      setFilters((prev) => ({ ...prev, cost: newCost }));
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          px: 2,
          py: 1,
        }}
      >
        <FormControl fullWidth>
          <FormLabel
            component="legend"
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "18px",
              color: Color.DARK_GRAY,
            }}
          >
            Cost
          </FormLabel>
          <Box sx={{ mt: 4, px: 1.5 }}>
            <CostSlider
              min={0}
              max={100}
              value={localSliderValue}
              onChange={handleSliderChange}
              onChangeCommitted={handleSliderCommit}
              valueLabelDisplay="on"
              valueLabelFormat={(val) => `$${mapSliderValueToCost(val).toLocaleString()}`}
            />
          </Box>
        </FormControl>
      </Box>
    </>
  );
};

export default CostFilter;
