import { Avatar } from "@mui/material";
import useAvatarCell from "../../hooks/scholarships/useAvatarCell";

type Props = {
  avatar: string | null;
  studentName: string | null;
};

//used in tables that show a user's avatar

export const AvatarCell = ({ avatar, studentName }: Props) => {
  const { url } = useAvatarCell({ fileName: avatar ? avatar : "" });

  return <Avatar alt={`${studentName}`} src={url || ""} sx={{ width: 40, height: 40, mt: 1, mb: 1, mr: 1 }} />;
};
