import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { Dispatch } from "react";
import { StudentGroup } from "../../../shared/types/types";
import { useManageGroupDialog } from "../../hooks/settings/useManageGroupDialog";

export type StudentGroupFormHookProps = {
  groupForm: StudentGroup;
  setGroupForm: Dispatch<React.SetStateAction<StudentGroup>>;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
  groups: StudentGroup[] | null;
};

interface StudentGroupFormProps extends StudentGroupFormHookProps {
  open: boolean;
}

export const ManageGroupsDialog = ({ groupForm, setGroupForm, open, setOpen, groups }: StudentGroupFormProps) => {
  const { handleTextChange, handleSave } = useManageGroupDialog({
    groupForm,
    setGroupForm,
    setOpen,
    groups,
  });

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle variant="h4" sx={{ fontSize: 44, textAlign: "center" }}>
        Create Student Group
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        ></Box>

        <Grid container sx={{ mt: 2 }} spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Group Name"
              variant="outlined"
              value={groupForm.groupName}
              name="groupName"
              onChange={handleTextChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} disabled={groupForm.groupName.length < 2}>
          Save
        </Button>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
