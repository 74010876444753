import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Box, Grid, InputAdornment, TextField } from "@mui/material";
import StudentPanelRow from "./StudentPanelRow";
import { useParams } from "react-router-dom";
import { StudentRecord } from "../../../shared/types/types";
import { Search } from "@mui/icons-material";
import { getImageUrl } from "../../../shared/utils/imageUtils";
import { allSchoolStudentsSelector, selectedStudentAtom } from "../../recoil/studentsAtoms";

const StudentPanel = () => {
  const { studentId } = useParams();
  const [keyword, setKeyword] = useState("");
  const [updatedStudents, setUpdatedStudents] = useState<StudentRecord[]>([]);
  const students = useRecoilValue(allSchoolStudentsSelector);
  const setSelectedStudent = useSetRecoilState(selectedStudentAtom);

  useEffect(() => {
    if (updatedStudents.length > 0) {
      const foundStudent = updatedStudents.find((student) => student.id === studentId);
      if (foundStudent) {
        setSelectedStudent(foundStudent);
      } else {
        setSelectedStudent(updatedStudents[0]);
      }
    } else {
      setSelectedStudent(null);
    }
  }, [updatedStudents, studentId, setSelectedStudent]);

  useEffect(() => {
    const studentsSetter = async () => {
      if (!students) return;
      const newRows = await Promise.all(
        students.map(async (student) => {
          const avatarURL = student.avatar.length > 5 ? await getImageUrl(student.avatar) : "";
          return { ...student, avatarURL };
        }),
      );
      setUpdatedStudents(newRows);
    };
    studentsSetter();
  }, [students]);

  return (
    <>
      <Grid
        container
        sx={{
          padding: "0px 16px 0px 16px",
          borderRight: 1,
          borderColor: "#989898",
        }}
      >
        <Grid item xs={12}></Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <TextField
            id="outlined-start-adornment"
            placeholder="Search"
            size="small"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search sx={{ width: "15px", height: "15px", color: "#989898" }} />
                </InputAdornment>
              ),
            }}
            onChange={(event) => setKeyword(event.target.value)}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Box sx={{ height: "calc(100VH - 157px)", overflowY: "scroll" }}>
            {updatedStudents
              .filter(
                (student) =>
                  student.firstName.toLowerCase().includes(keyword.toLowerCase()) ||
                  student.lastName.toLowerCase().includes(keyword.toLowerCase()),
              )
              .map((student, index) => (
                <StudentPanelRow key={student.id + index} student={student} />
              ))}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default StudentPanel;
