import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { ProviderProgramRecordTwo, StudentRecord } from "../../../shared/types/types";
import { allSchoolStudentsSelector } from "../../recoil/studentsAtoms";

type Props = {
  providerId: string;
  favoriteOnlyToggle: boolean;
  fromAllSchoolsToggle: boolean;
  matchedPrograms: ProviderProgramRecordTwo[];
  allEnrolledDistrictStudents: StudentRecord[];
};

type StudentList = {
  firstName: string;
  lastName: string;
  favorited: "Yes" | "No";
  programName: string;
  id: string;
};

const useProviderList = ({
  providerId,
  favoriteOnlyToggle,
  fromAllSchoolsToggle,
  matchedPrograms,
  allEnrolledDistrictStudents,
}: Props) => {
  const [studentsList, setStudentList] = useState<StudentList[]>([]);
  const allStudents = useRecoilValue(allSchoolStudentsSelector);
  const currentProvider = matchedPrograms.find((p) => p.providerId === providerId);

  useEffect(() => {
    const filteredStudents = (fromAllSchoolsToggle ? allEnrolledDistrictStudents : allStudents).filter((s) => {
      const programs = matchedPrograms.filter((p) => s.willowRecommendedProgramIds.includes(p.id));
      const matchedProviderIds = programs.map((p) => p.providerId);
      return matchedProviderIds.includes(providerId.toString());
    });
    const filteredMatchedStudents: StudentList[] = [];
    filteredStudents.forEach((s) => {
      const matchedProgramIds = s.willowRecommendedProgramIds.filter(
        (value, index) => s.willowRecommendedProgramIds.indexOf(value) === index,
      );
      matchedProgramIds.forEach((id) => {
        const currentProviderId = matchedPrograms.find((p) => p.id === id)?.providerId;
        if (!currentProviderId) return;
        if (currentProviderId === providerId.toString()) {
          if (matchedPrograms.some((p) => p.id === id) === false) return;
          filteredMatchedStudents.push({
            firstName: s.firstName,
            lastName: s.lastName,
            favorited: s.favoriteProgramIds.includes(id) ? "Yes" : "No",
            programName: matchedPrograms.find((p) => p.id === id)?.programName ?? "Unknown",
            id: `${s.id}_${id}`,
          });
        }
      });
    });

    const filteredFavoritedStudents = (fromAllSchoolsToggle ? allEnrolledDistrictStudents : allStudents).filter((s) => {
      const programs = matchedPrograms.filter((p) => s.willowRecommendedProgramIds.includes(p.id));
      const matchedProviderIds = programs.map((p) => p.providerId);
      return matchedProviderIds.includes(providerId.toString());
    });
    const filteredFavoritedMatchedStudents: StudentList[] = [];
    filteredFavoritedStudents.forEach((s) => {
      const matchedProgramIds = s.willowRecommendedProgramIds;
      matchedProgramIds.forEach((id) => {
        const currentProviderId = matchedPrograms.find((p) => p.id === id)?.providerId;
        if (currentProviderId === providerId.toString() && matchedPrograms.some((p) => p.id === id)) {
          filteredFavoritedMatchedStudents.push({
            firstName: s.firstName,
            lastName: s.lastName,
            favorited: s.favoriteProgramIds.includes(id) ? "Yes" : "No",
            programName: matchedPrograms.find((p) => p.id === id)?.programName ?? "Unknown",
            id: `${s.id}_${id}`,
          });
        }
      });
    });

    setStudentList(favoriteOnlyToggle ? filteredFavoritedMatchedStudents : filteredMatchedStudents);
  }, [
    fromAllSchoolsToggle,
    allStudents,
    allEnrolledDistrictStudents,
    currentProvider,
    favoriteOnlyToggle,
    providerId,
    matchedPrograms,
  ]);
  return { currentProvider, studentsList };
};

export default useProviderList;
