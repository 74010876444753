import { Formik, Form, Field } from "formik";
import { TextField, Button, Box, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import FormikPhone from "../../../shared/components/phone/FormikPhone";
import useCreateProfileForm from "../../hooks/createProfile/useCreateProfileForm";
import { User } from "firebase/auth";
import { DistrictRecord, SchoolRecord } from "../../../shared/types/types";
import LoadingBlock from "../../../shared/components/loading/LoadingBlock";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";

type Props = {
  currentAuthUser: User;
  schools: SchoolRecord[];
  districts: DistrictRecord[];
};

const CreateProfileForm = ({ currentAuthUser, schools, districts }: Props) => {
  const { initialValues, validationSchema, gradeLevels, isDemoUser, handleSubmit } = useCreateProfileForm({
    currentAuthUser,
    schools,
  });

  return (
    <Box sx={{ margin: "0 auto" }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        {({ errors, touched, isSubmitting, isValid }) => (
          <>
            {isSubmitting ? (
              <LoadingBlock size={100} mt={8} />
            ) : (
              <Form>
                <Field
                  as={TextField}
                  name="firstName"
                  label="First Name"
                  required
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={touched.firstName && Boolean(errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />

                <Field
                  as={TextField}
                  name="lastName"
                  required
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={touched.lastName && Boolean(errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />

                <Field
                  as={FormikPhone}
                  name="phone"
                  label="Phone"
                  variant="outlined"
                  fullWidth
                  required
                  margin="normal"
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={touched.phone && errors.phone}
                  sx={{ mt: 2 }}
                />

                <FormControl required fullWidth margin="normal">
                  <InputLabel id="school-label">School</InputLabel>
                  <Field
                    as={Select}
                    disabled={isDemoUser}
                    name="schoolId"
                    labelId="school-label"
                    label="School"
                    id="schoolId"
                    error={touched.schoolId && Boolean(errors.schoolId)}
                  >
                    <MenuItem value="My School Is Not Listed">My School Is Not Listed</MenuItem>
                    {schools.map((school) => (
                      <MenuItem key={school.id} value={school.id}>
                        {districts.find((district) => district.id === school.districtId)?.name}: {school.name}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>

                <FormControl required fullWidth margin="normal">
                  <InputLabel id="gradeLevel-label">Grade Level</InputLabel>
                  <Field
                    as={Select}
                    name="gradeLevel"
                    labelId="gradeLevel-label"
                    label="Grade Level"
                    id="gradeLevel"
                    error={touched.gradeLevel && Boolean(errors.gradeLevel)}
                  >
                    {gradeLevels.map((school) => (
                      <MenuItem key={school} value={school}>
                        {school}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>

                <Field
                  as={TextField}
                  name="externalId"
                  id="externalId"
                  label="School Id (optional)"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  inputProps={{
                    maxLength: 30, // Set the maximum length to 20 (or any desired value)
                  }}
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={isSubmitting || !isValid}
                  sx={{ mt: 2, zIndex: 1000, mb: 2 }}
                >
                  Next
                  <FeatherIcon icon="chevron-right" width="36px" />
                </Button>
              </Form>
            )}
          </>
        )}
      </Formik>
    </Box>
  );
};

export default CreateProfileForm;
