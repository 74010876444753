import { Dispatch, SetStateAction } from "react";
import { Box, Typography } from "@mui/material";
import { Color } from "../../../shared/types/enums";
import { CareerVideoRecord, VideoInteractionRecord } from "../../../shared/types/types";
import VideoInteractions from "./VideoInteractions";
import useVideoPlayer from "../../hooks/video/useVideoPlayer";
import onetMap from "../../../shared/assets/data/onetTitlesMap.json";

interface VideoPlayerProps {
  mobile: boolean;
  url: string;
  video: CareerVideoRecord;
  interaction: VideoInteractionRecord | null;
  currentlyPlayingVideo: string | null;
  setCurrentlyPlayingVideo: Dispatch<SetStateAction<string | null>>;
  pauseOtherVideos: (currentSrc: string | null) => void;
  observe: (element: HTMLVideoElement) => void;
  unobserve: (element: HTMLVideoElement) => void;
  globalMute: boolean;
  setGlobalMute: Dispatch<SetStateAction<boolean>>;
  handleLearnMoreClick?: (video: CareerVideoRecord) => void;
  studentId?: string;
  showInteractionBar?: boolean;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  mobile,
  url,
  studentId,
  video,
  interaction,
  observe,
  unobserve,
  globalMute,
  setGlobalMute,
  handleLearnMoreClick,
  showInteractionBar = true,
}) => {
  const { videoRef, sharedInteraction, setSharedInteraction } = useVideoPlayer({
    studentId,
    video,
    interaction,
    observe,
    unobserve,
    globalMute,
    setGlobalMute,
  });

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        margin: "0 auto",
        pb: 1,
        borderBottom: 1,
        borderColor: Color.LIGHT_GRAY,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {videoRef && url && (
        <>
          <Box sx={{ display: "flex", flexDirection: mobile ? "column" : "row" }}>
            <video
              webkit-playsinline={`${true}`}
              playsInline
              ref={videoRef}
              className="video-js"
              preload="auto"
              controls
              style={{
                width: mobile ? "100%" : "calc(80vh * 9/16)", // Adjust width for mobile
                height: mobile ? "100%" : "80vh", // Full viewport height for mobile
                objectFit: "contain", // Maintain aspect ratio
                borderRadius: "10px",
              }}
            >
              <source src={url} type="video/mp4" />
            </video>
            {showInteractionBar && studentId && handleLearnMoreClick && (
              <Box sx={{ width: "100%", mt: 1 }}>
                <VideoInteractions
                  url={url}
                  studentId={studentId}
                  video={video}
                  interaction={sharedInteraction}
                  setInteraction={setSharedInteraction}
                  videoRef={videoRef}
                  handleLearnMoreClick={handleLearnMoreClick}
                  mobile={mobile}
                />
              </Box>
            )}
          </Box>
          <Typography sx={{ mt: 1 }} variant="h5" fontSize={14} color={Color.CHARCOAL}>
            {onetMap[video.onet as keyof typeof onetMap]}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default VideoPlayer;
