import { useCallback, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import useAddFSDoc from "../../../shared/hooks/db/useAddFSDoc";
import { Collection } from "../../../shared/types/enums";
import { ManualScholarshipRecord, StudentRecord } from "../../../shared/types/types";
import useLogger from "../../../shared/hooks/logging/useLogger";
import { LogEventType } from "../../../shared/types/logEnums";

type UseManualScholarshipDialogProps = {
  scholarshipEntry?: ManualScholarshipRecord | null;
  loggedInStudent: StudentRecord;
  setManualScholarships: React.Dispatch<React.SetStateAction<ManualScholarshipRecord[]>>;
  onClose: () => void;
};

const buildBlankForm = (student: StudentRecord): ManualScholarshipRecord => ({
  name: "",
  sponsorName: "",
  status: "not applied",
  appliedDate: null,
  awardDate: null,
  awardAmount: "",
  studentId: student.id,
  schoolId: student.schoolId ?? "",
  districtId: student.districtId ?? "",
  id: "",
  createdAt: "",
  lastUpdatedAt: "",
});

const useManualScholarshipDialog = ({
  scholarshipEntry,
  loggedInStudent,
  setManualScholarships,
  onClose,
}: UseManualScholarshipDialogProps) => {
  const { updateFSDoc } = useUpdateFSDoc();
  const { addFSDoc } = useAddFSDoc();
  const { submitLog } = useLogger();

  const handleClose = () => {
    onClose();
    setFormData(buildBlankForm(loggedInStudent));
    setErrors({});
    setTouched({});
    setAppliedDate(null);
    setAwardDate(null);
  };

  const [formData, setFormData] = useState<ManualScholarshipRecord>(buildBlankForm(loggedInStudent));
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [appliedDate, setAppliedDate] = useState<Dayjs | null>(null);
  const [awardDate, setAwardDate] = useState<Dayjs | null>(null);

  const validateFields = useCallback(() => {
    const today = dayjs().startOf("day");
    const newErrors: { [key: string]: string } = {};

    if (touched.name && !formData.name.trim()) {
      newErrors.name = "Scholarship Name is required.";
    }
    if (touched.sponsorName && !formData.sponsorName.trim()) {
      newErrors.sponsorName = "Sponsor Name is required.";
    }

    if (touched.status && formData.status !== "not applied") {
      if (!appliedDate || !appliedDate.isValid()) {
        newErrors.appliedDate = "Applied Date is required and must be valid.";
      } else if (appliedDate.isAfter(today)) {
        newErrors.appliedDate = "Applied Date cannot be in the future.";
      }
    }

    if (touched.awardDate && formData.status === "awarded") {
      if (!awardDate || !awardDate.isValid()) {
        newErrors.awardDate = "Award Date is required and must be valid.";
      } else if (awardDate.isAfter(today)) {
        newErrors.awardDate = "Award Date cannot be in the future.";
      } else if (appliedDate && awardDate.isBefore(appliedDate)) {
        newErrors.awardDate = "Award Date cannot be before Applied Date.";
      }
    }

    if (touched.awardAmount && formData.status === "awarded") {
      if (!formData.awardAmount || isNaN(Number(formData.awardAmount))) {
        newErrors.awardAmount = "Award Amount must be a valid number.";
      }
    }

    setErrors(newErrors);
    setIsFormValid(Object.keys(newErrors).length === 0);
  }, [formData, appliedDate, awardDate, touched]);

  useEffect(() => {
    if (scholarshipEntry) {
      setFormData(scholarshipEntry);
      setAppliedDate(scholarshipEntry.appliedDate ? dayjs(scholarshipEntry.appliedDate) : null);
      setAwardDate(scholarshipEntry.awardDate ? dayjs(scholarshipEntry.awardDate) : null);
    } else {
      setFormData(buildBlankForm(loggedInStudent));
    }
  }, [loggedInStudent, scholarshipEntry]);

  useEffect(() => {
    if (Object.keys(touched).length > 0) {
      validateFields();
    }
  }, [formData, appliedDate, awardDate, validateFields, touched]);

  const handleBlur = (field: string) => {
    setTouched((prev) => ({ ...prev, [field]: true }));
    validateFields();
  };

  const handleSave = async () => {
    try {
      const updatedFormData = {
        ...formData,
        appliedDate: appliedDate ? appliedDate.toISOString() : null,
        awardDate: awardDate ? awardDate.toISOString() : null,
      };

      if (scholarshipEntry && scholarshipEntry.id) {
        await updateFSDoc({
          col: Collection.MANUAL_SCHOLARSHIPS,
          id: scholarshipEntry.id,
          data: updatedFormData,
        });

        setManualScholarships((prev) =>
          prev.map((scholarship) =>
            scholarship.id === scholarshipEntry.id ? (updatedFormData as ManualScholarshipRecord) : scholarship,
          ),
        );
      } else {
        const results = (await addFSDoc({
          col: Collection.MANUAL_SCHOLARSHIPS,
          data: updatedFormData,
        })) as ManualScholarshipRecord;
        submitLog({
          eventType: LogEventType.ADD_MANUAL_SCHOLARSHIP,
          changeLog: `${loggedInStudent.firstName} ${loggedInStudent.lastName} added a manual scholarship`,
          file: "useManualScholarshipDialog.ts",
        });

        setManualScholarships((prev) => [...prev, results]);
      }
      handleClose();
    } catch (error) {
      console.error("Error saving scholarship entry:", error);
      submitLog({
        eventType: LogEventType.ADD_MANUAL_SCHOLARSHIP_ERROR,
        file: "useManualScholarshipDialog.ts",
        error: error,
        snackbarMessage: "Error saving scholarship entry, please refresh and try again",
      });
    }
  };

  return {
    formData,
    setFormData,
    appliedDate,
    setAppliedDate,
    awardDate,
    setAwardDate,
    handleSave,
    touched,
    errors,
    isFormValid,
    handleBlur,
    handleClose,
  };
};

export default useManualScholarshipDialog;
