import { db } from "../../firebase";
import { Collection, FunctionName } from "../../shared/types/enums";
import { CareerVideoRecord, FeedFilterProps, VideoInteractionRecord } from "../../shared/types/types";
import {
  collection,
  DocumentData,
  query,
  Query,
  startAfter,
  orderBy,
  limit,
  getDocs,
  QueryConstraint,
  where,
} from "firebase/firestore";
import { fetchData } from "../../shared/utils/fetchUtils";

const getInitialVideos = async (q: Query) => {
  const snapshot = await getDocs(q);
  const tempVideos: CareerVideoRecord[] = [];
  snapshot.forEach((doc) => {
    tempVideos.push({ ...doc.data(), id: doc.id } as CareerVideoRecord);
  });
  const lastVideoDoc = snapshot.docs[snapshot.docs.length - 1];
  return { tempVideos, lastVideoDoc, snapshot };
};

type GetAndFilterVideosProps = {
  q: Query;
  studentId: string;
  accumulatedVideos?: CareerVideoRecord[];
  selectedIndustryIds: number[];
  filters: FeedFilterProps;
  interactions: VideoInteractionRecord[];
};

const getAndFilterVideos = async ({
  q,
  studentId,
  accumulatedVideos = [],
  selectedIndustryIds,
  filters,
  interactions, // Add this parameter
}: GetAndFilterVideosProps): Promise<{
  filteredVideos: CareerVideoRecord[];
  lastVideo: DocumentData;
}> => {
  // Fetch initial videos based on the query
  const { tempVideos, lastVideoDoc, snapshot } = await getInitialVideos(q);

  // Retrieve the onet codes that have received more than two dislikes from this student.
  const dislikedOnetCodes = interactions.filter((i) => i.interaction === "disliked").map((i) => i.onetCode);
  const onetCodeCounts = dislikedOnetCodes.reduce((acc: any, onetCode) => {
    acc[onetCode] = (acc[onetCode] || 0) + 1;
    return acc;
  }, {});
  const duplicatedOnetCodes = Object.keys(onetCodeCounts).filter((onetCode) => onetCodeCounts[onetCode] >= 2);

  // Filter out videos that have already been watched, interacted with, or have a onet code associated with more than two dislikes based on user interactions.
  const newFilteredVideos = tempVideos.filter((video) => {
    const interaction = interactions.find((i) => i.videoId === video.id);
    return (
      !duplicatedOnetCodes.includes(video.onet) &&
      interaction?.interaction !== "disliked" &&
      interaction?.interaction !== "liked" &&
      interaction?.watched !== true
    );
  });

  // Accumulate the filtered videos
  const accumulated = [...accumulatedVideos, ...newFilteredVideos];

  // Check if we have enough videos or if there are no more videos to fetch
  if (accumulated.length >= 15 || !lastVideoDoc) {
    const lastVideo = snapshot.docs.find((doc) => doc.id === accumulated[accumulated.length - 1].id);
    return {
      filteredVideos: accumulated,
      lastVideo: lastVideo ? lastVideo : lastVideoDoc,
    };
  }

  // Create a new query to fetch more videos
  const newQuery = createQuery(selectedIndustryIds, filters, lastVideoDoc);

  // Recursively fetch and filter more videos
  return getAndFilterVideos({
    q: newQuery,
    studentId,
    accumulatedVideos: accumulated,
    selectedIndustryIds,
    filters,
    interactions, // Pass interactions here
  });
};

export const createQuery = (selectedIndustryIds: number[], filters: FeedFilterProps, lastDoc?: DocumentData) => {
  // Build the query constraints dynamically
  const queryConstraints: QueryConstraint[] = [
    where("industry_id", "in", selectedIndustryIds),
    orderBy("__name__"), // Use __name__ as the sort key for pagination
    limit(15),
  ];

  if (filters.brightOutlook) {
    queryConstraints.push(where("brightOutlook", "==", true));
  }

  if (filters.greenEconomy) {
    queryConstraints.push(where("greenEconomy", "==", true));
  }

  if (filters.careerOutOfHS) {
    queryConstraints.push(where("careerOutOfHS", "==", true));
  }

  if (filters.medianIncome[0] === 0 && filters.medianIncome[1] === 60000) {
    queryConstraints.push(where("bottomThird", "==", true));
  } else if (filters.medianIncome[0] === 60000 && filters.medianIncome[1] === 120000) {
    queryConstraints.push(where("middleThird", "==", true));
  } else if (filters.medianIncome[0] === 120000 && filters.medianIncome[1] === 180000) {
    queryConstraints.push(where("topThird", "==", true));
  } else if (filters.medianIncome[0] === 0 && filters.medianIncome[1] === 120000) {
    queryConstraints.push(where("topThird", "==", false));
  } else if (filters.medianIncome[0] === 60000 && filters.medianIncome[1] === 180000) {
    queryConstraints.push(where("bottomThird", "==", false));
  }

  // Create the base query
  const baseQuery = query(collection(db, Collection.CAREER_VIDEOS), ...queryConstraints);

  // Conditionally add the startAfter constraint if lastDoc is provided
  if (lastDoc) {
    return query(baseQuery, startAfter(lastDoc));
  }

  return baseQuery;
};

type FetchVideosProps = {
  q: Query;
  studentId: string;
  selectedIndustryIds: number[];
  accumulatedVideos: CareerVideoRecord[];
  firstLoad: boolean;
  filters: FeedFilterProps;
  interactions: VideoInteractionRecord[];
  networkSpeed?: number | null;
};

export const fetchVideos = async ({
  q,
  studentId,
  selectedIndustryIds,
  accumulatedVideos,
  firstLoad,
  filters,
  interactions,
  networkSpeed,
}: FetchVideosProps) => {
  const { filteredVideos, lastVideo } = await getAndFilterVideos({
    q,
    studentId,
    selectedIndustryIds,
    filters,
    accumulatedVideos: firstLoad ? [] : accumulatedVideos,
    interactions,
  });

  if (filteredVideos.length === 0) {
    return { videosWithUrls: [], lastVideo: null };
  }

  function shuffleArray<T>(array: T[]): T[] {
    const newArray = [...array]; // Create a copy of the original array
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1)); // Get a random index from 0 to i
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]]; // Swap elements
    }
    return newArray;
  }

  const shuffledVideos = shuffleArray<CareerVideoRecord>(filteredVideos);

  const fileNames = shuffledVideos.map((doc) => doc.fileName);

  const throttle = networkSpeed && networkSpeed < 50 ? true : false;

  const results = await fetchData({
    functionName: FunctionName.LIST_FILES,
    payload: { fileNames, throttle },
  });

  const { urls } = await results.json();
  const videosWithUrls = shuffledVideos.map((video, i) => {
    return { ...video, url: urls[i] };
  });

  return { videosWithUrls, lastVideo };
};
