import { Dispatch } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { MentorUserRecord } from "../../../shared/types/types";
import useEditMentorDialog from "../../hooks/profile/useEditMentorDialog";
import LoadingBlock from "../../../shared/components/loading/LoadingBlock";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import { Color } from "../../../shared/types/enums";
import { Field, Form, Formik } from "formik";

const BGCOLOR = "#fbfbfb";

type Props = {
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
  mentorUser: MentorUserRecord | null;
};

const EditMentorDialog = ({ open, setOpen, mentorUser }: Props) => {
  const { loading, initialValues, validationSchema, handleSave, handleDelete, handleClose } = useEditMentorDialog({
    mentorUser,
    setOpen,
    open,
  });

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle
        variant="h3"
        sx={{
          textAlign: "center",
          backgroundColor: Color.LIGHT_BACKGROUND_COLOR,
        }}
      >
        Manage Mentor
      </DialogTitle>
      {loading ? (
        <Box sx={{ padding: 8, backgroundColor: BGCOLOR }}>
          <LoadingBlock size={100} mt={8} />
        </Box>
      ) : (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSave}>
          {({ handleChange, handleBlur, isValid, errors, touched }) => (
            <Form>
              <DialogContent sx={{ backgroundColor: BGCOLOR }}>
                <Grid container spacing={3} sx={{ mt: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      name="firstName"
                      fullWidth
                      label="First Name"
                      required
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.firstName && Boolean(errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      name="lastName"
                      fullWidth
                      required
                      label="Last Name"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.lastName && Boolean(errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="email"
                      fullWidth
                      required
                      label="Email"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      disabled={mentorUser ? true : false}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions sx={{ backgroundColor: Color.LIGHT_BACKGROUND_COLOR }}>
                <Button variant="outlined" onClick={handleClose}>
                  Cancel
                </Button>
                {mentorUser && (
                  <Button variant="outlined" color="error" onClick={handleDelete} disabled={loading}>
                    Delete
                  </Button>
                )}
                <Button type="submit" variant="contained" disabled={!isValid || loading}>
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};

export default EditMentorDialog;
