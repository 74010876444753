import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { GoalRecord, MilestoneRecord } from "../../../shared/types/types";
import MilestoneCard from "./MilestoneCard";

type Props = {
  goal: GoalRecord;
  studentId: string;
  milestones: MilestoneRecord[];
  setMilestones: React.Dispatch<React.SetStateAction<MilestoneRecord[]>>;
};

const GoalCard = ({ goal, studentId, milestones }: Props) => {
  const selectedGoalMilestones = milestones.filter((milestone) => milestone.goalId === goal.id);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        borderRadius="8px"
        justifyContent="space-between"
        sx={{ mt: 1, py: 1, px: 1 }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* Goal text */}
          <Box>
            <Typography
              variant="h6"
              sx={{
                flexGrow: 1,
                marginLeft: "8px",
                fontSize: 16,
              }}
            >
              {goal.title}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                flexGrow: 1,
                marginLeft: "8px",
              }}
            >
              {goal.goal}
            </Typography>
          </Box>
        </Box>

        {/* Edit menu */}
      </Box>
      <Box>
        {selectedGoalMilestones.map((milestone) => (
          <MilestoneCard key={milestone.id} milestone={milestone} studentId={studentId} />
        ))}
      </Box>
      <Divider />
    </>
  );
};

export default GoalCard;
