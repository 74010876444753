import { MouseEvent, useEffect, useState } from "react";
import { Box } from "@mui/material";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import { ScholarshipApplicationRecord, ScholarshipRecord, StudentRecord } from "../../../shared/types/types";
import TopNavigationToggle from "./TopNavigationToggle";
import AllScholarships from "./AllScholarships";
import ScholarshipApplicationDialog from "./ScholarshipApplicationDialog";
import ScholarshipDetailsDialog from "./ScholarshipDetailsDialog";
import ManualScholarships from "./ManualScholarships";
import useExploreScholarshipsContainer from "../../hooks/exploreScholarships/useExploreScholarshipsContainer";
import { useRecoilState } from "recoil";
import { selectedSubMenuAtom } from "../../recoil/navigationAtoms";

type Props = {
  loggedInStudent: StudentRecord;
};

const ExploreScholarshipsContainer = ({ loggedInStudent }: Props) => {
  const [selectedSubMenu, setSelectedSubMenu] = useRecoilState(selectedSubMenuAtom);
  const [selectedScholarship, setSelectedScholarship] = useState<ScholarshipRecord | null>(null);
  const [selectedScholarshipApplication, setSelectedScholarshipApplication] =
    useState<ScholarshipApplicationRecord | null>(null);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [openUpdateStatusDialog, setOpenUpdateStatusDialog] = useState(false);

  const { scholarships, applications, setApplications } = useExploreScholarshipsContainer(loggedInStudent);
  const { width } = useWindowDimensions();
  const mobile = width < 900;

  useEffect(() => {
    setSelectedSubMenu("allScholarships");
  }, [setSelectedSubMenu]);

  const handleSelected = (_: MouseEvent<HTMLElement>, value: string | null) => {
    if (value && value !== selectedSubMenu) {
      setSelectedSubMenu(value);
    }
  };

  const renderScholarships = () =>
    selectedSubMenu === "allScholarships" ? (
      <AllScholarships
        isMobile={mobile}
        scholarships={scholarships}
        scholarshipApplications={applications}
        setScholarshipApplications={setApplications}
        setSelectedScholarshipApplication={setSelectedScholarshipApplication}
        setSelectedScholarship={setSelectedScholarship}
        setOpenDetailDialog={setOpenDetailDialog}
        setOpenUpdateStatusDialog={setOpenUpdateStatusDialog}
      />
    ) : (
      <ManualScholarships />
    );

  return (
    <>
      {mobile ? (
        <>
          <TopNavigationToggle selected={selectedSubMenu} handleSelected={handleSelected} />
          {renderScholarships()}
          <Box sx={{ height: 90 }} />
        </>
      ) : (
        <>
          <Box sx={{ display: "flex" }}>
            {/* Main Content */}
            <Box
              sx={{
                flex: 1,
              }}
            >
              {renderScholarships()}
            </Box>
          </Box>
        </>
      )}

      {/* Modals */}
      {openUpdateStatusDialog && selectedScholarshipApplication && (
        <ScholarshipApplicationDialog
          scholarship={scholarships.find((item) => item.id === selectedScholarshipApplication.scholarshipId)}
          scholarshipApplication={selectedScholarshipApplication}
          setScholarshipApplications={setApplications}
          setSelectedScholarshipApplication={setSelectedScholarshipApplication}
          setOpen={setOpenUpdateStatusDialog}
        />
      )}

      {openDetailDialog && (
        <ScholarshipDetailsDialog
          scholarship={selectedScholarship}
          scholarshipApplication={selectedScholarshipApplication}
          setScholarshipApplications={setApplications}
          setOpen={setOpenDetailDialog}
        />
      )}
    </>
  );
};

export default ExploreScholarshipsContainer;
