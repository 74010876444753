import { Dispatch } from "react";
import { Box, Typography, styled } from "@mui/material";
import { Color, QuizResultsOption } from "../../types/enums";
import { personalityTypeMenuItems } from "../../utils/quizUtils";

const ScrollContainer = styled(Box)({
  display: "flex",
  overflowX: "auto",
  alignItems: "center",
  WebkitOverflowScrolling: "touch",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  scrollbarWidth: "none",
});

type Props = {
  selectedOption: QuizResultsOption;
  setSelectedOption: Dispatch<React.SetStateAction<QuizResultsOption>>;
};

const ResultsNavigation = ({ selectedOption, setSelectedOption }: Props) => {
  return (
    <Box sx={{ width: "100%", overflowX: "hidden", backgroundColor: Color.WHITE }}>
      <ScrollContainer>
        {personalityTypeMenuItems.map((item, index) => (
          <Box
            key={index}
            sx={{
              width: "150px",
              height: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 1,
              flexShrink: 0,
              borderBottom: selectedOption === item ? `2px solid ${Color.MINT}` : `2px solid ${Color.WHITE}`,
            }}
          >
            <Typography
              sx={{
                wordWrap: "break-word",
                whiteSpace: "normal",
                fontSize: 12,
                fontWeight: selectedOption === item ? "bold" : "normal",
              }}
              textAlign="center"
              key={index}
              onClick={() => setSelectedOption(item)}
            >
              {item}
            </Typography>
          </Box>
        ))}
      </ScrollContainer>
    </Box>
  );
};

export default ResultsNavigation;
