import { useMemo } from "react";
import { JobDetailsRecord } from "../../types/types";
import useWindowDimensions from "../responsiveLayout/useWindowDimensions";
import fields from "../../assets/data/fields.json";

type Props = {
  selectedFieldId: number | null;
  jobs: JobDetailsRecord[];
};

const useJobs = ({ selectedFieldId, jobs }: Props) => {
  const { width } = useWindowDimensions();
  const mobile = width < 900;

  const field = useMemo(() => fields.data.find((field) => field.id === selectedFieldId), [selectedFieldId]);

  const filteredJobs = useMemo(
    () => jobs.filter((job) => job.industry_sub_id === selectedFieldId),
    [selectedFieldId, jobs],
  );

  filteredJobs.sort((a, b) => {
    if (a.projectedOpenings === null) return 1; // Treat null values as greater to move them to the end
    if (b.projectedOpenings === null) return -1;
    return b.projectedOpenings - a.projectedOpenings;
  });

  return { mobile, field, filteredJobs };
};

export default useJobs;
