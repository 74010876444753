import { Box, Chip, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { Collection, Color } from "../../../shared/types/enums";
import { selectedStudentAtom } from "../../recoil/studentsAtoms";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import CustomAvatar from "../../../shared/components/avatar/CustomAvatar";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import { formatPhoneNumber } from "../../../shared/utils/formatUtils";

const StudentCard = () => {
  const selectedStudent = useRecoilValue(selectedStudentAtom);
  const { width } = useWindowDimensions();
  const isMobile = width < 900;

  return (
    <>
      {selectedStudent && (
        <>
          <Box
            sx={{
              backgroundColor: Color.CHALKBOARD,
              p: 3,
              borderRadius: 3,
              display: isMobile ? "block" : "flex",
              alignItems: "start",
              gap: 3,
              position: "relative",
              textAlign: isMobile ? "center" : "left",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CustomAvatar
                size={100}
                fileName={selectedStudent?.avatar ?? ""}
                collection={Collection.STUDENTS}
                documentId={selectedStudent.id}
                url={selectedStudent.avatarURL}
                edit={true}
              />
            </Box>
            <Box>
              <Typography sx={{ color: Color.CHALK }} variant="h4">
                {`${selectedStudent.firstName} ${selectedStudent.lastName}`}
              </Typography>
              <Chip label={selectedStudent.gradeLevel} color="warning" size="small" sx={{ mt: 1 }} />
              <Box
                sx={{
                  display: "flex",
                  verticalAlign: "center",
                  alignItems: "center",
                  justifyContent: isMobile ? "center" : "left",
                  gap: "4px",
                  color: Color.CHALK,
                  mt: 2,
                }}
              >
                <FeatherIcon width="20px" icon="location" />
                <Typography sx={{ color: Color.CHALK }} variant="body1">
                  {selectedStudent.address.address}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  verticalAlign: "center",
                  alignItems: "center",
                  justifyContent: isMobile ? "center" : "left",
                  gap: "4px",
                  color: Color.CHALK,
                  mt: 1,
                }}
              >
                <FeatherIcon width="20px" icon="phone" />
                <Typography sx={{ color: Color.CHALK }} variant="body1">
                  {formatPhoneNumber({ phoneNumber: selectedStudent.phone })}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  verticalAlign: "center",
                  alignItems: "center",
                  justifyContent: isMobile ? "center" : "left",
                  gap: "4px",
                  color: Color.CHALK,
                  mt: 1,
                }}
              >
                <FeatherIcon width="20px" height="24px" icon="mail" />
                <Typography sx={{ color: Color.CHALK }} variant="body1">
                  {selectedStudent.email}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ position: "absolute", right: 14, top: 16 }}></Box>
          </Box>
        </>
      )}
    </>
  );
};

export default StudentCard;
