import { DistrictRecord, SchoolRecord } from "../../shared/types/types";

export const getStudentDistrict = ({
  districts,
  districtId,
}: {
  districts: DistrictRecord[];
  districtId: string | null;
}) => {
  return districts.find((district) => district.id === districtId);
};

export const getStudentSchool = ({ schools, schoolId }: { schools: SchoolRecord[]; schoolId: string | null }) => {
  return schools.find((school) => school.id === schoolId);
};
