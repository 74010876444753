import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { Dispatch } from "react";
import StarsIcon from "@mui/icons-material/Stars";
import roiGraphic from "../../../shared/assets/static/roi.svg";

type Props = {
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
};

const WhatIsROIDialog = ({ open, setOpen }: Props) => {
  const typographySX = { fontSize: 16, mb: 0, textAlign: "justify" };
  return (
    <Dialog onClose={() => setOpen(false)} open={open} fullWidth maxWidth="sm">
      <DialogTitle
        variant="h4"
        sx={{
          fontSize: 44,
          textAlign: "center",
          alignItems: "start",
          justifyContent: "space-between",
          display: "flex",
        }}
      >
        <span>What is Return on Investment (ROI)?</span>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={roiGraphic} alt="ROI" />
        </Box>
        <Typography sx={typographySX}>
          Return on Investment (ROI) shows whether graduates earn back more money than they spent on their education
          over 10 years.
        </Typography>

        <Typography sx={{ fontSize: 16 }}>We looked at:</Typography>

        <List>
          <ListItem sx={{ mb: 2 }}>
            <StarsIcon sx={{ color: "#1DBC9D", mr: 1 }} />
            <Typography sx={typographySX}>
              How much more money graduates typically earn compared to high school graduates
            </Typography>
          </ListItem>
          <ListItem sx={{ mb: 2 }}>
            <StarsIcon sx={{ color: "#1DBC9D", mr: 1 }} />
            <Typography sx={typographySX}>How much the program costs (tuition & fees)</Typography>
          </ListItem>
          <ListItem sx={{ mb: 2 }}>
            <StarsIcon sx={{ color: "#1DBC9D", mr: 1 }} />
            <Typography sx={typographySX}>How long it takes to complete</Typography>
          </ListItem>
          <ListItem sx={{ mb: 2 }}>
            <StarsIcon sx={{ color: "#1DBC9D", mr: 1 }} />
            <Typography sx={typographySX}>
              Foregone wages: What could have been earned instead of time in a program
            </Typography>
          </ListItem>
        </List>

        <Typography sx={typographySX}>
          A positive ROI means graduates typically earn back more than they invested. A negative ROI means even
          graduates may not earn back what they spent.
        </Typography>
        <br />
        <Typography sx={typographySX}>
          Remember: This data only looks at people who finished their program - not those who started but didn’t
          complete it.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default WhatIsROIDialog;
