import { Box } from "@mui/material";
import { ChatGPTEntry } from "../../../shared/types/types";
import Markdown from "react-markdown";

type MessageProps = {
  index: number;
  entry: ChatGPTEntry;
};

const Message = ({ index, entry }: MessageProps) => {
  return (
    <Box
      key={index}
      sx={{
        p: 1,
        display: "flex",
        justifyContent: entry.role === "assistant" ? "start" : "end",
      }}
    >
      <Box sx={formatChatBackground(entry.role)}>
        <Markdown>{entry.content}</Markdown>
      </Box>
    </Box>
  );
};

export default Message;

const formatChatBackground = (role: string) => ({
  px: "10px",
  py: "5px",
  mx: "10px",
  my: 0,
  borderRadius: "10px",
  fontWeight: role === "assistant" ? 400 : 500,
  maxWidth: role === "assistant" ? "100%" : "75%",
  width: "fit-content",
  fontStyle: "normal",
  lineHeight: "20px",
  color: "#2E3230",
  fontFamily:
    '"Inter", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',

  fontSize: "14px",
  backgroundColor: role === "assistant" ? "none" : "#F3F3F3",
});
