import { useMemo } from "react";
import { JobDetailsRecord } from "../../../types/types";
import { Grid } from "@mui/material";
import JobCard from "./jobs/JobCard";

type SearchResultTypes = {
  searchPhrase: string;
  jobs: JobDetailsRecord[];
};

export const SearchResults = ({ searchPhrase, jobs }: SearchResultTypes) => {
  const filteredJobs = useMemo(
    () => jobs.filter((job) => job.title.toLowerCase().includes(searchPhrase.toLowerCase())),
    [jobs, searchPhrase],
  );
  return (
    <>
      <Grid container alignContent="start" spacing="20px">
        {filteredJobs.map((job, index: number) => (
          <Grid key={index} item xs={12} sm={6}>
            <JobCard job={job} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
