import { Dispatch, SetStateAction, useCallback } from "react";
import { useRecoilState } from "recoil";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import { Collection } from "../../../shared/types/enums";
import useLogger from "../../../shared/hooks/logging/useLogger";
import { LogEventType } from "../../../shared/types/logEnums";
import * as Yup from "yup";
import { selectedStudentAtom } from "../../recoil/studentsAtoms";
import { FormikHelpers } from "formik";

type Props = {
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const validationSchema = Yup.object({
  gpaValue: Yup.number().required("GPA is required").min(0, "GPA cannot be less than 0"),
  gpaMax: Yup.number()
    .required("GPA Max is required")
    .min(0, "GPA Max cannot be less than 0")
    .max(10, "GPA Max cannot be higher than 10"),
  frlStatus: Yup.string().required("FRL Status is required"),
});

type FormType = {
  gpaValue: number | null;
  gpaMax: number | null;
  act: string;
  sat: string;
  frlStatus: string;
};

const useEditAcademicsAndFinanceDialog = ({ setOpen }: Props) => {
  const { submitLog } = useLogger();
  const { updateFSDoc } = useUpdateFSDoc();
  const [selectedStudent, setSelectedStudent] = useRecoilState(selectedStudentAtom);

  const initialValues: FormType = {
    gpaValue: selectedStudent?.gpaValue || null,
    gpaMax: selectedStudent?.gpaMax || null,
    act: selectedStudent?.act || "",
    sat: selectedStudent?.sat || "",
    frlStatus: selectedStudent?.frlStatus || "",
  };

  const handleSubmit = useCallback(
    async (values: FormType, { setSubmitting }: FormikHelpers<FormType>) => {
      if (!selectedStudent) return;
      try {
        await updateFSDoc({
          col: Collection.STUDENTS,
          data: values,
          id: selectedStudent.id,
        });
        setSelectedStudent((pV) => (pV ? { ...pV, ...values } : null));
        submitLog({
          eventType: LogEventType.STAFF_UPDATED_USER_ACADEMICS_AND_FINANCE,
          changeLog: `Staff updated the academics and finance for user ${selectedStudent.id} successfully`,
          file: "useEditAcademicsAndFinanceDialog.ts",
        });
        setOpen(false);
      } catch (error) {
        submitLog({
          error: error instanceof Error ? error : new Error("An error occurred"),
          snackbarMessage: `There was an error updating the academics and finance for user ${selectedStudent.id}, please refresh and try again.`,
          eventType: LogEventType.STAFF_UPDATE_USER_ACADEMICS_AND_FINANCE_ERROR,
          file: "useEditAcademicsAndFinanceDialog.ts",
        });
      }
      setSubmitting(false);
      setOpen(false);
    },
    [selectedStudent, setOpen, updateFSDoc, setSelectedStudent, submitLog],
  );

  const handleClose = () => {
    setOpen(false);
  };

  return { initialValues, validationSchema, handleSubmit, handleClose };
};

export default useEditAcademicsAndFinanceDialog;
