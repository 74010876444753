import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Theme, Typography } from "@mui/material";
import useResetQuizResultsDialog from "../../hooks/quizResults/useResetQuizResultsDialog";
import { UserType } from "../../types/enums";
import CloseIcon from "../closeIcon/CloseIcon";

type Props = {
  open: boolean;
  userType: UserType;
  setOpen: (open: boolean) => void;
};

const ResetQuizResultsDialog = ({ open, userType, setOpen }: Props) => {
  const { loading, resetQuizResults } = useResetQuizResultsDialog({ userType });

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>Quiz Results</span>
        <CloseIcon onClose={() => setOpen(false)} size={32} />
      </DialogTitle>
      <DialogContent sx={{ px: 0, textAlign: "center" }}>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography sx={{ p: 2 }}>
              Are you sure you want to reset your quiz results? This will reset all your answers and you will have to
              retake the quiz.
            </Typography>
            <Button
              color="close"
              variant="outlined"
              onClick={() => setOpen(false)}
              sx={(theme: any) => ({
                mr: 2,
                ...theme.components?.MuiButton?.defaultProps?.sx,
              })}
            >
              Cancel
            </Button>
            <Button color="mint" variant="contained" onClick={resetQuizResults}>
              Yes, Reset My Quiz Results
            </Button>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ResetQuizResultsDialog;
