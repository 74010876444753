import { loggedInStudentAtom } from "../../shared/recoil/userAtoms";
import { useRecoilValue } from "recoil";
import FeedContainer from "../components/feed/FeedContainer";
import { Box } from "@mui/material";

const StudentFeedPage = () => {
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  return (
    <>
      {loggedInStudent && (
        <Box sx={{ height: "100VH" }}>
          <FeedContainer loggedInStudent={loggedInStudent} />
        </Box>
      )}
    </>
  );
};

export default StudentFeedPage;
