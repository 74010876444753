import { Dispatch, SetStateAction, useCallback, useEffect, useState, useMemo } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Typography,
  Link as MUILink,
  Rating,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  CircularProgress,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
import { Color, UserType } from "../../types/enums";
import ProgramStats from "./ProgramStats";
import Reviews from "./Reviews";
import { JobDetailsRecord, ProviderProgramRecordTwo, ReviewRecord } from "../../types/types";
import { formatCredentialCard, formatCurrency, formatProgramName } from "../../utils/formatUtils";
import WhatIsROIDialog from "../whatIsROI/WhatIsROIDialog";
import ProviderDetailsDialog from "../providerDetails/ProviderDetailsDialog";
import useProgramDetailsDialog from "../../hooks/programDetails/useProgramDetailsDialog";
import useProgramDetailsJobs from "../../hooks/programDetails/useProgramDetailsJobs";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { selectedJobIdAtom } from "../../recoil/exploreCareersAtoms";
import JobDetailsDialog from "../exploreCareers/common/jobs/JobDetailsDialog";
import PrintProgramContainer from "./printView/PrintProgramContainer";
import { printProgramDialogAtom } from "../../recoil/providerAndProgramAtom";
import { loggedInStaffAtom } from "../../recoil/userAtoms";
import WhatIsThat from "../whatIsThat/WhatIsThat";
import { selectedProgramForRecommendationsAtom } from "../../../staff/recoil/programAtoms";
import { userTypeAtom } from "../../recoil/userAtoms";
import CloseIcon from "../closeIcon/CloseIcon";
import Reactions from "../../../students/components/programCard/Reactions";
import useWindowDimensions from "../../hooks/responsiveLayout/useWindowDimensions";

type Props = {
  isEditable?: boolean;
  selectedProgram: ProviderProgramRecordTwo;
  setSelectedProgram: Dispatch<SetStateAction<ProviderProgramRecordTwo | null>>;
};

const ProgramDetailsDialog = ({ isEditable = true, selectedProgram, setSelectedProgram }: Props) => {
  const [providerDetailsOpen, setProviderDetailsOpen] = useState(false);
  const [reviewState, setReviewState] = useState<ReviewRecord | null>(null);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const { width } = useWindowDimensions();
  const isMobile = width < 900;

  const { openWhatIsROI, setWhatIsROIOpen, whatIsROIOpen, applyLink, reviews, programImage, loading } =
    useProgramDetailsDialog({
      programId: selectedProgram.id,
    });

  useEffect(() => {
    setReviewState(reviews);
  }, [reviews, setReviewState]);

  const userType = useRecoilValue(userTypeAtom);

  const { relatedJobs, relatedJobTableStyles } = useProgramDetailsJobs(selectedProgram.cipCode);
  const setSelectedProgramForRecommendations = useSetRecoilState(selectedProgramForRecommendationsAtom);
  const setSelectedJobId = useSetRecoilState(selectedJobIdAtom);
  const setPrintModalVisable = useSetRecoilState(printProgramDialogAtom);

  const handleJobClick = (job: JobDetailsRecord) => {
    setSelectedJobId(job.id);
  };

  const openPrintProgramContainer = useCallback(() => {
    setPrintModalVisable(true);
  }, [setPrintModalVisable]);

  const InstitutionTootip = useMemo(
    () => ({
      title: "Return on Investment (ROI)",
      description: `The ROI (Return on Investment) shown for this program is actually the ROI for all of ${selectedProgram.providerName}, 
        not this specific program. This happens when there isn’t enough data available for us to calculate the 
        ROI for the program itself.`,
    }),
    [selectedProgram.providerName],
  );

  const handleRecommendClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setSelectedProgramForRecommendations(selectedProgram);
    },
    [selectedProgram, setSelectedProgramForRecommendations],
  );

  return (
    <>
      {selectedProgram && (
        <>
          <>
            <Dialog open={true} fullWidth maxWidth="md" fullScreen={isMobile} onClose={() => setSelectedProgram(null)}>
              {loading ? ( // Show loading spinner if data is still loading
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 400,
                  }}
                >
                  <CircularProgress size={80} />
                </Box>
              ) : (
                <>
                  {isMobile && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                        pr: 2,
                        pt: 2,
                      }}
                    >
                      <Reactions programId={selectedProgram.id} />
                      <CloseIcon onClose={() => setSelectedProgram(null)} size={30} />
                    </Box>
                  )}
                  <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box
                      sx={{
                        textAlign: "center",
                        mt: 2,
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        mr: -60,
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <Typography sx={{ color: "#1DBC9D" }} variant="h5">
                          {selectedProgram.providerName}
                        </Typography>

                        <Box sx={{ textAlign: "center" }}>
                          <Button
                            onClick={() => setProviderDetailsOpen(true)}
                            sx={{ color: "#1DBC9D", textTransform: "none" }}
                          >
                            Learn more about&nbsp;
                            <span style={{ textDecoration: "underline" }}>{selectedProgram.providerName}</span>
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                    {!isMobile && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                        }}
                      >
                        {userType === UserType.STUDENT && <Reactions programId={selectedProgram.id} />}
                        <CloseIcon onClose={() => setSelectedProgram(null)} size={30} />
                      </Box>
                    )}
                  </DialogTitle>
                  <DialogContent>
                    <Container maxWidth="md">
                      <Typography sx={{ mt: 2 }} variant="h4">
                        {formatProgramName({
                          name: selectedProgram.programName,
                        })}
                      </Typography>
                      <Chip
                        label={selectedProgram.credentialNameCalculated}
                        size="medium"
                        color={formatCredentialCard(selectedProgram?.credLevel)}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          height: !programImage ? 40 : undefined,
                        }}
                      >
                        {programImage && (
                          <img alt={selectedProgram?.programName ?? ""} src={programImage} width="50%" />
                        )}
                      </Box>
                      <Typography sx={{ color: "#1DBC9D" }} variant="h5">
                        Overview
                      </Typography>
                      <Typography sx={{ mt: 2 }} variant="body1">
                        {selectedProgram.programShortDescription}
                      </Typography>
                      <Typography sx={{ mt: 2 }} variant="body1">
                        {selectedProgram.programShortDescription}
                      </Typography>
                      <ProgramStats currentProgram={selectedProgram} />

                      <Divider sx={{ mt: 2 }} />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mt: 2,
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ color: "#1DBC9D", mt: 2, fontSize: 30 }} variant="h5">
                          Return On Investment (ROI)
                        </Typography>
                        <MUILink
                          sx={{
                            color: Color.MINT,
                            textDecoration: "none",
                            fontStyle: "italic",
                            fontSize: 12,
                          }}
                          component="button"
                          onClick={openWhatIsROI}
                        >
                          Click To Learn More About ROI
                        </MUILink>
                        <Typography
                          variant="h3"
                          sx={{
                            mt: 3,
                            color: selectedProgram.willowROICalculated ? "inherit" : Color.STANDARD_GRAY,
                          }}
                        >
                          {formatCurrency({ amount: selectedProgram.willowROICalculated })}
                        </Typography>

                        {!selectedProgram.programEarningsCalculated ? (
                          <Box
                            sx={{
                              position: "relative",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              pt: 1,
                            }}
                          >
                            <Typography sx={{ color: Color.STANDARD_GRAY }}>(Institution)</Typography>
                            <WhatIsThat
                              information={InstitutionTootip}
                              style={{
                                position: "absolute",
                                bottom: 4,
                                right: -24,
                              }}
                            />
                          </Box>
                        ) : null}
                        <Typography textAlign="center" sx={{ mt: 2 }}>
                          This is how much more money you will make over 10 years by completing this program than you
                          would have made if you didn't complete it.
                        </Typography>
                      </Box>
                    </Container>
                    {reviews && (
                      <Box
                        sx={{
                          mt: 2,
                          backgroundColor: "rgb(0, 54, 46)",
                          padding: 4,
                        }}
                      >
                        <Container maxWidth="sm" sx={{ textAlign: "center" }}>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: 30,
                              color: Color.WHITE,
                            }}
                            variant="h6"
                          >
                            Hear From Students
                          </Typography>
                          {reviews.rating && reviews.rating > 0 && (
                            <>
                              <Rating
                                sx={{ borderColor: Color.CHALK }}
                                name="half-rating"
                                defaultValue={reviews.rating}
                                value={reviews.rating}
                                precision={0.5}
                                readOnly
                              />
                              <Typography sx={{ fontSize: 16, color: Color.CHALK }}>
                                {reviews.rating} out of 5 from {reviews.source.length > 1 ? reviews.source : "Google"}
                              </Typography>
                            </>
                          )}
                          <Reviews reviews={reviews} />
                        </Container>
                      </Box>
                    )}
                    <Container maxWidth="sm" sx={{ textAlign: "center", pb: 4 }}>
                      <Typography sx={{ color: "#1DBC9D", fontSize: 30, mt: 4 }} variant="h5">
                        About The Provider
                      </Typography>
                      <Typography sx={{ mt: 2 }}>{selectedProgram.providerDescription}</Typography>
                    </Container>

                    {relatedJobs.length !== 0 && (
                      <Container maxWidth="md" sx={{ textAlign: "center", pb: 4 }}>
                        <Box sx={{ mt: 5 }}>
                          <Typography sx={{ mt: 2 }} variant="h4">
                            Aligned Future Careers
                          </Typography>
                        </Box>
                        <TableContainer component={Paper} sx={relatedJobTableStyles.container}>
                          <Table>
                            <TableBody>
                              {relatedJobs.map((job) => (
                                <TableRow key={job.id}>
                                  <TableCell sx={relatedJobTableStyles.tableCell}>
                                    {job.title}
                                    <IconButton onClick={() => handleJobClick(job)}>
                                      <ArrowForwardIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Container>
                    )}
                  </DialogContent>
                  <DialogActions>
                    {userType !== "student" && (
                      <Button onClick={handleRecommendClick} variant="outlined">
                        Recommend To Students
                      </Button>
                    )}
                    {applyLink && isEditable && (
                      <Button component={Link} to={applyLink} variant="contained" target="_blank">
                        Apply to this program
                      </Button>
                    )}
                    {loggedInStaff && (
                      <Button onClick={openPrintProgramContainer} variant="contained">
                        Print
                      </Button>
                    )}
                  </DialogActions>
                </>
              )}
            </Dialog>
            <JobDetailsDialog />
            <WhatIsROIDialog open={whatIsROIOpen} setOpen={setWhatIsROIOpen} />
            <ProviderDetailsDialog
              providerId={selectedProgram.providerId}
              open={providerDetailsOpen}
              setOpen={setProviderDetailsOpen}
            />
            <PrintProgramContainer
              reviews={reviewState ?? undefined}
              selectedProgram={selectedProgram}
              relatedJobs={relatedJobs}
            />
          </>
        </>
      )}
    </>
  );
};

export default ProgramDetailsDialog;
