import { Autocomplete, Checkbox, FormControl, FormHelperText, InputLabel, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useField, useFormikContext } from "formik";
import { useEffect } from "react";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = {
  name: string;
  listOfCities: string[];
};

const CitySelect = ({ name, listOfCities }: Props) => {
  const [field, _unused, helpers] = useField(name);
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (!listOfCities || listOfCities.length === 0) return;
    const newValue = field.value.filter((city: string) => listOfCities.includes(city));

    if (newValue.length !== field.value.length) {
      helpers.setValue(newValue);
      setFieldValue(name, newValue);
    }
  }, [field.value, helpers, listOfCities, name, setFieldValue]);

  const handleCitiesChange = (_event: any, value: string[]) => {
    if (value.length <= 3) {
      helpers.setValue(value);
      setFieldValue(name, value);
    }
  };

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel
        shrink
        htmlFor="cities-filter"
        variant="standard"
        sx={{ display: "flex", fontSize: 16, fontWeight: 600, top: "-20px" }}
      >
        What cities do you want to see programs from (select up to 3)?
      </InputLabel>
      <Autocomplete
        multiple
        id="cities-filter"
        options={listOfCities}
        value={field.value}
        onChange={handleCitiesChange}
        getOptionLabel={(option) => option}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option}>
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {option}
          </li>
        )}
        renderInput={(params) => <TextField {...params} name={name} />}
        sx={{
          "& .MuiChip-root": {
            "& .MuiChip-deleteIcon": {
              margin: "0 0 0 5px", // Adjust the margin as needed
            },
          },
        }}
      />
      <FormHelperText>Leave Blank for All Cities In Selected States</FormHelperText>
    </FormControl>
  );
};

export default CitySelect;
