import { useState, useCallback, useEffect } from "react";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import useGetFSDocs from "../../hooks/db/useGetFSDocs";
import { Collection } from "../../types/enums";
import { CareerVideoRecord } from "../../types/types";
import { db } from "../../../firebase";
import {
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Container,
  Card,
  CardMedia,
  Grid,
  CardContent,
  CardActionArea,
} from "@mui/material";

const VideoExplorer = () => {
  const [onetCode, setOnetCode] = useState("");
  const [videos, setVideos] = useState<CareerVideoRecord[]>([]);
  const [videoUrls, setVideoUrls] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState(false);
  const [changingVideoId, setChangingVideoId] = useState<string | null>(null);

  const { getFSDocs } = useGetFSDocs();
  const storage = getStorage();

  const handleSearch = async () => {
    setLoading(true);
    try {
      const result = await getFSDocs<CareerVideoRecord>({
        col: Collection.CAREER_VIDEOS,
        config: { where: ["onet", "==", onetCode] },
      });

      result.sort((a, b) => {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
      });

      setVideos(result);
    } catch (error) {
      console.error("Error fetching videos:", error);
      alert("Error fetching videos. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const fetchVideoUrls = useCallback(
    async (videos: CareerVideoRecord[]) => {
      const urls: { [key: string]: string } = {};
      for (const video of videos) {
        try {
          const storageRef = ref(storage, `/career-videos/${video.fileName}`);
          const url = await getDownloadURL(storageRef);
          urls[video.id] = url;
        } catch (error) {
          console.error("Error fetching video URL:", error);
        }
      }
      setVideoUrls(urls);
    },
    [storage],
  );

  useEffect(() => {
    if (videos.length > 0) {
      fetchVideoUrls(videos);
    }
  }, [videos, fetchVideoUrls]);

  const handleDelete = async (videoId: string) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this video?");
    if (!confirmDelete) return;

    setLoading(true);
    try {
      await deleteDoc(doc(db, Collection.CAREER_VIDEOS, videoId));

      setVideos((prev) => prev.filter((video) => video.id !== videoId));

      alert("Video deleted successfully!");
    } catch (error) {
      console.error("Error deleting video:", error);
      alert("Error deleting video. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleChangeVideo = async (video: CareerVideoRecord, newFile: File) => {
    setLoading(true);
    setChangingVideoId(video.id);

    try {
      const filename = `${crypto.randomUUID()}.mp4`;

      const storageRef = ref(storage, `/career-videos/${filename}`);
      await uploadBytes(storageRef, newFile);

      await updateDoc(doc(db, Collection.CAREER_VIDEOS, video.id), {
        fileName: filename,
      });

      alert(`Video updated successfully!`);
      fetchVideoUrls(videos);
    } catch (error) {
      console.error("Error changing video:", error);
      alert("Error changing video. Please try again.");
    } finally {
      setLoading(false);
      setChangingVideoId(null);
    }
  };

  const handleFileChange = (video: CareerVideoRecord, event: any) => {
    const file = event.target.files[0];
    if (file) {
      handleChangeVideo(video, file);
    }
  };

  return (
    <Container maxWidth={false}>
      <Box mt={4} mb={4} textAlign="center">
        <Typography variant="h4" gutterBottom>
          Video Explorer
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
          <TextField
            label="Onet Code"
            variant="outlined"
            value={onetCode}
            onChange={(e) => setOnetCode(e.target.value)}
            placeholder="Enter Onet Code"
            sx={{ width: "300px" }}
          />
          <Button variant="contained" color="primary" onClick={handleSearch} disabled={loading || !onetCode}>
            {loading ? "Searching..." : "Search"}
          </Button>
        </Box>
      </Box>

      {loading && <CircularProgress />}

      {!loading && videos.length > 0 && (
        <Box>
          <Grid container spacing={2} sx={{ height: "800px", overflowY: "auto" }}>
            {videos.map((video) => (
              <Grid item key={video.id} xs={2}>
                <Card sx={{ width: "100%" }}>
                  <CardContent>
                    {videoUrls[video.id] ? (
                      <CardMedia component="video" controls src={videoUrls[video.id]} title={video.fileName} />
                    ) : (
                      <CircularProgress />
                    )}
                    <Box mt={2}>
                      <Typography variant="body1" gutterBottom>
                        OnetCode: {video.onet}
                      </Typography>
                      <Typography variant="body2">Created At: {new Date(video.createdAt).toLocaleString()}</Typography>
                    </Box>
                  </CardContent>
                  <CardActionArea sx={{ p: 2 }}>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        disabled={changingVideoId === video.id}
                      >
                        {changingVideoId === video.id ? "Changing..." : "Change Video"}
                        <input
                          type="file"
                          hidden
                          accept="video/mp4"
                          onChange={(event) => handleFileChange(video, event)}
                        />
                      </Button>
                      <Button variant="contained" color="secondary" onClick={() => handleDelete(video.id)}>
                        Delete
                      </Button>
                    </Box>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {!loading && videos.length === 0 && (
        <Typography variant="body1" color="textSecondary">
          No videos found for the given Onet Code.
        </Typography>
      )}
    </Container>
  );
};

export default VideoExplorer;
