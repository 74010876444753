import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import { Collection } from "../../../shared/types/enums";

const useTrackUserActivity = () => {
  const [loggedInStudent, setLoggedInStudent] = useRecoilState(loggedInStudentAtom);
  const { updateFSDoc } = useUpdateFSDoc();

  useEffect(() => {
    const isToday = (dateString: string) => {
      const today = new Date().toISOString().split("T")[0];
      return dateString.split("T")[0] === today;
    };

    const updateLastActivity = async () => {
      const timestamp = new Date().toISOString();
      if (!loggedInStudent) return;

      // Update Recoil state
      setLoggedInStudent((prev) =>
        prev
          ? {
              ...prev,
              lastActivity: timestamp,
            }
          : null,
      );

      // Update Firestore
      updateFSDoc({
        col: Collection.STUDENTS,
        id: loggedInStudent.id,
        data: { lastActivity: timestamp },
      });
    };

    const handleActivity = () => {
      if (!loggedInStudent) return;
      if (!isToday(loggedInStudent.lastActivity)) {
        updateLastActivity();
      }
    };

    window.addEventListener("click", handleActivity);
    window.addEventListener("keypress", handleActivity);

    return () => {
      window.removeEventListener("click", handleActivity);
      window.removeEventListener("keypress", handleActivity);
    };
  }, [loggedInStudent, setLoggedInStudent, updateFSDoc]);
};

export default useTrackUserActivity;
