import { Box, Container } from "@mui/material";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useTour } from "@reactour/tour";
import { TourName } from "../../../shared/types/tourEnums";
import { StudentRecord } from "../../../shared/types/types";
import StudentCard from "./StudentCard";
import MyWhy from "./MyWhy";
import PersonalityType from "./PersonalityType";
import Mentors from "./Mentors";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import RecommendationPreferences from "./RecommendationPreferences";
import ProfileBox from "../goals/ProfileBox";

type Props = {
  loggedInStudent: StudentRecord;
  navOpen: boolean;
  setNavOpen: Dispatch<SetStateAction<boolean>>;
  onAskAlma: (text: string) => void;
  setAlmaMobileDialogOpen: (open: boolean) => void;
};

const StudentProfileSetupComplete = ({ loggedInStudent, onAskAlma, setAlmaMobileDialogOpen }: Props) => {
  const { setIsOpen } = useTour(); // Use the hook for tour control
  const { width } = useWindowDimensions();
  const mobile = width < 900;

  useEffect(() => {
    if (!loggedInStudent) return;
    if (loggedInStudent.completedTours.includes(TourName.NAVIGATION)) return;
    setIsOpen(true);
  }, [loggedInStudent, setIsOpen]);

  return (
    <>
      <Box sx={{ display: "flex", backgroundColor: "#fafafa" }}>
        <Box component="main" sx={{ flexGrow: 1 }}>
          {mobile ? (
            <Box
              sx={{
                mt: 2,
                mb: 7,
                display: "flex",
                justifyContent: "center",
                padding: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  width: "100%",
                }}
              >
                <StudentCard />
                <PersonalityType loggedInStudent={loggedInStudent} />
                <MyWhy />
                <RecommendationPreferences />
                <Mentors />
                <ProfileBox onAskAlma={onAskAlma} setAlmaMobileDialogOpen={setAlmaMobileDialogOpen} />
              </Box>
            </Box>
          ) : (
            <Box sx={{ p: 2, mb: 12 }}>
              <Container maxWidth="sm">
                <StudentCard />
                <MyWhy />
                <ProfileBox onAskAlma={onAskAlma} setAlmaMobileDialogOpen={setAlmaMobileDialogOpen} />
                <PersonalityType loggedInStudent={loggedInStudent} />
                <RecommendationPreferences />
                <Mentors />
              </Container>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default StudentProfileSetupComplete;
