import { useState, useCallback } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import ManualScholarshipDialog from "./ManualScholarshipDialog";
import ManualScholarshipCard from "./ManualScholarshipCard";
import { ManualScholarshipRecord } from "../../../shared/types/types";
import useManualScholarships from "../../hooks/exploreScholarships/useManualScholarships";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";

const ManualScholarships = () => {
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const { manualScholarships, fetchManualScholarships } = useManualScholarships(loggedInStudent);
  const [manageOpen, setManageOpen] = useState<boolean>(false);
  const [selectedScholarship, setSelectedScholarship] = useState<ManualScholarshipRecord | null>(null);
  const { width } = useWindowDimensions();

  const handleOpen = useCallback(() => {
    setManageOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setManageOpen(false);
    setSelectedScholarship(null);
  }, []);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 1, mt: 1 }}>
        {width > 900 && <Typography variant="pageTitle">Manual Scholarships</Typography>}
        <Button variant="contained" color="primary" onClick={handleOpen} fullWidth={width < 900}>
          Create Manual Scholarship
        </Button>
      </Box>

      <Box sx={{ height: "calc(100vh - 60px)", overflowY: "scroll" }}>
        <Grid container spacing={2} sx={{ px: 2, mt: 2 }}>
          {manualScholarships.map((scholarship) => (
            <Grid item xs={12} lg={6} key={scholarship.id} sx={{ display: "flex", flexDirection: "column" }}>
              <ManualScholarshipCard
                scholarship={scholarship}
                setSelectedScholarship={setSelectedScholarship}
                setManageOpen={setManageOpen}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      {loggedInStudent && (
        <ManualScholarshipDialog
          open={manageOpen}
          onClose={handleClose}
          loggedInStudent={loggedInStudent}
          scholarshipEntry={selectedScholarship}
          setManualScholarships={fetchManualScholarships} // Refetch after saving
        />
      )}
    </>
  );
};

export default ManualScholarships;
