import { SearchResults, Fields, Jobs, Industries, Breadcrumb } from "./common";
import { Box, TextField, Typography } from "@mui/material";
import useExploreCareeresRouter from "../../hooks/exploreCareers/useExploreCareeresRouter";
import useWindowDimensions from "../../hooks/responsiveLayout/useWindowDimensions";
import { useRecoilValue } from "recoil";
import { selectedFieldIdAtom, selectedIndustryIdAtom } from "../../recoil/exploreCareersAtoms";
import { useCallback, useState } from "react";

const ExploreCareersRouter = (): JSX.Element => {
  const { jobs } = useExploreCareeresRouter();
  const { width } = useWindowDimensions();
  const mobile = width < 900;
  const selectedIndustryId = useRecoilValue(selectedIndustryIdAtom);
  const selectedFieldId = useRecoilValue(selectedFieldIdAtom);
  const [searchPhrase, setSearchPhrase] = useState("");

  const handleSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPhrase(event.target.value);
  }, []);

  return (
    <Box>
      <Box
        sx={{
          px: mobile ? 2.5 : 0,
          pt: mobile ? 1 : 0,
          pb: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {width > 900 && <Typography variant="pageTitle">Explore Careers</Typography>}
        <TextField
          fullWidth
          onChange={handleSearch}
          value={searchPhrase}
          placeholder="Search By Career"
          sx={{ maxWidth: 400 }}
        />
      </Box>
      <Box
        sx={{
          height: mobile ? "calc(100vh - 180px)" : "calc(100vh - 60px)",
          overflowY: "scroll",
        }}
      >
        {searchPhrase.length > 3 ? (
          <Box sx={{ px: mobile ? 2.5 : 3, py: 1 }}>
            <Typography variant="h5">Search Results</Typography>
            <SearchResults searchPhrase={searchPhrase} jobs={jobs} />
          </Box>
        ) : (
          <Box sx={{ px: mobile ? 2.5 : 3, py: 1 }}>
            {!selectedIndustryId ? (
              <Box>
                <Industries />
              </Box>
            ) : (
              <>
                {!selectedFieldId ? (
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2.5 }}>
                    <Breadcrumb jobs={jobs} />
                    <Fields />
                  </Box>
                ) : (
                  <>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2.5 }}>
                      <Breadcrumb jobs={jobs} />
                      <Jobs jobs={jobs} />
                    </Box>
                  </>
                )}
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ExploreCareersRouter;
