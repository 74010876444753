import { RefObject } from "react";
import { Box } from "@mui/material";
import ResultsNavigation from "../../../shared/components/quizResults/ResultsNavigation";
import QuizResultsContainer from "../../../shared/components/quizResults/QuizResultsContainer";
import useQuizResultsContainer from "../../../shared/hooks/quizResults/useQuizResultsContainer";

type Props = {
  dialogContentRef: RefObject<HTMLDivElement>;
};

const PersonalityTypeDetailsMobile = ({ dialogContentRef }: Props) => {
  const { personalityType, selectedOption, setSelectedOption } = useQuizResultsContainer({ dialogContentRef });
  if (!personalityType) return null;
  return (
    <>
      <Box sx={{ p: 0 }}>
        <ResultsNavigation selectedOption={selectedOption} setSelectedOption={setSelectedOption} />

        <Box sx={{ height: "calc(100VH - 211px)", overflow: "scroll" }}>
          <QuizResultsContainer
            personalityType={personalityType}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </Box>
      </Box>
    </>
  );
};

export default PersonalityTypeDetailsMobile;
