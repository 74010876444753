import { Box, Button, Container, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { Color, UserType } from "../../../types/enums";
import { SearchFilter } from "./Common/SearchFilter";
import SizeFilter from "./Common/SizeFilter";
import CostFilter from "./Common/CostFilter";
import FocusFilter from "./Common/FocusFilter";
import ProgramTypeFilter from "./Common/ProgramTypeFilter";
import CareerPathwaysFilter from "./Common/CareerPathwaysFilter";
import StateAndCityFilter from "./Common/StateAndCityFilter";
import useWindowDimensions from "../../../hooks/responsiveLayout/useWindowDimensions";
import { FilterProps, SearchedProvider } from "../../../types/types";
import { useRecoilValue } from "recoil";
import { userTypeAtom } from "../../../recoil/userAtoms";

type Props = {
  isDialogMode: boolean; // Should we show a dialog overlay?
  filters: FilterProps;
  setFilters: React.Dispatch<React.SetStateAction<FilterProps>>;
  searchedProviders: SearchedProvider[];
  setSearchedProviders: React.Dispatch<React.SetStateAction<SearchedProvider[]>>;
  handleResetButtonClick: () => void;
  searchButton: () => void;
  handleCloseButtonClick: () => void;
};

const FilterForm = ({
  isDialogMode,
  filters,
  setFilters,
  searchedProviders,
  setSearchedProviders,
  handleResetButtonClick,
  searchButton,
  handleCloseButtonClick,
}: Props) => {
  const { width } = useWindowDimensions();
  const mobile = width < 900;
  const currentUser = useRecoilValue(userTypeAtom);
  const studentUser = currentUser === UserType.STUDENT;

  return (
    <>
      <Container maxWidth="md">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            p: 1,
          }}
        >
          {/* 1) "Search" box */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              borderRadius: 2,
              background: Color.WHITE,
              px: 2,
              py: 1.5,
            }}
          >
            <Grid item xs={12} md={6}>
              {!mobile && (
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "16px",
                    lineHeight: "19px",
                    color: Color.DARK_GRAY,
                  }}
                >
                  Search within a specific college or organization
                </Typography>
              )}
              <SearchFilter searchedProviders={searchedProviders} setSearchedProviders={setSearchedProviders} />
            </Grid>
          </Box>

          {/* 2) The main filters, including CostFilter unconditionally */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              borderRadius: 2,
              background: Color.WHITE,
              px: 2,
              py: 1.5,
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <SizeFilter filters={filters} setFilters={setFilters} />
                {!mobile && <Divider sx={{ color: Color.BLACK, opacity: 12 }} />}
                <StateAndCityFilter filters={filters} setFilters={setFilters} />
                {!mobile && <Divider sx={{ color: Color.BLACK, opacity: 12 }} />}
                <CostFilter filters={filters} setFilters={setFilters} />
                {!mobile && <Divider sx={{ color: Color.BLACK, opacity: 12 }} />}
              </Grid>

              <Grid item xs={12} md={6}>
                <FocusFilter filters={filters} setFilters={setFilters} />
                {!mobile && <Divider sx={{ color: Color.BLACK, opacity: 12 }} />}
                <ProgramTypeFilter filters={filters} setFilters={setFilters} />
                {!mobile && <Divider sx={{ color: Color.BLACK, opacity: 12 }} />}
                <CareerPathwaysFilter filters={filters} setFilters={setFilters} userType={currentUser} />
              </Grid>

              {/* 3) Buttons */}
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: 2,
                    mt: 2,
                  }}
                >
                  {isDialogMode && (
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onClick={handleResetButtonClick}
                      sx={{ maxWidth: "100px" }}
                    >
                      Reset
                    </Button>
                  )}

                  <Button
                    type="submit"
                    variant="contained"
                    color={studentUser ? "primary" : "secondary"}
                    fullWidth
                    sx={{ flexGrow: 3 }}
                    onClick={searchButton}
                  >
                    {studentUser ? "Apply Filters" : "Search"}
                  </Button>

                  {isDialogMode && (
                    <Button
                      variant="outlined"
                      color="error"
                      fullWidth
                      sx={{ maxWidth: "100px" }}
                      onClick={handleCloseButtonClick}
                    >
                      Close
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default FilterForm;
