import React, { useEffect, useState } from "react";
import DisplayBox from "../../../shared/components/displayBox/DisplayBox";
import { Collection, Color } from "../../../shared/types/enums";
import { Box, Button, Divider, Typography } from "@mui/material";
import { GoalRecord, MilestoneRecord } from "../../../shared/types/types";
import useGetFSDocs from "../../../shared/hooks/db/useGetFSDocs";
import { useRecoilValue } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { parseGoalResponse, parseMilestoneResponse } from "../../../shared/utils/parserUtils";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import { useNavigate } from "react-router-dom";
import GoalCard from "./GoalCard";
import EmptyGoalCard from "./EmptyGoalCard";
import AlreadyThreeGoalsDialog from "./AlreadyThreeGoalsDialog";

type Props = {
  onAskAlma: (text: string) => void;
  setAlmaMobileDialogOpen: (open: boolean) => void;
};

const ProfileBox = ({ onAskAlma, setAlmaMobileDialogOpen }: Props) => {
  const [goals, setGoals] = useState<GoalRecord[]>([]);
  const [threeGoalsOpen, setThreeGoalsOpen] = useState(false);
  const [milestones, setMilestones] = useState<MilestoneRecord[]>([]);
  const [visibleGoalType, setVisibleGoalType] = useState<"active" | "completed" | "archived">("active");
  const { getFSDocs } = useGetFSDocs();
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const navigate = useNavigate();
  useEffect(() => {
    if (!loggedInStudent) return;
    const getGoals = async () => {
      const goalResponse = await getFSDocs<GoalRecord>({
        col: Collection.GOALS,
        config: { where: ["studentId", "==", loggedInStudent.id] },
      });
      const parsedGoals = parseGoalResponse(goalResponse);
      parsedGoals.sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });
      setGoals(parsedGoals);
    };
    getGoals();
  }, [getFSDocs, loggedInStudent]);

  useEffect(() => {
    if (!loggedInStudent) return;
    const getMilestones = async () => {
      const goalResponse = await getFSDocs<MilestoneRecord>({
        col: Collection.MILESTONES,
        config: { where: ["studentId", "==", loggedInStudent.id] },
      });
      const parsedMilestones = parseMilestoneResponse(goalResponse);
      // sort goals by iso timestamp
      parsedMilestones.sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });
      setMilestones(parsedMilestones);
    };
    getMilestones();
  }, [getFSDocs, loggedInStudent]);

  const handleNewGoalClick = () => {
    if (goals.filter((goal) => goal.state === "active").length >= 3) {
      setThreeGoalsOpen(true);
      return;
    }
    navigate("/student-manage-goals");
  };

  const hanldeThreeGoalsClose = () => {
    setThreeGoalsOpen(false);
  };

  return (
    <>
      <Box sx={{ mt: 2 }}>
        {loggedInStudent && (
          <DisplayBox
            title="SMART Goals"
            actionButton={
              <Button variant="contained" color="primary" onClick={handleNewGoalClick}>
                New Smart Goal
                <FeatherIcon icon="add" width={"14px"} />
              </Button>
            }
          >
            <Typography variant="body1" gutterBottom>
              These will help you achieve your post-secondary dreams by breaking them into manageable steps, providing
              motivation and a clear path to track your progress.
            </Typography>
            <Divider />
            <Box sx={{ py: 1, display: "flex", verticalAlign: "center", gap: 1 }}>
              <Button
                sx={{
                  boxShadow: "none",
                  textTransform: "none",
                  color: visibleGoalType === "active" ? "#333" : "#777",
                  backgroundColor: visibleGoalType === "active" ? "#B9F4E8" : "#fff",
                  "&:hover": {
                    backgroundColor: "#B9F4E8",
                  },
                }}
                onClick={() => setVisibleGoalType("active")}
              >
                Active
              </Button>
              <Button
                sx={{
                  boxShadow: "none",
                  textTransform: "none",
                  color: visibleGoalType === "completed" ? "#333" : "#777",
                  backgroundColor: visibleGoalType === "completed" ? "#B9F4E8" : "#fff",
                  "&:hover": {
                    backgroundColor: "#B9F4E8",
                  },
                }}
                onClick={() => setVisibleGoalType("completed")}
              >
                Completed
              </Button>
              <Button
                sx={{
                  boxShadow: "none",
                  textTransform: "none",
                  color: visibleGoalType === "archived" ? "#333" : "#777",
                  backgroundColor: visibleGoalType === "archived" ? "#B9F4E8" : "#fff",
                  "&:hover": {
                    backgroundColor: "#B9F4E8",
                  },
                }}
                onClick={() => setVisibleGoalType("archived")}
              >
                Archived
              </Button>
            </Box>
            {visibleGoalType === "active" && (
              <>
                {goals
                  .filter((goal) => goal.state === "active")
                  .map((goal) => (
                    <GoalCard
                      key={goal.id}
                      goal={goal}
                      studentId={loggedInStudent.id}
                      milestones={milestones}
                      setMilestones={setMilestones}
                      setGoals={setGoals}
                      visibleGoalType={visibleGoalType}
                      onAskAlma={onAskAlma}
                      goals={goals}
                      setThreeGoalsOpen={setThreeGoalsOpen}
                      setAlmaMobileDialogOpen={setAlmaMobileDialogOpen}
                    />
                  ))}
                {goals.filter((goal) => goal.state === "active").length === 0 && (
                  <>
                    <EmptyGoalCard />
                    <EmptyGoalCard />
                    <EmptyGoalCard />
                  </>
                )}
                {goals.filter((goal) => goal.state === "active").length === 1 && (
                  <>
                    <EmptyGoalCard />
                    <EmptyGoalCard />
                  </>
                )}
                {goals.filter((goal) => goal.state === "active").length === 2 && (
                  <>
                    <EmptyGoalCard />
                  </>
                )}
              </>
            )}
            {visibleGoalType === "completed" && (
              <>
                {goals
                  .filter((goal) => goal.state === "completed")
                  .map((goal) => (
                    <GoalCard
                      key={goal.id}
                      goal={goal}
                      studentId={loggedInStudent.id}
                      milestones={milestones}
                      setMilestones={setMilestones}
                      setGoals={setGoals}
                      visibleGoalType={visibleGoalType}
                      onAskAlma={onAskAlma}
                      goals={goals}
                      setThreeGoalsOpen={setThreeGoalsOpen}
                      setAlmaMobileDialogOpen={setAlmaMobileDialogOpen}
                    />
                  ))}
              </>
            )}
            {visibleGoalType === "archived" && (
              <>
                {goals
                  .filter((goal) => goal.state === "archived")
                  .map((goal) => (
                    <GoalCard
                      key={goal.id}
                      goal={goal}
                      studentId={loggedInStudent.id}
                      milestones={milestones}
                      setMilestones={setMilestones}
                      setGoals={setGoals}
                      visibleGoalType={visibleGoalType}
                      onAskAlma={onAskAlma}
                      goals={goals}
                      setThreeGoalsOpen={setThreeGoalsOpen}
                      setAlmaMobileDialogOpen={setAlmaMobileDialogOpen}
                    />
                  ))}
              </>
            )}
          </DisplayBox>
        )}
      </Box>
      <AlreadyThreeGoalsDialog open={threeGoalsOpen} onClose={hanldeThreeGoalsClose} />
    </>
  );
};

export default ProfileBox;
