import { getDownloadURL, ref, getStorage } from "firebase/storage";
import adaptableExperimenterImage from "../assets/profileImages/adaptable-experimenter.svg";
import focusedAchieverImage from "../assets/profileImages/focused-achiever.svg";
import dynamicDoerImage from "../assets/profileImages/dynamic-doer.svg";
import practicalSupporterImage from "../assets/profileImages/practical-supporter.svg";
import resourcefulPragmatistImage from "../assets/profileImages/resourceful-pragmatist.svg";
import intuitiveExplorerImage from "../assets/profileImages/intuitive-explorer.svg";
import analyticalMastermindImage from "../assets/profileImages/analytical-mastermind.svg";
import curiousResearcherImage from "../assets/profileImages/curious-researcher.svg";
import inquisitiveAnalystImage from "../assets/profileImages/inquisitive-analyst.svg";
import methodicalProblemSolverImage from "../assets/profileImages/methodical-problem-solver.svg";
import innovativeVisionaryImage from "../assets/profileImages/innovative-visionary.svg";
import imaginativeDesignerImage from "../assets/profileImages/imaginative-designer.svg";
import expressiveCommunicatorImage from "../assets/profileImages/expressive-communicator.svg";
import creativeIdealistImage from "../assets/profileImages/creative-idealist.svg";
import resilientCreatorImage from "../assets/profileImages/resilient-creator.svg";
import thoughtfulGuideImage from "../assets/profileImages/thoughtful-guide.svg";
import collaborativeDiplomatImage from "../assets/profileImages/collaborative-diplomat.svg";
import socialCreatorImage from "../assets/profileImages/social-creator.svg";
import compassionateAdvocateImage from "../assets/profileImages/compassionate-advocate.svg";
import steadyPeacemakerImage from "../assets/profileImages/steady-peacemaker.svg";
import enterprisingAdventurerImage from "../assets/profileImages/enterprising-adventurer.svg";
import disciplinedVisionaryImage from "../assets/profileImages/disciplined-visionary.svg";
import charismaticLeaderImage from "../assets/profileImages/charismatic-leader.svg";
import persuasiveStrategistImage from "../assets/profileImages/persuasive-strategist.svg";
import composedStrategistImage from "../assets/profileImages/composed-strategist.svg";
import methodicalOrganizerImage from "../assets/profileImages/methodical-organizer.svg";
import preciseAdministratorImage from "../assets/profileImages/precise-administrator.svg";
import efficientCoordinatorImage from "../assets/profileImages/efficient-coordinator.svg";
import reliableExecutorImage from "../assets/profileImages/reliable-executor.svg";
import steadyOrganizerImage from "../assets/profileImages/steady-organizer.svg";

/**
 * Get the download URL of an image from Firebase Storage
 * @param {string} filename - The name of the file in the storage
 * @returns {Promise<string>} - A promise that resolves to the download URL of the image
 */
export const getImageUrl = async (filename: string): Promise<string> => {
  if (!filename) return "";
  const storage = getStorage();
  try {
    // Create a reference to the resized version of the file in the storage
    const resizedFilename = filename.replace(/(\.[\w\d_-]+)$/i, "200x200$1");
    const resizedRef = ref(storage, resizedFilename);

    // Try to get the download URL for the resized version
    try {
      const resizedUrl = await getDownloadURL(resizedRef);
      return resizedUrl;
    } catch (resizedError: any) {
      if (resizedError.code !== "storage/object-not-found") {
        throw resizedError;
      }

      // If resized version is not found, get the original URL
      const originalRef = ref(storage, filename);
      const originalUrl = await getDownloadURL(originalRef);
      return originalUrl;
    }
  } catch (error) {
    console.error("Error getting download URL:", error);
    throw error;
  }
};

type ProfileImageType = {
  name: string;
  image: string;
};

const profileImages: ProfileImageType[] = [
  {
    name: "Adaptable Experimenter",
    image: adaptableExperimenterImage,
  },
  {
    name: "Focused Achiever",
    image: focusedAchieverImage,
  },
  {
    name: "Dynamic Doer",
    image: dynamicDoerImage,
  },
  {
    name: "Practical Supporter",
    image: practicalSupporterImage,
  },
  {
    name: "Resourceful Pragmatist",
    image: resourcefulPragmatistImage,
  },
  {
    name: "Intuitive Explorer",
    image: intuitiveExplorerImage,
  },
  {
    name: "Analytical Mastermind",
    image: analyticalMastermindImage,
  },
  {
    name: "Curious Researcher",
    image: curiousResearcherImage,
  },
  {
    name: "Inquisitive Analyst",
    image: inquisitiveAnalystImage,
  },
  {
    name: "Methodical Problem Solver",
    image: methodicalProblemSolverImage,
  },
  {
    name: "Innovative Visionary",
    image: innovativeVisionaryImage,
  },
  {
    name: "Imaginative Designer",
    image: imaginativeDesignerImage,
  },
  {
    name: "Expressive Communicator",
    image: expressiveCommunicatorImage,
  },
  {
    name: "Creative Idealist",
    image: creativeIdealistImage,
  },
  {
    name: "Resilient Creator",
    image: resilientCreatorImage,
  },
  {
    name: "Thoughtful Guide",
    image: thoughtfulGuideImage,
  },
  {
    name: "Collaborative Diplomat",
    image: collaborativeDiplomatImage,
  },
  {
    name: "Social Creator",
    image: socialCreatorImage,
  },
  {
    name: "Compassionate Advocate",
    image: compassionateAdvocateImage,
  },
  {
    name: "Steady Peacemaker",
    image: steadyPeacemakerImage,
  },
  {
    name: "Enterprising Adventurer",
    image: enterprisingAdventurerImage,
  },
  {
    name: "Disciplined Visionary",
    image: disciplinedVisionaryImage,
  },
  {
    name: "Charismatic Leader",
    image: charismaticLeaderImage,
  },
  {
    name: "Persuasive Strategist",
    image: persuasiveStrategistImage,
  },
  {
    name: "Composed Strategist",
    image: composedStrategistImage,
  },
  {
    name: "Methodical Organizer",
    image: methodicalOrganizerImage,
  },
  {
    name: "Precise Administrator",
    image: preciseAdministratorImage,
  },
  {
    name: "Efficient Coordinator",
    image: efficientCoordinatorImage,
  },
  {
    name: "Reliable Executor",
    image: reliableExecutorImage,
  },
  {
    name: "Steady Organizer",
    image: steadyOrganizerImage,
  },
];

export const getProfileImage = (name: string): string | undefined => {
  const profileImage = profileImages.find((profileImage) => profileImage.name === name);
  return profileImage ? profileImage.image : undefined;
};

const industryMap: { [key: string]: string } = {
  "1": "agriculture",
  "2": "architecture",
  "3": "arts",
  "4": "business",
  "5": "education",
  "6": "finance",
  "7": "gov",
  "8": "healthcare",
  "9": "travel",
  "10": "human-services",
  "11": "tech",
  "12": "law",
  "13": "manufacturing",
  "14": "marketing",
  "15": "science",
  "16": "transportation",
};

type GetCareerImageProps = {
  id: number;
};

export const getCareerImage = async ({ id }: GetCareerImageProps) => {
  const fileName = industryMap[String(id)];
  const imageModule = await import(`../assets/careerImages/${fileName}.svg`);
  return imageModule;
};

type GetProgramStatImageProps = {
  name: string;
};

export const getProgramStatImage = async ({ name }: GetProgramStatImageProps) => {
  const imageModule = await import(`../assets/stats/${name}.svg`);
  return imageModule;
};

type GetProgramImageProps = {
  id: string;
};

export const getProgramImage = async ({ id }: GetProgramImageProps) => {
  const imageModule = await import(`../assets/programImages/${id}.svg`);
  return imageModule;
};

type GetMentorImageProps = {
  name: string;
};

export const getMentorImage = async ({ name }: GetMentorImageProps) => {
  const imageModule = await import(`../assets/mentorImages/${name}.svg`);
  return imageModule;
};
