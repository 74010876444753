import { useRecoilState, useSetRecoilState } from "recoil";
import { CareerVideoRecord, VideoInteraction, VideoInteractionRecord } from "../../../shared/types/types";
import { videoInteractionsAtom } from "../../../students/recoil/videoInteractionAtoms";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import useSetFSDoc from "../../../shared/hooks/db/useSetFSDoc";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import useLogger from "../../../shared/hooks/logging/useLogger";
import { Collection, FunctionName } from "../../../shared/types/enums";
import { LogEventType } from "../../../shared/types/logEnums";
import { BLANK_VIDEO_INTERACTION_FORM } from "../../../shared/utils/blankUtils";
import { fetchData } from "../../../shared/utils/fetchUtils";

interface Props {
  url: string;
  studentId: string;
  video: CareerVideoRecord;
  interaction: VideoInteractionRecord | null;
  setInteraction: Dispatch<SetStateAction<VideoInteractionRecord | null>>;
  videoRef: React.RefObject<HTMLVideoElement>;
}

const useVideoInteractions = ({ studentId, video, interaction, setInteraction, videoRef }: Props) => {
  const setVideoInteractions = useSetRecoilState(videoInteractionsAtom);
  const [loggedInStudent, setLoggedInStudent] = useRecoilState(loggedInStudentAtom);
  const { setFSDoc } = useSetFSDoc();
  const { updateFSDoc } = useUpdateFSDoc();
  const { submitLog } = useLogger();

  const updateRecommendedCareers = useCallback(async () => {
    const response = await fetchData({
      functionName: FunctionName.UPDATE_RECOMMENDED_CAREERS_BY_INTERACTIONS,
      payload: { student: loggedInStudent },
    });
    const { updatedRecommendedJobIds } = await response.json();
    setLoggedInStudent((prev) => (prev ? { ...prev, willowRecommendedJobIds: updatedRecommendedJobIds } : prev));
  }, [loggedInStudent, setLoggedInStudent]);

  const updateVideoInteraction = useCallback(
    async (data: Partial<VideoInteraction>) => {
      const videoInteractionId = `${studentId}_${video.id}`;
      if (interaction) {
        try {
          await updateFSDoc({
            col: Collection.VIDEO_INTERACTIONS,
            id: videoInteractionId,
            data: { ...data },
          });

          setInteraction({ ...interaction, ...data });
          setVideoInteractions((prev) => [
            ...prev.filter((vi) => vi.id !== videoInteractionId),
            { ...interaction, ...data },
          ]);
          await updateRecommendedCareers();

          submitLog({
            eventType: LogEventType.USER_UPDATED_VIDEO_INTERACTION,
            changeLog: `User updated video interaction ${videoInteractionId} successfully`,
            file: "useVideoInteractions.ts",
          });
        } catch (error) {
          submitLog({
            error: error instanceof Error ? error : new Error("Error updating video interaction"),
            snackbarMessage: "There was an error updating the video interaction, please refresh and try again.",
            eventType: LogEventType.USER_UPDATE_VIDEO_INTERACTION_ERROR,
            file: "useVideoInteractions.ts",
          });
        }
      } else {
        try {
          await setFSDoc<VideoInteractionRecord>({
            col: Collection.VIDEO_INTERACTIONS,
            data: {
              ...BLANK_VIDEO_INTERACTION_FORM,
              id: videoInteractionId,
              studentId: studentId,
              schoolId: loggedInStudent?.schoolId ?? null,
              districtId: loggedInStudent?.districtId ?? null,
              videoId: video.id,
              timestamp: new Date().toISOString(),
              onetCode: video.onet,
              ...data,
            },
            id: videoInteractionId,
          });

          setInteraction({
            ...BLANK_VIDEO_INTERACTION_FORM,
            id: videoInteractionId,
            studentId: studentId,
            videoId: video.id,
            timestamp: new Date().toISOString(),
            onetCode: video.onet,
            ...data,
          });
          setVideoInteractions((prev) => [
            ...prev,
            {
              ...BLANK_VIDEO_INTERACTION_FORM,
              id: videoInteractionId,
              studentId: studentId,
              videoId: video.id,
              timestamp: new Date().toISOString(),
              onetCode: video.onet,
              ...data,
            },
          ]);
          await updateRecommendedCareers();

          submitLog({
            eventType: LogEventType.USER_ADDED_VIDEO_INTERACTION,
            changeLog: `User added video interaction ${videoInteractionId} successfully`,
            file: "useVideoInteractions.ts",
          });
        } catch (error: any) {
          submitLog({
            error,
            snackbarMessage: "There was an error add the video interaction, please refresh and try again.",
            eventType: LogEventType.USER_ADD_VIDEO_INTERACTION_ERROR,
            file: "useVideoInteractions.ts",
          });
        }
      }
    },
    [
      studentId,
      video.id,
      video.onet,
      interaction,
      updateFSDoc,
      setInteraction,
      setVideoInteractions,
      updateRecommendedCareers,
      submitLog,
      setFSDoc,
      loggedInStudent?.schoolId,
      loggedInStudent?.districtId,
    ],
  );

  const handleLearnMoreClick = useCallback(async () => {
    await updateVideoInteraction({ clickedLearnMore: true, watched: true });
    window.open(`/job/${video.onet}`, "_blank", "noopener,noreferrer");
  }, [updateVideoInteraction, video.onet]);

  //I removed watched so the video will show up in the feed
  const handleLikeClick = async () => {
    if (interaction?.interaction === "liked") {
      await updateVideoInteraction({ interaction: "none" });
    } else {
      await updateVideoInteraction({ interaction: "liked" });
      submitLog({
        eventType: LogEventType.VIDEO_BOOKMARKED,
        file: "useVideoInteractions.ts",
        changeLog: `Student ${loggedInStudent?.id} bookmarked video ${video.id}`,
      });
    }
  };

  const handleDislikeClick = async () => {
    if (interaction?.interaction === "disliked") {
      await updateVideoInteraction({ interaction: "none", watched: true });
    } else {
      await updateVideoInteraction({ interaction: "disliked", watched: true });
      submitLog({
        eventType: LogEventType.VIDEO_DISLIKED,
        file: "useVideoInteractions.ts",
        changeLog: `Student ${loggedInStudent?.id} disliked video ${video.id}`,
      });
    }
  };

  return {
    videoRef,
    handleLearnMoreClick,
    handleLikeClick,
    handleDislikeClick,
  };
};

export default useVideoInteractions;
