import { Box, IconButton } from "@mui/material";
import FeatherIcon from "../../../assets/icons/featherIcons/FeatherIcon";
import { REACTION_COLOR } from "../../../types/enums";
import useReactions from "../../../hooks/exploreCareers/useReactions";
import FirstCareerBookmarkDialog from "../../../../students/components/exploreCareers/common/FirstCareerBookmarkDialog";

export const Reactions = ({ jobId }: { jobId: string }) => {
  const { hearted, disliked, firstBookmarkOpen, setFirstBookmarkOpen, handleDislikeClick, handleHeartClick } =
    useReactions({ jobId });

  return (
    <>
      <Box sx={{ display: "flex", gap: 1 }}>
        {!hearted && (
          <IconButton onClick={handleDislikeClick} sx={{ p: 0, pt: 1, width: 40, height: 40, mr: -1 }}>
            <FeatherIcon
              icon="thumb-down"
              strokeColor={REACTION_COLOR.STROKE}
              fillColor={disliked ? REACTION_COLOR.DISLIKE : "transparent"}
              width="22px"
              height="22px"
            />
          </IconButton>
        )}
        {!disliked && (
          <IconButton onClick={handleHeartClick} sx={{ p: 0, pt: 1, width: 36, height: 36 }}>
            <FeatherIcon
              icon="bookmark"
              strokeColor={REACTION_COLOR.STROKE}
              fillColor={hearted ? REACTION_COLOR.BOOKMARK : "transparent"}
              width="19px"
              height="19px"
            />
          </IconButton>
        )}
      </Box>
      <FirstCareerBookmarkDialog open={firstBookmarkOpen} setOpen={setFirstBookmarkOpen} />
    </>
  );
};
