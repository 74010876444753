import { isValidPhoneNumber } from "react-phone-number-input";

type ValidateEmailBasicProps = {
  email: string;
};

export const validateEmailBasic = ({ email }: ValidateEmailBasicProps): boolean => {
  const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  return regex.test(email);
};

type ValidatePasswordProps = {
  password: string;
};

export const validatePassword = ({ password }: ValidatePasswordProps) => {
  // Check if the password is at least 8 characters long.
  if (password.length < 8) {
    return false;
  }

  // Check if the password contains at least one uppercase letter.
  if (!/[A-Z]/.test(password)) {
    return false;
  }

  // Check if the password contains at least one lowercase letter.
  if (!/[a-z]/.test(password)) {
    return false;
  }

  // Check if the password contains at least one number.
  if (!/[0-9]/.test(password)) {
    return false;
  }

  // The password is valid.
  return true;
};

type ValidatePhoneNumberProps = {
  phoneNumber: string;
};

export const validatePhoneNumber = ({ phoneNumber }: ValidatePhoneNumberProps) => {
  if (phoneNumber) {
    return isValidPhoneNumber(phoneNumber, "US");
  }
  return false;
};
