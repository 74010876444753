import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Fade,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowRightOutlined from "@mui/icons-material/KeyboardArrowRightOutlined";
import { useRecoilState } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useNavigate } from "react-router-dom";
import { Collection, PageRoute } from "../../../shared/types/enums";
import { styled } from "@mui/styles";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import CloseIcon from "@mui/icons-material/Close";

export const GetStartedModal = (): JSX.Element | null => {
  const [open, setOpen] = useState(true);
  const [loggedInStudent, setLoggedInStudent] = useRecoilState(loggedInStudentAtom);
  const [show, setShow] = useState(true);
  const navigate = useNavigate();
  const { updateFSDoc } = useUpdateFSDoc();

  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
  };

  const completionStatus = useMemo(
    () => ({
      personalityQuizComplete: loggedInStudent?.setupComplete,
      finishedPersonalityComplete: loggedInStudent?.getStartedData?.finishedPersonalitySuccess,
      careersBookmarkedComplete: loggedInStudent?.getStartedData?.careersBookmarkSuccess,
      programsBookmarkedComplete: loggedInStudent?.getStartedData?.programsBookmarkSuccess,
    }),
    [loggedInStudent],
  );

  const markUserCompleted = useCallback(() => {
    if (!loggedInStudent) return;
    if (!loggedInStudent.getStartedData?.getStartedComplete) {
      setTimeout(() => {
        updateFSDoc({
          col: Collection.STUDENTS,
          id: loggedInStudent.id,
          data: {
            getStartedData: {
              ...loggedInStudent.getStartedData,
              getStartedComplete: true,
            },
          },
        });

        setLoggedInStudent((prev) =>
          prev
            ? {
                ...prev,
                getStartedData: {
                  ...loggedInStudent.getStartedData,
                  getStartedComplete: true,
                },
              }
            : null,
        );
      }, 1000);
    }
  }, [setLoggedInStudent, loggedInStudent, updateFSDoc]);

  useEffect(() => {
    const allComplete = Object.values(completionStatus).every(Boolean);
    if (allComplete) {
      setTimeout(() => {
        setShow(false);
        markUserCompleted();
      }, 1000);
    }
  }, [completionStatus, markUserCompleted, setOpen]);

  const toggleCollapse = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const selectLink = useCallback((link: PageRoute) => [navigate(link)], [navigate]);

  const ListItemsText = {
    personalityQuizText: "Complete your Personality Quiz",
    finishedPersonalityText: "Reflect on your Personality Type with Alma",
    careersBookmarkedText: "Bookmark at least 3 careers you're interested in",
    programsBookmarkedText: "Bookmark at least 6 programs you're interested in",
  };

  const steps = [
    {
      complete: completionStatus.personalityQuizComplete,
      text: ListItemsText.personalityQuizText,
      link: null,
    },
    {
      complete: completionStatus.finishedPersonalityComplete,
      text: ListItemsText.finishedPersonalityText,
      link: PageRoute.STUDENT_PROFILE,
    },
    {
      complete: completionStatus.careersBookmarkedComplete,
      text: ListItemsText.careersBookmarkedText,
      link: PageRoute.EXPLORE_CAREERS,
    },
    {
      complete: completionStatus.programsBookmarkedComplete,
      text: ListItemsText.programsBookmarkedText,
      link: PageRoute.EXPLORE_PROGRAMS,
    },
  ];

  const CustomListItemIcon = styled(ListItemIcon)({
    minWidth: "initial",
  });

  if (loggedInStudent?.getStartedData?.getStartedComplete) {
    return null;
  }

  return (
    <Fade in={show} timeout={500}>
      <Grid direction={"column"} container justifyContent="flex-end">
        <Grid item>
          <Box sx={{ width: "100%", maxWidth: 380, bgcolor: "background.paper", borderRadius: 2, boxShadow: 3 }}>
            <Collapse in={open}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  px: 2,
                  py: open ? 3 : null,
                }}
              >
                <Typography variant="h5" sx={{ fontSize: 18, fontWeight: 700 }}>
                  Welcome to Willow!
                </Typography>
                <IconButton onClick={toggleCollapse}>
                  {open ? (
                    <KeyboardArrowDownOutlinedIcon color="secondary" />
                  ) : (
                    <KeyboardArrowUpOutlinedIcon color="secondary" />
                  )}
                </IconButton>
              </Box>
              <Divider />
              <Box
                sx={{
                  p: 2,
                }}
              >
                <Typography variant="h5" sx={{ my: 1, fontSize: 16, fontWeight: 700 }}>
                  Let's get you started
                </Typography>
                <Typography sx={{ mb: 1, fontSize: 14, fontWeight: 400 }}>
                  Follow these steps to get started off right.
                </Typography>
              </Box>

              <List>
                {steps.map((step, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      {step.complete ? (
                        <CheckCircleIcon sx={{ fontSize: 32 }} color="secondary" />
                      ) : (
                        <RadioButtonUncheckedIcon sx={{ fontSize: 32 }} />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      sx={[step.complete ? { textDecoration: "line-through" } : null, { fontSize: 14 }]}
                      primary={step.text}
                    />
                    <CustomListItemIcon sx={{ pl: "20px" }}>
                      {!step.complete && step.link && (
                        <IconButton onClick={() => selectLink(step.link)}>
                          <KeyboardArrowRightOutlined />
                        </IconButton>
                      )}
                    </CustomListItemIcon>
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </Box>
        </Grid>
        <Grid item>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            {visible && (
              <Button
                color="secondary"
                onClick={toggleCollapse}
                variant="contained"
                sx={{
                  mt: 0.7,
                  height: 50,
                  borderRadius: 10,
                  textTransform: "none",
                }}
              >
                Get started
                <IconButton onClick={handleClose} size="small" sx={{ color: "#fff" }}>
                  <CloseIcon />
                </IconButton>
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Fade>
  );
};
