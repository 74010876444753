import { ChangeEvent } from "react";
import { StudentGroup } from "../../../shared/types/types";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Collection } from "../../../shared/types/enums";
import useAddFSDoc from "../../../shared/hooks/db/useAddFSDoc";
import { allSchoolsAtom } from "../../../shared/recoil/schoolAndDistrictAtoms";
import { selectedSchoolIdAtom } from "../../recoil/selectedSchoolAtom";
import { schoolGroupsAtom } from "../../recoil/studentsAtoms";
import { StudentGroupFormHookProps } from "../../components/settings/ManageGroupsDialog";
import { useAuth } from "../../../shared/contexts/AuthContext";
import useLogger from "../../../shared/hooks/logging/useLogger";
import { LogEventType } from "../../../shared/types/logEnums";
import { loggedInStaffAtom } from "../../../shared/recoil/userAtoms";

export const useManageGroupDialog = ({ groupForm, setGroupForm, setOpen }: StudentGroupFormHookProps) => {
  const { addFSDoc } = useAddFSDoc();
  const { currentAuthUser } = useAuth();
  const setSchoolGroups = useSetRecoilState(schoolGroupsAtom);
  const selectedSchoolId = useRecoilValue(selectedSchoolIdAtom);
  const schools = useRecoilValue(allSchoolsAtom);
  const currentSchool = schools.find((school) => school.id === selectedSchoolId);
  const { submitLog } = useLogger();
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);

  const handleTextChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    setGroupForm((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    if (!currentSchool) return;
    setOpen(false);

    const requestData = {
      ...groupForm,
      createdByStaffId: currentAuthUser?.uid ?? null,
      schoolId: currentSchool.id ?? null,
      districtId: currentSchool.districtId ?? null,
    };
    try {
      const response = await addFSDoc<StudentGroup>({
        col: Collection.GROUPS,
        data: requestData,
      });
      if (response) {
        setSchoolGroups((prevValue) => [...prevValue, response]);
      }
      submitLog({
        eventType: LogEventType.STUDENT_GROUP_CREATED,
        changeLog: `${loggedInStaff?.firstName} ${loggedInStaff?.lastName} created a new group: ${groupForm.groupName} for school: ${currentSchool.name}. Staff Id: ${loggedInStaff?.id}`,
        file: "useManageGroupDialog.ts",
      });
    } catch (error) {
      submitLog({
        error,
        snackbarMessage: `There was an error creating your group. Please refresh and try again.`,
        eventType: LogEventType.STUDENT_GROUP_CREATED_ERROR,
        file: "useManageGroupDialog.ts",
      });
    }
  };

  return { handleSave, handleTextChange };
};
