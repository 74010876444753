import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import { Collection } from "../../../shared/types/enums";
import { useRecoilState } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";

type Props = {
  recommendedProgramIds: string[];
};

const useUpdateRecommendedPrograms = () => {
  const { updateFSDoc } = useUpdateFSDoc();
  const [loggedInStudent, setLoggedInStudent] = useRecoilState(loggedInStudentAtom);
  const updateRecommendedPrograms = async ({ recommendedProgramIds }: Props) => {
    if (!loggedInStudent) return;
    await updateFSDoc({
      col: Collection.STUDENTS,
      id: loggedInStudent.id,
      data: { willowRecommendedProgramIds: recommendedProgramIds },
    });
    setLoggedInStudent((prev) => (prev ? { ...prev, willowRecommendedProgramIds: recommendedProgramIds } : prev));
  };
  return { updateRecommendedPrograms };
};

export default useUpdateRecommendedPrograms;
