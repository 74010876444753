import ProgramForPrint from "./ProgramForPrint";
import { JobDetailsRecord, ProviderProgramRecordTwo, ReviewRecord } from "../../../types/types";

type Props = {
  selectedProgram: ProviderProgramRecordTwo;
  reviews: ReviewRecord | undefined;
  relatedJobs: JobDetailsRecord[];
};

function PrintTable({ selectedProgram, reviews, relatedJobs }: Props) {
  return (
    <>
      <table>
        <thead>
          <tr>
            <td>
              <div className="page-header-space"></div>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <ProgramForPrint reviews={reviews} selectedProgram={selectedProgram} relatedJobs={relatedJobs} />
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td>
              <div className="page-footer-space"></div>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
}

export default PrintTable;
