import { useEffect, useState } from "react";
import { AlmaChatRecord, ChatGPTEntry, SchoolRecord, StudentRecord } from "../../../shared/types/types";
import { useRecoilValue } from "recoil";
import { personalityTypeAtom } from "../../../shared/recoil/personalityTypeAtoms";

import useGenerateStartingPrompt from "./useGenerateStartingPrompt";

type Props = {
  loggedInStudent: StudentRecord;
  schools: SchoolRecord[];
  currentStudentAlmaChat: AlmaChatRecord | null;
};

const useChatContainer = ({ loggedInStudent, schools, currentStudentAlmaChat }: Props) => {
  const [chatHistory, setChatHistory] = useState<ChatGPTEntry[]>([]);
  const personalityType = useRecoilValue(personalityTypeAtom);
  const [initialChatContext, setInitialChatContext] = useState<string>("");
  const { generateInitialPrompt } = useGenerateStartingPrompt();

  useEffect(() => {
    const loadInitialContext = async () => {
      if (currentStudentAlmaChat) {
        setChatHistory(currentStudentAlmaChat.chatHistory);
      } else {
        setChatHistory([]);
        const startingPrompt = await generateInitialPrompt(loggedInStudent, schools, personalityType);
        setInitialChatContext(startingPrompt);
      }
    };
    loadInitialContext();
  }, [currentStudentAlmaChat, generateInitialPrompt, loggedInStudent, personalityType, schools]);

  return { chatHistory, setChatHistory, initialChatContext };
};

export default useChatContainer;

// Helper function to generate initial prompt

// Fetch bookmarked career IDs from video interactions
