import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { Dispatch } from "react";

type Props = {
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
};

const FirstCareerBookmarkDialog = ({ open, setOpen }: Props) => {
  return (
    <Dialog onClose={() => setOpen(false)} open={open} fullWidth maxWidth="sm">
      <DialogTitle variant="h4" sx={{ fontSize: 28, textAlign: "center" }}>
        Congrats on bookmarking your first career!
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ fontSize: 16, mb: 0, textAlign: "justify" }}>
          Your recommended programs will update as you bookmark or thumbs down careers. Keep in mind, we only show the
          best programs, so you might not see options for every career you bookmark.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FirstCareerBookmarkDialog;
