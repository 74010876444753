import { Dispatch } from "react";
import { Box } from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { StudentGroup, Student } from "../../../shared/types/types";
import useGroupsTable from "../../hooks/settings/useGroupsTable";

type Props = {
  groups: StudentGroup[];
  students: Student[];
  setSelectedGroup: Dispatch<React.SetStateAction<StudentGroup | null>>;
};

const GroupsTable = ({ groups, students, setSelectedGroup }: Props) => {
  const { rows, columns, handleRowClick } = useGroupsTable({
    groups,
    students,
    setSelectedGroup,
  });

  return (
    <Box>
      <DataGridPremium
        rows={rows}
        columns={columns}
        onRowClick={handleRowClick}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd")}
      />
    </Box>
  );
};

export default GroupsTable;
