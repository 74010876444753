import { useCallback } from "react";
import { TextField } from "@mui/material";
import { useField } from "formik";
import { formatPhoneNumber } from "../../utils/formatUtils";

type Props = {
  label?: string;
  name: string;
  sx?: { [key: string]: any };
  required?: boolean;
};

const FormikPhone = ({ label, name, sx, required = false }: Props) => {
  const [field, meta] = useField(name);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const cleanedValue = value.replace(/\D/g, ""); // Remove non-digit characters
      const formattedValue = formatPhoneNumber({ phoneNumber: cleanedValue });
      field.onChange({ target: { name, value: formattedValue } });
    },
    [field, name],
  );

  const handleBlur = useCallback(() => {
    field.onBlur({ target: { name } });
  }, [field, name]);

  return (
    <div className="phone-input-wrapper">
      <TextField
        fullWidth
        {...field}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        className="phone-input-override"
        label={label}
        variant="outlined"
        required={required}
        sx={{ ...sx }}
        onChange={handleChange}
        onBlur={handleBlur}
        inputProps={{
          maxLength: 16, // Limit input to 18 characters (including formatting and country code)
        }}
      />
    </div>
  );
};

export default FormikPhone;
