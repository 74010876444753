import { FilterProps } from "../types/types";

export const initialFiltersValues: FilterProps = {
  sizes: [],
  states: [],
  cities: [],
  cost: [0, 999999999],
  hbcuStatus: false,
  menOnly: false,
  womenOnly: false,
  credLevels: [],
  industries: [],
  tags: [],
  cipCodes: [],
  religiousAffiliation: false,
  locale: [],
  // admissionRate: [0, 100],
  // completionRate: [0, 100],
  onlineOnly: false,
  providerId: "",
  showNegativeROI: false,
  collegeOnly: false,
  nonCollegeOnly: false,
};
