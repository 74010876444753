import { Box, Divider, Grid, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { allSchoolStudentsSelector } from "../../recoil/studentsAtoms";
import StageSummaryBar from "./StageSummaryBar";
import { staffUsersAtom } from "../../../shared/recoil/userAtoms";

const StageSummary = () => {
  const staff = useRecoilValue(staffUsersAtom);
  const staffEmails = staff.map((s) => s.email);
  const allStudents = useRecoilValue(allSchoolStudentsSelector);
  const filteredStudents = allStudents.filter((student) => !staffEmails.includes(student.email));
  const quizNotCompletedStudents = filteredStudents.filter((student) => !student.quizComplete);
  const quizCompleteStudents = filteredStudents.filter((student) => student.quizComplete);
  const bookmarkedOneCareerStudents = filteredStudents.filter((student) => student.favoriteJobIds.length > 0);
  const bookmarkedOneProgramStudents = filteredStudents.filter((student) => student.favoriteProgramIds.length > 0);
  return (
    <>
      <Box sx={{ mt: 0 }}>
        <Grid container spacing={3} columns={4}>
          <Grid
            item
            xs={1}
            sx={{
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            <StageSummaryBar denominator={filteredStudents.length} students={quizNotCompletedStudents} />
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            <StageSummaryBar denominator={filteredStudents.length} students={quizCompleteStudents} />
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            <StageSummaryBar denominator={filteredStudents.length} students={bookmarkedOneCareerStudents} />
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            <StageSummaryBar denominator={filteredStudents.length} students={bookmarkedOneProgramStudents} />
          </Grid>
        </Grid>
        <Divider sx={{ mt: 0 }} />
        <Grid container spacing={3} columns={5} sx={{ pt: "2px" }}>
          <Grid
            item
            xs={1}
            sx={{
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            <Typography sx={{ fontSize: 12, mt: "4px" }} variant="body2">
              Not Finished Quiz
            </Typography>
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            <Typography sx={{ fontSize: 12, mt: "4px" }} variant="body2">
              Finished Quiz
            </Typography>
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            <Typography sx={{ fontSize: 12, mt: "4px" }} variant="body2">
              Bookmarked Careers
            </Typography>
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            <Typography sx={{ fontSize: 12, mt: "4px" }} variant="body2">
              Bookmarked Programs
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default StageSummary;
