import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Box,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ManualScholarshipRecord, StudentRecord } from "../../../shared/types/types";
import useManualScholarshipDialog from "../../hooks/exploreScholarships/useManualScholarshipDialog";
import dayjs from "dayjs";

type Props = {
  open: boolean;
  onClose: () => void;
  loggedInStudent: StudentRecord;
  scholarshipEntry?: ManualScholarshipRecord | null;
  setManualScholarships: React.Dispatch<React.SetStateAction<ManualScholarshipRecord[]>>;
};

const ManualScholarshipDialog = (props: Props) => {
  const { open, onClose } = props;
  const {
    formData,
    setFormData,
    appliedDate,
    setAppliedDate,
    awardDate,
    setAwardDate,
    handleSave,
    touched,
    errors,
    isFormValid,
    handleBlur,
    handleClose,
  } = useManualScholarshipDialog(props);

  const statusOptions = [
    { value: "not applied", label: "Not Applied For" },
    { value: "applied", label: "Applied For" },
    { value: "rejected", label: "Turned Down" },
    { value: "awarded", label: "Awarded" },
  ];

  const today = dayjs().startOf("day");

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>{formData.id ? "Edit Scholarship" : "New Scholarship"}</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2} mt={1}>
          <TextField
            label="Scholarship Name"
            fullWidth
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            onBlur={() => handleBlur("name")}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
          <TextField
            label="Organization Providing Scholarship"
            fullWidth
            value={formData.sponsorName}
            onChange={(e) => setFormData({ ...formData, sponsorName: e.target.value })}
            onBlur={() => handleBlur("sponsorName")}
            error={Boolean(touched.sponsorName && errors.sponsorName)}
            helperText={touched.sponsorName && errors.sponsorName}
          />
          <FormControl fullWidth>
            <InputLabel>Scholarship Status</InputLabel>
            <Select
              value={formData.status}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  status: e.target.value as ManualScholarshipRecord["status"],
                })
              }
              label="Scholarship Status"
            >
              {statusOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {formData.status !== "not applied" && (
            <DatePicker
              label="Applied Date"
              value={appliedDate}
              onChange={(date) => setAppliedDate(date)}
              onAccept={() => handleBlur("appliedDate")}
              onClose={() => handleBlur("appliedDate")}
              format="MM/DD/YYYY"
              maxDate={today}
              slots={{ textField: TextField }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: Boolean(touched.appliedDate && errors.appliedDate),
                  helperText: touched.appliedDate && errors.appliedDate,
                },
              }}
            />
          )}
          {formData.status === "awarded" && (
            <>
              <DatePicker
                label="Award Date"
                value={awardDate}
                onChange={(date) => setAwardDate(date)}
                onAccept={() => handleBlur("awardDate")}
                onClose={() => handleBlur("awardDate")}
                format="MM/DD/YYYY"
                minDate={appliedDate || undefined}
                maxDate={today}
                slots={{ textField: TextField }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: Boolean(touched.awardDate && errors.awardDate),
                    helperText: touched.awardDate && errors.awardDate,
                  },
                }}
              />
              <TextField
                label="Award Amount"
                fullWidth
                value={formData.awardAmount ? String(formData.awardAmount).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}
                onChange={(e) => {
                  const value = e.target.value.replace(/,/g, "");
                  if (/^\d*$/.test(value) && value.length <= 6) {
                    setFormData({
                      ...formData,
                      awardAmount: Number(value),
                    });
                  }
                }}
                onBlur={() => handleBlur("awardAmount")}
                error={Boolean(touched.awardAmount && errors.awardAmount)}
                helperText={touched.awardAmount && errors.awardAmount}
                InputProps={{
                  startAdornment: <span>$</span>,
                  inputProps: { inputMode: "numeric", pattern: "[0-9]*" },
                }}
              />
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary" disabled={!isFormValid}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManualScholarshipDialog;
