import { Box, Divider, Paper, Typography } from "@mui/material";
import React from "react";
import { Color } from "../../types/enums";

type Props = {
  children?: React.ReactNode;
  title: string;
  titleSX?: React.CSSProperties;
  actionButton?: React.ReactNode;
  height?: string;
  boxPadding?: number;
};

const DisplayBox = ({ children, title, titleSX, actionButton, height = "auto", boxPadding = 1 }: Props) => {
  return (
    <Paper sx={{ backgroundColor: "#fff", borderRadius: 1.5 }}>
      <Box
        sx={{
          pl: 2,
          pr: 1,
          py: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          minHeight: "40px",
        }}
      >
        <Typography sx={{ textTransform: "upperCase", fontSize: 16, ...titleSX }} color={Color.MINT} variant="h6">
          {title}
        </Typography>
        {actionButton}
      </Box>
      <Divider sx={{ borderColor: "#ddd" }} />

      <Box sx={{ p: boxPadding, height: height }}>{children}</Box>
    </Paper>
  );
};

export default DisplayBox;
