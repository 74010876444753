import { BrowserRouter as Router } from "react-router-dom";
import { SiteRoutes } from "./shared/routes/Routes";
import ErrorSnackbar from "./shared/components/errorHandling/ErrorSnackbar";
import { useRecoilState, useRecoilValue } from "recoil";
import { pageLoadingAtom } from "./shared/recoil/loadingAtoms";
import SiteLoadingPage from "./shared/pages/SiteLoadingPage";
import { StaffDBProvider } from "./staff/providers/StaffDBProvider";
import { Box } from "@mui/material";
import useWindowDimensions from "./shared/hooks/responsiveLayout/useWindowDimensions";
import StaffSideDesktopNavbar from "./staff/components/navigation/StaffSideDesktopSidebar";
import { useEffect } from "react";
import StaffTopDesktopNavbar from "./staff/components/navigation/StaffTopDesktopNavbar";
import { staffSideBarRouterAtom } from "./shared/recoil/routerAtoms";

const StaffBootstrap = () => {
  const loading = useRecoilValue(pageLoadingAtom);
  const { width } = useWindowDimensions();
  const [navOpen, setNavOpen] = useRecoilState<boolean>(staffSideBarRouterAtom);

  useEffect(() => {
    setNavOpen(width > 900);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  return (
    <>
      <StaffDBProvider>
        <Box sx={{ display: "flex", backgroundColor: "#fafafa" }}>
          <Router>
            {!loading ? (
              <>
                <StaffSideDesktopNavbar open={navOpen} setOpen={setNavOpen} />
                <Box component="main" sx={{ flexGrow: 1 }}>
                  <StaffTopDesktopNavbar />
                  <Box sx={{ height: "calc(100vh - 64px)", overflowY: "auto" }}>
                    <SiteRoutes />
                  </Box>
                </Box>
              </>
            ) : (
              <Box component="main" sx={{ flexGrow: 1 }}>
                <SiteLoadingPage />
              </Box>
            )}
            <ErrorSnackbar />
          </Router>
        </Box>
      </StaffDBProvider>
    </>
  );
};

export default StaffBootstrap;
