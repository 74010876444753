import { Box, Typography } from "@mui/material";
import { Color } from "../../types/enums";

type StatDetailProps = {
  label: string;
  value: string;
};

const StatDetail = ({ label, value }: StatDetailProps) => (
  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
    <Typography
      sx={{
        flex: "none",
        width: "150px",
        fontSize: 14,
        fontWeight: 700,
        color: Color.CHARCOAL,
      }}
    >
      {label}:
    </Typography>
    <Typography
      sx={{
        fontSize: 14,
        fontWeight: 400,
        color: Color.GRAY_800,
      }}
    >
      {value}
    </Typography>
  </Box>
);

export default StatDetail;
