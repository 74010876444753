import { useState } from "react";
import StudentProfileSetupNotComplete from "./StudentProfileSetupNotComplete";
import StudentProfileSetupComplete from "./StudentProfileSetupComplete";
import { StudentRecord } from "../../../shared/types/types";
import { Box, Typography } from "@mui/material";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";

type Props = {
  loggedInStudent: StudentRecord;
  onAskAlma: (text: string) => void;
  setAlmaMobileDialogOpen: (open: boolean) => void;
};

const StudentProfileContainer = ({ loggedInStudent, onAskAlma, setAlmaMobileDialogOpen }: Props) => {
  const [navOpen, setNavOpen] = useState(false);
  const { width } = useWindowDimensions();
  const mobile = width < 900;

  return (
    <Box sx={{ mt: 1, p: 1 }}>
      {!mobile && (
        <Typography sx={{ mt: 1, p: 1 }} variant="pageTitle">
          Profile
        </Typography>
      )}
      {loggedInStudent && (
        <Box height="calc(100vh - 60px)" sx={{ overflow: "auto" }}>
          {!loggedInStudent.setupComplete ? (
            <StudentProfileSetupNotComplete loggedInStudent={loggedInStudent} navOpen={navOpen} />
          ) : (
            <StudentProfileSetupComplete
              onAskAlma={onAskAlma}
              loggedInStudent={loggedInStudent}
              navOpen={navOpen}
              setNavOpen={setNavOpen}
              setAlmaMobileDialogOpen={setAlmaMobileDialogOpen}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default StudentProfileContainer;
