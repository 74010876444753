import { atom, selector } from "recoil";
import { StudentRecord, StudentGroup } from "../../shared/types/types";

export const currentGroupAtom = atom<string | null>({
  key: "currentGroupAtom",
  default: null,
});

export const schoolGroupsAtom = atom<StudentGroup[]>({
  key: "schoolGroupsAtom",
  default: [],
});

export const allSchoolStudentsAtom = atom<StudentRecord[]>({
  key: "allSchoolStudentsAtom",
  default: [], // Initialize with an empty array to avoid circular dependency
});

export const allSchoolStudentsSelector = selector<StudentRecord[]>({
  key: "allSchoolStudentsSelector",
  get: ({ get }): StudentRecord[] => {
    const currentGroupStudents = get(currentGroupStudentsAtom);
    const allSchoolStudents = get(allSchoolStudentsAtom);

    // Use current group students if available; otherwise, return all school students
    return currentGroupStudents !== null ? currentGroupStudents : allSchoolStudents;
  },
});

export const currentGroupStudentsAtom = atom<StudentRecord[] | null>({
  key: "currentGroupStudentsAtom",
  default: null,
});

export const selectedStudentAtom = atom<StudentRecord | null>({
  key: "selectedStudentAtom",
  default: null,
});
