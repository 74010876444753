import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import CloseIcon from "../../../shared/components/closeIcon/CloseIcon";
import { Dispatch, useState } from "react";
import { StudentGroup } from "../../../shared/types/types";
import { useSetRecoilState } from "recoil";
import { schoolGroupsAtom } from "../../recoil/studentsAtoms";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import { Collection } from "../../../shared/types/enums";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  group: StudentGroup;
  setSelectedGroup: Dispatch<React.SetStateAction<StudentGroup | null>>;
};

const EditGroupNameDialog = ({ open, setOpen, group, setSelectedGroup }: Props) => {
  const [newGroupName, setNewGroupName] = useState(group?.groupName || "");
  const setStudentGroups = useSetRecoilState(schoolGroupsAtom);
  const { updateFSDoc } = useUpdateFSDoc();

  const handleSaveGroupName = async () => {
    if (group && newGroupName.trim()) {
      await updateFSDoc({
        col: Collection.GROUPS,
        data: { groupName: newGroupName },
        id: group.id,
      });
      setSelectedGroup((prev) => (prev ? { ...prev, groupName: newGroupName } : prev));
      setStudentGroups((prev) => {
        if (prev) {
          const updatedGroups = prev.map((g) => (g.id === group.id ? { ...g, groupName: newGroupName } : g));
          return updatedGroups;
        }
        return prev;
      });
      setOpen(false);
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Edit Group Name
        <CloseIcon onClose={() => setOpen(false)} />
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Group Name"
          sx={{ mt: 2 }}
          value={newGroupName}
          onChange={(e) => setNewGroupName(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSaveGroupName} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditGroupNameDialog;
