import { Box, Chip, IconButton, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import DisplayBox from "../../../shared/components/displayBox/DisplayBox";
import { useState } from "react";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";

import { Color } from "../../../shared/types/enums";
import CareerUnlockSurveyDialog from "../careerUnlockSurvey/CareerUnlockSurveyDialog";

const RecommendationPreferences = () => {
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const [editOpen, setEditOpen] = useState(false);
  const formatProgramType = (programType: string) => {
    switch (programType) {
      case "college":
        return "Mainly College Programs";
      case "professional":
        return "Mainly Professional Programs";
      case "mix":
        return "A Mix of College and Professional Programs";
      default:
        return "Unknown";
    }
  };
  return (
    <>
      {loggedInStudent && (
        <Box sx={{ mt: 2 }}>
          <DisplayBox
            title="Recommendation Preferences"
            actionButton={
              <IconButton sx={{ color: Color.STANDARD_GRAY }} disableRipple onClick={() => setEditOpen(true)}>
                <FeatherIcon width="30" icon="edit" />
              </IconButton>
            }
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                  borderBottom: "1px solid #ddd",
                  pb: 1,
                }}
              >
                <Typography component="span" variant="body1" sx={{ fontWeight: "800" }}>
                  GPA
                </Typography>
                <Typography component="span" variant="body1" sx={{ ml: 1 }}>
                  {loggedInStudent.gpaValue}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                  borderBottom: "1px solid #ddd",
                  pb: 1,
                }}
              >
                <Typography component="span" variant="body1" sx={{ fontWeight: "800" }}>
                  Financial Status
                </Typography>
                <Typography component="span" variant="body1" sx={{ ml: 1 }}>
                  {loggedInStudent.frlStatus}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                  borderBottom: "1px solid #ddd",
                  pb: 1,
                }}
              >
                <Typography component="span" variant="body1" sx={{ fontWeight: "800" }}>
                  States You're Exploring:
                </Typography>
                {loggedInStudent.statesForRecommendations?.map((state) => (
                  <Chip key={state} label={state} sx={{ ml: 1 }} />
                ))}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                  borderBottom: "1px solid #ddd",
                  pb: 1,
                }}
              >
                <Typography component="span" variant="body1" sx={{ fontWeight: "800" }}>
                  Cities You're Exploring:
                </Typography>
                {loggedInStudent.citiesForRecommendations?.map((city) => (
                  <Chip key={city} label={city} sx={{ ml: 1 }} />
                ))}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                  borderBottom: "1px solid #ddd",
                  pb: 1,
                }}
              >
                <Typography component="span" variant="body1" sx={{ fontWeight: "800" }}>
                  Types of Programs You Want Recommended:
                </Typography>
                <Chip label={formatProgramType(loggedInStudent.programTypeForRecommendations)} sx={{ ml: 1 }} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                  borderBottom: "1px solid #ddd",
                  pb: 1,
                }}
              >
                <Typography component="span" variant="body1" sx={{ fontWeight: "800" }}>
                  Interested in Online Only Programs:
                </Typography>
                <Chip label={loggedInStudent.includeOnlineOnly ? "Yes" : "No"} sx={{ ml: 1 }} />
              </Box>
            </Box>
          </DisplayBox>
        </Box>
      )}
      <CareerUnlockSurveyDialog open={editOpen} setOpen={setEditOpen} mode="update" />
    </>
  );
};

export default RecommendationPreferences;
