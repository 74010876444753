import { Autocomplete, Box, Checkbox, TextField, Typography } from "@mui/material";
import { Color } from "../../../../types/enums";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Dispatch, useEffect, useState } from "react";
import { FilterProps } from "../../../../types/types";
import cities from "../../../../assets/data/cities.json";
import { states } from "../../../../assets/data/states";
import useWindowDimensions from "../../../../hooks/responsiveLayout/useWindowDimensions";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = {
  filters: FilterProps;
  setFilters: Dispatch<React.SetStateAction<FilterProps>>;
};

const StateAndCityFilter = ({ filters, setFilters }: Props) => {
  const [cityList, setCityList] = useState<string[]>([]);
  const { width } = useWindowDimensions();
  const mobile = width < 900;

  useEffect(() => {
    const citiesArray: string[] = filters.states
      .map((state) => cities[state as keyof typeof cities].map((city) => `${city}, ${state}`))
      .flat();
    setCityList(citiesArray);
  }, [filters.states]);

  const handleStatesChange = (_event: any, value: string[]) => {
    setFilters((pV) => {
      // Get cities from removed states
      const removedStates = pV.states.filter((state) => !value.includes(state));
      const removedStateCities = removedStates
        .map((state) => cities[state as keyof typeof cities].map((city) => `${city}, ${state}`))
        .flat();

      // Filter out cities that belong to removed states
      const remainingCities = pV.cities.filter((city) => !removedStateCities.includes(city));

      return {
        ...pV,
        states: value,
        cities: remainingCities,
      };
    });

    const citiesArray: string[] = [];
    value.forEach((state) => {
      const stateCities = cities[state as keyof typeof cities].map((city) => `${city}, ${state}`);
      citiesArray.push(...stateCities);
    });
    setCityList(citiesArray);
  };

  const handleCitiesChange = (_event: any, value: string[]) => {
    setFilters((pV) => ({ ...pV, cities: value }));
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          px: 2,
          py: 1,
        }}
      >
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "18px",
            color: Color.DARK_GRAY,
          }}
        >
          State & City
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5, maxWidth: 350, flexWrap: "wrap" }}>
          <Autocomplete
            multiple
            id="states-filter"
            options={states}
            value={filters.states}
            onChange={handleStatesChange}
            disableCloseOnSelect
            popupIcon={mobile ? undefined : false}
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => {
              const { ...optionProps } = props;
              return (
                <li {...optionProps} key={option}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search state"
                sx={{
                  "& .MuiChip-root": {
                    "& .MuiChip-deleteIcon": {
                      margin: "0 0 0 5px",
                    },
                  },
                  "& .MuiOutlinedInput-root": {
                    "& .MuiAutocomplete-input": {
                      minWidth: 100,
                    },
                  },
                }}
              />
            )}
          />
          <Autocomplete
            multiple
            id="cities-filter"
            options={cityList}
            value={filters.cities}
            onChange={handleCitiesChange}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            disabled={cityList.length === 0}
            popupIcon={mobile ? undefined : false}
            renderOption={(props, option, { selected }) => {
              const { ...optionProps } = props;
              return (
                <li {...optionProps} key={option}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search city"
                sx={{
                  "& .MuiChip-root": {
                    "& .MuiChip-deleteIcon": {
                      margin: "0 0 0 5px",
                    },
                  },
                  "& .MuiOutlinedInput-root": {
                    "& .MuiAutocomplete-input": {
                      minWidth: 100,
                    },
                  },
                }}
              />
            )}
          />
        </Box>
      </Box>
    </>
  );
};

export default StateAndCityFilter;
