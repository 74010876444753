import { Suspense } from "react";
import StudentTableTools from "./StudentTableTools";
import { Box, Divider, Paper } from "@mui/material";
import StudentTable from "./StudentTable";

const StudentTableAndFilters = () => {
  return (
    <>
      <Box>
        <Paper sx={{ backgroundColor: "#fff", borderRadius: 1.5, mt: 2 }}>
          <Box sx={{ padding: 1 }}>
            <Suspense fallback={<div>Loading...</div>}>
              <StudentTableTools />
            </Suspense>
          </Box>
          <Divider />
          <StudentTable />
        </Paper>
      </Box>
    </>
  );
};

export default StudentTableAndFilters;
