import { useState, useCallback } from "react";
import { doc, setDoc } from "firebase/firestore";
import { db, nationalDB } from "../../../firebase";

interface SendRequestProps {
  col: string;
  data: object;
  id: string;
  national?: boolean;
}

const useSetFSDoc = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const setFSDoc = useCallback(async <T>({ col, data, id, national = false }: SendRequestProps): Promise<T | null> => {
    setIsLoading(true);
    setError(null);
    const now = new Date().toISOString();
    const finalData = {
      ...data,
      createdAt: now,
      lastUpdatedAt: now,
      active: true,
    };
    const docRef = doc(national ? nationalDB : db, col, id);
    await setDoc(docRef, finalData);
    return { ...finalData, id } as T;
  }, []);
  return { isLoading, error, setFSDoc };
};

export default useSetFSDoc;
