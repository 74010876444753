import { selectedSchoolIdAtom } from "../../recoil/selectedSchoolAtom";
import { useRecoilState, useRecoilValue } from "recoil";
import { MenuItem, Select } from "@mui/material";
import { loggedInStaffAtom } from "../../../shared/recoil/userAtoms";
import { allSchoolsAtom } from "../../../shared/recoil/schoolAndDistrictAtoms";
import { useMemo } from "react";

const StaffSchoolSelect = () => {
  const [selectedSchoolId, setSelectedSchoolId] = useRecoilState(selectedSchoolIdAtom);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const allSchools = useRecoilValue(allSchoolsAtom);

  const filteredSchools = useMemo(() => {
    const schools =
      loggedInStaff?.email.split("@")[1] === "willowed.org" && loggedInStaff.email !== "demo-staff@willowed.org"
        ? allSchools
        : allSchools.filter((school) => school.districtId === loggedInStaff?.districtId);
    return schools.map((school) => (
      <MenuItem key={school.id} value={school.id}>
        {school.name}
      </MenuItem>
    ));
  }, [loggedInStaff, allSchools]);

  if (!loggedInStaff) {
    return null;
  }

  return (
    <Select value={selectedSchoolId} onChange={(e) => setSelectedSchoolId(e.target.value as string)}>
      {filteredSchools}
    </Select>
  );
};

export default StaffSchoolSelect;
