import { Box } from "@mui/material";
import ExploreProgramsContainer from "../components/explorePrograms/ExploreProgramsContainer";

const ExploreProgramsPage = () => {
  return (
    <>
      <Box>
        <ExploreProgramsContainer />
      </Box>
    </>
  );
};

export default ExploreProgramsPage;
