import Overview from "./Overview";
import StudentTableAndFiltersContainer from "./StudentTableAndFilters";
import { Box } from "@mui/material";

const StaffDashboardContainer = () => {
  return (
    <>
      <Box sx={{ display: "flex", backgroundColor: "#fafafa", height: "calc(100VH - 64px)", overflowY: "auto" }}>
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Box sx={{ p: 2 }}>
            <Overview />
            <StudentTableAndFiltersContainer />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default StaffDashboardContainer;
