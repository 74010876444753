import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FunctionName } from "../../types/enums";
import { ProviderProgramRecordTwo, ProviderTwoRecord } from "../../types/types";
import { fetchData } from "../../utils/fetchUtils";
import useWindowDimensions from "../responsiveLayout/useWindowDimensions";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import useLogger from "../logging/useLogger";
import { LogEventType } from "../../types/logEnums";

type Props = {
  providerId: string;
};

const useProviderDetailsDialog = ({ providerId }: Props) => {
  const navigate = useNavigate();
  const storage = getStorage();
  const { width } = useWindowDimensions();
  const [currentProvider, setCurrentProvider] = useState<ProviderTwoRecord | null>(null);
  const [programsByProvider, setProgramsByProvider] = useState<ProviderProgramRecordTwo[]>([]);
  const [getProviderTrigger, setGetProviderTrigger] = useState(false);
  const [providerImageUrl, setProviderImageUrl] = useState<string | null>(null);
  const [selectedLearnMoreProgram, setSelectedLearnMoreProgram] = useState<ProviderProgramRecordTwo | null>(null);
  const { submitLog } = useLogger();

  const mobile = width < 900;

  const fetchProviderImageUrl = useCallback(
    async (providerId: string) => {
      try {
        const filePath = `public/college-images/${providerId}.jpg`;
        const storageRef = ref(storage, filePath);
        const url = await getDownloadURL(storageRef);
        setProviderImageUrl(url);
      } catch (error) {
        setProviderImageUrl(null);
        submitLog({
          eventType: LogEventType.DOWNLOAD_PROVIDER_IMAGE_ERROR,
          file: "useProviderDetailsDialog.ts",
          error: error,
        });
      }
    },
    [storage, submitLog],
  );

  useEffect(() => {
    if (providerId) {
      fetchProviderImageUrl(providerId);
    }
  }, [fetchProviderImageUrl, providerId]);

  useEffect(() => {
    const getProvider = async () => {
      const results = await fetchData({
        functionName: FunctionName.GET_PROVIDER_BY_ID,
        payload: { providerId: providerId },
      });
      const { provider } = (await results.json()) as {
        provider: ProviderTwoRecord;
      };
      setCurrentProvider(provider ? provider : null);
    };

    const getProgramsByProviderId = async () => {
      const results = await fetchData({
        functionName: FunctionName.GET_PROGRAMS_AND_PROVIDER_BY_PROVIDER_ID,
        payload: { providerId: providerId },
      });
      const matchedResults = await results.json();
      setProgramsByProvider(matchedResults.programs);
    };

    getProvider();
    getProgramsByProviderId();
  }, [providerId, getProviderTrigger]);

  const navigateBack = () => {
    navigate(-1);
  };

  return {
    mobile,
    currentProvider,
    programsByProvider,
    navigateBack,
    setGetProviderTrigger,
    selectedLearnMoreProgram,
    setSelectedLearnMoreProgram,
    providerImageUrl,
  };
};

export default useProviderDetailsDialog;
