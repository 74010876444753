import { Box, Button, Chip, Grid, Paper, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { Color, FunctionName, UserType } from "../../../types/enums";
import { ProviderProgramRecordTwo } from "../../../types/types";
import Reactions from "../../../../students/components/programCard/Reactions";
import FeatherIcon from "../../../assets/icons/featherIcons/FeatherIcon";
import { formatCurrency, formatProgramName } from "../../../utils/formatUtils";
import WhatIsROIDialog from "../../whatIsROI/WhatIsROIDialog";
import WhatIsRTSSchool from "../../whatIsRTS/WhatIsRTSDialog";
import QualityMeasures from "../../../../students/components/programCard/QualityMeasures";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { loggedInStudentAtom, userTypeAtom } from "../../../recoil/userAtoms";
import infoIcon from "../../../assets/icons/info.svg";
import useProgramCard from "../../../hooks/programCard/useProgramCard";
import AutoAdmit from "../../../../students/components/programCard/AutoAdmit";
import ExpandableText from "../../expandableText/ExpandableText";
import { selectedProgramForRecommendationsAtom } from "../../../../staff/recoil/programAtoms";
import { fetchData } from "../../../utils/fetchUtils";
import useLogger from "../../../hooks/logging/useLogger";
import { LogEventType } from "../../../types/logEnums";

type ProgramCardProps = {
  program: ProviderProgramRecordTwo;
  setSelectedProgram?: Dispatch<SetStateAction<ProviderProgramRecordTwo | null>>;
  setRecommendOpen?: Dispatch<SetStateAction<boolean>>;
  setSelectedLearnMoreProgram?: Dispatch<SetStateAction<ProviderProgramRecordTwo | null>>;
};

type AccessLevelProps = {
  gpaValue: number | null;
  gpaMax: number | null;
  act25th: number | null;
  act75th: number | null;
  admissionPercentCalculated: number;
  act: string | null;
  // openAdmin: bool ?
};

type ConversionTableRow = {
  gpaPercent: number;
  act: number;
  safetyCutoff: number;
  reachCutoff: number;
};

const ProgramCard = ({ program, setSelectedLearnMoreProgram }: ProgramCardProps) => {
  const setSelectedProgramForRecommendations = useSetRecoilState(selectedProgramForRecommendationsAtom);
  const [whatIsROIOpen, setWhatIsROIOpen] = useState(false);
  const [whatIsRTSSchoolOpen, setWhatIsRTSSchoolOpen] = useState(false);
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const [personalizedCost, setPersonalizedCost] = useState<number | null>(null);
  const userType = useRecoilValue(userTypeAtom);

  const { submitLog } = useLogger();

  const student = userType === UserType.STUDENT;

  const getPersonalizedCost = useCallback(async () => {
    if (!loggedInStudent || personalizedCost !== null) return;
    if (program.totalCostCalculated === 0) {
      setPersonalizedCost(0);
      return;
    }
    if (program.providerId.length > 8) {
      setPersonalizedCost(null);
      return;
    }
    try {
      if (!loggedInStudent.efc) {
        setPersonalizedCost(null);
        return;
      }
      const dataToSubmit = {
        school: {
          ipedsId: program.providerId,
        },
        student: {
          address: {
            city: loggedInStudent.address.city,
            state: loggedInStudent.address.state,
            zip: loggedInStudent.address.zip,
          },
          profile: {
            efc: Math.round(loggedInStudent.efc ? loggedInStudent.efc : 0),
            act: loggedInStudent.act ? loggedInStudent.act : undefined,
            sat: loggedInStudent.sat ? Math.round(parseInt(loggedInStudent.sat) / 10) * 10 : undefined,
            gpa: {
              highSchool:
                loggedInStudent.gpaValue && loggedInStudent.gpaMax
                  ? (loggedInStudent.gpaValue * 4) / loggedInStudent.gpaMax
                  : undefined,
            },
          },
        },
      };
      if (student) {
        const userId = loggedInStudent.id;
        const response = await fetchData({
          functionName: FunctionName.GET_PERSONALIZED_TUITION_QUOTE,
          payload: {
            dataToSubmit,
            userId,
          },
        });

        if (!response) {
          setPersonalizedCost(null);
          return;
        }

        const jsonResponse = await response.json();
        const costValue = jsonResponse.cost;

        if (!costValue) {
          setPersonalizedCost(null);
          return;
        }

        setPersonalizedCost(costValue);
      }
    } catch (error) {
      setPersonalizedCost(null);
      submitLog({
        error: error instanceof Error ? error : new Error("Error getting personalized quote"),
        eventType: LogEventType.GET_PERSONALIZED_TUITION_QUOTE,
        file: "ProgramCard.tsx",
      });
    }
  }, [loggedInStudent, personalizedCost, program.providerId, program.totalCostCalculated, submitLog, student]);

  useEffect(() => {
    getPersonalizedCost();
  }, [getPersonalizedCost]);

  const handleLearnMore = useCallback(() => {
    if (!setSelectedLearnMoreProgram) return;
    setSelectedLearnMoreProgram(program);
  }, [program, setSelectedLearnMoreProgram]);

  const handleRecommendClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setSelectedProgramForRecommendations(program);
    },
    [program, setSelectedProgramForRecommendations],
  );

  const generateAccessLevel = ({
    gpaValue,
    gpaMax,
    act25th,
    act75th,
    admissionPercentCalculated,
    act,
  }: AccessLevelProps) => {
    // Ensure GPA percentage is within the valid range (0 to 100)
    if (admissionPercentCalculated > 0.9) return "Safety";
    const actNumber = act ? parseInt(act) : null;
    const gpaPercent = gpaValue && gpaMax ? gpaValue / gpaMax : null;
    if ((!gpaPercent || gpaPercent < 0 || gpaPercent > 1) && !act) {
      return "Unknown";
    }
    if ((!act25th || !act75th) && !admissionPercentCalculated) {
      return "Unknown";
    }

    // Define the conversion table
    const conversionTable: ConversionTableRow[] = [
      { gpaPercent: 36 / 36, act: 36, safetyCutoff: 0.5, reachCutoff: 0.1 },
      { gpaPercent: 35 / 36, act: 35, safetyCutoff: 0.5, reachCutoff: 0.15 },
      { gpaPercent: 34 / 36, act: 34, safetyCutoff: 0.55, reachCutoff: 0.15 },
      { gpaPercent: 33 / 36, act: 33, safetyCutoff: 0.55, reachCutoff: 0.2 },
      { gpaPercent: 32 / 36, act: 32, safetyCutoff: 0.55, reachCutoff: 0.2 },
      { gpaPercent: 31 / 36, act: 31, safetyCutoff: 0.55, reachCutoff: 0.25 },
      { gpaPercent: 30 / 36, act: 30, safetyCutoff: 0.6, reachCutoff: 0.25 },
      { gpaPercent: 29 / 36, act: 29, safetyCutoff: 0.6, reachCutoff: 0.3 },
      { gpaPercent: 28 / 36, act: 28, safetyCutoff: 0.6, reachCutoff: 0.3 },
      { gpaPercent: 27 / 36, act: 27, safetyCutoff: 0.6, reachCutoff: 0.35 },
      { gpaPercent: 26 / 36, act: 26, safetyCutoff: 0.65, reachCutoff: 0.35 },
      { gpaPercent: 25 / 36, act: 25, safetyCutoff: 0.65, reachCutoff: 0.4 },
      { gpaPercent: 24 / 36, act: 24, safetyCutoff: 0.65, reachCutoff: 0.45 },
      { gpaPercent: 23 / 36, act: 23, safetyCutoff: 0.65, reachCutoff: 0.5 },
      { gpaPercent: 22 / 36, act: 22, safetyCutoff: 0.7, reachCutoff: 0.55 },
      { gpaPercent: 21 / 36, act: 21, safetyCutoff: 0.7, reachCutoff: 0.6 },
      { gpaPercent: 20 / 36, act: 20, safetyCutoff: 0.75, reachCutoff: 0.7 },
      { gpaPercent: 19 / 36, act: 19, safetyCutoff: 0.75, reachCutoff: 0.7 },
      { gpaPercent: 18 / 36, act: 18, safetyCutoff: 0.8, reachCutoff: 0.7 },
      { gpaPercent: 17 / 36, act: 17, safetyCutoff: 0.8, reachCutoff: 0.75 },
      { gpaPercent: 16 / 36, act: 16, safetyCutoff: 0.85, reachCutoff: 0.8 },
      { gpaPercent: 15 / 36, act: 15, safetyCutoff: 0.85, reachCutoff: 0.85 },
      { gpaPercent: 14 / 36, act: 14, safetyCutoff: 0.9, reachCutoff: 0.9 },
      { gpaPercent: 13 / 36, act: 13, safetyCutoff: 0.9, reachCutoff: 0.9 },
      { gpaPercent: 12 / 36, act: 12, safetyCutoff: 0.95, reachCutoff: 0.9 },
      { gpaPercent: 11 / 36, act: 11, safetyCutoff: 0.95, reachCutoff: 0.9 },
    ];

    // Find the closest GPA percentage in the conversion table
    let closestGpaPercent: ConversionTableRow | null = null;
    if (gpaPercent && gpaPercent > 0 && gpaPercent < 1) {
      closestGpaPercent = conversionTable.reduce((prev, curr) =>
        Math.abs(curr.gpaPercent - gpaPercent) < Math.abs(prev.gpaPercent - gpaPercent) ? curr : prev,
      );
    }

    if (actNumber && act25th && act75th) {
      if (actNumber < act25th) {
        return "Reach";
      } else if (actNumber >= act25th && actNumber <= act75th) {
        return "Target";
      } else if (actNumber > act75th) {
        return "Safety";
      } else {
        return "Unknown";
      }
    }
    if (closestGpaPercent && act25th && act75th) {
      if (closestGpaPercent.act < act25th) {
        return "Reach";
      } else if (closestGpaPercent.act >= act25th && closestGpaPercent.act <= act75th) {
        return "Target";
      } else if (closestGpaPercent.act > act75th) {
        return "Safety";
      } else {
        return "Unknown";
      }
    }
    if ((!act25th || !act75th) && admissionPercentCalculated && closestGpaPercent) {
      if (admissionPercentCalculated > closestGpaPercent.safetyCutoff) {
        return "Safety";
      } else if (
        admissionPercentCalculated <= closestGpaPercent.safetyCutoff &&
        admissionPercentCalculated >= closestGpaPercent.reachCutoff
      ) {
        return "Target";
      } else if (admissionPercentCalculated < closestGpaPercent.reachCutoff) {
        return "Reach";
      } else {
        return "Unknown";
      }
    }

    return "Unknown";
  };

  const calculateStartingSalary = useMemo(
    () => (program: ProviderProgramRecordTwo) => {
      if (program && program.programEarningsCalculated) {
        return formatCurrency({ amount: program.programEarningsCalculated });
      } else if (program && program.provider1yrEarnings) {
        return formatCurrency({ amount: program.provider1yrEarnings });
      } else {
        return "Unknown";
      }
    },
    [],
  );

  const InnerView = () => (
    <Paper
      sx={{
        backgroundColor: Color.WHITE,
        color: Color.CHALKBOARD,
        pt: 2,
        maxWidth: 450,
        borderRadius: 4,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", px: 2 }}>
        <Chip label={program.credentialNameCalculated} color="surfaceGreen" />
        {student && <Reactions programId={program.id} />}
      </Box>
      <Box sx={{ px: 2 }}>
        <Typography component="h6" variant="cardProviderName">{`${program.providerName}`}</Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FeatherIcon icon="location" width="16px" />
          <Typography component="div" variant="cardLocation">{`${program.city}, ${program.state}`}</Typography>
        </Box>
        <Typography variant="h4">{formatProgramName({ name: program.programName })}</Typography>
        {student && (
          <Box>
            {loggedInStudent?.willowRecommendedProgramIds.includes(program.id) && (
              <Chip
                label="Willow Recommended"
                size="medium"
                sx={{
                  mt: 1,
                  backgroundColor: Color.MINT,
                  color: Color.SURFACE_GREEN,
                }}
              />
            )}
            {loggedInStudent?.staffRecommendedProgramIds.includes(program.id) && (
              <Chip
                label="Staff Recommended"
                size="medium"
                sx={{
                  mt: 1,
                  ml: 1,
                  backgroundColor: Color.ORANGE_700,
                  color: Color.BLACK,
                }}
              />
            )}
          </Box>
        )}
        {program.programShortDescription && (
          <ExpandableText
            content={program.programShortDescription}
            maxLength={95}
            sx={{ color: Color.CHALKBOARD, mt: 1 }}
          />
        )}
      </Box>
      <Box sx={{ px: 2, pb: 1, pt: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Typography sx={{ fontWeight: 600 }} variant="h6">
              {program.totalCostCalculated === 0
                ? "Free"
                : personalizedCost !== null
                  ? formatCurrency({ amount: personalizedCost })
                  : program.totalCostCalculated !== null
                    ? formatCurrency({ amount: program.totalCostCalculated })
                    : "Unknown"}
            </Typography>
            {personalizedCost !== null && (
              <>
                <Typography variant="body2" sx={{ mt: "-2px" }}>
                  This cost is personalized based on all your information.
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 600, color: "grey", mt: "-2px" }}>
                  {formatCurrency({ amount: program.totalCostCalculated })}
                </Typography>
                <Typography variant="body1" sx={{ color: "grey", mt: "-2px" }}>
                  Total Estimated Cost without financial aid
                </Typography>
              </>
            )}
            {program.totalCostCalculated !== null && personalizedCost === null && (
              <Typography variant="body1" sx={{ mt: "-2px" }}>
                Total Estimated Cost
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography sx={{ fontWeight: 600 }} variant="h6">
              {calculateStartingSalary(program)}
            </Typography>
            <Typography variant="body1" sx={{ mt: "-2px" }}>
              Average starting salary
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography sx={{ fontWeight: 600 }} variant="h6">
              {program.durationDisplayCalculated}
            </Typography>
            <Typography variant="body1" sx={{ mt: "-2px" }}>
              Length of program
            </Typography>
          </Grid>
          {student && loggedInStudent && (
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontWeight: 600 }} variant="h6">
                {generateAccessLevel({
                  gpaValue: loggedInStudent.gpaValue,
                  gpaMax: loggedInStudent.gpaMax,
                  act25th: program.act25th,
                  act75th: program.act75th,
                  admissionPercentCalculated: program.admissionPercentCalculated,
                  act: loggedInStudent.act,
                })}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body1">Fit</Typography>
                <img
                  src={infoIcon}
                  alt="info"
                  width="12px"
                  height="12px"
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                  onClick={() => setWhatIsRTSSchoolOpen(true)}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      <QualityMeasures program={program} setWhatIsROIOpen={setWhatIsROIOpen} personalizedCost={personalizedCost} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          py: 2,
        }}
      >
        <Button variant="outlined" onClick={handleLearnMore}>
          Learn More
        </Button>
        {!student && (
          <Button variant="outlined" onClick={handleRecommendClick}>
            Recommend To Students
          </Button>
        )}
      </Box>
    </Paper>
  );

  // TODO: Should auto admit be disabled?
  const AutoAdmitWrapper = () => (
    <AutoAdmit program={program}>
      <InnerView />
    </AutoAdmit>
  );

  return (
    <>
      {program && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {/* Custom boarder & messsage around programs that have auto-admit turned on */}
          {student ? <AutoAdmitWrapper /> : <InnerView />}
        </Box>
      )}

      <WhatIsROIDialog open={whatIsROIOpen} setOpen={setWhatIsROIOpen} />
      <WhatIsRTSSchool open={whatIsRTSSchoolOpen} setOpen={setWhatIsRTSSchoolOpen} />
    </>
  );
};

export default ProgramCard;
