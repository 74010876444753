import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { Typography } from "@mui/material";
import { StaffDashboardStudentRow } from "../../../shared/types/types";
import { AvatarCell } from "../../components/dashboard/AvatarCell";
import { GradeLevel } from "../../../shared/types/enums";
import personalityTypeConverter from "../../../shared/assets/data/personalityTitleConverter.json";

// Only move to a library if I'm going to reuse it
const useStudentTableColumns = () => {
  const formatGPA = (gpaValue: number | null, gpaMax: number | null) => {
    if (gpaValue && gpaMax) {
      return `${gpaValue} out of ${gpaMax}`;
    } else {
      return "-";
    }
  };

  const formatPersonalityType = (type: string) => {
    const personalityTypeObject = personalityTypeConverter as {
      [key: string]: string;
    };
    const personalityType = personalityTypeObject[type] ? personalityTypeObject[type] : "Quiz Not Completed";
    return personalityType;
  };

  const staffColumns: GridColDef<StaffDashboardStudentRow>[] = [
    {
      field: "avatar",
      headerName: "",
      headerClassName: "tableHeader",
      sortable: false,
      width: 80,
      cellClassName: "profilePictureCell",
      renderCell: (params: GridRenderCellParams<StaffDashboardStudentRow>) => <AvatarCell params={params} />,
    },
    {
      field: "firstName",
      headerName: "First Name",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 100,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 100,
    },
    {
      field: "gpaValue",
      headerName: "GPA",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 120,
      renderCell: (params: any) => {
        const gpaMax = params.row.gpaMax;
        const gpaValue = params.row.gpaValue;
        if (gpaValue && gpaMax) {
          return formatGPA(gpaValue, gpaMax);
        }
        return "-";
      },
    },
    {
      field: "grade",
      headerName: "Grade",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 120,
      valueGetter: (value: string) => {
        if (value === GradeLevel.NINTH) return 9;
        if (value === GradeLevel.TENTH) return 10;
        if (value === GradeLevel.ELEVENTH) return 11;
        if (value === GradeLevel.TWELFTH) return 12;
        return 0;
      },
      renderCell: (params: GridRenderCellParams<StaffDashboardStudentRow>) => {
        const grade = params.row.grade;
        if (grade === GradeLevel.NINTH) return 9;
        if (grade === GradeLevel.TENTH) return 10;
        if (grade === GradeLevel.ELEVENTH) return 11;
        if (grade === GradeLevel.TWELFTH) return 12;
        return 0;
      },
    },
    {
      field: "personalityType",
      headerName: "Personality Type",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 180,
      valueGetter: (value: string) => {
        return formatPersonalityType(value || "");
      },
      renderCell: (params: GridRenderCellParams<StaffDashboardStudentRow>) => {
        return formatPersonalityType(params.row.personalityType || "");
      },
    },
    {
      field: "bookmarkedPrograms",
      headerName: "# Programs",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "bookmarkedJobs",
      headerName: "# Jobs",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "myWhy",
      headerName: "My Why",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 280,
      flex: 1,
      renderCell: (params: GridRenderCellParams<StaffDashboardStudentRow>) => {
        const myWhyText = params.value?.length > 200 ? `${params.value.substring(0, 200)}...` : params.value;

        return (
          <Typography
            sx={{
              fontSize: 10,
              wordWrap: "break-word",
              whiteSpace: "normal",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            variant="body2"
          >
            {myWhyText}
          </Typography>
        );
      },
    },
    {
      field: "lastActivity",
      headerName: "Last Activity",
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      minWidth: 100,
      flex: 1,
      renderCell: (params: GridRenderCellParams<StaffDashboardStudentRow>) => {
        if (!params.row.lastActivity) return null;
        const lastActivityDate = new Date(params.row.lastActivity);
        const millisecondsInDay = 1000 * 60 * 60 * 24;
        const daysAgo = Math.floor((Date.now() - lastActivityDate.getTime()) / millisecondsInDay);

        return (
          <Typography sx={{ fontSize: 10, color: daysAgo > 7 ? "red" : "black" }} variant="body2">
            {`${daysAgo} days ago`}
          </Typography>
        );
      },
    },
  ];

  return { staffColumns };
};

export default useStudentTableColumns;
