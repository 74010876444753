import React from "react";
import Box from "@mui/material/Box";
import { Checkbox, Collapse, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { GradeLevel } from "../../../shared/types/enums";
import useStudentFiltersPanel from "../../hooks/dashboard/useStudentFiltersPanel";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const StageFilterOptions = [
  { value: "not-finished-quiz", label: "Not Finished Quiz" },
  { value: "finished-quiz", label: "Finished Quiz" },
  { value: "bookmarked-careers", label: "Bookmarked Careers" },
  { value: "bookmarked-programs", label: "Bookmarked Programs" },
];
const twentyFourHours = 24 * 60 * 60 * 1000;
const fourtyEightHours = 48 * 60 * 60 * 1000;
const oneWeek = 7 * 24 * 60 * 60 * 1000;
const oneMonth = 31 * 24 * 60 * 60 * 1000;
const GPAFilterOptions = [
  { value: "90up", label: "Above 90%" },
  { value: "75to89", label: "75% - 90%" },
  { value: "50to74", label: "50% - 75%" },
  { value: "below50", label: "Below 50%" },
];

const StudentFiltersPanel = ({ open }: Props) => {
  const {
    filterStages,
    handleStageChange,
    lastActivity,
    handleLastActivityChange,
    GPA,
    handleGPAChange,
    handleGradeChange,
    selectedGrades,
  } = useStudentFiltersPanel();
  return (
    <>
      <Box>
        <Collapse in={open}>
          <Box sx={{ mt: 2 }}>
            <Typography sx={{ mb: 1 }} variant="h3">
              Filters
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <Box sx={{ borderRight: "1px solid #ddd" }}>
                  <Typography sx={{ fontWeight: 600, mb: 1, fontSize: 16 }}>Stages</Typography>
                  {StageFilterOptions.map((stage) => (
                    <Box sx={{ display: "flex", alignItems: "center" }} key={stage.value}>
                      <Checkbox
                        checked={filterStages.includes(stage.value)}
                        onChange={() => handleStageChange(stage.value)}
                      />
                      <Typography>{stage.label}</Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box sx={{ borderRight: "1px solid #ddd" }}>
                  <Typography sx={{ fontWeight: 600, mb: 1, fontSize: 16 }}>Active In The</Typography>
                  <RadioGroup value={lastActivity} onChange={handleLastActivityChange}>
                    <FormControlLabel value={twentyFourHours} control={<Radio />} label="Last 24 Hours" />
                    <FormControlLabel value={fourtyEightHours} control={<Radio />} label="Last 48 Hours" />
                    <FormControlLabel value={oneWeek} control={<Radio />} label="Last Week" />
                    <FormControlLabel value={oneMonth} control={<Radio />} label="Last Month" />
                    <FormControlLabel value={999999999999} control={<Radio />} label="All Time" />
                  </RadioGroup>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box sx={{ borderRight: "1px solid #ddd" }}>
                  <Typography sx={{ fontWeight: 600, mb: 1, fontSize: 16 }}>GPA (in percentage)</Typography>
                  {GPAFilterOptions.map((option) => (
                    <Box sx={{ display: "flex", alignItems: "center" }} key={`key-${option.value}`}>
                      <Checkbox checked={GPA.includes(option.value)} onChange={handleGPAChange} value={option.value} />

                      <Typography>{option.label}</Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box>
                  <Typography sx={{ fontWeight: 600, mb: 1, fontSize: 16 }}>Grade Level</Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      checked={selectedGrades.includes(GradeLevel.NINTH)}
                      onChange={handleGradeChange}
                      value={GradeLevel.NINTH}
                    />

                    <Typography>{GradeLevel.NINTH}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      checked={selectedGrades.includes(GradeLevel.TENTH)}
                      onChange={handleGradeChange}
                      value={GradeLevel.TENTH}
                    />

                    <Typography>{GradeLevel.TENTH}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      checked={selectedGrades.includes(GradeLevel.ELEVENTH)}
                      onChange={handleGradeChange}
                      value={GradeLevel.ELEVENTH}
                    />

                    <Typography>{GradeLevel.ELEVENTH}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      checked={selectedGrades.includes(GradeLevel.TWELFTH)}
                      onChange={handleGradeChange}
                      value={GradeLevel.TWELFTH}
                    />

                    <Typography>{GradeLevel.TWELFTH}</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </Box>
    </>
  );
};

export default StudentFiltersPanel;
